import React, { useEffect, useState } from 'react';
import CodePromoMarketplaceAddStyle from './index.style';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    PageHeader,
    Row,
    Select,
} from 'antd';
import DebounceSelectComponent from '../../../../components/DebounceSelect';
import serviceCodePromoMarkerplace from '../index.service';
import moment from 'moment';
import { notifyError, notifySuccess } from '../../../../utils';

const { Option } = Select;

export default function CodePromoMarkeplaceEdit() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [typePromo, setTypePromo] = useState(1);
    const [dataPayment, setDataPayment] = useState([]);
    const [dataCC, setDataCC] = useState([]);
    const params = useParams();

    const routes = [
        {
            path: '',
            breadcrumbName: 'Code Promo Marketplace',
        },
        {
            path: '',
            breadcrumbName: 'Edit',
        },
    ];

    const [listCategory] = useState([
        { value: 'Aktivitas', label: 'Aktivitas' },
        { value: 'Kursus', label: 'Kursus' },
        { value: 'Experience', label: 'Experience' },
        { value: 'Daycare dan Pre-School', label: 'Daycare dan Pre-School' },
        { value: 'Activity Kit', label: 'Activity Kit' },
        { value: 'Kelas Online', label: 'Video Learning' },
        { value: 'Kesehatan', label: 'Kesehatan' },
        { value: 'Keluarga', label: 'Keluarga' },
        { value: 'Festival', label: 'Festival' },
        { value: 'Gratis', label: 'Gratis' },
        { value: 'Kerajinan Tangan', label: 'Kerajinan Tangan' },
        { value: 'Kognitif', label: 'Kognitif' },
        { value: 'Komunikasi', label: 'Komunikasi' },
        { value: 'Kulinari', label: 'Kulinari' },
        // { value: 'Kulwap', label: 'Kulwap' },
        { value: 'Motorik', label: 'Motorik' },
        { value: 'Olahraga', label: 'Olahraga' },
        // { value: 'Pesta', label: 'Pesta' },
        { value: 'Parenting', label: 'Parenting' },
        { value: 'Perawatan', label: 'Perawatan' },
        { value: 'Pertunjukan', label: 'Pertunjukan' },
        { value: 'Playdate', label: 'Playdate' },
        { value: 'Rekreasi', label: 'Rekreasi' },
        // { value: 'Rutin', label: 'Rutin' },
        { value: 'Sains', label: 'Sains' },
        { value: 'Seni Lukis', label: 'Seni Lukis' },
        { value: 'Seni Musik', label: 'Seni Musik' },
        { value: 'Seni Drama', label: 'Seni Drama' },
        { value: 'Seni Tari', label: 'Seni Tari' },
        { value: 'Sensorik', label: 'Sensorik' },
        { value: 'Teknologi', label: 'Teknologi' },
        { value: 'Lainnya', label: 'Lainnya' },
    ]);

    useEffect(() => {
        onGetDataPayment();
        onGetDataPromo(params.id);
    }, []);

    const getMerchantDetail = async (id) => {
        return await serviceCodePromoMarkerplace.getDetailMerchant(id);
    };

    const getProductDetail = async (id) => {
        return await serviceCodePromoMarkerplace.getDetailProduct(id);
    };

    const getUserDetail = async (id) => {
        return await serviceCodePromoMarkerplace.getDetailUsers(id);
    };

    const onGetDataPromo = async (id) => {
        const resultData = await serviceCodePromoMarkerplace.getCodePromoById(
            id,
        );
        if (resultData.isSuccess) {
            const result = resultData.response.payload;

            //MERCHANT
            if (
                result.included_merchant &&
                result.included_merchant.length > 0
            ) {
                const resultMerchant = await getMerchantDetail(
                    result.included_merchant,
                );
                result.included_merchant =
                    (resultMerchant.response.payload &&
                        resultMerchant.response.payload.length > 0 &&
                        resultMerchant.response.payload.map((item) => ({
                            label: item.name,
                            value: item.merchant_id,
                        }))) ||
                    [];
            }
            if (
                result.excluded_merchant &&
                result.excluded_merchant.length > 0
            ) {
                const resultMerchant = await getMerchantDetail(
                    result.excluded_merchant,
                );
                result.excluded_merchant =
                    (resultMerchant.response.payload &&
                        resultMerchant.response.payload.length > 0 &&
                        resultMerchant.response.payload.map((item) => ({
                            label: item.name,
                            value: item.merchant_id,
                        }))) ||
                    [];
            }

            //PRODUCT
            if (result.included_product && result.included_product.length > 0) {
                const resultProduct = await getProductDetail(
                    result.included_product,
                );
                result.included_product =
                    (resultProduct.response &&
                        resultProduct.response.length > 0 &&
                        resultProduct.response.map((item) => ({
                            label: item.activity,
                            value: item.product_id,
                        }))) ||
                    [];
            }
            if (result.excluded_product && result.excluded_product.length > 0) {
                const resultProduct = await getProductDetail(
                    result.excluded_product,
                );
                result.excluded_product =
                    (resultProduct.response &&
                        resultProduct.response.length > 0 &&
                        resultProduct.response.map((item) => ({
                            label: item.activity,
                            value: item.product_id,
                        }))) ||
                    [];
            }

            //USER
            if (result.included_users && result.included_users.length > 0) {
                const resultUser = await getUserDetail(result.included_users);
                result.included_users =
                    (resultUser.response.payload &&
                        resultUser.response.payload.length > 0 &&
                        resultUser.response.payload.map((item) => ({
                            label: item.name,
                            value: item.user_id,
                        }))) ||
                    [];
            }
            if (result.excluded_users && result.excluded_users.length > 0) {
                const resultUser = await getUserDetail(result.excluded_users);
                result.excluded_users =
                    (resultUser.response.payload &&
                        resultUser.response.payload.length > 0 &&
                        resultUser.response.payload.map((item) => ({
                            label: item.name,
                            value: item.user_id,
                        }))) ||
                    [];
            }

            result.expired_date = moment(result.expired_date);
            result.start_date = moment(result.start_date);
            result.type = result.type === 'Percentage' ? 1 : 2;
            setTypePromo(result.type === 'Percentage' ? 1 : 2);
            form.setFieldsValue(result);
        }
    };

    const onFinish = async (value) => {
        const resultValue = {
            id: form.getFieldValue('id'),
            name: value.name,
            type: value.type === 1 ? 'Percentage' : 'Fixed',
            amount: value.amount,
            maximum_discount: value.maximum_discount,
            minimum_cart_amount: value.minimum_cart_amount,
            maximum_cart_amount: value.maximum_cart_amount,
            title: value.title,
            description: value.description,
            image: value.image,
            image_two: value.image_two,

            included_product:
                (value.included_product?.length > 0 &&
                    value.included_product.map((item) => Number(item.value))) ||
                [],
            excluded_product:
                (value.excluded_product?.length > 0 &&
                    value.excluded_product.map((item) => Number(item.value))) ||
                [],
            included_merchant:
                (value.included_merchant?.length > 0 &&
                    value.included_merchant.map((item) =>
                        Number(item.value),
                    )) ||
                [],
            excluded_merchant:
                (value.excluded_merchant?.length > 0 &&
                    value.excluded_merchant.map((item) =>
                        Number(item.value),
                    )) ||
                [],
            included_category: value.included_category || [],
            excluded_category: value.excluded_category || [],

            included_payment: value.included_payment || [],
            excluded_payment: value.excluded_payment || [],
            included_cc: value.included_cc || [],
            excluded_cc: value.excluded_cc || [],
            included_users:
                (value.included_users?.length > 0 &&
                    value.included_users.map((item) => Number(item.value))) ||
                [],
            excluded_users:
                (value.excluded_users?.length > 0 &&
                    value.excluded_users.map((item) => Number(item.value))) ||
                [],
            premium_user: value.premium_user,
            combine: 'Y',

            minimum_ticket: value.minimum_ticket,
            maximum_ticket: value.maximum_ticket,
            coupon_total: value.coupon_total,
            coupon_user: value.coupon_user,
            coupon_exist: value.coupon_exist,
            start_date: moment(value.start_date).toISOString(),
            expired_date: moment(value.expired_date).toISOString(),
            position: value.position,
            button_text: value.button_text,
            button_url: value.button_url,
            is_shown: value.is_shown,
        };
        const resultSubmit =
            await serviceCodePromoMarkerplace.updatePromoMarketplace(
                resultValue,
            );

        if (resultSubmit.isSuccess) {
            notifySuccess('Success update code promo');
            history.push('/codepromo/marketplace/list');
        } else {
            notifyError(resultSubmit.message);
        }
    };

    const onGetDataPayment = async () => {
        const result = await serviceCodePromoMarkerplace.getPayment();
        if (result.isSuccess) {
            const resultPayment = result.response.payload.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            const resultCC = result.response.payload
                .filter((item) => item.code.includes('creditcard'))
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            setDataPayment(resultPayment);
            setDataCC(resultCC);
        }
    };

    return (
        <CodePromoMarketplaceAddStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Edit Code Promo Marketplace"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <h3 className="title">Code Promo</h3>
                <Card style={{ borderRadius: '24px', marginBottom: '42px' }}>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="name"
                                label="Nama"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Nama Promo"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="type"
                                label="Tipe Promo"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                initialValue={1}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pilih Tipe Promo',
                                    },
                                ]}>
                                <Select
                                    defaultValue={1}
                                    onChange={(value) => setTypePromo(value)}
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={1}>
                                        Diskon Presentase
                                    </Option>
                                    <Option key={2} value={2}>
                                        Diskon Tetap
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                extra={
                                    typePromo === 1
                                        ? 'Masukkan angka dalam diskon %'
                                        : 'Masukkan angka dalam rupiah'
                                }
                                name="amount"
                                label="Jumlah Diskon"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jumlah Diskon wajib diisi',
                                    },
                                ]}>
                                {typePromo === 1 ? (
                                    <InputNumber
                                        addonAfter="%"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    <InputNumber
                                        style={{
                                            width: '100%',
                                        }}
                                        formatter={(value) =>
                                            `${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                '.',
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/Rp\s?|(\.*)/g, '')
                                        }
                                        addonBefore="Rp. "
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="maximum_discount"
                                label="Maksimal Diskon"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{
                                        width: '100%',
                                    }}
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Rp\s?|(\.*)/g, '')
                                    }
                                    addonBefore="Rp. "
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="minimum_cart_amount"
                                label="Minimal Cart Amount"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{
                                        width: '100%',
                                    }}
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Rp\s?|(\.*)/g, '')
                                    }
                                    addonBefore="Rp. "
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="maximum_cart_amount"
                                label="Maximum Cart Amount"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{
                                        width: '100%',
                                    }}
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Rp\s?|(\.*)/g, '')
                                    }
                                    addonBefore="Rp. "
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                extra="Judul kode promo yang ditampilkan dibanner"
                                name="title"
                                label="Judul"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Judul wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan Judul Code Promo"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="description"
                                label="Deskripsi"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Deskripsi wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan Deskripsi promo"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="image"
                                label="Upload Image Main Poster"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan URL Image Poster"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="image_two"
                                label="Upload Banner Image in Promo Details"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan URL Image Poster"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <h3 className="title">Product & Merchant</h3>
                <Card style={{ borderRadius: '24px', marginBottom: '42px' }}>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="included_product"
                                label="Include Product"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include Product wajib diisi',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) =>
                                        form.setFieldValue(
                                            'included_product',
                                            value,
                                        )
                                    }
                                    value={form.getFieldValue(
                                        'included_product',
                                    )}
                                    placeholder="Select included product"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                title: value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoMarkerplace.getProduct(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.title,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="excluded_product"
                                label="Exclude Product"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude Product wajib diisi',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) =>
                                        form.setFieldValue(
                                            'excluded_product',
                                            value,
                                        )
                                    }
                                    value={form.getFieldValue(
                                        'excluded_product',
                                    )}
                                    placeholder="Select excluded product"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                title: value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoMarkerplace.getProduct(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.title,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="included_merchant"
                                label="Include Merchant"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include Merchant wajib diisi',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'included_merchant',
                                            value,
                                        );
                                    }}
                                    value={form.getFieldValue(
                                        'included_merchant',
                                    )}
                                    placeholder="Select included merchant"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'm.name': value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoMarkerplace.getMerchant(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.name,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="excluded_merchant"
                                label="Exclude Merchant"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude Merchant wajib diisi',
                                //         type: 'array',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'excluded_merchant',
                                            value,
                                        );
                                    }}
                                    value={form.getFieldValue(
                                        'excluded_merchant',
                                    )}
                                    placeholder="Select excluded merchant"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'm.name': value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoMarkerplace.getMerchant(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.name,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="included_category"
                                label="Include Category"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include Category wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select include category"
                                    onChange={(value) => {}}
                                    optionLabelProp="label">
                                    {listCategory.map((item) => (
                                        <Option
                                            value={item.value}
                                            label={item.label}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="excluded_category"
                                label="Exclude Category"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude Category wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select exclude category"
                                    onChange={(value) => {}}
                                    optionLabelProp="label">
                                    {listCategory.map((item) => (
                                        <Option
                                            value={item.value}
                                            label={item.label}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <h3 className="title">Payment & User</h3>
                <Card style={{ borderRadius: '24px', marginBottom: '42px' }}>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="include_payment"
                                label="Include Payment"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include Payment wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataPayment}
                                    style={{ width: '100%' }}
                                    placeholder="Select Payment"></Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="excluded_payment"
                                label="Exclude Payment"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude Payment wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataPayment}
                                    style={{ width: '100%' }}
                                    placeholder="Select Payment"></Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="include_cc_bin"
                                label="Include CC"
                                extra="Masukkan Include CC format [id_cc]"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include CC wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataCC}
                                    style={{ width: '100%' }}
                                    placeholder="Select CC"></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                extra="Masukkan Exclude CC format [id_cc]"
                                name="exclude_cc_bin"
                                label="Exclude CC"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude CC wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataCC}
                                    style={{ width: '100%' }}
                                    placeholder="Select CC"></Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="include_users"
                                label="Include Users"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include Users wajib diisi',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'include_users',
                                            value,
                                        );
                                    }}
                                    value={form.getFieldValue('include_users')}
                                    placeholder="Select include users"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'users.email': value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoMarkerplace.getUser(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.name,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="exclude_users"
                                label="Exclude Users"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude Users wajib diisi',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'exclude_users',
                                            value,
                                        );
                                    }}
                                    value={form.getFieldValue('exclude_users')}
                                    placeholder="Select exclude users"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'users.email': value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoMarkerplace.getUser(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.name,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="premium_user"
                                label="Premium User"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <DebounceSelectComponent
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'premium_user',
                                            value,
                                        );
                                    }}
                                    value={form.getFieldValue('premium_user')}
                                    placeholder="Select exclude users"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'users.email': value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoMarkerplace.getUser(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.name,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="combine"
                                label="Combine"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                initialValue={'Y'}>
                                <Input
                                    disabled
                                    defaultValue={'Y'}
                                    size="large"
                                    placeholder="Masukkan Include CC"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <h3 className="title">Coupon Manage</h3>
                <Card style={{ borderRadius: '24px', marginBottom: '42px' }}>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="coupon_total"
                                label="Coupon Total"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Coupon Total wajib diisi',
                                    },
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan total coupon"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="coupon_user"
                                label="Coupon User"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Coupon User wajib diisi',
                                    },
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan user coupon"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="coupon_exist"
                                label="Coupon Exist"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Coupon Exist wajib diisi',
                                    },
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan coupon exist"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="start_date"
                                label="Start Date Time"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Start Date wajib diisi',
                                    },
                                ]}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    disabledDate={(current) => {
                                        // Can not select days before today and today
                                        return (
                                            current &&
                                            current < moment().endOf('day')
                                        );
                                    }}
                                    showTime={{
                                        defaultValue: moment(
                                            '00:00:00',
                                            'HH:mm:ss',
                                        ),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="expired_date"
                                label="Expired Date Time"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Expired Date wajib diisi',
                                    },
                                ]}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    disabledDate={(current) => {
                                        // Can not select days before today and today
                                        return (
                                            current &&
                                            current < moment().endOf('day')
                                        );
                                    }}
                                    showTime={{
                                        defaultValue: moment(
                                            '00:00:00',
                                            'HH:mm:ss',
                                        ),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="position"
                                label="Position"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan position"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="button_text"
                                label="Text for Button"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan text for button"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="button_url"
                                label="URL for Button"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan url for button"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="is_shown"
                                label="Show Code"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    type="number"
                                    size="large"
                                    placeholder="Masukkan Show Code"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Form.Item name="submit">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={isLoading}
                            style={{ marginRight: 10, width: 200 }}
                            disabled={isLoading ? true : false}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                history.goBack();
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </CodePromoMarketplaceAddStyle>
    );
}
