import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    PageHeader,
    Row,
    message,
    Upload,
} from 'antd';
import { useHistory } from 'react-router-dom';
import KiddofestEdufairCreateStyle from './index.style';
import InputMultiple from '../../../../components/InputMultiple';
import serviceKiddofestEdufair from '../index.service';
import { notifyError, notifySuccess } from '../../../../utils';

export default function KiddofestEdufairCreate() {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'Kiddofest Edufair',
        },
        {
            path: '',
            breadcrumbName: 'Create',
        },
    ];

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const onFinish = async (value) => {
        const formValue = new FormData();
        formValue.append('edufair_id', value.id_edufair);
        formValue.append('address', value.address);
        formValue.append('name', value.name_brand);
        formValue.append('link', value.link);
        formValue.append('file', value?.image?.file?.originFileObj);
        const res = await serviceKiddofestEdufair.addEdufair(formValue);
        if (res.isSuccess) {
            setIsLoading(false);
            notifySuccess('Success add new banner');
            history.push('/kiddofest/edufair');
        } else {
            setIsLoading(false);
            notifyError(res.response.message);
        }
    };
    return (
        <KiddofestEdufairCreateStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Create Edufair Kiddofest"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="id_edufair"
                                label="ID Edufair"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'ID Edufair wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan ID Edufair"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="name_brand"
                                label="Name Brand"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Name Brand wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Name Brand"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="address"
                                label="Address"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Address wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Address"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="link"
                                label="Link"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputMultiple
                                    onChange={(value) => {
                                        form.setFieldsValue({ link: value });
                                    }}
                                    placeholder={'Input Link'}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Image"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Image wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onPreview={() => {}}
                                    onChange={(info) => {
                                        const isLt2M =
                                            info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error(
                                                'Image must smaller than 2MB!',
                                            );
                                        } else {
                                            setFileUpload(info.fileList);
                                        }
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}>
                                    {fileUpload.length == 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Submit
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history.goBack();
                                    }}>
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </KiddofestEdufairCreateStyle>
    );
}
