import styled from '@emotion/styled';

const TrackerUserProfileStyle = styled.div`
    display: block;
    width: 100%;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        Button {
            margin-bottom: 10px;
        }
    }
`;

export default TrackerUserProfileStyle;
