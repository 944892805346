import serviceApi from '../../../services/index';

const serviceProductCreate = {
    postPhoto: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/products/photo/upload`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    postProduct: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/products`,
            noVersion: true,
            appType: 1,
            token: true,
            params: null,
            data: data,
        });
    },
    getMerchantList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/merchants/names`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    getProvince: async () => {
        return await serviceApi({
            method: 'GET',
            url: `v1/address/province`,
            noVersion: true,
            appType: 2,
            token: true,
        });
    },
    getCity: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/address/city`,
            noVersion: true,
            appType: 2,
            token: true,
            params: params,
        });
    },
    getSubDistrict: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/address/subdistrict`,
            noVersion: true,
            appType: 2,
            token: true,
            params: params,
        });
    },
};

export default serviceProductCreate;
