import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Space, Table } from 'antd';
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { setLocale } from 'yup';
import { notifyError } from '../../../utils';
import serviceUsers from '../index.service';
import UsersListStyle from './index.style';

const UsersList = () => {
    const history = useHistory();
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
    });
    const [dataOrderList, setDataOrderList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, [queryString]);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceUsers.getUsersList(resultQuery);
        if (result.isSuccess && result.response.payload.length > 0) {
            setDataOrderList(result.response);
            setIsLoading(false);
        } else {
            if (result.response.payload.length === 0) {
                setDataOrderList([]);
            } else {
                notifyError(result.response.message);
            }
            setIsLoading(false);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => handleResetSearch(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
    };

    const handleResetFilter = () => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        setQueryString(defaultQuery);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Order List',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const columns = [
        {
            title: 'User Id',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => {
                return (
                    <Link to={`users/detail/${record.id}`}>{record.id}</Link>
                );
            },
            ...getColumnSearchProps('id_user'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('user_name'),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            filters: [
                { text: 'PAPA', value: 'Papa' },
                { text: 'MAMA', value: 'Mama' },
            ],
            filteredValue: queryString?.filters?.user_role || null,
            filterMultiple: false,
            ellipsis: true,
            align: 'center',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('user_email'),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
            ...getColumnSearchProps('user_phone_number'),
        },
        {
            title: 'Kiddo Points',
            dataIndex: 'point',
            key: 'point',
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.point
                ? queryString?.sorts?.point === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.created_at
                ? queryString?.sorts?.created_at === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
        },
        {
            title: 'Action',
            align: 'center',
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Button
                            onClick={() =>
                                history.push(`/users/detail/${record.id}`)
                            }
                            style={{ width: '100%' }}
                            type="dashed">
                            Detail
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.role != null) {
            paramResult.filters = {
                user_role: filters.role,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
    };

    return (
        <UsersListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Users List"
                    breadcrumb={{ routes }}
                    subTitle="Table of Users List "
                    style={{ paddingLeft: 0 }}
                />
                <Button onClick={handleResetFilter}>Reset Filter</Button>
            </div>
            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataOrderList.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataOrderList.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </UsersListStyle>
    );
};

export default UsersList;
