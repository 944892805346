import React, { useState, useEffect } from 'react';
import { Button, Form, message, Modal, Row, Spin, Upload } from 'antd';
import InputItem from './InputItem';
import serviceBanner from './Banner.service';
import { UploadOutlined } from '@ant-design/icons';

const FormBanner = ({ jmlInput, type }) => {
    const [bannerList, setBannerList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        handleLoadData(null, 'all');
    }, []);

    const handleUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading', key: 'updatable' });
        }
    };

    const handleSubmit = async () => {
        const result = await serviceBanner.updateBanner(bannerList);
        if (result.isSuccess) {
            modalSuccess();
        } else {
            Modal.error('Gagal Mengupdate Banner');
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil Mengupdate Banner',
            onOk: () => {
                handleLoadData(null, 'all');
                setIsUpdated(false);
            },
        });
    };

    const handleAddBanner = async (e) => {
        if (bannerList.length > 0) {
            setBannerList((prevState) => {
                prevState.map((item) => {
                    return (item.position += 1);
                });
            });

            const result = await serviceBanner.updateBanner(bannerList);

            if (result.isSuccess) {
                const formData = new FormData();
                formData.append('source', e.file);
                formData.append('category', 'pcp_banner');

                const result2 = await serviceBanner.postImage(formData);

                if (result2.isSuccess) {
                    handleLoadData(null, 'all');
                }
            } else {
                Modal.error('Gagal Mengupdate Banner');
            }
        } else {
            const formData = new FormData();
            formData.append('source', e.file);
            formData.append('category', 'pcp_banner');

            const result2 = await serviceBanner.postImage(formData);

            if (result2.isSuccess) {
                handleLoadData(null, 'all');
            }
        }
    };

    const handleLoadData = async (props, type) => {
        setLoading(true);
        const result = await serviceBanner.getHomeSlider(props, type);
        if (result.isSuccess) {
            setBannerList(result.response.payload);
        }
        setLoading(false);
    };

    const resetBanner = () => {
        setIsUpdated(false);
        handleLoadData(null, 'all');
    };

    return (
        <div>
            <Spin tip="Fetching Banner Data..." spinning={isLoading}>
                <Form
                    layout="vertical"
                    name="validate_other"
                    onFinish={handleSubmit}>
                    <Form.Item style={{ float: 'left' }}>
                        <Upload
                            accept="image/*"
                            customRequest={handleAddBanner}
                            onChange={handleUploadChange}
                            showUploadList={false}>
                            <Button icon={<UploadOutlined />}>
                                Add Banner
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item style={{ float: 'right' }}>
                        <Button
                            onClick={resetBanner}
                            style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoadingUpdate}
                            disabled={!isUpdated}>
                            Update Banner
                        </Button>
                    </Form.Item>
                    <InputItem
                        loadBanner={handleLoadData}
                        bannerList={bannerList}
                        setBannerList={setBannerList}
                        setIsUpdated={setIsUpdated}
                    />
                </Form>
            </Spin>
        </div>
    );
};

export default FormBanner;
