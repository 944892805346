import serviceApi from '../../services/index';

const serviceOrderGift = {
    getOrderGift: async (params, data) => {
        return await serviceApi({
            method: 'GET',
            url: `/gift/order`,
            version: 'v1',
            token: true,
            params: params,
            data: data,
        });
    },
    getDataGiftDetail: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/gift/order/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceOrderGift;
