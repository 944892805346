import React from 'react';
import { useContext } from 'react';
import { AppStateContext } from '../contexts/app';
import { Redirect } from 'react-router-dom';
import localStorages from '../utils/localStorages';

export default function ProtectedRoute(props) {
    const state = useContext(AppStateContext);

    let isUserLogin = localStorages().get('APP_STATE');

    if (!!!isUserLogin?.user) {
        return <Redirect to="/" />;
    }
    return props.children;
}
