import React from 'react';
import MerchantHistorySubscriptionStyle from './index.style';
import serviceMerchantHistoryPaket from './index.service';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
    Button,
    Input,
    PageHeader,
    Pagination,
    Select,
    Space,
    Table,
    Tag,
    message,
} from 'antd';
import { formatDate } from '../../../utils/format-date';
import { formatMoney } from '../../../utils/format-money';

const MerchantHistorySubscription = (props) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(10);
    const [queryString, setQueryString] = useState({
        queries: {
            page: 1,
            row: 10,
        },
    });
    const params = useParams();
    const [merchantList, setMerchantList] = useState([]);

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Merchant',
        },
        {
            path: 'list',
            breadcrumbName: 'History Paket',
        },
    ];

    const columns = [
        {
            title: 'No',
            width: 40,
            dataIndex: '',
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Tanggal Transaksi',
            dataIndex: 'start_date',
            key: 'transaction_date',
            render: (date) => formatDate(date, 'DD MMM YYYY'),
        },
        {
            title: 'Nama Paket',
            dataIndex: 'paket',
            key: 'paket_name',
        },
        {
            title: 'Nominal',
            dataIndex: 'price',
            key: 'price',
            render: (data) => formatMoney(data),
        },
        {
            title: 'Masa Berlaku',
            dataIndex: 'expired_date',
            key: 'expired_date',
            render: (date) =>
                date == '0001-01-01 00:00:00'
                    ? 'Selamanya'
                    : formatDate(date, 'DD MMM YYYY'),
        },
    ];

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (queryString) => {
        setIsLoading(true);
        let resultQuery = { ...queryString };
        const result = await serviceMerchantHistoryPaket.onGetListHistoryPaket(
            resultQuery,
            params.id,
        );
        if (result.isSuccess) {
            setMerchantList(result.response);
        }
        setIsLoading(false);
    };

    const onPaginationChange = (page, pageSize) => {
        setQueryString({
            ...queryString,
            queries: {
                page: page,
                row: pageSize,
            },
        });
        setCurrentPage(page);
        setTotalPage(pageSize);
    };

    return (
        <>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Merchant List"
                    breadcrumb={{ routes }}
                    subTitle="Table of merchant list "
                    style={{ paddingLeft: 0 }}
                />
            </div>
            <MerchantHistorySubscriptionStyle>
                <Table
                    rowKey="uid"
                    loading={isLoading}
                    bordered
                    columns={columns}
                    dataSource={merchantList}
                    pagination={false}
                />
                <div className="pagination">
                    <Pagination
                        pageSize={totalPage}
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={merchantList.length}
                        onChange={onPaginationChange}
                    />
                </div>
            </MerchantHistorySubscriptionStyle>
        </>
    );
};

export default MerchantHistorySubscription;
