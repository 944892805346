import React, { createRef, useRef } from 'react';
import LMSClassEditStyle, { WysiwygStyle } from './index.style';
import {
    Form,
    Select,
    Row,
    Col,
    PageHeader,
    Input,
    Upload,
    Button,
    message,
} from 'antd';
import ReactQuill from 'react-quill';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import serviceLMSClass from '../index.service';
import DebounceSelectComponent from '../../../../components/DebounceSelect';
import _, { result } from 'lodash';
import { notifyError, notifySuccess } from '../../../../utils';

const { Option } = Select;

const LMSClassEdit = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [type, setType] = React.useState('');
    const [valueDescription, setDescription] = React.useState('');
    const [detailSchedule, setDetailSchedule] = React.useState('');
    const [dataProduct, setDataProduct] = React.useState([]);
    const [file, setFile] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const params = useParams();

    const routes = [
        {
            path: '',
            breadcrumbName: 'Class',
        },
        {
            path: '',
            breadcrumbName: 'Edit',
        },
    ];

    const uploadButton = (
        <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    React.useEffect(() => {
        onGetDetailClass(params.id);
    }, []);

    const onGetDetailClass = async (id) => {
        const res = await serviceLMSClass.getDetailClass(id);
        if (res.isSuccess) {
            const results = res.response.payload;

            const resultMerchant = await serviceLMSClass.getDetailMerchant(
                results.merchant_id,
            );
            if (
                resultMerchant.isSuccess &&
                resultMerchant.response.payload &&
                resultMerchant.response.payload.length > 0
            ) {
                results.merchant_id = {
                    label: resultMerchant.response.payload[0].merchant_name,
                    value: resultMerchant.response.payload[0].merchant_id,
                };
            }

            const resultProduct = await serviceLMSClass.getDetailProduct(
                results.product_id,
            );
            if (
                resultProduct.isSuccess &&
                resultProduct.response.payload &&
                resultProduct.response.payload.length > 0
            ) {
                results.product_id = {
                    label: resultProduct.response.payload[0].title,
                    value: resultProduct.response.payload[0].product_id,
                };
            }

            setFile(results.image);
            setType(results.type_class);
            results.is_quiz = results.is_quiz.toUpperCase();
            results.file = results.image;
            setDescription(results.description || '');
            setDetailSchedule(results.schedule || '');
            form.setFieldsValue(results);
        }
    };

    const onFinish = async (value) => {
        setLoading(true);
        const data = new FormData();
        data.append('id', params.id);
        data.append('merchant_id', value.merchant_id.value);
        data.append('merchant_name', value.merchant_id.label);
        data.append('product_id', value.product_id.value);
        data.append('name_product', value.product_id.label);
        data.append('judul_kelas', value.judul_kelas);
        data.append('sub_judul', value.sub_judul);
        data.append('type_class', value.type_class);
        data.append(
            'link_zoom',
            value.type_class == 'zoom' ? value.link_zoom : '-',
        );
        data.append(
            'link_file',
            value.type_class == 'file' ? value.link_file : '-',
        );
        // data.append('category', value.);
        data.append('description', value.description);
        data.append('schedule', value.schedule);
        data.append('notif_bar', value.notif_bar);
        data.append('is_quiz', value.is_quiz);

        if (typeof value.file === 'string') {
            data.append('image', value.file);
        } else {
            data.append('file', value.file.file.originFileObj);
        }

        const res = await serviceLMSClass.updateDataClass(data);
        if (res.isSuccess) {
            notifySuccess('Success add new class');
            history.push('/learnerscamp/class');
        } else {
            notifyError(res.message);
        }
        setLoading(false);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading...' }, 1000);
            setIsLoading(true);
            setFile(info.file.originFileObj);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            return;
        }
    };

    return (
        <LMSClassEditStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Class Create"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item label="Nama Merchant" name="merchant_id">
                                <DebounceSelectComponent
                                    mode="single"
                                    onChange={(value) => {
                                        // form.setFieldValue(
                                        //     'merchant_id',
                                        //     value,
                                        // );
                                        setDataProduct([]);
                                        form.setFieldsValue({
                                            merchant_id: value,
                                            product_id: null,
                                        });
                                    }}
                                    value={form.getFieldValue('merchant_id')}
                                    placeholder="Pilih Merchant"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'm.name': value,
                                            },
                                        };
                                        const result =
                                            await serviceLMSClass.getMerchant(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        id: item.merchant_id,
                                                        label: item.name,
                                                        value: item.merchant_id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item label="Nama Produk" name="product_id">
                                <DebounceSelectComponent
                                    outerSource
                                    options={dataProduct}
                                    setOptions={setDataProduct}
                                    mode="single"
                                    onChange={(value) =>
                                        form.setFieldValue('product_id', value)
                                    }
                                    value={form.getFieldValue('product_id')}
                                    placeholder="Pilih Produk"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                title: value,
                                                merchant_name:
                                                    form.getFieldValue(
                                                        'merchant_id',
                                                    ).label,
                                            },
                                        };
                                        const result =
                                            await serviceLMSClass.getProduct(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        id: item.product_id,
                                                        label: item.title,
                                                        value: item.product_id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                label="Judul Kelas (Master)"
                                name="judul_kelas"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Judul Kelas wajib diisi',
                                    },
                                ]}
                                style={{ marginBottom: 0 }}>
                                <Input placeholder="Masukkan Judul Kelas" />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                label="Sub Judul Kelas (Episode)"
                                name="sub_judul"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Sub Judul wajib diisi',
                                    },
                                ]}
                                style={{ marginBottom: 0 }}>
                                <Input placeholder="Masukkan Sub Judul" />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                required
                                label="Tipe Kelas"
                                name="type_class">
                                <Select
                                    placeholder="Pilih tipe kelas"
                                    onChange={(value) => {
                                        setType(value);
                                    }}>
                                    <Option value="file">File</Option>
                                    <Option value="zoom">Zoom</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {type === 'zoom' && (
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    label="Link Zoom"
                                    name="link_zoom"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Link Zoom wajib diisi',
                                        },
                                    ]}
                                    style={{ marginBottom: 0 }}>
                                    <Input placeholder="Masukkan Link Zoom" />
                                </Form.Item>
                            </div>
                        )}

                        {type === 'file' && (
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    label="Link File"
                                    name="link_file"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Link File wajib diisi',
                                        },
                                    ]}
                                    style={{ marginBottom: 0 }}>
                                    <Input placeholder="Masukkan Link File" />
                                </Form.Item>
                            </div>
                        )}

                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                label="Deskripsi Kelas"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Deskripsi Kelas wajib diisi',
                                    },
                                ]}
                                style={{ marginBottom: 0 }}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={valueDescription}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            setDescription(val);
                                            form.setFieldsValue({
                                                description: val || null,
                                            });
                                        }}
                                        placeholder="Jelaskan deskripsi kelas"
                                    />
                                </WysiwygStyle>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                label="Jadwal"
                                name="schedule"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Deskripsi Jadwal wajib diisi',
                                    },
                                ]}
                                style={{ marginBottom: 0 }}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={detailSchedule}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            setDetailSchedule(val);
                                            form.setFieldsValue({
                                                schedule: val || null,
                                            });
                                        }}
                                        placeholder="Jelaskan detail Aktivitas"
                                    />
                                </WysiwygStyle>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                        <div>
                            <h4>
                                <span style={{ color: 'red' }}>*</span> Gambar
                            </h4>
                            <Form.Item
                                name="file"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Foto wajib diisi!',
                                    },
                                ]}>
                                <Upload
                                    accept="image/*"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    customRequest={dummyRequest}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}>
                                    {file ? (
                                        <img
                                            src={
                                                typeof file === 'object'
                                                    ? URL.createObjectURL(file)
                                                    : file
                                            }
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                label="Notifikasi Bar"
                                name="notif_bar"
                                style={{ marginBottom: 0 }}>
                                <Input placeholder="Masukkan Notifikasi Bar" />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Field ini wajib diisi',
                                    },
                                ]}
                                label="Apakah Ada Kuis di Kelas Ini ?"
                                name="is_quiz">
                                <Select>
                                    <Option value="Y">Ada</Option>
                                    <Option value="N">Tidak</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Form.Item name="submit">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={loading}
                            style={{ marginRight: 10, width: 200 }}
                            disabled={loading ? true : false}>
                            Update
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                history.goBack();
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </LMSClassEditStyle>
    );
};

export default LMSClassEdit;
