import {
    DeleteFilled,
    FormOutlined,
} from '@ant-design/icons';
import { Button, Input, PageHeader, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ModalRemove from '../../../../components/ModalRemove';
import { notifyError } from '../../../../utils';
import serviceKiddofestTestimoni from '../index.service';
import KiddofestTestimoniStyle from './index.style';
import _ from 'lodash';

const { Search } = Input;

export default function KiddofestTestimoni() {
    const history = useHistory();
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
        filter: {
            'kiddofest_testimoni.name': '',
        },
    });
    const [dataTestimoni, setDataTestimoni] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        handleLoadData(queryString);

        return () => {
            setDataTestimoni([]);
        };
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceKiddofestTestimoni.getListTestimoni(resultQuery);
        if (result.isSuccess) {
            setDataTestimoni(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            key: 'no',
            width: 60,
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Nama',
            dataIndex:'name',
            key:'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Jabatan',
            dataIndex:'title',
            key:'title',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Deskrpsi',
            dataIndex:'desc',
            key:'desc',
            width: 300,
            ellipsis: true,
        },
        {
            title: 'Foto',
            dataIndex: 'image',
            key: 'image',
            width: 200,
            render: (value) => {
                return <img src={value} width={180} />;
                },
        },
        {
            title: 'Aksi',
            key: 'action',
            width: 100,
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(
                                        `/kiddofest/testimoni/${record.id}`,
                                    );
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/kiddofest/testimoni/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage:
                                                'Sukses Hapus Data Testimoni',
                                        },
                                        onReGetData: () => {
                                            handleLoadData(queryString);
                                        },
                                        title: 'Konfirmasi Hapus Data',
                                        desc: 'Apakah Anda yakin ingin menghapus data Testimoni',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'list',
            breadcrumbName: 'LIST TABLE',
        },

        {
            path: 'index',
            breadcrumbName: 'Testimoni Kiddofest',
        },
       
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.queries.page = 1;
        resultQuery.filter['kiddofest_testimoni.name'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <KiddofestTestimoniStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="TESTIMONI"
                    breadcrumb={{ routes }}
                    subTitle="List Table Testimoni Kiddofest"
                />
            </div>
            <div className="btn-top-table">
                <Button
                    onClick={() =>
                        history.push('/kiddofest/testimoni/add')
                    }
                    type="primary"
                    style={{ marginBottom: 10 }}>
                    + Tambah Testimoni
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Cari berdasarkan nama testimoni"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataTestimoni.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={
                    dataTestimoni?.payload ? [...dataTestimoni.payload] : []
                }
                showSorterTooltip={true}
                onChange={handleChange}
                onChange={handleSearch}
                onChange={handleResetSearch}
            />
        </KiddofestTestimoniStyle>
    );
}
