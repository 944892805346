import serviceApi from '../../../services';

const servicesMerchantAndProduct = {
    getData: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/reports/merchant-product`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getDataProductLocation: async () => {
        return await serviceApi({
            method: 'GET',
            url: `/reports/merchant-product/product-locations`,
            version: 'v1',
            token: true,
        });
    },
};

export default servicesMerchantAndProduct;
