import serviceApi from '../../../services';

const serviceKiddofestTestimoni = {
    getListTestimoni: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/testimoni`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postTestimoniData: async (bodyData) => {
        return await serviceApi({
            method: 'POST',
            url: `/kiddofest/testimoni`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    updateTestimoniData: async (bodyData) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kiddofest/testimoni`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    getDetailTestimoni: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/testimoni/${id}`,
            version: 'v1',
            token: true,
        });
    },
    deleteTestimoni: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/kiddofest/testimoni/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceKiddofestTestimoni;

