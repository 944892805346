import React, { useState, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    DatePicker,
    Upload,
    message,
    Image,
    TimePicker,
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import CreateMerchantStyle from '../../../pages/Merchant/Create/index.style';
import UploadImage from '../../Upload/Image';
import serviceFormMerchant from './index.service';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { listCountryCode } from '../../../utils/index-data';
const FormMerchant = ({
    handleSubmit,
    initVal,
    isLoading,
    setIsLoading,
    isCreate,
}) => {
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const [form] = Form.useForm();
    const [jenisUsaha, setJenisUsaha] = useState('perorangan');
    const [typeMerchant, setTypeMerchant] = useState('');
    const [rtValue, setRtValue] = useState('');

    const [MPNCC, setMPNCC] = useState(); //merchant_phone_number_country_code
    const [PPNCC, setPPNCC] = useState(); //pic_phone_number_country_code
    const [BPNCC, setBPNCC] = useState(); //bussiness_phone_number_country_code

    const [fileListFT, setFileListFT] = useState([]);
    const [id, setId] = useState(null);
    const [fromFirstInit, setFromFirstInit] = useState(true);

    const [key, setKey] = useState('updatable');
    const [fileList, setFileList] = useState({
        image_src: '',
        logo_src: '',
        indv_nik_src: '',
        indv_npwp_src: '',
        bus_dir_nik_src: '',
        bus_dir_npwp_src: '',
        bus_ent_npwp_src: '',
        bus_ent_akta_src: '',
        bus_ent_siup_src: '',
        bus_ent_nib_src: '',
    });
    const [provinceList, setProvinceList] = useState([]);
    const [provinceId, setProvinceId] = useState('');
    const [cityList, setCityList] = useState([]);
    const [cityId, setCityId] = useState('');
    const [subdistrictList, setSubdistrictList] = useState([]);
    const [subdistrictId, setSubdistrictId] = useState('');

    const [shippingList, setShippingList] = useState([]);
    const [shippingId, setShippingId] = useState('');
    const [imageList, setImageList] = useState([]);
    const [isShowPassword, setShowPassword] = React.useState(true);
    const [operationalHour, setOperationalHour] = React.useState('');

    useEffect(() => {
        if (initVal !== null) {
            if (initVal.email) {
                onCheckExistEmail(initVal.email);
            }

            setId(initVal.id);
            form.setFieldsValue({
                merchant_name: initVal.merchant_name,
                slug: initVal.slug,
                email: initVal.email,
                instagram: initVal.instagram,
                // description: initVal.description,
                referral_code: initVal.referral_code,
                operational_time: initVal.start_operating_hour,
                bank_name: initVal.bank_name,
                bank_account: initVal.bank_account,
                bank_account_registered_name:
                    initVal.bank_account_registered_name,
                password: initVal.password,
                facebook: initVal.facebook,
                full_address: initVal.full_address,
                shipping:
                    initVal.shipping == ''
                        ? undefined
                        : JSON.parse(initVal.shipping),
                approve: initVal.approve,
                member_count: initVal.number_of_member,
                open_member: initVal.open_member,
                postal_code: initVal.postal_code,
                // start_premium_merchant: moment(initVal.merchant_premium_start_date),
                // expired_premium_merchant: moment(initVal.merchant_premium_expired_date),
                commision_percent: initVal.commission_percentage,
                commision_value: initVal.commission_value,
                merchant_type: initVal.merchant_type,
                status_merchant: initVal.premium_id,
            });
            setRtValue(initVal.description);
            setOperationalHour(initVal.start_operating_hour);

            form.setFieldsValue({
                date_premium_merchant: [
                    initVal.merchant_premium_start_date == ''
                        ? ''
                        : moment(initVal.merchant_premium_start_date),
                    initVal.merchant_premium_expired_date == ''
                        ? ''
                        : moment(initVal.merchant_premium_expired_date),
                ],
            });

            // if (initVal.merchant_premium_start_date !== '') {
            //     form.setFieldsValue({
            //         start_premium_merchant: moment(
            //             initVal.merchant_premium_start_date,
            //         ),
            //     });
            // }
            // if (initVal.merchant_premium_expired_date !== '') {
            //     form.setFieldsValue({
            //         expired_premium_merchant: moment(
            //             initVal.merchant_premium_expired_date,
            //         ),
            //     });
            // }

            if (initVal.merchant_type == 'community') {
                setTypeMerchant('community');
                form.setFieldsValue({
                    number_of_member: initVal.member_count,
                    website: initVal.website,
                    open_member: initVal.open_member,
                    pic_name: initVal.pic_name,
                    pic_phone_number_country_code: getCountryCodeOrPhone(
                        initVal.pic_phone_number,
                        1,
                    ),
                    pic_phone_number: Number(
                        getCountryCodeOrPhone(initVal.pic_phone_number, 2),
                    ),
                });
            } else if (initVal.merchant_type == 'tutor') {
                setTypeMerchant('tutor');
                form.setFieldsValue({
                    tutor_product_id: initVal.tutor_product_id,
                });
            } else {
                setTypeMerchant('other');
            }
            if (initVal.institution_type == 'Badan Usaha') {
                setJenisUsaha('badanusaha');
                form.setFieldsValue({
                    institution_type: initVal.institution_type,
                    bussiness_name: initVal.bussiness_name,
                    bussiness_director: initVal.bussiness_director,
                    bussiness_phone_number_country_code: getCountryCodeOrPhone(
                        initVal.bussiness_phone_number,
                        1,
                    ),
                    bussiness_phone_number: Number(
                        getCountryCodeOrPhone(
                            initVal.bussiness_phone_number,
                            2,
                        ),
                    ),
                    bussiness_owner_nik: initVal.bussiness_owner_nik,
                    bussiness_owner_npwp: initVal.bussiness_owner_npwp,
                    bussiness_npwp: initVal.bussiness_npwp,
                    pic_name: initVal.pic_name,
                    pic_phone_number_country_code: getCountryCodeOrPhone(
                        initVal.pic_phone_number,
                        1,
                    ),
                    pic_phone_number: Number(
                        getCountryCodeOrPhone(initVal.pic_phone_number, 2),
                    ),
                });
            } else if (initVal.institution_type == 'Perorangan') {
                setJenisUsaha('perorangan');

                form.setFieldsValue({
                    owner_name: initVal.owner_name,
                    merchant_phone_number_country_code: getCountryCodeOrPhone(
                        initVal.merchant_phone_number,
                        1,
                    ),
                    merchant_phone_number: Number(
                        getCountryCodeOrPhone(initVal.merchant_phone_number, 2),
                    ),
                    merchant_nik: initVal.merchant_nik,
                    merchant_npwp: initVal.merchant_npwp,
                    pic_name: initVal.pic_name,
                    pic_phone_number_country_code: getCountryCodeOrPhone(
                        initVal.pic_phone_number,
                        1,
                    ),
                    pic_phone_number: Number(
                        getCountryCodeOrPhone(initVal.pic_phone_number, 2),
                    ),
                });
            } else {
                form.setFieldsValue({
                    pic_phone_number: Number(
                        getCountryCodeOrPhone(initVal.pic_phone_number, 2),
                    ),
                });
            }

            getProvinceList();
            var logo_src = '';
            var indv_nik_src = '';
            var indv_npwp_src = '';
            var bus_dir_nik_src = '';
            var bus_dir_npwp_src = '';
            var bus_ent_npwp_src = '';
            var bus_ent_akta_src = '';
            var bus_ent_siup_src = '';
            var bus_ent_nib_src = '';
            var listGallery = [];
            initVal.image.map((res, idx) => {
                switch (res.category) {
                    case 'logo':
                        logo_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'nik':
                        indv_nik_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'npwp':
                        indv_npwp_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'nik_direktur':
                        bus_dir_nik_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'npwp_direktur':
                        bus_dir_npwp_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'npwp_usaha':
                        bus_ent_npwp_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'akta':
                        bus_ent_akta_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'siup':
                        bus_ent_siup_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'nib':
                        bus_ent_nib_src = defaultValueImageObject(
                            res.id,
                            res.url,
                            res.category,
                            res.origin_name,
                        );
                        break;
                    case 'gallery':
                        listGallery.push({
                            uid: res.id,
                            status: 'done',
                            url: res.url,
                            category: 'gallery',
                        });
                        break;
                    default:
                        return '';
                }
            });
            setFileListFT(listGallery);
            setFileList({
                ...fileList,
                logo_src: logo_src,
                indv_nik_src: indv_nik_src,
                indv_npwp_src: indv_npwp_src,
                bus_dir_nik_src: bus_dir_nik_src,
                bus_dir_npwp_src: bus_dir_npwp_src,
                bus_ent_npwp_src: bus_ent_npwp_src,
                bus_ent_akta_src: bus_ent_akta_src,
                bus_ent_siup_src: bus_ent_siup_src,
                bus_ent_nib_src: bus_ent_nib_src,
            });
        } else {
            form.setFieldsValue({
                commision_value: '10000',
                commision_percent: '5',
                institution_type: 'perorangan',
                merchant_phone_number_country_code: '+62',
                bussiness_phone_number_country_code: '+62',
                pic_phone_number_country_code: '+62',
            });
            getProvinceList();
        }
    }, [initVal]);

    useEffect(() => {
        if (provinceId !== '') {
            getCityList({ province_id: provinceId });
        }
    }, [provinceId]);

    useEffect(() => {
        if (cityId !== '') {
            getSubdistrictList({ city_id: cityId });
        }
    }, [cityId]);

    useEffect(() => {
        if (shippingId == '') {
            getShippingList();
        }
    }, [shippingId]);

    const bankData = [
        {
            label: 'PT. BANK CENTRAL ASIA, Tbk',
            value: 'PT. BANK CENTRAL ASIA, Tbk',
        },
        {
            label: 'PT. BANK MANDIRI (PERSERO), Tbk',
            value: 'PT. BANK MANDIRI (PERSERO), Tbk',
        },
        {
            label: 'PT. BANK NEGARA INDONESIA (PERSERO), Tbk',
            value: 'PT. BANK NEGARA INDONESIA (PERSERO), Tbk',
        },
        {
            label: 'PT. BANK OCBC NISP, Tbk',
            value: 'PT. BANK OCBC NISP, Tbk',
        },
        {
            label: 'PT. BANK RAKYAT INDONESIA (PERSERO), Tbk',
            value: 'PT. BANK RAKYAT INDONESIA (PERSERO), Tbk',
        },
        {
            label: 'PT. BANK TABUNGAN NEGARA (PERSERO), Tbk',
            value: 'PT. BANK TABUNGAN NEGARA (PERSERO), Tbk',
        },
        // {
        //     label: '',
        //     value: 'separator',
        // },
        {
            label: 'PT. BANK AMAR INDONESIA',
            value: 'PT. BANK AMAR INDONESIA',
        },
        {
            label: 'PT. BANK ANZ INDONESIA',
            value: 'PT. BANK ANZ INDONESIA',
        },
        {
            label: 'PT. BANK ARTHA GRAHA INTERNASIONAL, Tbk',
            value: 'PT. BANK ARTHA GRAHA INTERNASIONAL, Tbk',
        },
        {
            label: 'PT. BANK ARTOS INDONESIA',
            value: 'PT. BANK ARTOS INDONESIA',
        },
        {
            label: 'PT. BANK BCA SYARIAH',
            value: 'PT. BANK BCA SYARIAH',
        },
        {
            label: 'PT. BANK BISNIS INTERNASIONAL',
            value: 'PT. BANK BISNIS INTERNASIONAL',
        },
        // {
        //     label: 'PT. BANK BNI SYARIAH',
        //     value: 'PT. BANK BNI SYARIAH',
        // },
        {
            label: 'PT. BANK BNP PARIBAS INDONESIA',
            value: 'PT. BANK BNP PARIBAS INDONESIA',
        },
        // {
        //     label: 'PT. BANK BRISYARIAH, Tbk',
        //     value: 'PT. BANK BRISYARIAH, Tbk',
        // },
        {
            label: 'PT. BANK BTPN, Tbk',
            value: 'PT. BANK BTPN, Tbk',
        },
        {
            label: 'PT. BANK BUKOPIN, Tbk',
            value: 'PT. BANK BUKOPIN, Tbk',
        },
        {
            label: 'PT. BANK BUMI ARTA, Tbk',
            value: 'PT. BANK BUMI ARTA, Tbk',
        },
        {
            label: 'PT. BANK CAPITAL INDONESIA, Tbk',
            value: 'PT. BANK CAPITAL INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK CHINA CONSTRUCTION BANK INDONESIA, Tbk',
            value: 'PT. BANK CHINA CONSTRUCTION BANK INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK CIMB NIAGA, Tbk',
            value: 'PT. BANK CIMB NIAGA, Tbk',
        },
        {
            label: 'PT. BANK COMMONWEALTH',
            value: 'PT. BANK COMMONWEALTH',
        },
        {
            label: 'PT. BANK CTBC INDONESIA',
            value: 'PT. BANK CTBC INDONESIA',
        },
        {
            label: 'PT. BANK DANAMON INDONESIA, Tbk',
            value: 'PT. BANK DANAMON INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK DBS INDONESIA',
            value: 'PT. BANK DBS INDONESIA',
        },
        {
            label: 'PT. BANK FAMA INTERNASIONAL',
            value: 'PT. BANK FAMA INTERNASIONAL',
        },
        {
            label: 'PT. BANK GANESHA, Tbk',
            value: 'PT. BANK GANESHA, Tbk',
        },
        {
            label: 'PT. BANK HARDA INTERNASIONAL',
            value: 'PT. BANK HARDA INTERNASIONAL',
        },
        {
            label: 'PT. BANK HSBC INDONESIA',
            value: 'PT. BANK HSBC INDONESIA',
        },
        {
            label: 'PT. BANK IBK INDONESIA, Tbk',
            value: 'PT. BANK IBK INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK ICBC INDONESIA',
            value: 'PT. BANK ICBC INDONESIA',
        },
        {
            label: 'PT. BANK INA PERDANA, Tbk',
            value: 'PT. BANK INA PERDANA, Tbk',
        },
        {
            label: 'PT. BANK INDEX SELINDO',
            value: 'PT. BANK INDEX SELINDO',
        },
        {
            label: 'PT. BANK JABAR BANTEN SYARIAH',
            value: 'PT. BANK JABAR BANTEN SYARIAH',
        },
        {
            label: 'PT. BANK JASA JAKARTA',
            value: 'PT. BANK JASA JAKARTA',
        },
        {
            label: 'PT. BANK JTRUST INDONESIA, Tbk',
            value: 'PT. BANK JTRUST INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK KEB HANA INDONESIA',
            value: 'PT. BANK KEB HANA INDONESIA',
        },
        {
            label: 'PT. BANK KESEJAHTERAAN EKONOMI',
            value: 'PT. BANK KESEJAHTERAAN EKONOMI',
        },
        {
            label: 'PT. BANK MANDIRI TASPEN',
            value: 'PT. BANK MANDIRI TASPEN',
        },
        {
            label: 'PT. BANK MASPION INDONESIA, Tbk',
            value: 'PT. BANK MASPION INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK MAYAPADA INTERNATIONAL, Tbk',
            value: 'PT. BANK MAYAPADA INTERNATIONAL, Tbk',
        },
        {
            label: 'PT. BANK MAYBANK  INDONESIA, Tbk',
            value: 'PT. BANK MAYBANK  INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK MAYORA',
            value: 'PT. BANK MAYORA',
        },
        {
            label: 'PT. BANK MEGA SYARIAH',
            value: 'PT. BANK MEGA SYARIAH',
        },
        {
            label: 'PT. BANK MEGA, Tbk',
            value: 'PT. BANK MEGA, Tbk',
        },
        {
            label: 'PT. BANK MESTIKA DHARMA, Tbk',
            value: 'PT. BANK MESTIKA DHARMA, Tbk',
        },
        {
            label: 'PT. BANK MIZUHO INDONESIA',
            value: 'PT. BANK MIZUHO INDONESIA',
        },
        {
            label: 'PT. BANK MNC INTERNASIONAL, Tbk',
            value: 'PT. BANK MNC INTERNASIONAL, Tbk',
        },
        {
            label: 'PT. BANK MUAMALAT INDONESIA, Tbk',
            value: 'PT. BANK MUAMALAT INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK MULTIARTA SENTOSA',
            value: 'PT. BANK MULTIARTA SENTOSA',
        },
        {
            label: 'PT. BANK NATIONALNOBU, Tbk',
            value: 'PT. BANK NATIONALNOBU, Tbk',
        },
        {
            label: 'PT. BANK NET INDONESIA SYARIAH',
            value: 'PT. BANK NET INDONESIA SYARIAH',
        },
        {
            label: 'PT. BANK OF INDIA INDONESIA, Tbk',
            value: 'PT. BANK OF INDIA INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK OKE INDONESIA, Tbk',
            value: 'PT. BANK OKE INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK PANIN DUBAI SYARIAH, Tbk',
            value: 'PT. BANK PANIN DUBAI SYARIAH, Tbk',
        },
        {
            label: 'PT. BANK PERMATA, Tbk',
            value: 'PT. BANK PERMATA, Tbk',
        },
        {
            label: 'PT. BANK QNB INDONESIA, Tbk',
            value: 'PT. BANK QNB INDONESIA, Tbk',
        },
        {
            label: 'PT. BANK RABOBANK INTERNATIONAL INDONESIA',
            value: 'PT. BANK RABOBANK INTERNATIONAL INDONESIA',
        },
        {
            label: 'PT. BANK RESONA PERDANIA',
            value: 'PT. BANK RESONA PERDANIA',
        },
        {
            label: 'PT. BANK ROYAL INDONESIA',
            value: 'PT. BANK ROYAL INDONESIA',
        },
        {
            label: 'PT. BANK SAHABAT SAMPOERNA',
            value: 'PT. BANK SAHABAT SAMPOERNA',
        },
        {
            label: 'PT. BANK SBI INDONESIA',
            value: 'PT. BANK SBI INDONESIA',
        },
        {
            label: 'PT. BANK SHINHAN INDONESIA',
            value: 'PT. BANK SHINHAN INDONESIA',
        },
        {
            label: 'PT. BANK SINARMAS, Tbk',
            value: 'PT. BANK SINARMAS, Tbk',
        },
        {
            label: 'PT. BANK SYARIAH BUKOPIN',
            value: 'PT. BANK SYARIAH BUKOPIN',
        },
        {
            label: 'PT. BANK SYARIAH INDONESIA',
            value: 'PT. BANK SYARIAH INDONESIA',
        },
        // {
        //     label: 'PT. BANK SYARIAH MANDIRI',
        //     value: 'PT. BANK SYARIAH MANDIRI',
        // },
        {
            label: 'PT. BANK TABUNGAN PENSIUNAN NASIONAL SYARIAH, Tbk',
            value: 'PT. BANK TABUNGAN PENSIUNAN NASIONAL SYARIAH, Tbk',
        },
        {
            label: 'PT. BANK UOB INDONESIA',
            value: 'PT. BANK UOB INDONESIA',
        },
        {
            label: 'PT. BANK VICTORIA INTERNATIONAL, Tbk',
            value: 'PT. BANK VICTORIA INTERNATIONAL, Tbk',
        },
        {
            label: 'PT. BANK VICTORIA SYARIAH',
            value: 'PT. BANK VICTORIA SYARIAH',
        },
        {
            label: 'PT. BANK WOORI SAUDARA INDONESIA 1906, Tbk',
            value: 'PT. BANK WOORI SAUDARA INDONESIA 1906, Tbk',
        },
        {
            label: 'PT. BANK YUDHA BHAKTI, Tbk',
            value: 'PT. BANK YUDHA BHAKTI, Tbk',
        },
        {
            label: 'PT. PAN INDONESIA BANK, Tbk',
            value: 'PT. PAN INDONESIA BANK, Tbk',
        },
        {
            label: 'PT. PRIMA MASTER BANK',
            value: 'PT. PRIMA MASTER BANK',
        },
    ];

    const validateMessages = {
        required: '${label} harus diisi!',
        types: {
            email: 'Masukkan ${label} yang valid',
            number: 'Masukkan ${label} yang valid',
        },
        number: {
            min: 'Nilai minimal ${min}',
            max: 'Nilai maksimal ${max}',
            range: 'Nilai harus berada diantara ${min} dan ${max}',
        },
    };

    const getProvinceList = async () => {
        const result = await serviceFormMerchant.getProvince();
        if (result.isSuccess && result.response.payload !== null) {
            setProvinceList(result.response.payload);
            if (initVal !== null && fromFirstInit) {
                setProvinceId(initVal.province_id);
                form.setFieldsValue({
                    province: initVal.province_id,
                });
            }
        } else {
            setProvinceList([]);
        }
    };

    const getCityList = async (param) => {
        const result = await serviceFormMerchant.getCity(param);
        if (result.isSuccess && result.response.payload !== null) {
            setCityList(result.response.payload);
            if (initVal !== null && fromFirstInit) {
                setCityId(initVal.city_id);
                form.setFieldsValue({
                    city: initVal.city,
                });
            }
        } else {
            setCityList([]);
        }
    };
    const getSubdistrictList = async (param) => {
        const result = await serviceFormMerchant.getSubDistrict(param);
        if (result.isSuccess && result.response.payload !== null) {
            setSubdistrictList(result.response.payload);
            if (initVal !== null && fromFirstInit) {
                setSubdistrictId(initVal.subdistrict_id);
                form.setFieldsValue({
                    sub_district: initVal.subdistrict,
                });
            }
        } else {
            setSubdistrictList([]);
        }
    };
    const getShippingList = async () => {
        const result = await serviceFormMerchant.getShippingList();
        if (result.isSuccess && result.response.payload !== null) {
            setShippingList(result.response.payload);
        } else {
            setShippingList([]);
        }
    };

    const handleProvinceChange = (value) => {
        setProvinceId(value);
        setSubdistrictId('');
        setSubdistrictList([]);
        setCityId('');
        setCityList([]);
        form.setFieldsValue({
            city: '',
            sub_district: '',
        });
        setFromFirstInit(false);
    };
    const handleCityChange = (value) => {
        setCityId(value);
        form.setFieldsValue({
            sub_district: '',
        });
        setFromFirstInit(false);
    };
    const handleSubdistrictChange = (value) => {
        setSubdistrictId(value);
        setFromFirstInit(false);
    };

    const handleSLug = (e) => {
        let val = e.target.value;
        form.setFieldsValue({
            slug: val
                .replace(/^[a-zA-Z\d\-_\s]+$/g, '')
                .replace(/\s+/g, '-')
                .toLowerCase(),
        });
    };

    const postImage = async (file, category) => {
        const formData = new FormData();
        formData.append('photo', file);
        formData.append('category', category);
        const result = await serviceFormMerchant.postPhoto(formData);
        if (result.isSuccess) {
            let res = result.response.payload;
            if (category == 'logo') {
                setFileList({
                    ...fileList,
                    logo_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                    ),
                });
            } else if (category == 'nik') {
                setFileList({
                    ...fileList,
                    indv_nik_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            } else if (category == 'npwp') {
                setFileList({
                    ...fileList,
                    indv_npwp_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            } else if (category == 'nik_direktur') {
                setFileList({
                    ...fileList,
                    bus_dir_nik_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            } else if (category == 'npwp_direktur') {
                setFileList({
                    ...fileList,
                    bus_dir_npwp_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            } else if (category == 'npwp_usaha') {
                setFileList({
                    ...fileList,
                    bus_ent_npwp_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            } else if (category == 'akta') {
                setFileList({
                    ...fileList,
                    bus_ent_akta_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            } else if (category == 'siup') {
                setFileList({
                    ...fileList,
                    bus_ent_siup_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            } else if (category == 'nib') {
                setFileList({
                    ...fileList,
                    bus_ent_nib_src: defaultValueImageObject(
                        res.id,
                        res.url,
                        category,
                        res.origin_name,
                    ),
                });
            }
            message.success({ content: 'Success Upload', key });
        } else {
            message.error({ content: 'Failed to upload!', key });
        }
    };
    const defaultValueImageObject = (id, url, category, name, type) => {
        return {
            uid: id,
            status: 'done',
            url: url,
            category: category,
            name: name,
        };
    };

    const opsionalPic = async ({ file }) => {
        const formData = new FormData();
        formData.append('photo', file);
        formData.append('category', 'gallery');
        const result = await serviceFormMerchant.postPhoto(formData);
        if (result.isSuccess) {
            let res = result.response.payload;
            setFileListFT([
                ...fileListFT,
                {
                    uid: res.id,
                    status: 'done',
                    url: res.url,
                    category: 'gallery',
                },
            ]);
            message.success({ content: 'Success Upload Image', key });
        } else {
            message.error({ content: 'Failed to upload!', key });
        }
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChange = ({ file, fileList: newFileList }) => {
        if (file.status === 'removed' || file.status === 'done') {
            setFileListFT(newFileList);
        } else {
            return false;
        }
    };
    const handleChange = (info) => {
        let file = info.file;
        if (file) {
            if (info.file.status === 'uploading') {
                message.loading({ content: 'Uploading...', key });
                return;
            }
        }
    };

    const validateLowImage = (file) => {
        setKey(Math.random());
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
                key,
            });
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
                key,
            });
            return false;
        }
        return isJpgOrPng && isLt2M;
    };
    const validateHiResImage = (file) => {
        setKey(Math.random());
        const isJpgOrPng =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type == 'application/pdf';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
                key,
            });
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error({ content: 'Ukuran file maksimum adalah 2MB', key });
            return false;
        }
        return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleOnSubmit = (values) => {
        form.validateFields([
            'logo_src',
            'indv_nik_src',
            'indv_npwp_src',
            'bus_dir_nik_src',
            'bus_dir_npwp_src',
            'bus_ent_npwp_src',
            'bus_ent_akta_src',
            'bus_ent_siup_src',
            'bus_ent_nib_src',
        ]);
        var imgList = handleImageList(fileListFT);
        if (fileList.logo_src != '') {
            imgList.push(renameKey(fileList.logo_src, 'uid', 'id'));
        }
        if (fileList.indv_nik_src != '') {
            imgList.push(renameKey(fileList.indv_nik_src, 'uid', 'id'));
        }
        if (fileList.indv_npwp_src != '') {
            imgList.push(renameKey(fileList.indv_npwp_src, 'uid', 'id'));
        }
        if (fileList.bus_dir_nik_src != '') {
            imgList.push(renameKey(fileList.bus_dir_nik_src, 'uid', 'id'));
        }
        if (fileList.bus_dir_npwp_src != '') {
            imgList.push(renameKey(fileList.bus_dir_npwp_src, 'uid', 'id'));
        }
        if (fileList.bus_ent_npwp_src != '') {
            imgList.push(renameKey(fileList.bus_ent_npwp_src, 'uid', 'id'));
        }
        if (fileList.bus_ent_akta_src != '') {
            imgList.push(renameKey(fileList.bus_ent_akta_src, 'uid', 'id'));
        }
        if (fileList.bus_ent_siup_src != '') {
            imgList.push(renameKey(fileList.bus_ent_siup_src, 'uid', 'id'));
        }
        if (fileList.bus_ent_nib_src != '') {
            imgList.push(renameKey(fileList.bus_ent_nib_src, 'uid', 'id'));
        }

        const submitData = {
            id: id,
            merchant_name: values.merchant_name,
            email: values.email,
            instagram: values.instagram,
            description: rtValue,
            province_id: provinceList.find((x) => x.id === provinceId).id,
            province: provinceList.find((x) => x.id === provinceId).name,
            city_id: cityList.find((x) => x.id === cityId).id,
            city: cityList.find((x) => x.id === cityId).name,
            subdistrict_id: subdistrictList.find((x) => x.id === subdistrictId)
                .id,
            subdistrict: subdistrictList.find((x) => x.id === subdistrictId)
                .name,
            referral_code: values.referral_code,
            start_operating_hour: values.operational_time,
            // end_operating_hour:
            //     values.operational_time && values.operational_time[1]
            //         ? moment(values.operational_time[1]).format('HH:mm')
            //         : '',
            bank_name: values.bank_name,
            bank_account: values.bank_account + '',
            bank_account_registered_name: values.bank_account_registered_name,
            institution_type:
                jenisUsaha == 'perorangan' ? 'Perorangan' : 'Badan Usaha',
            owner_name: values.owner_name || '',
            merchant_phone_number: !!values.merchant_phone_number
                ? values.merchant_phone_number_country_code +
                  values.merchant_phone_number
                : '',
            merchant_nik: checkUndefinedObject(values.merchant_nik),
            merchant_npwp: checkUndefinedObject(values.merchant_npwp),
            pic_name: values.pic_name,
            pic_phone_number: !!values.pic_phone_number
                ? values.pic_phone_number_country_code + values.pic_phone_number
                : '',

            bussiness_name: values.bussiness_name || '',
            bussiness_director: values.bussiness_director || '',
            bussiness_phone_number: !!values.bussiness_phone_number
                ? values.bussiness_phone_number_country_code +
                  values.bussiness_phone_number
                : '',
            bussiness_npwp: checkUndefinedObject(values.bussiness_npwp),
            bussiness_owner_nik: checkUndefinedObject(
                values.bussiness_owner_nik,
            ),
            bussiness_owner_npwp: checkUndefinedObject(
                values.bussiness_owner_npwp,
            ),

            image: imgList,

            password: values.password,
            merchant_type: values.merchant_type || '',
            facebook: values.facebook || '',
            full_address: values.full_address || '',
            shipping:
                values.shipping == undefined || values.shipping.length == 0
                    ? ''
                    : '[' + values.shipping.map((x) => '"' + x + '"') + ']',
            approve: values.approve || '',
            merchant_premium_start_date:
                !!values.date_premium_merchant &&
                !!values.date_premium_merchant[0]
                    ? moment(values.date_premium_merchant[0])
                          .set({ hour: 0, minutes: 0, second: 0 })
                          .utc(0)
                          .toISOString()
                    : '',
            merchant_premium_expired_date:
                !!values.date_premium_merchant &&
                !!values.date_premium_merchant[1]
                    ? moment(values.date_premium_merchant[1])
                          .set({ hour: 23, minutes: 59, second: 59 })
                          .utc(0)
                          .toISOString()
                    : '',
            commission_percentage: values.commision_percent || '',
            commission_value: values.commision_value || '',
            slug: values.slug || '',

            member_count: values.number_of_member || '',
            website: values.website || '',
            open_member: values.open_member || '',
            tutor_product_id: String(values.tutor_product_id) || '',
            postal_code: values.postal_code || '',
        };
        // const formData = new FormData();
        // for (var key in submitData) {
        //     formData.append(key, submitData[key]);
        // }
        handleSubmit(submitData);
    };

    const handleImageList = (fileList) => {
        if (fileList) {
            let tmp = [];
            fileList.map((el, idx) => {
                if (el.id == undefined) {
                    tmp.push({
                        id: el.uid,
                        url: el.url,
                        category: el.category,
                    });
                } else {
                    tmp.push({
                        id: el.id,
                        url: el.url,
                        category: el.category,
                    });
                }
            });
            return [...tmp];
        } else {
            return imageList;
        }
    };

    const renameKey = (obj, oldKey, newKey) => {
        if (obj[oldKey] != undefined) {
            obj[newKey] = obj[oldKey];
            delete obj[oldKey];
        }
        return obj;
    };
    const getCountryCodeOrPhone = (allString, retType) => {
        const countryCode = listCountryCode.find((item) =>
            allString.includes(item.value),
        );

        if (retType == 1) {
            if (countryCode)
                return allString.slice(0, countryCode?.value?.length);
            return '';
        } else {
            if (countryCode) return allString.slice(countryCode?.value?.length);
            return '';
        }

        // if (
        //     allString.startsWith('+673') ||
        //     allString.startsWith('+885') ||
        //     allString.startsWith('+886')
        // ) {
        //     if (retType == 1) {
        //         return allString.substring(0, 4);
        //     } else {
        //         return allString.substring(4, allString.length);
        //     }
        // } else {
        //     if (retType == 1) {
        //         return allString.substring(0, 3);
        //     } else {
        //         return allString.substring(3, allString.length);
        //     }
        // }
    };
    const checkUndefinedObject = (strVal) => {
        if (strVal == undefined) {
            return '';
        } else {
            return strVal.toString();
        }
    };

    const changeMilisecondToZero = (date) => {
        // return moment(moment(date).milliseconds(0).toISOString());
        return moment(moment(date).seconds(0).millisecond(0).toISOString());
    };
    function disabledDate(current) {
        return current && current < moment().startOf('day');
    }

    const onCheckExistEmail = async (value) => {
        const formData = new FormData();
        formData.append('email', value);
        const resultResponse = await serviceFormMerchant.onCheckExistEmail(
            formData,
        );
        if (resultResponse.response.payload?.is_email_exist) {
            setShowPassword(false);
        } else {
            setShowPassword(true);
        }
    };

    return (
        <CreateMerchantStyle>
            <Form
                form={form}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleOnSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <div className="detail-usaha">
                            <h3>Detail Merchant</h3>
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        name="logo_src"
                                        label="Logo Merchant"
                                        rules={[
                                            {
                                                required:
                                                    fileList.logo_src === ''
                                                        ? true
                                                        : false,
                                                message:
                                                    'Logo Merchant wajib diupload!',
                                            },
                                        ]}
                                        required>
                                        <ImgCrop rotate>
                                            <Upload
                                                accept="image/png, image/jpeg"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                customRequest={({ file }) => {
                                                    postImage(file, 'logo');
                                                }}
                                                onChange={handleChange}
                                                beforeUpload={validateLowImage}>
                                                {fileList.logo_src.url ? (
                                                    <img
                                                        src={
                                                            fileList.logo_src
                                                                .url
                                                        }
                                                        alt="avatar"
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    />
                                                ) : (
                                                    uploadButton
                                                )}
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name="merchant_name"
                                        label="Nama Merchant"
                                        extra="Contoh: Kiddo Creative School"
                                        rules={[{ required: true }]}>
                                        <Input
                                            placeholder="Masukkan Nama Merchant"
                                            onChange={handleSLug}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Slug" name="slug">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <small>
                                Ukuran file maksimum 500Kb. Kiddo.id berhak
                                menambahkan atau mengubah foto/gambar agar
                                sesuai dengan layanan aktivitas. Pastikan foto
                                yang diupload tidak melanggar aturan hak
                                kekayaan intelektual dari pihak selain merchant
                                terkait. Kiddo.id tidak bertanggung jawab jika
                                ada pelanggaran hak cipta terkait gambar/foto
                                yang diupload.
                            </small>
                            <Form.Item name="other-pic" label="Foto Tambahan">
                                <Upload
                                    listType="picture-card"
                                    accept="image/png, image/jpeg"
                                    fileList={fileListFT}
                                    customRequest={opsionalPic}
                                    onPreview={onPreview}
                                    onChange={onChange}
                                    beforeUpload={validateLowImage}
                                    // onRemove={(e) => { onRemove(e) }}
                                >
                                    {fileListFT.length < 100 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { type: 'email', required: true },
                                    {
                                        async validator(rule, value) {
                                            onCheckExistEmail(value);
                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                <Input
                                    placeholder="Masukkan Email"
                                    disabled={initVal !== null}
                                />
                            </Form.Item>
                            <Form.Item
                                name="instagram"
                                label="Instagram"
                                extra="Contoh: id.kiddo (Tanpa tanda @)">
                                <Input placeholder="Masukkan Instagram" />
                            </Form.Item>
                            <Form.Item
                                name="facebook"
                                label="Facebook"
                                extra="Contoh: facebook.com/id.kiddo"
                                rules={[
                                    {
                                        required: false,
                                        pattern: new RegExp('facebook.com'),
                                        message:
                                            'Masukan link Facebook, contoh facebook.com/.....',
                                    },
                                ]}>
                                <Input placeholder="Masukkan Facebook" />
                            </Form.Item>

                            {/* <Form.Item name="description" label="Tentang merchant" rules={[{ required: true }]}>
                                <Input.TextArea placeholder="Masukkan Tentang merchant" />
                            </Form.Item> */}
                            <Form.Item
                                name="description"
                                label="Deskripsi Tentang Merchant"
                                style={{ marginBottom: 0 }}
                                // required
                                validateTrigger={['onChange']}
                                rules={[
                                    {
                                        async validator(rule, value) {
                                            var val = rtValue.replace(
                                                /<[^>]+>/g,
                                                '',
                                            );
                                            if (val == '') {
                                                return Promise.reject(
                                                    new Error(
                                                        'Deskripsi tentang merchant wajib diisi',
                                                    ),
                                                );
                                            } else if (val.length > 500) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Deskripsi maksimal 500 karakter',
                                                    ),
                                                );
                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}>
                                {/* <TextArea
								rows={10}
								showCount
								maxLength={1000}
								placeholder="Jelaskan detail Aktivitas"
							/> */}
                                <>
                                    <ReactQuill
                                        theme="snow"
                                        value={rtValue}
                                        onChange={(value) => {
                                            setRtValue(value);
                                            form.validateFields([
                                                'description',
                                            ]);
                                        }}
                                        placeholder="Jelaskan detail Aktivitas"
                                    />
                                </>
                            </Form.Item>
                            <br />

                            <Form.Item
                                name="operational_time"
                                label="Jam Operasinal Merchant"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange']}
                                rules={[{ required: true }]}>
                                <>
                                    <ReactQuill
                                        theme="snow"
                                        value={operationalHour}
                                        onChange={(value) => {
                                            setOperationalHour(value);
                                            form.setFieldsValue({
                                                operational_time: value,
                                            });
                                        }}
                                        placeholder="Masukkan Jam Operasional Merchant"
                                    />
                                </>
                            </Form.Item>
                            <br />

                            {/* <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Jam Operasional Merchant"
                                        name="operational_time">
                                        <TimePicker.RangePicker format="HH:mm" />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Tanggal Mulai Premium Merchant"
                                        name="date_premium_merchant">
                                        <RangePicker
                                            disabledDate={disabledDate}
                                        />
                                        {/* <DatePicker
                                            format="YYYY-MM-DD"
                                            showTime={{
                                                hideDisabledOptions: true,
                                            }}
                                            disabledDate={disabledDate}
                                        /> */}
                                    </Form.Item>
                                </Col>
                                {/* <Col span={8}>
                                    <Form.Item
                                        label="Tanggal Akhir Premium Merchant"
                                        name="expired_premium_merchant"
                                        rules={[
                                            {
                                                async validator(rule, value) {
                                                    var beforeT =
                                                        form.getFieldValue(
                                                            `start_premium_merchant`,
                                                        );
                                                    var afterT =
                                                        form.getFieldValue(
                                                            `expired_premium_merchant`,
                                                        );
                                                    var before =
                                                        changeMilisecondToZero(
                                                            beforeT,
                                                        );
                                                    var current =
                                                        changeMilisecondToZero(
                                                            afterT,
                                                        );
                                                    if (
                                                        before >= current &&
                                                        beforeT != undefined &&
                                                        afterT != undefined
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Tanggal akhir harus lebih besar dari tanggal sebelumnya',
                                                            ),
                                                        );
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                },
                                                validateTrigger: 'onChange',
                                            },
                                        ]}>
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            disabledDate={disabledDate}
                                            showTime={{
                                                hideDisabledOptions: true,
                                            }}
                                            showTime={{
                                                defaultValue: moment(
                                                    '00',
                                                    'mm',
                                                ),
                                            }}
                                        />
                                    </Form.Item>
                                </Col> */}
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Komisi (dalam %)"
                                        name="commision_percent"
                                        rules={[
                                            {
                                                async validator(rule, value) {
                                                    var commision_value =
                                                        form.getFieldValue(
                                                            `commision_value`,
                                                        );
                                                    commision_value =
                                                        commision_value ==
                                                        undefined
                                                            ? ''
                                                            : commision_value;
                                                    var commision_percent =
                                                        form.getFieldValue(
                                                            `commision_percent`,
                                                        );
                                                    commision_percent =
                                                        commision_percent ==
                                                        undefined
                                                            ? ''
                                                            : commision_percent;
                                                    if (
                                                        // (commision_percent != "0" && commision_value != "0") ||
                                                        commision_percent ==
                                                            '' ||
                                                        (commision_percent.length ==
                                                            0 &&
                                                            commision_value.length >
                                                                0)
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Komisi (dalam %) harus diisi!',
                                                            ),
                                                        );
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                },
                                                validateTrigger: 'onChange',
                                            },
                                        ]}>
                                        <Input
                                            type="number"
                                            min={0}
                                            max={100}
                                            suffix=" % "
                                            onWheel={() =>
                                                document.activeElement.blur()
                                            }
                                            placeholder="Masukkan komisi dalam %"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Komisi Minimal"
                                        name="commision_value"
                                        rules={[
                                            {
                                                async validator(rule, value) {
                                                    var commision_value =
                                                        form.getFieldValue(
                                                            `commision_value`,
                                                        );
                                                    commision_value =
                                                        commision_value ==
                                                        undefined
                                                            ? ''
                                                            : commision_value;
                                                    var commision_percent =
                                                        form.getFieldValue(
                                                            `commision_percent`,
                                                        );
                                                    commision_percent =
                                                        commision_percent ==
                                                        undefined
                                                            ? ''
                                                            : commision_percent;

                                                    if (
                                                        // (commision_percent != "0" && commision_value != "0") ||
                                                        commision_value == '' ||
                                                        (commision_percent.length >
                                                            0 &&
                                                            commision_value.length ==
                                                                0)
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Komisi harus diisi!',
                                                            ),
                                                        );
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                },
                                                validateTrigger: 'onChange',
                                            },
                                        ]}>
                                        <Input
                                            type="number"
                                            min={0}
                                            placeholder="Masukkan komisi"
                                            prefix="Rp "
                                            onWheel={() =>
                                                document.activeElement.blur()
                                            }
                                            // formatter={(value) =>
                                            //     `Rp ${value}`.replace(
                                            //         /\B(?=(\d{3})+(?!\d))/g,
                                            //         '.',
                                            //     )
                                            // }
                                            // parser={(value) =>
                                            //     value.replace(
                                            //         /Rp\s?|(\.*)/g,
                                            //         '',
                                            //     )
                                            // }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="lokasi-usaha">
                            <h3>Lokasi Usaha</h3>
                            <Form.Item
                                name="province"
                                label="Provinsi"
                                rules={[{ required: true }]}>
                                {/* <Input placeholder="Masukkan Provinsi" /> */}
                                <Select
                                    showSearch
                                    placeholder="Pilih Provinsi"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={handleProvinceChange}>
                                    {provinceList.map((el, idx) => {
                                        return (
                                            <Option key={el.id} value={el.id}>
                                                {el.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            {provinceId != '' && (
                                <Form.Item
                                    name="city"
                                    label="Kota"
                                    rules={[{ required: true }]}>
                                    {/* <Input placeholder="Masukkan Kota" /> */}
                                    <Select
                                        showSearch
                                        placeholder="Pilih Kota"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={handleCityChange}>
                                        {cityList.map((el, idx) => {
                                            return (
                                                <Option
                                                    key={el.id}
                                                    value={el.id}>
                                                    {el.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            )}

                            {cityId != '' && (
                                <Form.Item
                                    name="sub_district"
                                    label="Kecamatan"
                                    rules={[{ required: true }]}>
                                    {/* <Input placeholder="Masukkan Kecamatan" /> */}
                                    <Select
                                        showSearch
                                        placeholder="Pilih Kecamatan"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={handleSubdistrictChange}>
                                        {subdistrictList.map((el, idx) => {
                                            return (
                                                <Option
                                                    key={el.id}
                                                    value={el.id}>
                                                    {el.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            )}
                            <Form.Item
                                name="full_address"
                                label="Alamat Lengkap"
                                rules={[{ required: true }]}>
                                <Input.TextArea placeholder="Masukkan Alamat lengkap" />
                            </Form.Item>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        name="postal_code"
                                        rules={[
                                            {
                                                min: 5,
                                                message:
                                                    'Kode pos harus 5 digit',
                                            },
                                            {
                                                max: 5,
                                                message:
                                                    'Kode pos harus 5 digit',
                                            },
                                        ]}
                                        label="Kode Pos">
                                        <Input
                                            onWheel={() =>
                                                document.activeElement.blur()
                                            }
                                            type="number"
                                            placeholder="Masukkan Kode Pos"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="referral_code"
                                        label="Kode Referal">
                                        <Input placeholder="Masukkan Kode Referal" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="shipping" label="Shipping">
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            placeholder="Pilih Shipping">
                                            {shippingList.map((el, index) => (
                                                <Option
                                                    key={index}
                                                    value={el.code}>
                                                    {el.name}
                                                </Option>
                                            ))}
                                        </Select>

                                        {/* <Select
                                            showSearch
                                            // mode="multiple"
                                            mode="tags"
                                            tokenSeparators={[',']}
                                            placeholder="Pilih Shipping"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }>
                                            {shippingList.map((el, idx) => {
                                                return (
                                                    <Option
                                                        key={idx}
                                                        value={el.code}>
                                                        {el.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select> */}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="merchant_type">
                            <h3>Tipe Merchant</h3>
                            <Form.Item
                                name="merchant_type"
                                label="Tipe Merchant"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tipe Merchant Wajib dipilih!',
                                    },
                                ]}>
                                <Select
                                    placeholder="Pilih Jenis Merchant"
                                    onChange={(val) => {
                                        setTypeMerchant(val);
                                    }}>
                                    <Option value="tutor">Tutor</Option>
                                    <Option value="community">Community</Option>

                                    <Option value="shop">Shop</Option>
                                    <Option value="school">School</Option>
                                </Select>
                            </Form.Item>

                            {typeMerchant == 'tutor' && (
                                <Form.Item
                                    name="tutor_product_id"
                                    label="Tutor Product Id"
                                    rules={[{ required: true }]}>
                                    <InputNumber
                                        placeholder="Masukkan Tutor Product Id"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )}

                            {typeMerchant == 'community' && (
                                <>
                                    <Form.Item
                                        name="number_of_member"
                                        label="Jumlah Anggota Komunitas"
                                        rules={[{ required: true }]}>
                                        <Input placeholder="Masukkan Jumlah Anggota Komunitas" />
                                    </Form.Item>
                                    <Form.Item name="website" label="Website">
                                        <Input placeholder="Masukkan Alamat Website" />
                                    </Form.Item>
                                    <Form.Item
                                        name="open_member"
                                        label="Open Member"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Open Member Wajib dipilih!',
                                            },
                                        ]}>
                                        <Select placeholder="Pilih Open Member">
                                            <Option value="Y">Yes</Option>
                                            <Option value="N">No</Option>
                                        </Select>
                                    </Form.Item>
                                </>
                            )}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="info-bank">
                            <h3>Info Bank</h3>
                            <Form.Item
                                name="bank_name"
                                label="Nama Bank"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Nama Bank Wajib dipilih!',
                                //     },
                                // ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Pilih Nama Bank"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {bankData.map((el, idx) => {
                                        return (
                                            <Option key={idx} value={el.value}>
                                                {el.label}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="bank_account"
                                label="Nomor Rekening"
                                // rules={[{ required: true }]}
                            >
                                <Input
                                    type="number"
                                    onWheel={() =>
                                        document.activeElement.blur()
                                    }
                                    placeholder="Masukkan Nomor Rekening"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="bank_account_registered_name"
                                label="Nama Pemilik Rekening"
                                // rules={[{ required: true }]}
                            >
                                <Input placeholder="Masukkan Nama Pemilik Rekening" />
                            </Form.Item>
                        </div>
                        <div className="detail-merchant">
                            <h3>Detail Merchant</h3>
                            <Form.Item
                                name="institution_type"
                                label="Jenis Usaha"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jenis Usaha Wajib dipilih!',
                                    },
                                ]}>
                                <Select
                                    placeholder="Pilih Jenis Usaha"
                                    onChange={(val) => {
                                        setJenisUsaha(val);
                                        setFileList({
                                            ...fileList,
                                            indv_nik_src: '',
                                            indv_npwp_src: '',
                                            bus_dir_nik_src: '',
                                            bus_dir_npwp_src: '',
                                            bus_ent_npwp_src: '',
                                            bus_ent_akta_src: '',
                                            bus_ent_siup_src: '',
                                            bus_ent_nib_src: '',
                                        });
                                    }}>
                                    <Option value="perorangan">
                                        Perorangan
                                    </Option>
                                    <Option value="badanusaha">
                                        Badan Usaha
                                    </Option>
                                </Select>
                            </Form.Item>
                            {jenisUsaha === 'perorangan' && (
                                <div className="pemilik">
                                    <Form.Item
                                        name="owner_name"
                                        label="Nama Pemilik"
                                        // rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Masukkan Nama Pemilik" />
                                    </Form.Item>
                                    <Row gutter={24}>
                                        <Col span={5}>
                                            <Form.Item
                                                name="merchant_phone_number_country_code"
                                                label="Kode Negara">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(
                                                        input,
                                                        option,
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase(),
                                                            ) >= 0
                                                    }
                                                    defaultValue={'+62'}
                                                    optionLabelProp="label"
                                                    onChange={(val) => {
                                                        setMPNCC(val);
                                                        form.validateFields([
                                                            'merchant_phone_number',
                                                        ]);
                                                    }}>
                                                    {listCountryCode.map(
                                                        (item) => {
                                                            return (
                                                                <Option
                                                                    value={
                                                                        item.value
                                                                    }
                                                                    label={
                                                                        item.value
                                                                    }>
                                                                    {item.label}
                                                                </Option>
                                                            );
                                                        },
                                                    )}

                                                    {/* <Option
                                                        value="+62"
                                                        label="+62">
                                                        Indonesia +62
                                                    </Option>
                                                    <Option
                                                        value="+60"
                                                        value="+60">
                                                        Malaysia +60
                                                    </Option>
                                                    <Option
                                                        value="+63"
                                                        value="+63">
                                                        Philippines +63
                                                    </Option>
                                                    <Option
                                                        value="+65"
                                                        value="+65">
                                                        Singapore +65
                                                    </Option>
                                                    <Option
                                                        value="+66"
                                                        value="+66">
                                                        Thailand +66
                                                    </Option>
                                                    <Option
                                                        value="+84"
                                                        value="+84">
                                                        Vietnam +84
                                                    </Option>
                                                    <Option
                                                        value="+95"
                                                        value="+95">
                                                        Myanmar +95
                                                    </Option>
                                                    <Option
                                                        value="+673"
                                                        value="+673">
                                                        Brunei +673
                                                    </Option>
                                                    <Option
                                                        value="+885"
                                                        value="+885">
                                                        Cambodia +885
                                                    </Option>
                                                    <Option
                                                        value="+886"
                                                        value="+886">
                                                        Laos +886
                                                    </Option> */}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={19}>
                                            <Form.Item
                                                name="merchant_phone_number"
                                                label="Nomor Ponsel Pemilik"
                                                validateTrigger={['onChange']}
                                                rules={[
                                                    // {
                                                    //     required: true,
                                                    //     message:
                                                    //         'Nomor ponsel harus diisi!',
                                                    // },
                                                    {
                                                        async validator(
                                                            rule,
                                                            value,
                                                        ) {
                                                            if (
                                                                !!value &&
                                                                form.getFieldValue(
                                                                    'merchant_phone_number_country_code',
                                                                ) == '+62'
                                                            ) {
                                                                if (
                                                                    !String(
                                                                        value,
                                                                    ).match(
                                                                        /^(^8)[0-9]+$/g,
                                                                    )
                                                                ) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            'Masukkan Nomor Ponsel yang valid',
                                                                        ),
                                                                    );
                                                                }
                                                            }
                                                        },
                                                    },
                                                    // {
                                                    //     ...(form.getFieldValue(
                                                    //         'merchant_phone_number_country_code',
                                                    //     ) === '+62'
                                                    //         ? {
                                                    //               pattern:
                                                    //                   new RegExp(
                                                    //                       /^(^8)[0-9]+$/g,
                                                    //                   ),
                                                    //               message:
                                                    //                   'Masukkan Nomor Ponsel yang valid ',
                                                    //           }
                                                    //         : {}),
                                                    // },
                                                ]}>
                                                <InputNumber
                                                    type="number"
                                                    onWheel={() =>
                                                        document.activeElement.blur()
                                                    }
                                                    placeholder="Masukkan Nomor Ponsel Pemilik"
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        name="merchant_nik"
                                        label="NIK Pemilik"
                                        rules={[
                                            // { required: true },
                                            {
                                                required: false,
                                                pattern: new RegExp(
                                                    /^[\d]{12,16}$/,
                                                ),
                                                message:
                                                    'NIK min 12 Digit, max 16 Digit',
                                            },
                                        ]}>
                                        <InputNumber
                                            onWheel={() =>
                                                document.activeElement.blur()
                                            }
                                            placeholder="Masukkan NIK Pemilik"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="merchant_npwp"
                                        label="NPWP Pemilik"
                                        rules={[
                                            // { required: true },
                                            {
                                                required: false,
                                                message: 'NPWP Max 15 Digit',
                                            },
                                        ]}>
                                        <InputNumber
                                            onWheel={() =>
                                                document.activeElement.blur()
                                            }
                                            placeholder="Masukkan NPWP Pemilik"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    <Row gutter={1}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="indv_nik_src"
                                                label="KTP Pemilik"
                                                rules={
                                                    [
                                                        // {
                                                        //     required:
                                                        //         fileList.indv_nik_src ===
                                                        //         ''
                                                        //             ? true
                                                        //             : false,
                                                        //     message:
                                                        //         'KTP Pemilik wajib diupload!',
                                                        // },
                                                    ]
                                                }>
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(file, 'nik');
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.indv_nik_src
                                                        .url ? (
                                                        fileList?.indv_nik_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.indv_nik_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .indv_nik_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .indv_nik_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="indv_npwp_src"
                                                label="NPWP Pemilik">
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(file, 'npwp');
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.indv_npwp_src
                                                        .url ? (
                                                        fileList?.indv_npwp_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.indv_npwp_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .indv_npwp_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .indv_npwp_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            {jenisUsaha === 'badanusaha' && (
                                <div className="badan-usaha">
                                    <Form.Item
                                        name="bussiness_name"
                                        label="Nama Badan Usaha"
                                        rules={[{ required: true }]}>
                                        <Input placeholder="Masukkan Nama Badan Usaha" />
                                    </Form.Item>
                                    <Form.Item
                                        name="bussiness_director"
                                        label="Nama Direktur"
                                        rules={[{ required: true }]}>
                                        <Input placeholder="Masukkan Nama Direktur" />
                                    </Form.Item>
                                    <Row gutter={24}>
                                        <Col span={5}>
                                            <Form.Item
                                                name="bussiness_phone_number_country_code"
                                                label="Kode Negara"
                                                rules={[{ required: true }]}>
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(
                                                        input,
                                                        option,
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase(),
                                                            ) >= 0
                                                    }
                                                    defaultValue={['+62']}
                                                    optionLabelProp="label"
                                                    onChange={(val) => {
                                                        setBPNCC(val);
                                                        form.validateFields([
                                                            'bussiness_phone_number',
                                                        ]);
                                                    }}>
                                                    {listCountryCode.map(
                                                        (item) => {
                                                            return (
                                                                <Option
                                                                    value={
                                                                        item.value
                                                                    }
                                                                    label={
                                                                        item.value
                                                                    }>
                                                                    {item.label}
                                                                </Option>
                                                            );
                                                        },
                                                    )}

                                                    {/* <Option
                                                        value="+60"
                                                        value="+60">
                                                        Malaysia +60
                                                    </Option>
                                                    <Option
                                                        value="+63"
                                                        value="+63">
                                                        Philippines +63
                                                    </Option>
                                                    <Option
                                                        value="+65"
                                                        value="+65">
                                                        Singapore +65
                                                    </Option>
                                                    <Option
                                                        value="+66"
                                                        value="+66">
                                                        Thailand +66
                                                    </Option>
                                                    <Option
                                                        value="+84"
                                                        value="+84">
                                                        Vietnam +84
                                                    </Option>
                                                    <Option
                                                        value="+95"
                                                        value="+95">
                                                        Myanmar +95
                                                    </Option>
                                                    <Option
                                                        value="+673"
                                                        value="+673">
                                                        Brunei +673
                                                    </Option>
                                                    <Option
                                                        value="+885"
                                                        value="+885">
                                                        Cambodia +885
                                                    </Option>
                                                    <Option
                                                        value="+886"
                                                        value="+886">
                                                        Laos +886
                                                    </Option> */}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={19}>
                                            <Form.Item
                                                name="bussiness_phone_number"
                                                label="Nomor Ponsel Direktur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Nomor ponsel harus diisi!',
                                                    },
                                                    {
                                                        async validator(
                                                            rule,
                                                            value,
                                                        ) {
                                                            if (
                                                                form.getFieldValue(
                                                                    'bussiness_phone_number_country_code',
                                                                ) == '+62'
                                                            ) {
                                                                if (
                                                                    !String(
                                                                        value,
                                                                    ).match(
                                                                        /^(^8)[0-9]+$/g,
                                                                    )
                                                                ) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            'Masukkan Nomor Ponsel yang valid',
                                                                        ),
                                                                    );
                                                                }
                                                            }
                                                        },
                                                    },
                                                ]}>
                                                <InputNumber
                                                    onWheel={() =>
                                                        document.activeElement.blur()
                                                    }
                                                    type="number"
                                                    placeholder="Masukkan Nomor Ponsel Direktur"
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        name="bussiness_owner_nik"
                                        label="NIK Direktur"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            placeholder="Masukkan NIK Direktur"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="bussiness_owner_npwp"
                                        label="NPWP Direktur"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            placeholder="Masukkan NPWP Direktur"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="bussiness_npwp"
                                        label="NPWP Badan Usaha"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            placeholder="Masukkan NPWP Badan Usaha"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="bus_dir_nik_src"
                                                label="KTP Direktur"
                                                rules={[
                                                    {
                                                        required:
                                                            fileList.bus_dir_nik_src ===
                                                            ''
                                                                ? true
                                                                : false,
                                                        message:
                                                            'KTP Direktur wajib diupload!',
                                                    },
                                                ]}
                                                required>
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(
                                                            file,
                                                            'nik_direktur',
                                                        );
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.bus_dir_nik_src
                                                        .url ? (
                                                        fileList?.bus_dir_nik_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.bus_dir_nik_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .bus_dir_nik_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .bus_dir_nik_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="bus_dir_npwp_src"
                                                label="NPWP Direktur"
                                                rules={[
                                                    {
                                                        required:
                                                            fileList.bus_dir_npwp_src ===
                                                            ''
                                                                ? true
                                                                : false,
                                                        message:
                                                            'NPWP Direktur wajib diupload!',
                                                    },
                                                ]}
                                                required>
                                                {/* <UploadImage fieldName="bus_dir_npwp_src" fileList={fileList} setFileList={setFileList} /> */}
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(
                                                            file,
                                                            'npwp_direktur',
                                                        );
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.bus_dir_npwp_src
                                                        .url ? (
                                                        fileList?.bus_dir_npwp_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.bus_dir_npwp_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .bus_dir_npwp_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .bus_dir_npwp_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="bus_ent_npwp_src"
                                                label="NPWP Badan Usaha"
                                                rules={[
                                                    {
                                                        required:
                                                            fileList.bus_ent_npwp_src ===
                                                            ''
                                                                ? true
                                                                : false,
                                                        message:
                                                            'NPWP Badan Usaha wajib diupload!',
                                                    },
                                                ]}
                                                required>
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(
                                                            file,
                                                            'npwp_usaha',
                                                        );
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.bus_ent_npwp_src
                                                        .url ? (
                                                        fileList?.bus_ent_npwp_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.bus_ent_npwp_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .bus_ent_npwp_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .bus_ent_npwp_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="bus_ent_akta_src"
                                                label="Akta Badan Usaha"
                                                rules={[
                                                    {
                                                        required:
                                                            fileList.bus_ent_akta_src ===
                                                            ''
                                                                ? true
                                                                : false,
                                                        message:
                                                            'Akta Badan Usaha wajib diupload!',
                                                    },
                                                ]}
                                                required>
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(file, 'akta');
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.bus_ent_akta_src
                                                        .url ? (
                                                        fileList?.bus_ent_akta_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.bus_ent_akta_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .bus_ent_akta_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .bus_ent_akta_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="bus_ent_siup_src"
                                                label="SIUP"
                                                rules={[
                                                    {
                                                        required:
                                                            fileList.bus_ent_siup_src ===
                                                            ''
                                                                ? true
                                                                : false,
                                                        message:
                                                            'SIUP Badan Usaha wajib diupload!',
                                                    },
                                                ]}
                                                required>
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(file, 'siup');
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.bus_ent_siup_src
                                                        .url ? (
                                                        fileList?.bus_ent_siup_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.bus_ent_siup_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .bus_ent_siup_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .bus_ent_siup_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="bus_ent_nib_src"
                                                label="NIB/TDP/SKDU/SKDP"
                                                rules={[
                                                    {
                                                        required:
                                                            fileList.bus_ent_nib_src ===
                                                            ''
                                                                ? true
                                                                : false,
                                                        message:
                                                            'NIB/TDP/SKDU/SKDP Badan Usaha wajib diupload!',
                                                    },
                                                ]}
                                                required>
                                                {/* <UploadImage fieldName="bus_ent_nib_src" fileList={fileList} setFileList={setFileList} /> */}
                                                <Upload
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    customRequest={({
                                                        file,
                                                    }) => {
                                                        postImage(file, 'nib');
                                                    }}
                                                    onChange={handleChange}
                                                    beforeUpload={
                                                        validateHiResImage
                                                    }>
                                                    {fileList.bus_ent_nib_src
                                                        .url ? (
                                                        fileList?.bus_ent_nib_src?.url.split(
                                                            '.',
                                                        )[
                                                            fileList?.bus_ent_nib_src?.url.split(
                                                                '.',
                                                            ).length - 1
                                                        ] == 'pdf' ? (
                                                            <div>
                                                                {
                                                                    fileList
                                                                        .bus_ent_nib_src
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={
                                                                    fileList
                                                                        .bus_ent_nib_src
                                                                        .url
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            <Form.Item
                                name="pic_name"
                                label="Nama Pengurus"
                                extra="Diisi dengan nama orang yang berhubungan langsung dengan tim operasional Kiddo.id."
                                // rules={[{ required: true }]}
                            >
                                <Input placeholder="Masukkan Nama Pengurus" />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col span={5}>
                                    <Form.Item
                                        name="pic_phone_number_country_code"
                                        label="Kode Negara"
                                        rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }
                                            defaultValue={['+62']}
                                            optionLabelProp="label"
                                            onChange={(val) => {
                                                setPPNCC(val);
                                                form.validateFields([
                                                    'pic_phone_number',
                                                ]);
                                            }}>
                                            {listCountryCode.map(
                                                (item, key) => {
                                                    return (
                                                        <Option
                                                            key={key}
                                                            value={item?.value}>
                                                            {item.label}
                                                        </Option>
                                                    );
                                                },
                                            )}
                                            {/* <Option value="+62" label="+62">
                                                Indonesia +62
                                            </Option>
                                            <Option value="+60" value="+60">
                                                Malaysia +60
                                            </Option>
                                            <Option value="+63" value="+63">
                                                Philippines +63
                                            </Option>
                                            <Option value="+65" value="+65">
                                                Singapore +65
                                            </Option>
                                            <Option value="+66" value="+66">
                                                Thailand +66
                                            </Option>
                                            <Option value="+84" value="+84">
                                                Vietnam +84
                                            </Option>
                                            <Option value="+95" value="+95">
                                                Myanmar +95
                                            </Option>
                                            <Option value="+673" value="+673">
                                                Brunei +673
                                            </Option>
                                            <Option value="+885" value="+885">
                                                Cambodia +885
                                            </Option>
                                            <Option value="+886" value="+886">
                                                Laos +886
                                            </Option> */}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={19}>
                                    <Form.Item
                                        name="pic_phone_number"
                                        label="Nomor Ponsel Pengurus"
                                        validateTrigger={['onChange']}
                                        rules={[
                                            // {
                                            //     required: true,
                                            //     message:
                                            //         'Nomor ponsel harus diisi!',
                                            // },
                                            {
                                                async validator(rule, value) {
                                                    if (
                                                        !!value &&
                                                        form.getFieldValue(
                                                            'pic_phone_number_country_code',
                                                        ) == '+62'
                                                    ) {
                                                        if (
                                                            !String(
                                                                value,
                                                            ).match(
                                                                /^(^8)[0-9]+$/g,
                                                            )
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Masukkan Nomor Ponsel yang valid',
                                                                ),
                                                            );
                                                        }
                                                    }
                                                },
                                            },
                                        ]}>
                                        <InputNumber
                                            onWheel={() =>
                                                document.activeElement.blur()
                                            }
                                            type="number"
                                            placeholder="Masukkan Nomor Ponsel Pengurus"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        {/* {!initVal && ( */}
                        {isShowPassword && (
                            <Form.Item
                                name="password"
                                label="Password Merchant"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password harus diisi!',
                                    },
                                ]}>
                                <Input.Password
                                    autoComplete="new-password"
                                    placeholder="Masukkan Password Merchant"
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                />
                            </Form.Item>
                        )}

                        {/* )} */}
                        {/* <Form.Item
                            valuePropName="checked"
                            name="aggre"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Wajib menyetujui Syarat dan Ketentuan yang berlaku',
                                },
                            ]}>
                            <Checkbox>
                                Setuju dengan{' '}
                                <a
                                    href="https://kiddo.id/snk-merchant/"
                                    rel="noreferrer"
                                    target="_blank">
                                    Perjanjian Kerja Sama Merchant dan Prosedur
                                    Operasi Standar(POS)
                                </a>{' '}
                                yang berlaku.
                            </Checkbox>
                        </Form.Item> */}
                        {/* <Col span={8}> */}
                        <Form.Item
                            name="status_merchant"
                            label="Status Merchant">
                            <Select disabled>
                                <Option value=""></Option>
                                <Option value={1}>Bronze</Option>
                                <Option value={2}>Silver</Option>
                                <Option value={3}>Gold</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="approve"
                            label="Approve"
                            rules={[
                                {
                                    required: true,
                                    message: 'Approve Wajib dipilih!',
                                },
                            ]}>
                            <Select placeholder="Pilih Approve">
                                <Option value="Y">Yes</Option>
                                <Option value="N">No</Option>
                            </Select>
                        </Form.Item>
                        {/* </Col> */}
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            disabled={isLoading ? true : false}>
                            Submit
                        </Button>
                        {/* <Button type="secondary" htmlType="reset" style={{ marginLeft: 10 }}>Reset</Button> */}
                    </Col>
                </Row>
            </Form>
        </CreateMerchantStyle>
    );
};

export default FormMerchant;
