import styled from '@emotion/styled';

const PageNotFoundStyle = styled.div`
  .page-404 {
    text-align: center;
    position: relative;
    z-index: 0;
    margin-top: 20px;
    .page_404 {
      padding: 40px 0;
      background: #fff;
      // font-family: 'Arvo', serif;
    }

    .page_404 img {
      width: 100%;
    }

    .four_zero_four_bg {
      background-image: url('/404.gif');
      height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      margin: 40px 0px;
    }

    .four_zero_four_bg h1 {
      font-size: 80px;
      font-weight: 700;
    }

    .four_zero_four_bg h3 {
      font-size: 80px;
    }

    .link_404 {
      color: #fff !important;
      padding: 10px 20px;
      background: #39ac31;
      margin: 20px 0;
      display: inline-block;
    }
    .contant_box_404 {
      margin-top: -50px;
    }
  }
`;

export default PageNotFoundStyle;
