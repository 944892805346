import serviceApi from '../../../services/index';

const serviceFormMerchant = {
    getProvince: async () => {
        return await serviceApi({
            method: 'GET',
            url: `v1/locations/provinces`,
            noVersion: true,
            appType: 1,
            token: true,
        });
    },
    getCity: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/locations/cities`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    getSubDistrict: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/locations/subdistricts`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    getShippingList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/shippings`,
            noVersion: true,
            appType: 1,
            token: true,
        });
    },

    postPhoto: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/merchants/photo/upload`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    onCheckExistEmail: async (formData) => {
        return await serviceApi({
            method: 'POST',
            url: `/users/email/exist`,
            appType: 1,
            token: true,
            versin: 'v1',
            data: formData,
            formData: true,
        });
    },
};

export default serviceFormMerchant;
