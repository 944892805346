import React from 'react';
import ProductPPOBStyle from './index.style';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Col,
    Form,
    Input,
    PageHeader,
    Row,
    Select,
    InputNumber,
} from 'antd';
import servicePPOBProduct from '../index.service';
import DebounceSelectComponent from '../../../../components/DebounceSelect';
import { notifyError, notifySuccess } from '../../../../utils';

export default function ProductPPOB() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [dataCategory] = React.useState([
        {
            label: 'Pln-prepaid',
            value: 'pln-prepaid',
        },
        {
            label: 'Pln-postpaid',
            value: 'pln-postpaid',
        },
        {
            label: 'Bpjs-kesehatan',
            value: 'bpjs-kesehatan',
        },
        {
            label: 'Bpjs-ketenagakerjaan',
            value: 'bpjs-keternagakerjaan',
        },
        {
            label: 'Pulsa',
            value: 'pulsa',
        },
        {
            label: 'Data',
            value: 'data',
        },
        {
            label: 'Pbb',
            value: 'pbb',
        },
    ]);
    const [selectedProduct, setSelectedProduct] = React.useState();
    const [productFetch, setProductFetch] = React.useState([]);
    const [loadingFetchProduct, setLoadingFeatchProduct] = React.useState(true);

    const routes = [
        {
            path: '',
            breadcrumbName: 'PRODUK PPOB',
        },
        {
            path: '',
            breadcrumbName: 'BUAT',
        },
    ];

    React.useEffect(() => {
        onGetDataProduct();
    }, []);

    const onGetDataProduct = async () => {
        setLoadingFeatchProduct(true);
        const result = await servicePPOBProduct.getProductOuter();
        if (result.isSuccess) {
            const resultData =
                (result.response.payload &&
                    result.response.payload.list.length > 0 &&
                    result.response.payload.list.map((item) => ({
                        ...item,
                        label: item.label,
                        value: item.product_id,
                    }))) ||
                [];
            setProductFetch(resultData.filter((item) => item.enabled == 1));
            setLoadingFeatchProduct(false);
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        const resultData = {
            ...values,
            margin: Number(values.margin) || 0,
            ppob_id: Number(values.ppob_id),
        };
        const results = await servicePPOBProduct.addProduct(resultData);
        if (results.isSuccess) {
            notifySuccess('Sukses menambahkan data produk');
            history.push('/ppob/product');
        } else {
            notifyError(results.message);
            setLoading(false);
        }
    };

    return (
        <ProductPPOBStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Form Buat Produk"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="ppob_name"
                            label="Nama Produk PPOB"
                            style={{ marginBottom: '12px' }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Produk wajib diisi',
                                },
                            ]}>
                            <Select
                                loading={loadingFetchProduct}
                                onChange={(value) => {
                                    const findProduct = productFetch.find(
                                        (item) => item.product_id == value,
                                    );
                                    setSelectedProduct(findProduct);
                                    form.setFieldValue(
                                        'ppob_name',
                                        findProduct.label,
                                    );
                                    form.setFieldValue(
                                        'ppob_id',
                                        findProduct.product_id,
                                    );
                                    form.setFieldValue(
                                        'ppob_type',
                                        findProduct.type,
                                    );
                                    form.setFieldValue(
                                        'deposit_price',
                                        findProduct.price,
                                    );
                                }}
                                showSearch
                                mode="single"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={productFetch}
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Pilih / Cari Produk PPOB"></Select>
                            {/* <DebounceSelectComponent
                                mode="single"
                                onChange={(value) => {
                                    const findProduct = productFetch.find(
                                        (item) =>
                                            item.product_id == value.value,
                                    );
                                    console.log(`FIND PRODUCT :`, findProduct);
                                    setSelectedProduct(findProduct);
                                    form.setFieldValue(
                                        'ppob_name',
                                        findProduct.ppob_name,
                                    );
                                    form.setFieldValue(
                                        'ppob_id',
                                        findProduct.value,
                                    );
                                    form.setFieldValue(
                                        'ppob_type',
                                        findProduct.ppob_type,
                                    );
                                    form.setFieldValue(
                                        'deposit_price',
                                        findProduct.deposit_price,
                                    );
                                }}
                                value={form.getFieldValue('name')}
                                placeholder="Pilih / Cari Produk PPOB"
                                fetchList={async (value) => {
                                    const params = {
                                        queries: {
                                            page: 1,
                                            rows: 10,
                                        },
                                        filters: {
                                            ppob_name: value,
                                        },
                                    };
                                    const result =
                                        await servicePPOBProduct.getProductOuter(
                                            params,
                                        );
                                    if (result.isSuccess) {
                                        const resultData =
                                            result.response.payload &&
                                            result.response.payload.list
                                                .length > 0 &&
                                            result.response.payload.list.map(
                                                (item) => ({
                                                    ...item,
                                                    label: item.label,
                                                    value: item.product_id,
                                                }),
                                            );
                                        setProductFetch(resultData);
                                        return resultData;
                                    }
                                    return [];
                                }}
                            /> */}
                        </Form.Item>
                    </Col>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="ppob_id"
                            label="ID PPOB"
                            style={{ marginBottom: '12px' }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Kategori PPOB wajib diisi',
                                },
                            ]}>
                            <Input
                                disabled
                                size="large"
                                type={'text'}
                                placeholder="Masukkan Nama Provider"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="ppob_type"
                            label="Kategori PPOB"
                            style={{ marginBottom: '12px' }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Kategori PPOB wajib diisi',
                                },
                            ]}>
                            <Select
                                mode="single"
                                options={dataCategory}
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Pilih Kategori PPOB"></Select>
                        </Form.Item>
                    </Col>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="deposit_price"
                            label="Harga Modal"
                            style={{ marginBottom: '12px' }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Kategori PPOB wajib diisi',
                                },
                            ]}>
                            <InputNumber
                                disabled
                                style={{ width: '100%' }}
                                prefix={'Rp.'}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                size="large"
                                placeholder="Masukkan Harga Modal"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="selling_price"
                            label="Harga Jual (+ Margin u/ Pulsa)"
                            style={{ marginBottom: '12px' }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Harga Jual wajib diisi',
                                },
                            ]}>
                            <InputNumber
                                style={{ width: '100%' }}
                                prefix={'Rp.'}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                size="large"
                                placeholder="Masukkan Harga Jual"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="margin"
                            label="Margin (Untuk Tagihan Pascabayar)"
                            style={{ marginBottom: '12px' }}
                            validateTrigger={['onChange', 'onBlur']}>
                            <Input
                                style={{ width: '100%' }}
                                prefix={'Rp.'}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                size="large"
                                type={'text'}
                                placeholder="Masukkan Margin"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={loading}
                            style={{
                                marginRight: 10,
                                marginTop: '24px',
                                width: 200,
                            }}
                            disabled={loading ? true : false}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                history.goBack();
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </ProductPPOBStyle>
    );
}
