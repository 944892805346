import serviceApi from '../../../services/index';

const serviceKiddofestBanner = {
    getListBanner: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/banner`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postDataBanner: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/kiddofest/banner`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updateDataBanner: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kiddofest/banner`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getdetailBanner: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/banner/${id}`,
            version: 'v1',
            token: true,
        });
    },
    deleteBanner: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/kiddofest/banner/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceKiddofestBanner;
