import serviceApi from '../../../services/index';

const serviceMerchantList = {
    getMerchantList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/merchants`,
            version: 'v1',
            appType: 1,
            token: true,
            params: params,
        });
    },

    getMerchantList2: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/merchants/names`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },

};

export default serviceMerchantList;
