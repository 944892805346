import styled from '@emotion/styled';

const UpdateProductStyle = styled.div`
  .avatar-uploader > .ant-upload {
    width: 300px;
    height: 300px;
  } 
`;

export default UpdateProductStyle;
