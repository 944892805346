import React from 'react';
import { Button, PageHeader, Table } from 'antd';
import TrackerMerchantAndProductStyle from './index.style';
import { useHistory } from 'react-router-dom';
import servicesMerchantAndProduct from './index.service';
import { formatStringDate } from '../../../utils/format-date';

const TrackerMerchantAndProduct = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        groups: {
            unit_time: 'day',
        },
    });
    const [dataMercantProduct, setDataMerchantProduct] = React.useState([]);
    const [columnLocation, setColumnLocation] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const onHandleColumnLocation = (param = []) => {
        if (param && param.length > 0) {
            setColumnLocation(() => [
                ...(param.length > 0 &&
                    param.map((item) => {
                        return {
                            title: item,
                            key: item.split(' ').join('_').toLowerCase(),
                            dataIndex: item.split(' ').join('_').toLowerCase(),
                            width: 150,
                        };
                    })),
            ]);
        }
    };

    const manageLocation = (data = []) => {
        let tempData = { ...data };
        for (let i = 0; i < data.payload.length; i++) {
            for (
                let j = 0;
                j < data?.payload[i]?.product_locations?.length;
                j++
            ) {
                tempData.payload[i][
                    tempData.payload[i].product_locations[j].name
                        .split(' ')
                        .join('_')
                        .toLowerCase()
                ] = tempData?.payload[i]?.product_locations[j].count;
            }
        }
        return tempData;
    };

    const handleLoadData = async (param) => {
        setIsLoading(true);
        let resultLocation =
            await servicesMerchantAndProduct.getDataProductLocation();
        if (resultLocation.isSuccess) {
            onHandleColumnLocation(resultLocation.response.payload);
        }
        let resultDataMerchantProduct =
            await servicesMerchantAndProduct.getData(param);
        if (resultDataMerchantProduct.isSuccess) {
            setDataMerchantProduct(
                manageLocation(resultDataMerchantProduct.response),
            );
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: 'Tanggal',
            dataIndex: 'timestamp',
            fixed: 'left',
            width: 150,
            filters: [
                { text: 'Dialy', value: 'day' },
                { text: 'Weekly', value: 'week' },
            ],
            filterMultiple: false,
            filteredValue: [queryString.groups.unit_time] || null,
            render: (_, record) => {
                return formatStringDate(record.timestamp, 'DD-MM-YYYY', 'LL');
            },
        },
        {
            title: 'Product Type Available',
            children: [
                {
                    title: 'Aktivitas',
                    key: 'aktivitas',
                    dataIndex: 'product_type_activity',
                    width: 150,
                },
                {
                    title: 'Kursus',
                    dataIndex: 'product_type_course',
                    key: 'kursus',
                    width: 150,
                },
                {
                    title: 'Daycare',
                    dataIndex: 'product_type_daycare',
                    key: 'daycare',
                    width: 150,
                },
                {
                    title: 'Activity Kit',
                    dataIndex: 'product_type_activity_kit',
                    key: 'activitykit',
                    width: 150,
                },
                {
                    title: 'Experience',
                    dataIndex: 'product_type_experience',
                    key: 'experience',
                    width: 150,
                },
                {
                    title: 'Online Class',
                    dataIndex: 'product_type_online_class',
                    key: 'onlineclass',
                    width: 150,
                },
                {
                    title: 'Kesehatan',
                    dataIndex: 'product_type_kesehatan',
                    key: 'kesehatan',
                    width: 150,
                },
                {
                    title: 'Keluarga',
                    dataIndex: 'product_type_keluarga',
                    key: 'keluarga',
                    width: 150,
                },
            ],
        },
        {
            title: 'Product Category',
            children: [
                {
                    title: 'Festival',
                    dataIndex: 'product_category_festival',
                    key: 'aktivitas',
                    width: 150,
                },
                {
                    title: 'Kerajinan Tangan',
                    dataIndex: 'product_category_handycraft',
                    key: 'kerajinan-tangan',
                    width: 150,
                },
                {
                    title: 'Kognitif',
                    dataIndex: 'product_category_cognitive',
                    key: 'kognitif',
                    width: 150,
                },
                {
                    title: 'Kompetisi',
                    dataIndex: 'product_category_competition',
                    key: 'kompetisi',
                    width: 150,
                },
                {
                    title: 'Komunikasi',
                    dataIndex: 'product_category_communication',
                    key: 'komunikasi',
                    width: 150,
                },
                {
                    title: 'Kulinari',
                    dataIndex: 'product_category_culinary',
                    key: 'kulinari',
                    width: 150,
                },
                {
                    title: 'Motorik',
                    dataIndex: 'product_category_motorize',
                    key: 'motorik',
                    width: 100,
                },
                {
                    title: 'Olahraga',
                    dataIndex: 'product_category_sport',
                    key: 'olahraga',
                    width: 150,
                },
                {
                    title: 'Parenting',
                    dataIndex: 'product_category_parenting',
                    key: 'parenting',
                    width: 150,
                },
                {
                    title: 'Perawatan',
                    dataIndex: 'product_category_nursing',
                    key: 'perawatan',
                    width: 150,
                },
                {
                    title: 'Pertunjukan',
                    dataIndex: 'product_category_performance',
                    key: 'pertunjukan',
                    width: 150,
                },
                {
                    title: 'Playdate',
                    dataIndex: 'product_category_playdate',
                    key: 'playdate',
                    width: 150,
                },
                {
                    title: 'Rekreasi',
                    dataIndex: 'product_category_recreation',
                    key: 'rekreasi',
                    width: 150,
                },
                {
                    title: 'Sains',
                    dataIndex: 'product_category_science',
                    key: 'sains',
                    width: 150,
                },
                {
                    title: 'Seni Drama',
                    dataIndex: 'product_category_drama',
                    key: 'seni-drama',
                    width: 150,
                },
                {
                    title: 'Seni Lukis',
                    dataIndex: 'product_category_painting',
                    key: 'seni-lukis',
                    width: 150,
                },
                {
                    title: 'Seni Musik',
                    dataIndex: 'product_category_music',
                    key: 'seni-musik',
                    width: 150,
                },
                {
                    title: 'Seni Tari',
                    dataIndex: 'product_category_dance',
                    key: 'seni-tari',
                    width: 150,
                },
                {
                    title: 'Sensorik',
                    dataIndex: 'product_category_sensory',
                    key: 'sensorik',
                    width: 150,
                },
                {
                    title: 'Teknologi',
                    dataIndex: 'product_category_technology',
                    key: 'teknologi',
                    width: 150,
                },
            ],
        },
        {
            title: 'Product Location',
            children: [...columnLocation],
        },
        {
            title: 'Product Age Range',
            children: [
                {
                    title: '0 - 6 month',
                    dataIndex: 'age_zero_to_six_month',
                    key: '0_6_month',
                    width: 150,
                },
                {
                    title: '7 - 12 month',
                    dataIndex: 'age_seven_to_twelve_month',
                    key: '7_12_month',
                    width: 150,
                },
                {
                    title: '13 - 18 month',
                    dataIndex: 'age_thirteen_to_eighteen_month',
                    key: '13_18_month',
                    width: 150,
                },
                {
                    title: '1 years',
                    dataIndex: 'age_one_year',
                    key: '1_years',
                    width: 150,
                },
                {
                    title: '2 years',
                    dataIndex: 'age_two_year',
                    key: '2_years',
                    width: 150,
                },
                {
                    title: '3 years',
                    dataIndex: 'age_three_year',
                    key: '3_years',
                    width: 150,
                },
                {
                    title: '4 years',
                    dataIndex: 'age_four_year',
                    key: '4_years',
                    width: 150,
                },
                {
                    title: '5 years',
                    dataIndex: 'age_five_year',
                    key: '5_years',
                    width: 150,
                },
                {
                    title: '6 years',
                    dataIndex: 'age_six_year',
                    key: '6_years',
                    width: 150,
                },
                {
                    title: '7 years',
                    dataIndex: 'age_seven_year',
                    key: '7_years',
                    width: 150,
                },
                {
                    title: '8 years',
                    dataIndex: 'age_eight_year',
                    key: '8_years',
                    width: 150,
                },
                {
                    title: '9 years',
                    dataIndex: 'age_nine_year',
                    key: '9_years',
                    width: 150,
                },
                {
                    title: '10 years',
                    dataIndex: 'age_ten_year',
                    key: '10_years',
                    width: 150,
                },
                {
                    title: '11 years',
                    dataIndex: 'age_eleven_year',
                    key: '11_years',
                    width: 150,
                },
                {
                    title: '12 years',
                    dataIndex: 'age_twelve_year',
                    key: '12_years',
                    width: 150,
                },
                {
                    title: '13 years',
                    dataIndex: 'age_thirteen_year',
                    key: '13_years',
                    width: 150,
                },
                {
                    title: '14 years',
                    dataIndex: 'age_fourteen_year',
                    key: '14_years',
                    width: 150,
                },
                {
                    title: '15 years',
                    dataIndex: 'age_fifteen_year',
                    key: '15_years',
                    width: 150,
                },
                {
                    title: '16 years',
                    dataIndex: 'age_sixeen_year',
                    key: '16_years',
                    width: 150,
                },
                {
                    title: '17 years',
                    dataIndex: 'age_seventeen_year',
                    key: '17_years',
                    width: 150,
                },
                {
                    title: '18 years',
                    dataIndex: 'age_eighteen_year',
                    key: '18_years',
                    width: 150,
                },
                {
                    title: '19 years',
                    dataIndex: 'age_nineteen_year',
                    key: '19_years',
                    width: 150,
                },
                {
                    title: 'Parents',
                    dataIndex: 'age_parents',
                    key: 'age_parents',
                    width: 150,
                },
                {
                    title: 'All Age',
                    dataIndex: 'age_all_age',
                    key: 'all_age',
                    width: 150,
                },
            ],
        },
        {
            title: 'Merchant Location',
            width: 150,
        },
        {
            title: 'Number Of Active Merchant',
            width: 150,
            dataIndex: 'active_merchant',
        },
        {
            title: 'Number Of Total Merchant',
            width: 150,
            dataIndex: 'total_merchant',
        },
        {
            title: 'Number Of Active Premium Merchant',
            width: 150,
            dataIndex: 'premium_merchant',
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Tracker Report',
        },
        {
            path: 'merchant-and-product',
            breadcrumbName: 'Merchant And Product',
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        if (!!filters.timestamp) {
            paramResult.groups.unit_time = filters.timestamp[0];
        }
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;
        setQueryString(paramResult);
        handleLoadData(paramResult);
    };

    return (
        <TrackerMerchantAndProductStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Tracker Report"
                    breadcrumb={{ routes }}
                    subTitle="Table of merchant and product"
                />
            </div>
            <Table
                scroll={{ x: 1500, y: 1000 }}
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataMercantProduct.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataMercantProduct.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </TrackerMerchantAndProductStyle>
    );
};

export default TrackerMerchantAndProduct;
