import serviceApi from '../../../services/index';

const serviceCampaignPage = {
    getListCampaign: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/campaign/page`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    addCampaign: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: '/campaign/page',
            version: 'v1',
            token: true,
            data: data,
        });
    },

    onGetDetailCampaign: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/campaign/page/${id}`,
            version: 'v1',
            token: true,
        });
    },

    updateCampaign: async (id, data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/campaign/page/${id}`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    onDeleteCampaign: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/campaign/page/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceCampaignPage;
