import styled from '@emotion/styled';

const FlashSaleEditStyle = styled.div`
    display: block;

    .avatar-uploader > .ant-upload {
        width: 250px;
        height: 250px;
    }

    .form-uploader {
        margin-top: 24px;
    }
`;

export default FlashSaleEditStyle;
