import serviceApi from '../../../services';

const serviceCodePromoPremium = {
    getCodePromoPremiumList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/premium/promo-codes`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    deleteCodePromo: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/premium/promo-codes/${id}`,
            version: 'v1',
            token: true,
        });
    },
    getProduct: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/products`,
            version: 'v2',
            token: true,
            params: params,
        });
    },
    getMerchant: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/merchants`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getUser: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/users`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getPayment: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/payments`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    onSubmitPromoPremium: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/premium/promo-codes`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updatePromPremium: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/premium/promo-codes`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getCodePromoById: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/premium/promo-codes/${id}`,
            version: 'v1',
            token: true,
        });
    },
    getDetailUsers: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/users?user_id=${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceCodePromoPremium;
