import serviceApi from '../../services';

const serviceFlashSale = {
    listFlashSale: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/flashsale`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getDetailFlashSale: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/flashsale/${id}`,
            version: 'v1',
            token: true,
        });
    },
    createFlashSale: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/flashsale`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updateFlashSale: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/flashsale`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getProduct: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/products`,
            version: 'v2',
            token: true,
            params: params,
        });
    },
    getDetailProduct: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/products?products_id=${id}`,
            version: 'v2',
            token: true,
        });
    },
    onUploadImage: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/upload`,
            version: 'v1',
            token: true,
            data: data,
            formData: true,
        });
    },
    onUpdateImage: async (data, id) => {
        return await serviceApi({
            method: 'POST',
            url: `/upload/${id}`,
            version: 'v1',
            token: true,
            data: data,
            formData: true,
        });
    },
};

export default serviceFlashSale;
