import React from 'react';

const Logo = ({ width = 175, height = 72 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 175 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ zIndex: 1 }}
    >
      <g id='Logo Kiddo'>
        <path
          id='Path'
          d='M28.3792 0.480141C22.8277 0.480141 18.3273 4.98053 18.3273 10.532C18.3273 16.0835 22.8277 20.5839 28.3792 20.5839C33.9307 20.5839 38.4311 16.0835 38.4311 10.532C38.4233 4.98375 33.9275 0.48792 28.3792 0.480141V0.480141Z'
          fill='#4DBFAA'
        />
        <path
          id='Path_2'
          d='M49.028 15.2544C47.8464 15.276 46.6894 15.5956 45.6642 16.1836C44.3933 16.853 42.9472 18.3612 42.5574 18.8102C39.9054 21.7815 33.8641 26.3428 25.3627 25.3063C17.1043 24.2867 13.9156 14.9211 13.427 13.2886C12.1758 9.12268 10.6619 7.10891 6.60616 7.04113C4.85384 7.0298 3.16981 7.71992 1.92938 8.9577C0.688957 10.1955 -0.00477208 11.878 0.00280805 13.6304C0.00280805 15.8164 2.04764 19.1944 3.23105 21.6967C5.2081 25.8711 6.93379 29.8874 8.28666 38.7163C9.24976 44.9921 9.44464 48.884 4.89742 60.0911C4.08683 61.8252 3.21975 63.5368 3.2254 65.3331C3.2254 67.2508 4.13202 69.1516 5.36062 70.1825C5.89725 70.6344 7.90254 72.0607 9.98127 71.928C15.774 71.558 16.74 66.0194 17.9544 62.4523C18.6944 60.2747 19.1915 56.9222 21.0302 56.2387C23.0694 55.4704 25.2667 55.8461 26.1762 59.3003C26.6506 61.0966 27.7465 65.8302 33.4291 66.0222C37.0725 66.1437 40.0296 63.0651 40.0296 59.4217C40.0291 58.6506 39.8924 57.8857 39.6258 57.1622C39.2767 56.4487 38.8716 55.764 38.4141 55.1146C37.8492 54.352 36.0021 51.9485 34.9571 50.2341C29.5569 41.3825 34.5984 37.0556 39.4761 33.8528C42.5433 31.839 48.0649 29.845 52.5783 27.4019C55.8828 25.6141 55.6201 22.7615 55.6201 21.8521C55.6185 18.2111 52.669 15.259 49.028 15.2544Z'
          fill='#FCBA2B'
        />
        <path
          id='Path_3'
          d='M52.2055 38.8265C51.9516 38.1903 51.6435 37.577 51.2847 36.9935C50.7053 36.0932 49.7901 35.4617 48.7428 35.2395C47.6921 34.9571 46.3251 35.0842 44.5712 35.5954C44.0063 35.7564 43.5205 35.923 43.0941 36.0897C41.439 36.7449 40.5098 37.5019 40.168 38.5186C39.8856 39.3349 39.959 40.2669 40.3629 41.2921C40.5504 41.7543 40.7703 42.2026 41.021 42.6337C42.1168 44.5486 44.4102 49.1608 45.1728 51.0814C45.8264 52.7378 46.368 54.4362 46.794 56.1652C47.0109 57.0733 47.2797 57.9682 47.5989 58.8455C48.0435 60.0506 49.1167 60.912 50.3894 61.0853C50.6286 61.1221 50.8704 61.1401 51.1124 61.1389C52.2236 61.0972 53.3174 60.8491 54.3378 60.4074C55.0734 60.1015 55.7803 59.731 56.4505 59.3003C57.5708 58.6107 58.2749 57.4091 58.3287 56.0946C58.4049 55.1541 58.2496 53.2533 56.0042 47.5679L52.2055 38.8265Z'
          fill='#4DBFAA'
        />
        <path
          id='Shape'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M89.8627 22.1402C90.4501 26.4953 90.9613 30.955 91.3737 35.3864C91.7239 39.1315 92.0487 42.9416 92.334 46.7093C92.5204 49.3133 92.3481 51.2282 91.8087 52.5726C91.2015 54.0883 89.9559 55.2585 88.4053 55.7698C86.6392 56.3673 84.8236 56.8069 82.9797 57.0831C81.2455 57.3825 79.486 57.6169 77.7546 57.7779C76.9892 57.8514 76.2295 57.9022 75.4754 57.9389H74.9105C72.3918 57.9248 69.916 57.2852 67.7056 56.0777C65.6466 54.9564 64.1271 53.177 63.1866 50.7792C62.3195 48.5818 61.7292 45.8365 61.427 42.6224C61.026 38.3435 61.3282 35.039 62.3449 32.514C63.4973 29.6134 65.1495 27.69 67.2537 26.7891C68.8518 26.0653 70.5508 25.5891 72.2923 25.3769C73.9917 25.1932 75.7105 25.3409 77.3536 25.8118C77.8658 25.9819 78.3938 26.1001 78.9296 26.1649C78.9126 26.0971 78.9126 26.0011 78.9126 25.8824C78.8081 24.7809 78.4523 22.7192 77.8478 19.7536C77.7514 19.2955 77.6798 18.8326 77.6332 18.3668C77.5185 17.467 77.6292 16.5528 77.9552 15.7063C78.5285 14.232 80.1243 13.3423 82.686 13.1022C86.1684 12.7689 88.4816 14.2941 89.182 17.4009C89.404 18.4058 89.57 19.4222 89.6791 20.4455C89.7356 21.0556 89.7949 21.6092 89.8627 22.1402ZM80.3643 46.8533C80.6439 46.6421 80.8197 46.3214 80.8473 45.9721C80.87 45.4597 80.8577 44.9464 80.8106 44.4357L80.5818 41.7158C80.4688 39.9929 80.3869 38.8632 80.3361 38.2983C80.2933 37.8073 80.2245 37.319 80.1299 36.8353C80.0385 36.2876 79.7247 35.802 79.2628 35.4937C79.0454 35.3469 78.5483 35.1407 77.4694 35.1407C77.1756 35.1407 76.8367 35.1548 76.4526 35.1915C74.8964 35.3356 73.7638 35.8016 73.0803 36.5698C72.829 36.8579 72.2584 37.8973 72.5635 41.1509C72.6623 42.227 72.8035 43.289 72.9786 44.3029C73.3825 46.5342 74.0067 47.1188 74.2553 47.2628C74.9133 47.6639 75.9188 47.7853 77.2406 47.6639C79.5453 47.4464 80.2147 46.9889 80.3643 46.8533Z'
          fill='#4DBFAA'
        />
        <path
          id='Shape_2'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M120.888 19.0108C121.843 17.7483 123.625 17.3331 126.15 17.8048C129.601 18.4487 131.406 20.55 131.234 23.7133C131.172 24.7416 131.052 25.7656 130.875 26.7806C130.771 27.3737 130.675 27.9244 130.593 28.4526C129.966 32.7993 129.234 37.2279 128.418 41.6085C127.723 45.3253 126.989 49.0902 126.235 52.7619C125.701 55.3179 125.012 57.1142 124.125 58.2581C123.124 59.5474 121.604 60.3289 119.973 60.3933C119.55 60.4244 119.092 60.4385 118.601 60.4385C117.198 60.4254 115.797 60.3359 114.404 60.1702C112.65 59.9696 110.896 59.7239 109.187 59.4048C108.43 59.2654 107.685 59.1092 106.953 58.9359C104.319 58.286 101.909 56.9398 99.9739 55.0383C98.2934 53.3945 97.3218 51.265 97.0704 48.7004C96.8388 46.3506 97.0224 43.5488 97.6155 40.3742C98.4035 36.1518 99.6011 33.0535 101.267 30.9041C103.171 28.4385 105.301 27.0263 107.56 26.7439C109.296 26.4849 111.06 26.4915 112.794 26.7636C114.478 27.0533 116.09 27.6661 117.541 28.5684C117.986 28.8706 118.459 29.1297 118.954 29.3423C118.973 29.2717 118.999 29.1785 119.019 29.0598C119.222 27.9583 119.442 25.8909 119.674 22.8745C119.708 22.4085 119.766 21.9447 119.849 21.4849C119.985 20.5869 120.342 19.7368 120.888 19.0108ZM115.771 47.421L116.296 44.7435C116.663 43.0461 116.897 41.9192 116.996 41.3882C117.095 40.8572 117.166 40.3658 117.202 39.9252C117.262 39.3731 117.092 38.8208 116.734 38.3972C116.522 38.143 115.895 37.6685 114.113 37.3352C112.579 37.05 111.362 37.1742 110.495 37.7391C110.173 37.9481 109.339 38.7898 108.741 42.0039C108.543 43.063 108.385 44.1222 108.275 45.1502C108.054 47.4041 108.492 48.1356 108.69 48.3446C109.218 48.8981 110.147 49.302 111.455 49.5478C113.729 49.9714 114.497 49.7144 114.677 49.624C115.005 49.4974 115.262 49.2375 115.386 48.9094C115.55 48.423 115.678 47.9256 115.771 47.421Z'
          fill='#4DBFAA'
        />
        <path
          id='Shape_3'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M133.085 44.1411C135.846 37.4873 142.343 33.1523 149.547 33.158C159.372 33.1767 167.33 41.1407 167.34 50.9656C167.34 58.1694 163 64.6635 156.344 67.419C149.688 70.1744 142.028 68.6483 136.936 63.5524C131.844 58.4566 130.324 50.7949 133.085 44.1411ZM149.547 57.1086C152.94 57.1086 155.69 54.3583 155.69 50.9656C155.685 47.5748 152.938 44.8273 149.547 44.8226C146.154 44.8226 143.404 47.5729 143.404 50.9656C143.404 54.3583 146.154 57.1086 149.547 57.1086Z'
          fill='#4DBFAA'
        />
        <path
          id='Path_4'
          d='M143.362 24.8289C149.88 30.4014 158.797 31.7882 167.668 25.0577C175.294 19.2791 175.294 12.7548 173.218 9.93327C169.829 5.30415 164.968 6.26161 159.661 7.27273C149.708 9.1481 150.513 3.28755 145.025 0.708914C141.243 -1.07043 133.683 0.107325 134.369 8.89956C134.671 12.7492 136.739 19.1633 143.362 24.8289Z'
          fill='#FCBA2B'
        />
        <path
          id='Path_5'
          d='M146.401 19.5869C146.209 19.5892 146.019 19.6177 145.836 19.6717C145.381 19.8018 145.011 20.1311 144.828 20.567C144.729 20.7895 144.677 21.0298 144.675 21.2731C144.688 21.5411 144.747 21.805 144.85 22.0526C144.991 22.4282 145.356 22.6937 145.954 22.8632C146.155 22.9266 146.363 22.9599 146.573 22.962C146.824 22.9544 147.072 22.9057 147.307 22.818C147.682 22.6459 147.959 22.3138 148.061 21.9142C148.138 21.704 148.179 21.4826 148.183 21.259C148.2 20.8592 148.064 20.468 147.802 20.1659C147.442 19.7764 146.93 19.5646 146.401 19.5869Z'
          fill='white'
        />
        <path
          id='Path_6'
          d='M151.154 13.105C151.314 13.1356 151.477 13.1516 151.64 13.153C151.872 13.1481 152.102 13.1091 152.323 13.0372C152.677 12.9008 152.951 12.6125 153.069 12.2521C153.196 11.9715 153.262 11.6675 153.264 11.3596C153.256 11.1589 153.23 10.9594 153.185 10.7636C153.114 10.4162 152.812 10.1366 152.289 9.9361C152.019 9.83126 151.732 9.77485 151.442 9.76946C151.152 9.7807 150.865 9.84086 150.595 9.9474C150.191 10.0914 149.923 10.4981 149.801 11.1449C149.768 11.2838 149.751 11.4259 149.75 11.5686C149.736 12.1363 149.976 12.865 151.154 13.105Z'
          fill='white'
        />
        <path
          id='Path_7'
          d='M153.216 15.9153C153.173 15.6115 153.026 15.332 152.801 15.1244C152.486 14.87 152.098 14.7217 151.694 14.7008C151.548 14.6826 151.402 14.6732 151.256 14.6726C150.781 14.6726 150.454 14.8025 150.284 15.0595C150.146 15.3033 150.08 15.5816 150.095 15.8616C150.096 16.0305 150.105 16.1993 150.123 16.3672C150.211 17.1445 150.256 17.9262 150.256 18.7086C150.255 19.3689 150.22 20.0288 150.151 20.6856C150.109 21.1375 150.086 21.4595 150.086 21.6713C150.086 22.1289 150.284 22.8943 151.598 22.8943C151.824 22.8901 152.048 22.8588 152.267 22.8011C152.632 22.7032 152.919 22.4198 153.021 22.0554C153.19 21.6092 153.272 20.6037 153.272 18.9853V16.6072C153.27 16.3755 153.251 16.1443 153.216 15.9153Z'
          fill='white'
        />
        <path
          id='Shape_4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M163.674 13.0655V13.5456C163.725 14.7855 163.751 16.048 163.765 17.3049C163.765 18.3555 163.756 19.4316 163.737 20.502C163.759 21.0454 163.663 21.5872 163.454 22.0893C163.256 22.4753 162.897 22.7536 162.474 22.8491C161.97 22.9669 161.457 23.0416 160.941 23.0722C160.446 23.1089 159.952 23.1287 159.463 23.1287C159.357 23.1287 159.251 23.1252 159.144 23.1216C159.038 23.1181 158.931 23.1146 158.825 23.1146C158.08 23.0718 157.36 22.8289 156.741 22.4113C156.201 22.0437 155.812 21.4928 155.648 20.8607C155.447 20.1084 155.352 19.3318 155.365 18.5532C155.365 17.3783 155.538 16.4462 155.876 15.7882C156.263 15.0284 156.758 14.5455 157.348 14.3562C157.803 14.1991 158.279 14.1115 158.76 14.0964C159.229 14.0901 159.695 14.1745 160.133 14.3449C160.303 14.4248 160.485 14.4781 160.672 14.5031C160.687 14.4431 160.694 14.3813 160.692 14.3195C160.692 14.006 160.644 13.4129 160.554 12.5656C160.538 12.4372 160.531 12.308 160.531 12.1786C160.522 11.9394 160.573 11.7018 160.678 11.4867C160.864 11.111 161.305 10.9218 161.994 10.9218C162.929 10.9218 163.505 11.3709 163.615 12.2182C163.65 12.4993 163.67 12.7822 163.674 13.0655ZM160.743 18.782C160.763 18.2962 160.763 17.9742 160.763 17.8161C160.767 17.6719 160.763 17.5277 160.751 17.3839C160.736 17.2053 160.65 17.0402 160.511 16.9264C160.401 16.8445 160.175 16.7485 159.664 16.7485C159.288 16.7192 158.915 16.8343 158.622 17.0704C158.526 17.1721 158.339 17.4856 158.339 18.4035C158.339 18.7086 158.351 19.0249 158.373 19.3073C158.43 19.9513 158.596 20.1659 158.692 20.2337C158.957 20.3898 159.264 20.4617 159.571 20.4399C160.206 20.4399 160.438 20.3382 160.52 20.2789C160.618 20.2137 160.687 20.1122 160.712 19.9965C160.732 19.8486 160.743 19.6995 160.743 19.5502V18.782Z'
          fill='white'
        />
      </g>
    </svg>
  );
};

export default Logo;
