import serviceApi from '../../services';

const LoginServices = {
    login: async (params) => {
        return await serviceApi({
            method: 'POST',
            url: `/auths/login`,
            version: 'v1',
            formData: true,
            data: params,
            appType: 1,
        });
    },
};

export default LoginServices;
