import serviceApi from '../../../services';

const serviceGiftCategory = {
    getListCategory: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/gift/category/all`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    addCategory: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: '/gift/category',
            version: 'v1',
            token: true,
            data: data,
        });
    },
    postCategoryData: async (bodyData) => {
        return await serviceApi({
            method: 'POST',
            url: `/gift/category`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    updateCategory: async (id, data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/gift/category/${id}`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getDetailCategory: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/gift/category/${id}`,
            version: 'v1',
            token: true,
        });
    },
    onDeleteCategory: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/gift/category/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceGiftCategory;
