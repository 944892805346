import { PageHeader, Select, Tabs, Form } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BannerStyle from "./index.style";
import SliderBanner from "../../components/Banner/HomePage/sliderbanner";
import PromoBanner from "../../components/Banner/HomePage/promo";
import FixBanner from "../../components/Banner/HomePage/fixbanner";
import CatalogSlider from "../../components/Banner/Catalog/catalogslider";
import FixBannerSquare from "../../components/Banner/InfoTips/fixbannersquare";
import FixBannerBottom from "../../components/Banner/InfoTips/fixbannerbottom";
const Banner = () => {
  const history = useHistory();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [bannerNow, setBannerNow] = useState('')
  const routes = [
    {
      path: "index",
      breadcrumbName: "Dashboard",
    },
    {
      path: "banner",
      breadcrumbName: "Banner",
    },
  ];

  function ShowBanner() {
    switch (bannerNow) {
      case 'slider':
        return <SliderBanner />
      case 'promo':
        return <PromoBanner />
      case 'fixbanner':
        return <FixBanner />
      case 'fixsquare':
        return <FixBannerSquare />
      case 'fixbottom':
        return <FixBannerBottom />
      default:
        return <></>
    }
  }

  return (
    <BannerStyle>
      <PageHeader
        onBack={() => history.goBack()}
        className="site-page-header"
        title="Banner Management"
        breadcrumb={{ routes }}
        style={{ paddingLeft: 0 }}
      />
      <div>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {
            setBannerNow('');
            form.setFieldsValue({ type1: null, type2: null })
          }}>
          {/* <TabPane tab="Home Page" key="1">
            <Form form={form} layout="vertical">
              <Form.Item name="type1">
                <Select placeholder="Pilih Jenis Banner" onChange={(val) => { setBannerNow(val) }}>
                  <Option value="slider">Slider Banner</Option>
                  <Option value="promo">Promo Banner</Option>
                  <Option value="fixbanner">Fix Banner</Option>
                </Select>
              </Form.Item>
            </Form>
            <ShowBanner />
          </TabPane> */}
          <TabPane tab="Catalog Page" key="2">
            <CatalogSlider />
          </TabPane>
          {/* <TabPane tab="Info & Tips" key="3">
            <Form form={form} layout="vertical">
              <Form.Item name="type2">
                <Select placeholder="Pilih Jenis Banner" onChange={(val) => { setBannerNow(val) }}>
                  <Option value="fixsquare">Fix Banner Square</Option>
                  <Option value="fixbottom">Fix Banner Bottom</Option>
                </Select>
              </Form.Item>
            </Form>
            <ShowBanner />
          </TabPane> */}
        </Tabs>
      </div>
    </BannerStyle>
  );
};

export default Banner;
