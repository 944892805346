import serviceApi from '../../../../services/index';

const serviceGiftProductList = {
    getGiftProductList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/gift/products`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    getListCategory: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/gift/category`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postSlug: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/gift/products/slug/exist`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    getMerchantList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/merchants/names`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    getDetailProductGift: async (id, data) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/gift/products/${id}`,
            version: 'v1',
            token: true,
            data: data,
            formData: true,
        });
    },
    updateProductGift: async (id, data) => {
        return await serviceApi({
            method: 'PUT',
            url: `v1/gift/products/${id}`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    
    deleteProduct: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `v1/gift/products/${id}`,
            noVersion: true,
            appType: 1,
            token: true,
        });
    },
};

export default serviceGiftProductList;
