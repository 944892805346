import React, { useState } from 'react';
import Sider from 'antd/lib/layout/Sider';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import {
    DashboardOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    InboxOutlined,
    BookOutlined,
    PictureOutlined,
    FileImageOutlined,
    UsergroupAddOutlined,
    ShopOutlined,
    FundProjectionScreenOutlined,
    ContainerOutlined,
    AuditOutlined,
    PercentageOutlined,
    MessageOutlined,
    GiftOutlined,
    OrderedListOutlined,
    ShoppingOutlined,
    CameraOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import MainLayoutStyle from './index.style';
import Logo from '../assets/Logo/index.logo';
import { useApp } from '../contexts/app';
import { RESET, SET_CONFIG } from '../contexts/types';
import { useHistory } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import LetteredAvatar from 'react-lettered-avatar';
import localStorages from '../utils/localStorages';

const MainLayout = (props) => {
    const [state, dispatch] = useApp();
    const [rightMargin, setRightMargin] = useState(200);
    const history = useHistory();
    const toggle = () => {
        if (state.config.drawer) {
            setRightMargin(200);
        } else {
            setRightMargin(70);
        }
        dispatch({
            type: SET_CONFIG,
            payload: { key: 'drawer', value: !state.config.drawer },
        });
    };

    const goTo = (e, url) => {
        history.push(url);
    };

    const handleLogout = async () => {
        await dispatch({ type: RESET });
        history.push('/');
    };

    return (
        <MainLayoutStyle collapsed={state.config.drawer}>
            <Layout className="container-layout">
                <Sider
                    className="sider"
                    trigger={null}
                    collapsible
                    collapsed={state.config.drawer}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        width: '240px',
                        maxWidth: '240px',
                    }}>
                    <div className="logo">
                        <Logo width={100} height={80} />
                    </div>
                    <Menu
                        theme="light"
                        mode="inline"
                        defaultSelectedKeys={['1']}>
                        <Menu.Item
                            key="1"
                            onClick={(e) => goTo(e, '/dashboard')}
                            icon={
                                <DashboardOutlined
                                    style={{ fontSize: '18px' }}
                                />
                            }>
                            <b style={{ fontSize: '14px' }}>Dashboard</b>
                        </Menu.Item>

                        <SubMenu
                            key="sub1"
                            icon={<OrderedListOutlined />}
                            title="Order Marketplace">
                            <Menu.Item
                                key="sub1-1-1"
                                onClick={(e) => goTo(e, '/order-list')}>
                                List
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub3"
                            icon={<InboxOutlined />}
                            title="Product">
                            <Menu.Item
                                key="sub3-1-2"
                                onClick={(e) => goTo(e, '/product')}>
                                List
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub5"
                            icon={<ShopOutlined />}
                            title="Merchant">
                            <Menu.Item
                                key="sub5-1-1"
                                onClick={(e) => goTo(e, '/merchant/create')}>
                                Create
                            </Menu.Item>
                            <Menu.Item
                                key="sub5-1-2"
                                onClick={(e) => goTo(e, '/merchant')}>
                                List
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item
                            key="sub13"
                            icon={<ShoppingCartOutlined />}
                            onClick={(e) => goTo(e, '/flashsale')}>
                            <span style={{ fontSize: '14px' }}>Flashsale</span>
                        </Menu.Item>

                        <SubMenu
                            key="sub9"
                            icon={<ShoppingOutlined />}
                            title="Promo Code">
                            <Menu.Item
                                key="sub9-1-1"
                                onClick={(e) =>
                                    goTo(e, '/codepromo/premium/list')
                                }>
                                Premium
                            </Menu.Item>
                            <Menu.Item
                                key="sub9-2-1"
                                onClick={(e) =>
                                    goTo(e, '/codepromo/marketplace/list')
                                }>
                                Marketplace
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub15"
                            icon={<ShopOutlined />}
                            title="Campaign">
                            <Menu.Item
                                key="sub15-1-1"
                                onClick={(e) => goTo(e, '/campaign/banner')}>
                                Banner Campaign
                            </Menu.Item>
                            <Menu.Item
                                key="sub15-1-2"
                                onClick={(e) => goTo(e, '/campaign/page')}>
                                Page Campaign
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub16"
                            icon={<GiftOutlined />}
                            title="Master Gift Voucher ">
                            <Menu.Item
                                key="sub16-1-1"
                                onClick={(e) => goTo(e, '/gift/category')}>
                                Kategori Gift
                            </Menu.Item>
                            <Menu.Item
                                key="sub16-1-3"
                                onClick={(e) => goTo(e, '/gift/product/')}>
                                Produk Gift
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub17"
                            icon={<UnorderedListOutlined />}
                            title="Gift Voucher Order">
                            <Menu.Item
                                key="sub17-1-1"
                                onClick={(e) => goTo(e, '/order-gift')}>
                                List Gift Order
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub18"
                            icon={<GiftOutlined />}
                            title="Master PPOB ">
                            <Menu.Item
                                key="sub22-1-1"
                                onClick={(e) => goTo(e, '/ppob/provider')}>
                                Provider Prefix
                            </Menu.Item>
                            <Menu.Item
                                key="sub22-1-2"
                                onClick={(e) => goTo(e, '/ppob/product/')}>
                                Produk PPOB
                            </Menu.Item>
                            <Menu.Item
                                key="sub22-1-3"
                                onClick={(e) => goTo(e, '/ppob/order/')}>
                                Order PPOB
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item
                            key="sub12"
                            icon={<MessageOutlined />}
                            onClick={(e) => goTo(e, '/inbox')}>
                            <span style={{ fontSize: '14px' }}>
                                Pesan Masuk
                            </span>
                        </Menu.Item>

                        <SubMenu
                            key="sub10"
                            icon={<ShopOutlined />}
                            title="Kiddofest">
                            <Menu.Item
                                key="sub10-1-1"
                                onClick={(e) => goTo(e, '/kiddofest/banner')}>
                                Master Banner
                            </Menu.Item>
                            <Menu.Item
                                key="sub10-1-2"
                                onClick={(e) => goTo(e, '/kiddofest/gallery')}>
                                Master Galeri
                            </Menu.Item>
                            <Menu.Item
                                key="sub10-1-3"
                                onClick={(e) =>
                                    goTo(e, '/kiddofest/testimoni')
                                }>
                                Master Testimoni
                            </Menu.Item>
                            <Menu.Item
                                key="sub10-1-4"
                                onClick={(e) => goTo(e, '/kiddofest/edufair')}>
                                Master Mitra
                            </Menu.Item>
                            <Menu.Item
                                key="sub10-1-5"
                                onClick={(e) => goTo(e, '/kiddofest/schedule')}>
                                Master Jadwal
                            </Menu.Item>
                            <Menu.Item
                                key="sub10-1-6"
                                onClick={(e) =>
                                    goTo(e, '/kiddofest/registration')
                                }>
                                List Registrasi Tenant
                            </Menu.Item>
                            <Menu.Item
                                key="sub10-1-7"
                                onClick={(e) => goTo(e, '/kiddofest/coupon')}>
                                List Registrasi Kupon
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub11"
                            icon={<ShopOutlined />}
                            title="Learners Camp">
                            <Menu.Item
                                key="sub11-1-1"
                                onClick={(e) => goTo(e, '/learnerscamp/team')}>
                                Team
                            </Menu.Item>
                            <Menu.Item
                                key="sub11-2-1"
                                onClick={(e) =>
                                    goTo(e, '/learnerscamp/testimoni')
                                }>
                                Testimoni
                            </Menu.Item>
                            <Menu.Item
                                key="sub11-3-1"
                                onClick={(e) =>
                                    goTo(e, '/learnerscamp/certificate')
                                }>
                                Sertifikat
                            </Menu.Item>
                            <Menu.Item
                                key="sub11-4-1"
                                onClick={(e) =>
                                    goTo(e, '/learnerscamp/participant')
                                }>
                                Peserta
                            </Menu.Item>
                            <Menu.Item
                                key="sub11-5-1"
                                onClick={(e) => goTo(e, '/learnerscamp/quiz')}>
                                Kuis
                            </Menu.Item>
                            <Menu.Item
                                key="sub11-6-1"
                                onClick={(e) => goTo(e, '/learnerscamp/class')}>
                                Kelas
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub14"
                            icon={<CameraOutlined />}
                            title="Kio & Friends">
                            <Menu.Item
                                key="sub14-1-1"
                                onClick={(e) => goTo(e, '/kio/banner')}>
                                Master Banner
                            </Menu.Item>
                            <Menu.Item
                                key="sub14-2-1"
                                onClick={(e) => goTo(e, '/kio/episode')}>
                                Master Episode
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub2"
                            icon={<FundProjectionScreenOutlined />}
                            title="Tracker Report">
                            <Menu.Item
                                key="sub2-1-1"
                                onClick={(e) =>
                                    goTo(e, '/tracker-report/transaction')
                                }>
                                Transaction
                            </Menu.Item>
                            <Menu.Item
                                key="sub2-2-1"
                                onClick={(e) =>
                                    goTo(e, '/tracker-report/user-profile')
                                }>
                                User Profile
                            </Menu.Item>
                            <Menu.Item
                                key="sub2-3-1"
                                onClick={(e) =>
                                    goTo(
                                        e,
                                        '/tracker-report/merchant-and-product',
                                    )
                                }>
                                Merchant And Product
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub8"
                            icon={<AuditOutlined />}
                            title="Journal">
                            <Menu.Item
                                key="sub8-1-1"
                                onClick={(e) =>
                                    goTo(e, '/journal/immunization')
                                }>
                                Immunization
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub4"
                            icon={<BookOutlined />}
                            title="Artikel Managament">
                            <Menu.Item
                                key="sub4-1-1"
                                onClick={(e) => goTo(e, '/article')}>
                                Artikel
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item
                            key="11"
                            icon={<ContainerOutlined />}
                            onClick={(e) => goTo(e, '/homepage')}>
                            <span style={{ fontSize: '14px' }}>Homepage</span>
                        </Menu.Item>

                        <Menu.Item
                            key="10"
                            icon={<PictureOutlined />}
                            onClick={(e) => goTo(e, '/picture-gallery')}>
                            <span style={{ fontSize: '14px' }}>
                                Picture Gallery
                            </span>
                        </Menu.Item>

                        <Menu.Item
                            key="3"
                            onClick={(e) => goTo(e, '/merchant-gallery')}
                            icon={<PictureOutlined />}>
                            <span style={{ fontSize: '14px' }}>
                                Merchant Gallery
                            </span>
                        </Menu.Item>

                        <Menu.Item
                            key="4"
                            onClick={(e) => goTo(e, '/banner')}
                            icon={<FileImageOutlined />}>
                            <span style={{ fontSize: '14px' }}>Banner</span>
                        </Menu.Item>
                        <Menu.Item
                            key="5"
                            onClick={(e) => goTo(e, '/users')}
                            icon={<UsergroupAddOutlined />}>
                            <span style={{ fontSize: '14px' }}>Users List</span>
                        </Menu.Item>
                        <SubMenu
                            key="sub6"
                            icon={<BookOutlined />}
                            title="Operation And Finance">
                            <Menu.Item
                                key="sub6-1-1"
                                onClick={(e) =>
                                    goTo(
                                        e,
                                        '/operation-and-finance/list-of-ticket',
                                    )
                                }>
                                List Of Ticket
                            </Menu.Item>
                            <Menu.Item
                                key="sub6-1-2"
                                onClick={(e) =>
                                    goTo(
                                        e,
                                        '/operation-and-finance/merchant-saldo',
                                    )
                                }>
                                Merchant Saldo
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="sub7"
                            icon={<SettingOutlined />}
                            title="Settings">
                            <Menu.Item
                                key="sub7-1"
                                onClick={(e) => goTo(e, '/settings/user-role')}>
                                User Role
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <Layout
                    className="site-layout"
                    style={{ marginLeft: rightMargin }}>
                    <Header
                        className="site-layout-background header"
                        style={{ padding: 0 }}>
                        <div className="account-settings">
                            <div className="left">
                                <div
                                    className="toggle"
                                    onClick={() => toggle()}>
                                    {state.config.drawer ? (
                                        <MenuUnfoldOutlined />
                                    ) : (
                                        <MenuFoldOutlined />
                                    )}
                                </div>
                            </div>
                            <div className="right">
                                <div className="account">
                                    <LetteredAvatar
                                        name={state?.user?.username || 'USER'}
                                        size={40}
                                    />
                                </div>
                                <div className="settings">
                                    <Menu>
                                        <SubMenu
                                            key="SubMenu"
                                            icon={<SettingOutlined />}>
                                            <Menu.ItemGroup>
                                                <Menu.Item key="setting:1">
                                                    Edit Profile
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={handleLogout}
                                                    key="setting:3">
                                                    Logout
                                                </Menu.Item>
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </Header>
                    <Content className="content">{props.children}</Content>
                </Layout>
            </Layout>
        </MainLayoutStyle>
    );
};

MainLayout.propTypes = {};

export default MainLayout;
