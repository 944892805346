import React, { useState, useEffect } from "react";
import { Alert, Button, Col, DatePicker, Form, Input, Row, Select, Tag } from "antd";
import UploadImage from "../../Upload/Image";
import UsersFormStyle from "../../../pages/Merchant/Create/index.style";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";


const UsersForm = ({ initialValue }) => {
    const { Option } = Select;
    const [form] = Form.useForm();
    useEffect(() => {
        if (initialValue !== null) {
            form.setFieldsValue({
                user_id: initialValue.id,
                role: initialValue.role,
                name: initialValue.name,
                email: initialValue.email,
                kiddo_points: initialValue.kiddo_points,
                name_address: initialValue.name_address,
                full_address: initialValue.full_address,
                province: initialValue.province,
                subdistrict: initialValue.subdistrict,
                city: initialValue.city,
                postal_code: initialValue.postal_code,
                address_phone_number: initialValue.address_phone_number,
                recipient_name: initialValue.recipient_name,
            });
            if (initialValue.participants !== null && initialValue.participants.length !== 0) {
                initialValue.participants.map((el, idx) => {
                    var tgl = moment().format(el.birthdate, "YYYY-DD-MM");
                    var year = moment().diff(tgl, 'years');
                    var month = moment().diff(tgl, 'months');

                    form.setFieldsValue({
                        [`participant_name${idx}`]: el.name,
                        [`participant_gender${idx}`]: el.gender,
                        [`participant_usia${idx}`]: `${year} Tahun ${month} Bulan`,
                        [`participant_relation${idx}`]: el.relation,
                    });
                })
            }
        }
    }, [initialValue]);

    return (
        <UsersFormStyle>
            <Form
                form={form}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <div className="detail-user">
                            <h3>Detail User</h3>
                            <Row gutter={5}>
                                <Col span={6}>
                                    <Form.Item label="Profile Picture">
                                        <UploadImage />
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="user_id" label="User ID">
                                        <Input placeholder="User ID" />
                                    </Form.Item>
                                    <Form.Item name="role" label="Sapaan">
                                        <Select placeholder="Sapaan" >
                                            <Option value="Papa">Papa</Option>
                                            <Option value="Mama">Mama</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="name" label="Nama Lengkap">
                                <Input placeholder="Nama Lengkap" />
                            </Form.Item>
                            <Form.Item name="tgl_lahir" label="Tanggal Lahir">
                                <DatePicker placeholder="Pilih Tanggal Lahir" style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name="email" label="Email">
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Status Verifikasi Email">
                                        {
                                            initialValue?.verified_email_status === "Y" ?
                                                <Tag color="success" icon={<CheckCircleOutlined />}>Success</Tag>
                                                :
                                                <Tag color="warning" icon={<ExclamationCircleOutlined />}>No</Tag>
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Tanggal Verifikasi Email">
                                        {initialValue?.verification_email_date || '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="phone_number" label="Nomor Telepon">
                                <Input placeholder="Nomor Telepon" />
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Status Verifikasi Nomor Telepon">
                                        {
                                            initialValue?.verified_phone_status === "Y" ?
                                                <Tag color="success" icon={<CheckCircleOutlined />}>Success</Tag>
                                                :
                                                <Tag color="warning" icon={<ExclamationCircleOutlined />}>No</Tag>
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Tanggal Verifikasi Nomor Telepon">
                                        {initialValue?.verification_phone_date || '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="date_created" label="Date Created">
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name="kiddo_points" label="Kiddo Points">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="address">
                            <h3>Detail Alamat</h3>
                            <Form.Item name="name_address" label="Nama Alamat">
                                <Input placeholder="Nama Alamat" />
                            </Form.Item>
                            <Form.Item name="full_address" label="Alamat Lengkap">
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name="provinci" label="Provinsi">
                                <Select placeholder="Pilih Provinsi">
                                    <Option value="Jakarta">Jakarta</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="district" label="Kecamatan">
                                <Select placeholder="Pilih Kecamatan">
                                    <Option value="Cengakreng">Cengakreng</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="sub_district" label="Kota">
                                <Select placeholder="Pilih Kota">
                                    <Option value="Duri">Duri Kosambi</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="postal_code" label="Kode Pos">
                                <Input placeholder="Kode Pos" />
                            </Form.Item>
                            <Form.Item name="address_phone_number" label="Nomor Telepon Alamat">
                                <Input placeholder="Nomor Telepon Alamat" />
                            </Form.Item>
                            <Form.Item name="recipient_name" label="Nama Penerima">
                                <Input placeholder="Nama Penerima" />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="participant">
                            <h3>Daftar Participant</h3>

                            {
                                initialValue?.participants === null || initialValue?.participants.length === 0 ? <Alert showIcon message="Tidak ada daftar participant" type="warning" /> : (
                                    initialValue?.participants.map((el, idx) => {
                                        return (
                                            <div>
                                                <h4>Participant Ke-{idx + 1}</h4>
                                                <Form.Item name={`participant_name${idx}`} label="Nama Lengkap">
                                                    <Input placeholder="Nama Lengkap" />
                                                </Form.Item>
                                                <Form.Item name={`participant_gender${idx}`} label="Jenis Kelamin">
                                                    <Select placeholder="Pilih Jenis Kelamin">
                                                        <Option value="Laki-laki">Laki-laki</Option>
                                                        <Option value="Perempuan">Perempaun</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name={`participant_usia${idx}`} label="Usia">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name={`participant_relation${idx}`} label="Hubungan">
                                                    <Select placeholder="Pilih Status Hubungan">
                                                        <Option value="diri_sendiri">Diri Sendiri</Option>
                                                        <Option value="Anak">Anak</Option>
                                                        <Option value="Keponakan">Keponakan</Option>
                                                        <Option value="Cucu">Cucu</Option>
                                                        <Option value="Istri">Istri</Option>
                                                        <Option value="Suami">Suami</Option>
                                                        <Option value="Teman">Teman</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </Form>
        </UsersFormStyle>
    );
}

export default UsersForm;