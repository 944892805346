import styled from "@emotion/styled";

export const HomePageStyle = styled.div`
  .picture-uploader {
    padding-top: 16px;
  }

  .avatar-uploader > .ant-upload, img {
    width: 240px;
    height: 240px;
    object-fit: contain;
  }
`
export const PictureBannerStyle = styled.div`
  display: flex;
  width: 100%;
`

export const ModalImageSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .image-item {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    transition: all .2s ease;

    &.selected {
      border: 3px solid #1890ff
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
