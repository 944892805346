import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';

function DebounceSelect({
    fetchOptions,
    debounceTimeout = 800,
    outerSource,
    OuterOptions,
    setOuterOptions,
    ...props
}) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    React.useEffect(() => {
        debounceFetcher();
    }, []);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            if (outerSource) {
                setOuterOptions([]);
            } else {
                setOptions([]);
            }
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                if (outerSource) {
                    setOuterOptions(newOptions);
                } else {
                    setOptions(newOptions);
                }
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : 'No data found'}
            {...props}
            options={outerSource ? OuterOptions : options}
        />
    );
} // Usage of DebounceSelect

const DebounceSelectComponent = ({
    fetchList = () => {},
    value,
    onChange = () => {},
    placeholder = '',
    mode = 'multiple',
    size = 'middle',
    outerSource = false,
    options,
    setOptions = () => {},
}) => {
    return (
        <DebounceSelect
            outerSource={outerSource}
            OuterOptions={options}
            setOuterOptions={setOptions}
            size={size}
            mode={mode === 'single' ? false : mode}
            showSearch
            value={value}
            placeholder={placeholder}
            fetchOptions={fetchList}
            onChange={onChange}
            style={{
                width: '100%',
            }}
        />
    );
};

export default DebounceSelectComponent;
