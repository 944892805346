import {
    Badge,
    Card,
    Col,
    Descriptions,
    Divider,
    PageHeader,
    Row,
    Table,
    Tag,
} from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import OrderDetailStyle from './index.style';
import serviceOrderDetails from './index.service';
import OrderDetailLoading from './index.loading';
import { formatMoney } from '../../../utils/format-money';
import { formatDate } from '../../../utils/format-date';

const OrderListDetail = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = React.useState(true);
    const [dataOrderDetail, setDataOrderDetail] = React.useState({});

    React.useEffect(() => {
        handleLoadData(props.match.params.id);
    }, []);

    const handleLoadData = async (id) => {
        const result = await serviceOrderDetails.getDataDetail(id);
        if (result.isSuccess && !!result.response) {
            setDataOrderDetail(result.response.payload);
            setLoading(false);
        }
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Order List',
        },
        {
            path: 'detail',
            breadcrumbName: 'Detail',
        },
    ];

    const columns = [
        {
            title: 'ID Product',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => {
                return formatMoney(record.price);
            },
        },
    ];

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'Completed') {
            color = 'green';
        }
        if (status === 'Pending') {
            color = 'geekblue';
        }
        if (status === 'Cancelled') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    return (
        <OrderDetailStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Order List Detail"
                breadcrumb={{ routes }}
                subTitle="Table of order list detail"
            />
            {isLoading ? (
                <OrderDetailLoading />
            ) : (
                <Row gutter={16}>
                    <Col span={24}>
                        <Card
                            loading={isLoading}
                            title="Order Details"
                            bordered={true}>
                            <Descriptions title="" bordered>
                                <Descriptions.Item label="Order ID" span={2}>
                                    {dataOrderDetail.order_detail.id}
                                </Descriptions.Item>
                                <Descriptions.Item label="Date">
                                    {formatDate(
                                        dataOrderDetail.order_detail
                                            .date_ordered,
                                        'LL',
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Updated Timestamp">
                                    {formatDate(
                                        dataOrderDetail.order_detail
                                            .date_updated,
                                        'LL',
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Amount">
                                    {formatMoney(
                                        dataOrderDetail.order_detail.amount,
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Promo Code">
                                    {dataOrderDetail.order_detail.promo_code}
                                </Descriptions.Item>
                                <Descriptions.Item label="Total Promo">
                                    {formatMoney(
                                        dataOrderDetail.order_detail
                                            .promo_amount,
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Shipping Service">
                                    {!!dataOrderDetail.order_detail
                                        .shipping_service
                                        ? dataOrderDetail.order_detail
                                              .shipping_service
                                        : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Shipping Cost">
                                    {formatMoney(
                                        dataOrderDetail.order_detail
                                            .shipping_cost,
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Service Pay">
                                    {formatMoney(
                                        dataOrderDetail.order_detail
                                            .service_charge,
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Payment Gateway">
                                    {
                                        dataOrderDetail.order_detail
                                            .payment_gateway_name
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Payment Gateway Status">
                                    {handleLabelStatus(
                                        dataOrderDetail.order_detail.status,
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Payment Gateway Info">
                                    {dataOrderDetail.order_detail
                                        .payment_gateway_linkpay && (
                                        <>
                                            <p>Link pay : </p>
                                            {
                                                dataOrderDetail.order_detail
                                                    .payment_gateway_linkpay
                                            }
                                            <Divider />
                                        </>
                                    )}
                                    {dataOrderDetail.order_detail
                                        .payment_gateway_send && (
                                        <>
                                            <p>Payment gateway send : </p>
                                            {
                                                dataOrderDetail.order_detail
                                                    .payment_gateway_send
                                            }
                                            <Divider />
                                        </>
                                    )}

                                    {dataOrderDetail.order_detail
                                        .payment_gateway_webhook && (
                                        <>
                                            <p>Payment gateway webhook : </p>
                                            {
                                                dataOrderDetail.order_detail
                                                    .payment_gateway_webhook
                                            }
                                            <Divider />
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            loading={isLoading}
                            title="Customer Detail"
                            bordered={true}>
                            <Descriptions bordered>
                                <Descriptions.Item label="Name" span={3}>
                                    {dataOrderDetail.customer_detail.name}
                                </Descriptions.Item>
                                <Descriptions.Item label="Phone" span={3}>
                                    {
                                        dataOrderDetail.customer_detail
                                            .phone_number
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Address" span={3}>
                                    {
                                        dataOrderDetail.customer_detail
                                            .full_address
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label="Custom Field"
                                    span={3}>
                                    <ul>
                                        {!!dataOrderDetail.customer_detail
                                            .custom_field_one ? (
                                            <li>
                                                {
                                                    dataOrderDetail
                                                        .customer_detail
                                                        .custom_field_one
                                                }
                                            </li>
                                        ) : (
                                            false
                                        )}
                                        {!!dataOrderDetail.customer_detail
                                            .custom_field_two ? (
                                            <li>
                                                {
                                                    dataOrderDetail
                                                        .customer_detail
                                                        .custom_field_two
                                                }
                                            </li>
                                        ) : (
                                            false
                                        )}
                                        {!!dataOrderDetail.customer_detail
                                            .custom_field_three ? (
                                            <li>
                                                {
                                                    dataOrderDetail
                                                        .customer_detail
                                                        .custom_field_three
                                                }
                                            </li>
                                        ) : (
                                            false
                                        )}
                                    </ul>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col span={16}>
                        <Card
                            loading={isLoading}
                            title="Product Details"
                            bordered>
                            <Table
                                columns={columns}
                                dataSource={
                                    dataOrderDetail.product_detail.products
                                }
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </OrderDetailStyle>
    );
};

export default OrderListDetail;
