import React from 'react';
import TrackerUserProfileStyle from './index.style';
import {
    Statistic,
    Card,
    Row,
    Col,
    DatePicker,
    Menu,
    Dropdown,
    Button,
    PageHeader,
    Empty,
    Select,
} from 'antd';
import serviceTrakcerUserProfile from './index.service';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DownOutlined,
} from '@ant-design/icons';
import PieChart from '../../../components/PieChart';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Option } from 'antd/lib/mentions';

const TrackerUserProfile = () => {
    const history = useHistory();
    const compareDate = moment().subtract(1, "days");
    const [queryString, setQueryString] = React.useState({
        compares: {
            day: 1,
        },
        filters: {
            date: compareDate,
        },
    });
    const [dataUserProfile, setDataUserProfile] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isEmpty, setIsEmpty] = React.useState(false);

    React.useEffect(() => {
        handleLoadData();
    }, []);

    const onChangeDate = (momentDate, dateString) => {
        if (!!dateString) {
            let tempQueryString = { ...queryString };
            queryString.filters.date = momentDate;
            setQueryString(tempQueryString);
            handleLoadData();
        }
    };

    const onChangeType = async (value) => {
        let tempQueryString = { ...queryString };
        queryString.compares.day = value;
        setQueryString(tempQueryString);
        handleLoadData(true);
    };

    const handleLoadData = async (changeType = false) => {
        setIsLoading(true);
        setIsEmpty(false);
        let params = { ...queryString };
        if (changeType) {
            params.filters.date = queryString.filters.date;
        } else {
            params.filters.date = queryString.filters.date.format('YYYY-MM-DD');
        }

        const result = await serviceTrakcerUserProfile.getData(queryString);
        if (result.isSuccess && result.response) {
            setDataUserProfile(result.response.payload);
            setIsLoading(false);
            return true;
        }
        setIsEmpty(true);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Tracker Report',
        },
        {
            path: 'transaction',
            breadcrumbName: 'User Profile',
        },
    ];

    const handleValueStyle = (data, key) => {
        if (data?.current?.[key] == data?.prior?.[key]) {
            return { color: '#8c8c8c' };
        } else if (data?.current?.[key] > data?.prior?.[key]) {
            return { color: '#3f8600' };
        } else {
            return { color: '#cf1322' };
        }
    };

    const handlePrefix = (data, key, type) => {
        if (data?.current?.[key] == data?.prior?.[key]) {
            return '';
        } else if (data?.current?.[key] > data?.prior?.[key]) {
            return (
                <>
                    <ArrowUpOutlined />
                    {data?.difference?.[type]}
                </>
            );
        } else {
            return (
                <>
                    <ArrowDownOutlined />
                    {data?.difference?.[type]}
                </>
            );
        }
    };

    return (
        <TrackerUserProfileStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Tracker Report"
                    breadcrumb={{ routes }}
                    subTitle="Table of Report User Profile"
                />
                <div className="right">
                    <DatePicker
                        defaultValue={compareDate}
                        onChange={onChangeDate}
                    />
                    <Select
                        defaultValue={1}
                        style={{ width: 120 }}
                        onChange={onChangeType}>
                        <Option value={1}>1 Hari lalu</Option>
                        <Option value={7}>7 Hari lalu</Option>
                        <Option value={30}>30 Hari lalu</Option>
                    </Select>
                </div>
            </div>
            <div className="site-statistic-demo-card">
                {isEmpty ? (
                    <Empty />
                ) : (
                    <>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card loading={isLoading}>
                                    <Statistic
                                        title="Number Of User"
                                        value={
                                            dataUserProfile?.current?.totalUser
                                        }
                                        valueStyle={handleValueStyle(
                                            dataUserProfile,
                                            'totalUser',
                                        )}
                                        suffix={handlePrefix(
                                            dataUserProfile,
                                            'totalUser',
                                            'number_of_users'
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card loading={isLoading}>
                                    <Statistic
                                        title="Number Of Customer"
                                        value={
                                            dataUserProfile?.current
                                                ?.total_customer
                                        }
                                        precision={2}
                                        valueStyle={handleValueStyle(
                                            dataUserProfile,
                                            'total_customer',
                                        )}
                                        suffix={handlePrefix(
                                            dataUserProfile,
                                            'total_customer',
                                            'number_of_customers'
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <PieChart
                                    data={[
                                        {
                                            type: '0 - 3 years',
                                            value: Number(
                                                dataUserProfile?.current
                                                    ?.participant_age_0_to_3_years,
                                            ),
                                        },
                                        {
                                            type: '4 - 5 years',
                                            value: Number(
                                                dataUserProfile?.current
                                                    ?.participant_age_4_to_5_years,
                                            ),
                                        },
                                        {
                                            type: '6 - 8 years',
                                            value: Number(
                                                dataUserProfile?.current
                                                    ?.participant_age_6_to_8_years,
                                            ),
                                        },
                                        {
                                            type: '9 - 12 years',
                                            value: Number(
                                                dataUserProfile?.current
                                                    ?.participant_age_9_to_12_years,
                                            ),
                                        },
                                        {
                                            type: '12+ years',
                                            value: Number(
                                                dataUserProfile?.current
                                                    ?.participant_age_12_plus_years,
                                            ),
                                        },
                                        {
                                            type: 'Parent',
                                            value: Number(
                                                dataUserProfile?.current
                                                    ?.participant_age_parents,
                                            ),
                                        },
                                    ]}></PieChart>
                            </Col>
                            <Col span={12}>
                                <PieChart
                                    data={[
                                        {
                                            type: 'Mama',
                                            value:
                                                Number(
                                                    dataUserProfile?.current
                                                        ?.gender_mom,
                                                ) || 0,
                                        },
                                        {
                                            type: 'Papa',
                                            value:
                                                Number(
                                                    dataUserProfile?.current
                                                        ?.gender_dad,
                                                ) || 0,
                                        },
                                        {
                                            type: 'Belum diisi',
                                            value:
                                                Number(
                                                    dataUserProfile?.current
                                                        ?.gender_undefined,
                                                ) || 0,
                                        },
                                    ]}></PieChart>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </TrackerUserProfileStyle>
    );
};
export default TrackerUserProfile;
