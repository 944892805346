import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Upload,
    Select,
    Checkbox,
    message,
    Radio,
    DatePicker,
    Modal,
    InputNumber,
    Popover,
} from 'antd';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import WysiwygStyle from './index.style';
import moment from 'moment';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ImgCrop from 'antd-img-crop';
import serviceFormProduct from './index.service';
import { convertToIsoWithoutTime } from '../../utils/format-date';

function FormProduct({
    handleSubmit,
    initVal,
    isLoading,
    setIsLoading,
    isCreate,
    isDuplicate,
}) {
    const history = useHistory();
    const [form] = Form.useForm();

    const { Option } = Select;
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;

    const blankVariant = {
        id: 0,
        disc: '',
        price: 0,
    };

    const [loading, setLoading] = useState(false);
    const [productId, setProductId] = useState(null);
    const [productProductId, setProductProductId] = useState(null);
    const [merchantId, setMerchantId] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [aggre, setAggre] = useState(false);
    const [semuaUmur, setSemuaUmur] = useState(false);
    const [prodType, setProdType] = useState('1');
    const [isVarian, setIsVarian] = useState('');
    const [tipePelaksanaan, setTipePelaksanaan] = useState('');
    const [varian, setVarian] = useState([blankVariant]);
    const [masaBerlaku, setMasaBerlaku] = useState('');
    const [jmlPilTgl, setJmlPilTgl] = useState(2);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedAge, setSelectedAge] = useState('');
    const [selectedCat, setSelectedCat] = useState('');
    const [subCatLength, setSubCatLength] = useState(0);
    const [batasPembelian, setBatasPembelian] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [checkSlug, setCheckSlug] = useState(false);

    const [rtValue, setRtValue] = useState('');
    const [kidsBenefit, setKidsBenefit] = useState('');
    const [learningMethod, setLearningMethod] = useState('');
    const [scheduleDetail, setScheduleDetail] = useState('');
    const [hrgTermasuk, setHrgTermasuk] = useState('');
    const [hrsDisiapkan, setHrsDisiapkan] = useState('');
    const [snkTambahan, setSnkTambahan] = useState('');
    const [infoLainnya, setInfoLainnya] = useState('');

    const [provinceList, setProvinceList] = useState([]);
    const [provinceId, setProvinceId] = useState('');
    const [cityList, setCityList] = useState([]);
    const [allCityList, setAllCityList] = useState([]);

    const [cityId, setCityId] = useState('');
    const [subdistrictList, setSubdistrictList] = useState([]);
    const [subdistrictId, setSubdistrictId] = useState('');
    const [fromFirstInit, setFromFirstInit] = useState(true);

    const [shippingList, setShippingList] = useState([]);
    const [shippingId, setShippingId] = useState('');

    const [fieldName, setFieldName] = useState({
        merchant: '',
        province: '',
        city: '',
        sub_district: '',
        activity: '',
    });
    const [modalType, setModalType] = useState('');
    const [merchantList, setMerchantList] = useState(null);
    const [isDiskon, setIsDiskon] = useState({
        status: false,
        id: -1,
        harga_asli: '',
    });
    const [fileList, setFileList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [ageValidator, setAgeValidator] = useState(false);

    const [errorPhoto, setErrorPhoto] = useState(false);
    const [diffDate, setDiffDate] = React.useState({
        start: 0,
        end: 0,
    });
    const [toggleCheckDiff, setToggleCheckDiff] = React.useState(false);
    const [idSmallMerchant, setIdSmallMerchant] = React.useState('');
    const key = 'updatable';
    const categori = [
        { value: 'Aktivitas', text: 'Aktivitas' },
        { value: 'Kursus', text: 'Kursus' },
        { value: 'Experience', text: 'Experience' },
        { value: 'Daycare dan Pre-School', text: 'Daycare dan Pre-School' },
        { value: 'Activity Kit', text: 'Activity Kit' },
        { value: 'Kelas Online', text: 'Video Learning' },
        { value: 'Kesehatan', text: 'Kesehatan' },
        { value: 'Keluarga', text: 'Keluarga' },
        { value: 'Sekolah', text: 'Sekolah' },
    ];
    const subcat = [
        { value: 'Festival', text: 'Festival' },
        { value: 'Gratis', text: 'Gratis' },
        { value: 'Kerajinan Tangan', text: 'Kerajinan Tangan' },
        { value: 'Kognitif', text: 'Kognitif' },
        { value: 'Komunikasi', text: 'Komunikasi' },
        { value: 'Kompetisi', text: 'Kompetisi' },
        { value: 'Kulinari', text: 'Kulinari' },
        // { value: 'Kulwap', text: 'Kulwap' },
        { value: 'Motorik', text: 'Motorik' },
        { value: 'Olahraga', text: 'Olahraga' },
        // { value: 'Pesta', text: 'Pesta' },
        { value: 'Parenting', text: 'Parenting' },
        { value: 'Perawatan', text: 'Perawatan' },
        { value: 'Pertunjukan', text: 'Pertunjukan' },
        { value: 'Playdate', text: 'Playdate' },
        { value: 'Rekreasi', text: 'Rekreasi' },
        // { value: 'Rutin', text: 'Rutin' },
        { value: 'Sains', text: 'Sains' },
        { value: 'Seni Lukis', text: 'Seni Lukis' },
        { value: 'Seni Musik', text: 'Seni Musik' },
        { value: 'Seni Drama', text: 'Seni Drama' },
        { value: 'Seni Tari', text: 'Seni Tari' },
        { value: 'Sensorik', text: 'Sensorik' },
        { value: 'Teknologi', text: 'Teknologi' },
        { value: 'Lainnya', text: 'Lainnya' },
    ];
    const rangeUmur = [
        { value: '0-6 bulan', text: '0-6 bulan' },
        { value: '7-12 bulan', text: '7-12 bulan' },
        { value: '13-18 bulan', text: '13-18 bulan' },
        { value: '1 tahun', text: '19-24 bulan' },
        { value: '2 tahun', text: '2 tahun' },
        { value: '3 tahun', text: '3 tahun' },
        { value: '4 tahun', text: '4 tahun' },
        { value: '5 tahun', text: '5 tahun' },
        { value: '6 tahun', text: '6 tahun' },
        { value: '7 tahun', text: '7 tahun' },
        { value: '8 tahun', text: '8 tahun' },
        { value: '9 tahun', text: '9 tahun' },
        { value: '10 tahun', text: '10 tahun' },
        { value: '11 tahun', text: '11 tahun' },
        { value: '12 tahun', text: '12 tahun' },
        { value: '13 tahun', text: '13 tahun' },
        { value: '14 tahun', text: '14 tahun' },
        { value: '15 tahun', text: '15 tahun' },
        { value: '16 tahun', text: '16 tahun' },
        { value: '17 tahun', text: '17 tahun' },
        { value: '18 tahun', text: '18 tahun' },
        { value: '19 tahun', text: '19 tahun' },
    ];
    const batasHari = [
        // { value: "1 Hari Sebelum", text: "1 Hari Sebelum" },
        // { value: "2 Hari Sebelum", text: "2 Hari Sebelum" },
        // { value: "3 Hari Sebelum", text: "3 Hari Sebelum" },
        // { value: "4 Hari Sebelum", text: "4 Hari Sebelum" },
        // { value: "5 Hari Sebelum", text: "5 Hari Sebelum" },
        // { value: "6 Hari Sebelum", text: "6 Hari Sebelum" },
    ];
    let indexEnd = 1;
    for (let index = 1; index <= 60; index++) {
        if (index < 31) {
            batasHari.push({
                value: index + ' Hari Sebelum',
                text: index + ' Hari Sebelum',
            });
        } else {
            batasHari.push({
                value: indexEnd + ' Hari Sesudah',
                text: indexEnd + ' Hari Sesudah',
            });
            indexEnd++;
        }
    }
    const batasJam = [
        // { value: "1 Jam Sebelum", text: "1 Jam Sebelum" },
        // { value: "2 Jam Sebelum", text: "2 Jam Sebelum" },
        // { value: "3 Jam Sebelum", text: "3 Jam Sebelum" },
        // { value: "4 Jam Sebelum", text: "4 Jam Sebelum" },
        // { value: "5 Jam Sebelum", text: "5 Jam Sebelum" },
        // { value: "6 Jam Sebelum", text: "6 Jam Sebelum" },
        // { value: "7 Jam Sebelum", text: "7 Jam Sebelum" },
        // { value: "8 Jam Sebelum", text: "8 Jam Sebelum" },
        // { value: "9 Jam Sebelum", text: "9 Jam Sebelum" },
        // { value: "10 Jam Sebelum", text: "10 Jam Sebelum" },
        // { value: "11 Jam Sebelum", text: "11 Jam Sebelum" },
        // { value: "12 Jam Sebelum", text: "12 Jam Sebelum" },
    ];
    for (let index = 1; index < 24; index++) {
        batasJam.push({
            value: index + ' Jam Sebelum',
            text: index + ' Jam Sebelum',
        });
    }

    useEffect(() => {
        onGetFirstData();
    }, [initVal]);

    const onGetFirstData = async () => {
        let resultMerchant = [];
        if (merchantList === null) {
            resultMerchant = await getMerchantList({ search: 'all' });
        }

        if (initVal !== null) {
            setFieldName({
                merchant: initVal.merchant,
                province: initVal.province,
                city: initVal.city,
                sub_district: initVal.subdistrict,
                activity: initVal.activity,
            });
            setSubCatLength(initVal.sub_category.length || 0);
            const cekUsia = (age, type) => {
                if (age && age.length) {
                    const filtered = age.filter((el) => {
                        if (
                            el.toLowerCase() === 'orang tua' ||
                            el.toLowerCase() === 'semua umur'
                        ) {
                            return false;
                        } else {
                            return el;
                        }
                    });

                    if (type === 'dari') {
                        return filtered[0];
                    } else {
                        return filtered[filtered.length - 1];
                    }
                }
            };

            const isSemuaUmur = (age, type) => {
                var rv = false;
                const filtered = age.filter((el) => {
                    const filtered = age.filter((el) => {
                        if (type == 1 && el.toLowerCase() === 'semua umur') {
                            rv = true;
                        } else if (
                            type == 2 &&
                            el.toLowerCase() === 'orang tua'
                        ) {
                            rv = true;
                        }
                    });
                });
                return rv;
            };
            var act = isDuplicate
                ? initVal.activity + ' - Duplicate'
                : initVal.activity;
            form.setFieldsValue({
                activity: act,
                slug: isDuplicate
                    ? act.replace(/\s+/g, '-').toLowerCase()
                    : initVal.slug,
                merchant_id: Number(initVal.merchant_id),
                // description: initVal.description,
                // include_price: initVal.include_price,
                // prepare_before: initVal.prepare_before,
                // tnc: initVal.tnc,
                // detail_info: initVal.detail_info,
                status: initVal.status,
                category: initVal.category,
                sub_category: initVal.sub_category,
                semua_umur:
                    initVal.age !== null &&
                    // initVal.age[initVal.age.length - 1].toLowerCase() ===
                    // 'semua umur'
                    isSemuaUmur(initVal.age, 1)
                        ? 'checked'
                        : '',
                orang_tua:
                    initVal.age !== null &&
                    // initVal.age[initVal.age.length - 1].toLowerCase() ===
                    // 'orang tua'
                    isSemuaUmur(initVal.age, 2)
                        ? 'checked'
                        : '',
                video: initVal.video,
                usia_dari: cekUsia(initVal.age, 'dari'),
                usia_hingga: cekUsia(initVal.age, 'hingga'),
                type: initVal.type,
                aggre: initVal.weight !== '0' ? 'checked' : '',
                weight: initVal.weight,
                google_maps: initVal.google_maps,
                additional_transport_fee: initVal.additional_transport_fee,
                location_detail: initVal.location_detail,
                pre_order_day: Number(initVal.pre_order_day),
                kids_benefit: initVal.kids_benefit,
                learning_method: initVal.learning_method,
                schedule_detail: initVal.schedule_detail,
                schedule_type: initVal.schedule_type,
                event_duration: initVal.event_duration,
                batas_pembelian: initVal.purchase_limit,
                purchase_expired: initVal.purchase_expired,
                purchase_limit: handlePurchaseLimitForInit(initVal),
                schedule: initVal.schedule,
                date_duration: initVal.date_duration,
                // initVal.date_duration === 'dengan_tanggal'
                //     ? 'tanggal_expired'
                //     : initVal.date_duration,
                expired_ticket: !initVal.expired_ticket.includes('Hari')
                    ? `${initVal.expired_ticket} Hari`
                    : initVal.expired_ticket,
                expired: moment(initVal.expired),
                have_varian: !!initVal.variant
                    ? initVal.variant.length > 1
                        ? true
                        : false
                    : '',
                tag: initVal.tag || [],
                jmltgl:
                    initVal.select_date.length === 0
                        ? '2'
                        : initVal.select_date.length.toString(),
                send_from: initVal.send_from,
                city_name: Array.isArray(initVal.city)
                    ? initVal.city.filter(Boolean)
                    : [],
                // shipping_info_1: initVal.shipping_info_1,
                shipping_info_2: initVal.shipping_info_2,
                shipping_info_3: initVal.shipping_info_3,

                product_field_1: initVal.product_field_1,
                product_field_2: initVal.product_field_2,
                product_field_3: initVal.product_field_3,
            });

            setRtValue(initVal.description);
            setHrgTermasuk(initVal.include_price);
            setHrsDisiapkan(initVal.prepare_before);
            setSnkTambahan(initVal.tnc);
            setInfoLainnya(initVal.detail_info);
            setKidsBenefit(initVal.kids_benefit);
            setLearningMethod(initVal.learning_method);
            setScheduleDetail(initVal.schedule_detail);

            setCheckIsOrangtua(
                // initVal.age[initVal.age.length - 1].toLowerCase() ===
                //     'orang tua'
                isSemuaUmur(initVal.age, 2) ? true : false,
            );

            switch (initVal.schedule_type) {
                case 'pilih_tanggal':
                    initVal.select_date.map((el, idx) => {
                        form.setFieldsValue({
                            [`tglmulai${idx}`]: moment(el.start_date),
                            [`tglselesai${idx}`]: moment(el.end_date),
                        });
                        return '';
                    });
                    break;
                case 'dengan_tanggal':
                    form.setFieldsValue({
                        start_date1: moment(initVal.start_date), //initVal.select_date.length !== 0 ? moment(initVal.select_date[0].start_date) : '',
                        end_date1: moment(initVal.end_date), //initVal.select_date.length !== 0 ? moment(initVal.select_date[0].end_date) : '',
                    });
                    break;
                default:
                    break;
            }

            setCityId(initVal.cityId);
            setIsVarian(
                initVal.variant
                    ? initVal.variant.length > 1
                        ? true
                        : false
                    : '',
            );
            setProductId(initVal.product_id);
            setProductProductId(initVal.product_product_id);
            setSemuaUmur(
                // initVal.age !== null &&
                //     initVal.age[initVal.age.length - 1].toLowerCase() ===
                //     'semua umur'
                isSemuaUmur(initVal.age, 1) ? true : false,
            );
            setAggre(initVal.weight !== '0' ? true : false);
            setProdType(initVal.type);
            setIsDisabled(false);
            setSelectedCat(initVal.category);
            setTipePelaksanaan(initVal.schedule_type);
            setJmlPilTgl(
                initVal.select_date.length === 0
                    ? 2
                    : initVal.select_date.length,
            );
            setBatasPembelian(
                initVal.purchase_limit === 'Hitungan Hari' ? 'hari' : 'jam',
            );
            setMasaBerlaku(
                initVal.date_duration,
                // == 'dengan_tanggal'
                //     ? 'tanggal_expired'
                //     : initVal.date_duration,
            );
            let initVar =
                initVal.variant !== null &&
                initVal.variant.map((el, idx) => {
                    return {
                        id: el.id,
                        name: el.activity,
                        product_id: el.product_id,
                        product_product_id: el.product_product_id,
                        price: el.price,
                        sale_price: el.sale_price,
                        sale_price_start: el.sale_price_start,
                        sale_price_expired: el.sale_price_expired,
                        stock: el.stock,
                        disc: el.sale_price !== 0 ? true : false,
                    };
                });
            setVarian(initVal.variant === null ? [blankVariant] : initVar);
            if (initVal.variant !== null && initVal.variant.length > 1) {
                initVal.variant.map((el, idx) => {
                    form.setFieldsValue({
                        [`namavarian${idx}`]: el.activity,
                        [`harga_asli${idx}`]: el.price,
                        [`harga_diskon${idx}`]: el.sale_price,
                        [`stock${idx}`]: el.stock,
                        [`isdiskon${idx}`]:
                            el.sale_price === 0 ? '' : 'checked',
                        [`sale_price_expired${idx}`]:
                            el.sale_price_start !== ''
                                ? [
                                      moment(el.sale_price_start, 'D-MM-YYYY'),
                                      moment(
                                          el.sale_price_expired,
                                          'D-MM-YYYY',
                                      ),
                                  ]
                                : '',
                    });
                    return true;
                });
            } else if (initVal.variant !== null) {
                form.setFieldsValue({
                    harga_asli1: initVal.variant[0].price,
                    harga_diskon1: initVal.variant[0].sale_price,
                    stock1: initVal.variant[0].stock,
                    diskon1:
                        initVal.variant[0].sale_price === 0 ? '' : 'checked',
                    sale_price_expired:
                        initVal.variant[0].sale_price_start !== ''
                            ? [
                                  moment(
                                      initVal.variant[0].sale_price_start,
                                      'D-MM-YYYY',
                                  ),
                                  moment(
                                      initVal.variant[0].sale_price_expired,
                                      'D-MM-YYYY',
                                  ),
                              ]
                            : '',
                });
                setIsDiskon({
                    status: initVal.variant[0].sale_price === 0 ? false : true,
                    harga_asli: initVal.variant[0].price,
                    id: -1,
                });
            }
            form.setFieldsValue({
                premium_product: [
                    initVal.product_premium_start_date == ''
                        ? ''
                        : moment(initVal.product_premium_start_date),
                    initVal.product_premium_expired_date == ''
                        ? ''
                        : moment(initVal.product_premium_expired_date),
                ],
            });
            getProvinceList();
            const cover = initVal.image.filter((el, idx) => {
                return el.cover === true;
            });

            const option_image = initVal.image.filter((el, idx) => {
                return el.cover === false;
            });

            if (isDuplicate) {
                cover.forEach((x, idx) => {
                    uploadMainPicDuplicate(x.id);
                });
                option_image.forEach((x, idx) => {
                    opsionalPicDuplicate(option_image, x.id, idx);
                });
            } else {
                setImageUrl(cover.length !== 0 ? cover[0].url : '');
                setImageList(initVal.image);
                setFileList(
                    option_image.map((el, idx) => {
                        return {
                            uid: el.id,
                            url: el.url,
                            status: 'done',
                        };
                    }),
                );
            }
            onCheckDisabledSelectDay(initVal);
            let dataMerchant = merchantList || resultMerchant;
            let result = dataMerchant.find(
                (item) => item.merchant_id == initVal.merchant_id,
            );
            setIdSmallMerchant(result.id);
        } else {
            form.setFieldsValue({
                start_premium_product: '',
                expired_premium_product: '',
            });
            getProvinceList();
        }
    };

    const handleChangeUsia = (value) => {
        if (
            rangeUmur.findIndex((e) => e.value == value) >
            rangeUmur.findIndex(
                (e) => e.value == form.getFieldValue('usia_hingga'),
            )
        ) {
            form.setFieldsValue({
                usia_dari: value,
                usia_hingga: value,
            });
            return;
        }
        form.setFieldsValue({
            usia_dari: value,
        });
    };

    const uploadMainPic = async ({ file }) => {
        const formData = new FormData();
        formData.append('photo', file);
        const result = await serviceFormProduct.postPhoto(formData);
        if (result.isSuccess) {
            let res = result.response;
            setImageUrl(res.image);
            let objIndex = imageList.findIndex((el) => el.cover === true);
            if (objIndex !== -1) {
                imageList[objIndex].id = res.image_id;
                imageList[objIndex].url = res.image;
                setImageList([...imageList]);
            } else {
                setImageList([
                    ...imageList,
                    {
                        id: res.image_id,
                        url: res.image,
                        cover: true,
                    },
                ]);
            }

            message.success({ content: 'Success Upload Image', key });
            setLoading(false);
        } else {
            message.error({ content: 'Failed to upload!', key });
            setLoading(false);
        }
    };

    const uploadMainPicDuplicate = async (id) => {
        const formData = new FormData();
        formData.append('id', id);
        const result = await serviceFormProduct.postPhotoDuplicate(formData);
        if (result.isSuccess) {
            let res = result.response;
            setImageUrl(res.image);
            let objIndex = imageList.findIndex((el) => el.cover === true);
            if (objIndex !== -1) {
                imageList[objIndex].id = res.image_id;
                imageList[objIndex].url = res.image;
                setImageList([...imageList]);
            } else {
                setImageList([
                    ...imageList,
                    {
                        id: res.image_id,
                        url: res.image,
                        cover: true,
                    },
                ]);
            }

            message.success({ content: 'Success Upload Image', key });
            setLoading(false);
        } else {
            message.error({ content: 'Failed to upload!', key });
            setLoading(false);
        }
    };

    const checkSlugToApi = async (formData) => {
        const result = await serviceFormProduct.postSlug(formData);
        setCheckSlug(!result.response.is_slug_exist);
        return !result.response.is_slug_exist;
        // form.validateFields(['slug']);
    };
    const opsionalPic = async ({ file }) => {
        const formData = new FormData();
        formData.append('photo', file);
        const result = await serviceFormProduct.postPhoto(formData);
        if (result.isSuccess) {
            let res = result.response;
            setImageList([
                ...imageList,
                {
                    id: res.image_id,
                    url: res.image,
                    cover: false,
                },
            ]);
            setFileList([
                ...fileList,
                {
                    uid: res.image_id,
                    status: 'done',
                    url: res.image,
                },
            ]);
            message.success({ content: 'Success Upload Image', key });
            setLoading(false);
        } else {
            message.error({ content: 'Failed to upload!', key });
            setLoading(false);
        }
    };
    const opsionalPicDuplicate = async (option_image, id, idx) => {
        const formData = new FormData();
        formData.append('id', id);
        const result = await serviceFormProduct.postPhotoDuplicate(formData);
        if (result.isSuccess) {
            let res = result.response;

            setFileList(
                option_image.map((el, x) => {
                    if (x == idx) {
                        return {
                            uid: res.image_id,
                            url: res.image,
                            status: 'done',
                        };
                    } else {
                        return {
                            uid: el.id,
                            url: el.url,
                            status: 'done',
                        };
                    }
                }),
            );
            message.success({ content: 'Success Upload Image', key });
            setLoading(false);
        } else {
            message.error({ content: 'Failed to upload!', key });
            setLoading(false);
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
                key,
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
                key,
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
        // return false;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading...', key });
            setLoading(true);
            return;
        }
    };

    useEffect(() => {
        if (imageUrl) {
            form.setFieldsValue({
                main_photo: imageUrl,
            });
        } else {
            form.setFieldsValue({
                main_photo: null,
            });
        }
    }, [imageUrl]);

    const getProductId = async (id, name) => {
        // const result = await serviceFormProduct.postProduct({ merchant_id: id.toString(), merchant: name });
        // if (result.isSuccess) {
        // 	setProductId(result.response.product_id);
        // 	setMerchantId(result.response.merchant_id);
        // } else {
        // 	message.error('Terjadi kesalahan saat pemilihan Merhchant, coba lagi!')
        // }
    };

    const getMerchantList = async (params) => {
        let resultQuery = { ...params };
        const result = await serviceFormProduct.getMerchantList(resultQuery);
        if (result.isSuccess) {
            setMerchantList(result.response.payload);
            return result.response.payload;
        }
    };

    const onChange = ({ file, fileList: newFileList }) => {
        if (file.status === 'removed' || file.status === 'done') {
            setFileList(newFileList);
        } else {
            return false;
        }
    };

    const onRemove = (e) => {
        // return false;
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleProdType = (value) => {
        setProdType(value);
        form.setFieldsValue({
            // 	...fieldName,
            province: '',
            city: '',
            sub_district: '',
        });
        setProvinceId('');
        setCityId('');
        setSubdistrictId('');
    };

    const handleDelVariant = (index) => {
        let resultVarian = varian.filter((el, idx) => idx !== index);
        resultVarian.map((el, idx) => {
            form.setFieldsValue({
                [`namavarian${idx}`]: el.name,
                [`harga_asli${idx}`]: el.price,
                [`harga_diskon${idx}`]: el.sale_price,
                [`stock${idx}`]: el.stock,
                [`isdiskon${idx}`]:
                    !!!el.sale_price || el.sale_price === 0 ? false : 'checked',
                [`sale_price_expired${idx}`]:
                    el.sale_price_start !== ''
                        ? [
                              moment(el.sale_price_start, 'D-MM-YYYY'),
                              moment(el.sale_price_expired, 'D-MM-YYYY'),
                          ]
                        : '',
            });
        });
        setVarian(resultVarian);
    };

    const handleAddVariant = () => {
        let resultVarian = [...varian, blankVariant];
        resultVarian.map((item, key) => {
            form.setFieldsValue({
                [`namavarian${key}`]: item.name,
                [`harga_asli${key}`]: item.price,
                [`harga_diskon${key}`]: item.sale_price,
                [`stock${key}`]: item.stock,
                [`isdiskon${key}`]:
                    !!!item.sale_price || item.sale_price === 0
                        ? false
                        : 'checked',
                [`sale_price_expired${key}`]: !!item.sale_price_start
                    ? [
                          moment(item.sale_price_start, 'D-MM-YYYY'),
                          moment(item.sale_price_expired, 'D-MM-YYYY'),
                      ]
                    : '',
            });
        });

        setVarian(resultVarian);
    };

    const handlePromo = (index, value = 0, disc = null) => {
        var foundIndex = varian.findIndex((x, idx) => idx === index);
        if (value === 0) {
            if (disc !== null) {
                varian[foundIndex].disc = disc;
            } else {
                varian[foundIndex].disc = !varian[foundIndex].disc;
            }
        } else {
            varian[foundIndex].price = value;
        }
        setVarian([...varian]);
    };

    useEffect(() => {
        if (provinceId !== '') {
            getCityList({ province_id: provinceId });
        }
        getAllCityList();
    }, [provinceId]);

    useEffect(() => {
        if (cityId !== '') {
            getSubdistrictList({ city_id: cityId });
        }
    }, [cityId]);
    // useEffect(() => {
    // 	if (shippingId == '') {
    // 		getShippingList();
    // 	}
    // }, [shippingId]);

    const getProvinceList = async () => {
        const result = await serviceFormProduct.getProvince();
        if (result.isSuccess && result.response.payload !== null) {
            setProvinceList(result.response.payload);
            if (initVal !== null && fromFirstInit) {
                setProvinceId(initVal.province_id);
                form.setFieldsValue({
                    province: initVal.province,
                });
            }
        } else {
            setProvinceList([]);
        }
    };

    const getCityList = async (param) => {
        const result = await serviceFormProduct.getCity(param);
        if (result.isSuccess && result.response.payload !== null) {
            setCityList(result.response.payload);
            if (initVal !== null && fromFirstInit) {
                setCityId(initVal.city_id);
                form.setFieldsValue({
                    city: initVal.city,
                });
            }
        } else {
            setCityList([]);
        }
    };

    const getAllCityList = async () => {
        const result = await serviceFormProduct.getCity();
        if (result.isSuccess && result.response.payload !== null) {
            setAllCityList(result.response.payload);
            if (initVal !== null && fromFirstInit) {
                setCityId(initVal.city_id);
                form.setFieldsValue({
                    city_name: initVal.city,
                });
            }
        } else {
            setAllCityList([]);
        }
    };
    const getSubdistrictList = async (param) => {
        const result = await serviceFormProduct.getSubDistrict(param);
        if (result.isSuccess && result.response.payload !== null) {
            setSubdistrictList(result.response.payload);
            if (initVal !== null && fromFirstInit) {
                setSubdistrictId(initVal.subdistrict_id);
                form.setFieldsValue({
                    sub_district: initVal.subdistrict,
                });
            }
        } else {
            setSubdistrictList([]);
        }
    };
    // const getShippingList = async (param) => {
    //     const result = await serviceFormProduct.getShippingList(param);
    //     if (result.isSuccess && result.response.payload !== null) {
    //         setShippingList(result.response.payload);
    //     } else {
    //         setShippingList([]);
    //     }
    // };

    const range = (start, end) => {
        const result = [];

        for (let i = start; i < end; i++) {
            result.push(i);
        }

        return result;
    };

    function disabledDate(current) {
        return current && current < moment().startOf('day');
    }

    function disabledTime(date) {
        return {
            disabledHours:
                date && date.valueOf() < Date.now()
                    ? () => range(0, moment().hour() + 1)
                    : null,
        };
    }

    function CategoriInformation(props) {
        switch (props.type) {
            case 'Aktivitas':
                return (
                    <small>
                        Merupakan segala jenis kegiatan menarik yang dapat
                        dilakukan oleh anak dan orang tua baik secara online
                        maupun offline. Contoh: Workshop Membuat Lilin
                        Aromaterapi, Sesi Melukis Watercolor, Peralatan
                        Aktivitas (Learning Kit & Activity Kit), DIY Costume.
                    </small>
                );
            case 'Kursus':
                return (
                    <small>
                        Merupakan kegiatan belajar mengajar berkelanjutan yang
                        difokuskan pada satu atau beberapa bidang tertentu.
                        Contoh: Les Piano, Les Privat, Kursus Balet, Bimbel,
                        Online Class.
                    </small>
                );
            case 'Experience':
                return (
                    <small>
                        Merupakan aktivitas non edukasi untuk mendapatkan
                        pengalaman yang menyenangkan Contoh: Newborn
                        Photography, Playground (Taman Bermain), Homecare, Mom &
                        Babycare.
                    </small>
                );
            case 'Kelas Online':
                return (
                    <small>
                        Merupakan layanan aktivitas yang disediakan oleh
                        Kiddo.id agar pengguna dapat menikmati aktivitas secara
                        online baik melalui video maupun melalui Zoom meeting.
                    </small>
                );
            case 'Kesehatan':
                return (
                    <small>
                        Merupakan produk dan layanan di bidang Kesehatan yang
                        disediakan oleh Kiddo.id, dimana orang tua dapat memesan
                        produk dan layanan kesehatan yang berkualitas untuk si
                        kecil dan keluarga.
                    </small>
                );
            case 'Keluarga':
                return (
                    <small>
                        Merupakan produk dan layanan non-edukasi yang disediakan
                        oleh Kiddo.id, dimana orang tua dapat memesan produk dan
                        layanan pendukung yang berkualitas untuk si kecil dan
                        keluarga.
                    </small>
                );
            default:
                return '';
        }
    }

    const handleVariant = (values) => {
        let variants = [];
        if (!values.have_varian) {
            variants.push({
                id: varian[0].id,
                product_product_id: varian[0].product_product_id,
                product_id: Number(productId),
                activity: '',
                price: Number(values.harga_asli1),
                sale_price: Number(values.harga_diskon1) || 0,
                sale_price_start:
                    values.sale_price_expired === undefined
                        ? ''
                        : moment(values.sale_price_expired[0]).format(
                              'YYYY-MM-D',
                          ),
                sale_price_expired:
                    values.sale_price_expired === undefined
                        ? ''
                        : moment(values.sale_price_expired[1]).format(
                              'YYYY-MM-D',
                          ),
                stock: Number(values.stock1),
            });
        } else {
            const tmp = {
                varian: [
                    values.namavarian0,
                    values.namavarian1,
                    values.namavarian2,
                    values.namavarian3,
                    values.namavarian4,
                ],
                price: [
                    values.harga_asli0,
                    values.harga_asli1,
                    values.harga_asli2,
                    values.harga_asli3,
                    values.harga_asli4,
                ],
                sale_price: [
                    values.harga_diskon0,
                    values.harga_diskon1,
                    values.harga_diskon2,
                    values.harga_diskon3,
                    values.harga_diskon4,
                ],
                sale_price_expired: [
                    values.sale_price_expired0,
                    values.sale_price_expired1,
                    values.sale_price_expired2,
                    values.sale_price_expired3,
                    values.sale_price_expired4,
                ],
                stok: [
                    values.stock0,
                    values.stock1,
                    values.stock2,
                    values.stock3,
                    values.stock4,
                ],
            };
            varian.map((el, index) => {
                variants.push({
                    id: el.id,
                    product_product_id: el.product_product_id,
                    product_id: Number(productId),
                    activity: tmp.varian[index],
                    price: Number(tmp.price[index]),
                    sale_price_start:
                        tmp.sale_price_expired[index] === undefined
                            ? ''
                            : moment(tmp.sale_price_expired[index][0]).format(
                                  'YYYY-MM-D',
                              ),
                    sale_price_expired:
                        tmp.sale_price_expired[index] === undefined
                            ? ''
                            : moment(tmp.sale_price_expired[index][1]).format(
                                  'YYYY-MM-D',
                              ),
                    sale_price: Number(tmp.sale_price[index]) || 0,
                    stock: Number(tmp.stok[index]),
                });
                return true;
            });
        }
        return variants;
    };

    const handleAge = (values) => {
        let tmp = [];

        let id_dari = rangeUmur.findIndex((el, idx) => {
            return el.value === values.usia_dari;
        });
        let id_hingga = rangeUmur.findIndex((el, idx) => {
            return el.value === values.usia_hingga;
        });

        if (semuaUmur) {
            rangeUmur.map((el, index) => {
                tmp.push(el.value);
                return true;
            });
            tmp.push('Semua umur');
            return [...tmp, 'Orang tua'];
        } else {
            if (values.usia_hingga !== '') {
                rangeUmur.map((el, index) => {
                    if (id_dari - 1 < index && id_hingga + 1 > index) {
                        tmp.push(el.value);
                    }
                    return true;
                });
            }
            if (values.orang_tua) {
                tmp.push('Orang tua');
            }
            return [...tmp];
        }
    };

    const handleSelectDate = (values) => {
        let select_date = [];
        let tmp = {
            tglmulai: [
                values.tglmulai0,
                values.tglmulai1,
                values.tglmulai2,
                values.tglmulai3,
                values.tglmulai4,
            ],
            tglselesai: [
                values.tglselesai0,
                values.tglselesai1,
                values.tglselesai2,
                values.tglselesai3,
                values.tglselesai4,
            ],
        };
        switch (values.schedule_type) {
            case 'pilih_tanggal':
                for (let i = 0; i < jmlPilTgl; i++) {
                    select_date.push({
                        // start_date: tmp.tglmulai[i].format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
                        // end_date: tmp.tglselesai[i].format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
                        start_date: moment(tmp.tglmulai[i]).toISOString(),
                        end_date: moment(tmp.tglselesai[i]).toISOString(),
                    });
                }
                return select_date;
            // case 'dengan_tanggal':
            // 	select_date.push({
            // 		start_date: moment(values.start_date1).toISOString(),
            // 		end_date: moment(values.end_date1).toISOString()
            // 	})
            // return select_date;
            default:
                return [];
        }
    };

    function TipePelaksanaanKet({ type }) {
        switch (type) {
            case 'pilih_tanggal':
                return (
                    <small>
                        Pembeli dapat memilih tanggal pelaksanaan dari pilihan
                        tanggal tersedia.
                    </small>
                );
            case 'dengan_tanggal':
                return (
                    <small>
                        Aktivitas dengan tanggal pelaksanaan tertentu.
                    </small>
                );
            case 'input_tanggal':
                return (
                    <small>
                        Aktivitas yang tanggal pelaksanaannya dipilih oleh
                        pembeli. Merchant akan menghubungi pembeli untuk
                        konfirmasi.
                    </small>
                );
            case 'kursus':
                return (
                    <small>
                        Aktivitas yang memiliki beberapa kali pertemuan dalam
                        satu pembelian tiket. Merchant akan menghubungi pembeli
                        untuk penentuan tanggal pertemuan.
                    </small>
                );
            case 'tanggal_expired':
                return (
                    <small>
                        Aktivitas dengan masa berlaku tiket untuk ditukarkan
                        kepada merchant.
                    </small>
                );
            default:
                return '';
        }
    }

    const clearVariantInput = (have_varian) => {
        if (have_varian) {
            form.setFieldsValue({
                namavarian0: '',
                namavarian1: '',
                namavarian2: '',
                namavarian3: '',
                namavarian4: '',
                stock0: '',
                stock1: '',
                stock2: '',
                stock3: '',
                stock4: '',
                harga_diskon0: '',
                harga_diskon1: '',
                harga_diskon2: '',
                harga_diskon3: '',
                harga_diskon4: '',
                harga_asli0: '',
                harga_asli1: '',
                harga_asli2: '',
                harga_asli3: '',
                harga_asli4: '',
                sale_price_expired0: '',
                sale_price_expired1: '',
                sale_price_expired2: '',
                sale_price_expired3: '',
                sale_price_expired4: '',
                have_varian: true,
            });
        } else {
            form.setFieldsValue({
                harga_asli1: '',
                harga_diskon1: '',
                stock1: '',
                diskon1: '',
                sale_price_expired: '',
                have_varian: false,
            });
        }
        return true;
    };

    const tagchilds = [];
    for (let i = 10; i < 36; i++) {
        tagchilds.push(<Option key={i}>{i.toString(36) + i}</Option>);
    }

    const handlePurchaseLimit = (values) => {
        switch (values.schedule_type) {
            case 'pilih_tanggal':
                return values.purchase_limit.toLowerCase().includes('jam')
                    ? 'Hitungan Jam'
                    : 'Hitungan Hari';
            case 'dengan_tanggal':
                return values.purchase_limit.toLowerCase().includes('jam')
                    ? 'Hitungan Jam'
                    : 'Hitungan Hari';
            case 'input_tanggal':
                return `${values.purchase_limit}` + ' Hari';
            case 'kursus':
                return `${values.purchase_limit}` + ' Hari';
            case 'tanggal_expired':
                return !values.purchase_limit
                    ? ''
                    : `${values.purchase_limit}` + ' Hari';
            default:
                return '';
        }
    };

    const handlePurchaseLimitForInit = (values) => {
        switch (values.schedule_type) {
            case 'pilih_tanggal':
                return values.purchase_limit.toLowerCase().includes('jam')
                    ? 'Hitungan Jam'
                    : 'Hitungan Hari';
            case 'dengan_tanggal':
                return values.purchase_limit.toLowerCase().includes('jam')
                    ? 'Hitungan Jam'
                    : 'Hitungan Hari';
            case 'input_tanggal':
                return values.purchase_limit; //+ ' Hari';
            case 'kursus':
                return values.purchase_limit; //+ ' Hari';
            case 'tanggal_expired':
                return values.purchase_limit; //+ ' Hari';
            default:
                return '';
        }
    };

    const handleImageList = (fileList) => {
        if (fileList) {
            const coverImage = imageList.filter((el, idx) => {
                return el.cover === true;
            });
            let tmp = [];
            fileList.map((el, idx) => {
                if (el.id == undefined) {
                    tmp.push({
                        id: el.uid,
                        url: el.url,
                        cover: false,
                    });
                } else {
                    tmp.push({
                        id: el.id,
                        url: el.url,
                        cover: false,
                    });
                }
            });
            return [...tmp, coverImage[0]];
        } else {
            return imageList;
        }
    };

    const checkTanggal = (values) => {
        [...Array(Number(jmlPilTgl))].forEach((_, i) => {
            if (
                changeMilisecondToZero(values[`tglmulai${i}`]) >=
                changeMilisecondToZero(values[`tglselesai${i}`])
            ) {
                return 'Tanggal selesai harus lebih besar daripada tanggal mulai';
            }
        });

        return false;
    };

    const onFinish = (values) => {
        // console.log(`VALUES :`, values);
        // return false;

        var v_city_id = 0;
        var v_city = [];
        if (prodType == 'offline') {
            v_city =
                cityId == ''
                    ? []
                    : [cityList.find((x) => x.id === cityId.toString())?.name];
            v_city_id = cityId == '' ? [] : Number(cityId);
        } else if (prodType == 'offline_at_participant') {
            v_city_id = 0;
            v_city = values.city_name;
        }

        if (imageUrl) {
            const submitData = {
                ...(isDuplicate
                    ? { product_id: '' }
                    : { product_id: productId }),
                ...(isDuplicate
                    ? { product_product_id: '' }
                    : { product_product_id: productProductId }),
                // product_product_id: productProductId,
                merchant: fieldName.merchant,
                merchant_id: values.merchant_id.toString(),
                activity: values.activity,
                slug: values.slug,
                video: values.video || '',
                category: values.category,
                sub_category: values.sub_category,
                age: handleAge(values),
                type: values.type,

                province_id: provinceId == '' ? 0 : Number(provinceId),
                province:
                    provinceId == ''
                        ? ''
                        : provinceList.find((x) => x.id === provinceId)?.name,
                city_id: v_city_id,
                city: v_city,
                subdistrict_id: subdistrictId == '' ? 0 : Number(subdistrictId),
                subdistrict:
                    subdistrictId == ''
                        ? ''
                        : subdistrictList.find((x) => x.id === subdistrictId)
                              ?.name,

                location_detail: values.location_detail || '',
                google_maps: values.google_maps || '',
                weight: values.weight || '',
                variant: handleVariant(values),
                image: handleImageList(fileList),
                description: rtValue, //values.description,
                additional_transport_fee:
                    prodType == 'offline_at_participant'
                        ? false
                        : !!values.additional_transport_fee
                        ? values.additional_transport_fee
                        : false,
                kids_benefit: values.kids_benefit,
                schedule_detail: values.schedule_detail,
                learning_method: values.learning_method,
                pre_order_day: Number(values.pre_order_day),
                include_price: hrgTermasuk, // values.include_price,
                prepare_before: hrsDisiapkan, // values.prepare_before || '',
                tnc: snkTambahan, // values.tnc || '',
                detail_info: infoLainnya, // values.detail_info || '',
                schedule_type: values.schedule_type || '',
                event_duration: values.event_duration.trim(),
                select_date:
                    values.schedule_type === 'dengan_tanggal'
                        ? null
                        : handleSelectDate(values),
                start_date:
                    values.schedule_type === 'dengan_tanggal'
                        ? moment(values.start_date1).toISOString()
                        : '',
                end_date:
                    values.schedule_type === 'dengan_tanggal'
                        ? moment(values.end_date1).toISOString()
                        : '',
                date_duration: values.date_duration,
                // == 'tanggal_expired'
                //     ? 'dengan_tanggal'
                //     : values.date_duration || '',
                product_premium_start_date:
                    !!values.premium_product && !!values.premium_product[0]
                        ? moment(values.premium_product[0])
                              .set({ hour: 0, minutes: 0, second: 0 })
                              .utc(0)
                              .toISOString()
                        : '',
                product_premium_expired_date:
                    !!values.premium_product && !!values.premium_product[1]
                        ? moment(values.premium_product[1])
                              .set({ hour: 23, minutes: 59, second: 59 })
                              .utc(0)
                              .toISOString()
                        : '',
                schedule: values.schedule,
                expired:
                    (!!values.expired &&
                        convertToIsoWithoutTime(values.expired)) ||
                    '',
                expired_ticket: values.expired_ticket || '',
                purchase_limit: handlePurchaseLimit(values),
                purchase_expired: values.purchase_expired || '',
                status: values.status,
                tag: values.tag || null,
                send_from: values.send_from || '',
                // shipping_info_1: Array.isArray(values.shipping_info_1) ? values.shipping_info_1 : [],
                shipping_info_2: values.shipping_info_2 || '',
                shipping_info_3: values.shipping_info_3 || '',
                product_field_1: values.product_field_1 || '',
                product_field_2: values.product_field_2 || '',
                product_field_3: values.product_field_3 || '',
            };

            handleSubmit(submitData);
        } else {
            message.error('Foto Utama wajib diupload!');
            setErrorPhoto(true);
        }
    };
    const popoverContent = (
        <div>
            <p>Merchant ini belum memilih kurir.</p>
            <p>
                Silahkan lengkapi data kurir untuk merchant ini terlebih dahulu.
            </p>
        </div>
    );
    const handleSLug = (e) => {
        let val = e.target.value;
        form.setFieldsValue({
            slug:
                fieldName.merchant
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .replace(/\s+/g, '-')
                    .toLowerCase() +
                '-' +
                val
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .replace(/\s+/g, '-')
                    .toLowerCase(),
        });
        // checkSlug_(e);
    };

    const checkSlug_ = (_, value) => {
        if (checkSlug) {
            return Promise.resolve();
        } else {
            return Promise.reject(
                new Error(
                    'Slug ini sudah digunakan di produk lain, coba masukkan slug baru',
                ),
            );
        }
    };

    const handleClearTipeTanggal = (type) => {
        switch (type) {
            case 'pilih_tanggal':
                form.setFieldsValue({
                    tglmulai0: '',
                    tglmulai1: '',
                    tglmulai2: '',
                    tglmulai3: '',
                    tglmulai4: '',
                    tglselesai0: '',
                    tglselesai1: '',
                    tglselesai2: '',
                    tglselesai3: '',
                    tglselesai4: '',
                    purchase_limit: '',
                    purchase_expired: '',
                    jmltgl: '2',
                });
                break;
            case 'dengan_tanggal':
                form.setFieldsValue({
                    start_date1: '',
                    end_date1: '',
                    purchase_limit: '',
                    purchase_expired: '',
                });
                break;
            case 'input_tanggal':
                form.setFieldsValue({
                    purchase_limit: '',
                });
                break;
            case 'kursus':
                form.setFieldsValue({
                    schedule: '',
                    purchase_limit: '',
                });
                break;
            case 'tanggal_expired':
                form.setFieldsValue({
                    date_duration: '',
                    expired_ticket: '',
                    purchase_limit: '',
                    expired: '',
                });
                break;
            default:
                return true;
        }

        form.setFieldsValue({
            event_duration: '',
        });
    };

    const handleProvinceChange = (value) => {
        setProvinceId(value);
        setSubdistrictId('');
        setSubdistrictList([]);
        setCityId('');
        setCityList([]);
        form.setFieldsValue({
            city: '',
            sub_district: '',
        });
        setFromFirstInit(false);
    };
    const handleCityChange = (value) => {
        setCityId(value);
        form.setFieldsValue({
            sub_district: '',
        });
        setFromFirstInit(false);
    };
    const handleSubdistrictChange = (value) => {
        setSubdistrictId(value);
        setFromFirstInit(false);
    };

    const [checkIsOrangtua, setCheckIsOrangtua] = useState(false);

    // useEffect(() => {
    //     form.validateFields(['usia_dari']);
    //     form.validateFields(['usia_hingga']);
    // }, []);

    const orang_tua_onCheckboxChange = (e) => {
        form.validateFields(['usia_dari']);
        form.validateFields(['usia_hingga']);
        setCheckIsOrangtua(e.target.checked);
    };

    const changeMilisecondToZero = (date) => {
        // return moment(moment(date).milliseconds(0).toISOString());
        return moment(moment(date).seconds(0).millisecond(0).toISOString());
    };

    React.useEffect(() => {
        if (
            !!form.getFieldValue('start_date1') ||
            !!form.getFieldValue('end_date1')
        ) {
            onCheckDisabledSelectDay();
        }
    }, [toggleCheckDiff]);

    const onCheckDisabledSelectDay = (value) => {
        let values = value || form.getFieldsValue();
        const dateDiffStart = Math.floor(
            (new Date(values.start_date1 || values.start_date) - new Date()) /
                (1000 * 60 * 60 * 24),
        );
        const dateDiffEnd = Math.floor(
            (new Date(values.end_date1 || values.end_date) -
                new Date(values.start_date1 || values.start_date)) /
                (1000 * 60 * 60 * 24),
        );

        setDiffDate({
            start: dateDiffStart,
            end: dateDiffEnd,
        });

        if (values.start_date) {
            if (
                values.purchase_expired.includes('Sebelum') &&
                diffDate.start != dateDiffStart &&
                values.purchase_expired.split(' ')[0] > dateDiffStart
            ) {
                form.setFieldsValue({
                    purchase_expired:
                        dateDiffStart < 1
                            ? ''
                            : `${dateDiffStart} Hari Sebelum`,
                });
            }
        }
        if (values.end_date) {
            if (
                dateDiffEnd < diffDate.end &&
                values.purchase_expired.includes('Sesudah')
            ) {
                form.setFieldsValue({
                    purchase_expired:
                        dateDiffEnd < 1 ? '' : `${dateDiffEnd} Hari Sesudah`,
                });
            }
        }
    };

    const checkDisabledSelectDay = (el) => {
        if (el.value.includes('Sesudah')) {
            if (el.value.split(' ')[0] > diffDate.end) {
                return true;
            } else {
                return false;
            }
        } else {
            if (el.value.split(' ')[0] > diffDate.start) {
                return true;
            }
            return false;
        }
    };

    const handleChangeVarianChild = (index, name, value) => {
        let tempVarian = [...varian];
        if (name == 'sale_price_start') {
            if (value == null) {
                tempVarian[index]['sale_price_start'] = '';
                tempVarian[index]['sale_price_expired'] = '';
            } else {
                tempVarian[index]['sale_price_start'] = value[0];
                tempVarian[index]['sale_price_expired'] = value[1];
            }
        } else {
            tempVarian[index][name] = value;
        }
        setVarian(tempVarian);
    };

    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={16}>
                <Col md={16} sm={24} xs={24}>
                    <div>
                        <Form.Item
                            name="merchant_id"
                            label="Merchant"
                            rules={[
                                {
                                    required: true,
                                    message: 'Merchant wajib dipilih',
                                },
                            ]}>
                            <Select
                                showSearch
                                placeholder="Pilih Merchant"
                                optionFilterProp="children"
                                onSelect={(val, option) => {
                                    setFieldName({
                                        ...fieldName,
                                        merchant: option.children,
                                    });
                                    if (
                                        merchantId != val &&
                                        isCreate === true
                                    ) {
                                        getProductId(val, option.children);
                                    }
                                    setIdSmallMerchant(option.title);
                                    form.setFieldsValue({
                                        slug:
                                            option.children
                                                .replace(/\s+/g, '-')
                                                .toLowerCase() +
                                            '-' +
                                            fieldName.activity
                                                .replace(/\s+/g, '-')
                                                .toLowerCase(),
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }>
                                {merchantList?.map((el, index) => {
                                    return (
                                        <Option
                                            key={index}
                                            title={el.id}
                                            value={el.merchant_id}>
                                            {el.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            name="activity"
                            required
                            label="Nama Aktivitas"
                            validateTrigger={['onChange']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Aktivitas wajib diisi',
                                },
                                {
                                    max: 100,
                                    message:
                                        'Nama Aktivitas maksimal 100 karakter',
                                },
                                {
                                    async validator(rule, value) {
                                        const formData = new FormData();
                                        formData.append(
                                            'slug',
                                            fieldName.merchant
                                                .replace(
                                                    /^[a-zA-Z\d\-_\s]+$/g,
                                                    '',
                                                )
                                                .replace(/\s+/g, '-')
                                                .toLowerCase() +
                                                '-' +
                                                value
                                                    .replace(
                                                        /^[a-zA-Z\d\-_\s]+$/g,
                                                        '',
                                                    )
                                                    .replace(/\s+/g, '-')
                                                    .toLowerCase(),
                                        );
                                        formData.append(
                                            'id',
                                            productId == null ? 0 : productId,
                                        );
                                        // checkSlugToApi(formData);
                                        // form.validateFields(['slug']);
                                    },
                                    // validateTrigger: 'onBlur',
                                },
                            ]}>
                            <Input
                                placeholder="Masukkan Nama Aktivitas"
                                onChange={handleSLug}
                            />
                        </Form.Item>
                        <small>
                            Contoh: Entertaining Dance Class, Fun English
                            course, Les Matematika Pintar, Kelas Renang Bersama
                            Atlet, DIY Princess Costume Kit.
                        </small>
                    </div>

                    <div>
                        <Form.Item
                            label="Slug"
                            name="slug"
                            rules={[
                                { required: true, message: 'Slug wajib diisi' },
                                {
                                    async validator(rule, value) {
                                        const formData = new FormData();
                                        formData.append(
                                            'slug',
                                            value
                                                .replace(/\s+/g, '-')
                                                .toLowerCase(),
                                        );
                                        formData.append(
                                            'id',
                                            productId == null ? 0 : productId,
                                        );
                                        const isExistSlug =
                                            await checkSlugToApi(formData);
                                        if (isExistSlug) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                new Error(
                                                    'Slug ini sudah digunakan di produk lain, coba masukkan slug baru',
                                                ),
                                            );
                                        }
                                    },
                                    validateTrigger: 'onBlur',
                                },
                            ]}>
                            <Input disabled={true} />
                        </Form.Item>
                    </div>

                    <div style={{ marginBottom: 20 }}>
                        <Button
                            type="link"
                            style={{
                                position: 'absolute',
                                right: 0,
                                zIndex: 999,
                            }}
                            onClick={() => {
                                setIsModalVisible(true);
                                setModalType('deskripsi');
                            }}>
                            Lihat Contoh
                        </Button>
                        <Form.Item
                            name="description"
                            label="Detail Aktivitas"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Detail Aktivitas wajib diisi',
                                },
                            ]}>
                            {/* <TextArea
								rows={10}
								showCount
								maxLength={1000}
								placeholder="Jelaskan detail Aktivitas"
							/> */}
                            <WysiwygStyle>
                                <ReactQuill
                                    theme="snow"
                                    value={rtValue}
                                    onChange={(val) => {
                                        if (val === '<p><br></p>') {
                                            val = null;
                                        }
                                        setRtValue(val);
                                        form.setFieldsValue({
                                            description: val || null,
                                        });
                                    }}
                                    placeholder="Jelaskan detail Aktivitas"
                                />
                            </WysiwygStyle>
                        </Form.Item>
                        <small>
                            Jelaskan dengan bahasa yang mudah dimengerti
                        </small>
                        {isModalVisible === true &&
                            modalType === 'deskripsi' && (
                                <Modal
                                    title="Detail Aktivitas"
                                    visible={true}
                                    footer={[]}
                                    onCancel={() => setIsModalVisible(false)}>
                                    <p>
                                        Contoh: Les privat berbagai mata
                                        pelajaran untuk siswa sekolah dasar
                                        dengan sistem online atau offline (tatap
                                        muka), difasilitasi oleh pengajar yang
                                        berpengalaman, dan didukung dengan
                                        fasilitas yang memadai dan suasana
                                        belajar yang menyenangkan. Setelah
                                        mengikuti les privat ini, anak di
                                        harapkan telah mengerti dan dapat
                                        mengerjakan soal-soal mata pelajaran
                                        kelas sekolah dasar.
                                    </p>
                                </Modal>
                            )}
                    </div>

                    <div style={{ marginBottom: 20 }}>
                        <Button
                            type="link"
                            style={{
                                position: 'absolute',
                                right: 0,
                                zIndex: 999,
                            }}
                            onClick={() => {
                                setIsModalVisible(true);
                                setModalType('kids_benefit');
                            }}>
                            Lihat Contoh
                        </Button>
                        <Form.Item
                            name="kids_benefit"
                            label="Manfaat Untuk Peserta"
                            style={{ marginBottom: 0 }}>
                            <WysiwygStyle>
                                <ReactQuill
                                    theme="snow"
                                    value={kidsBenefit}
                                    onChange={(val) => {
                                        if (val === '<p><br></p>') {
                                            val = null;
                                        }
                                        setKidsBenefit(val);
                                        form.setFieldsValue({
                                            kids_benefit: val || null,
                                        });
                                    }}
                                    placeholder="Jelaskan Manfaat Untuk Peserta"
                                />
                            </WysiwygStyle>
                        </Form.Item>
                        <small>Jelaskan manfaat untuk peserta</small>
                        {isModalVisible === true &&
                            modalType === 'kids_benefit' && (
                                <Modal
                                    title="Manfaat Untuk Peserta"
                                    visible={true}
                                    footer={[]}
                                    onCancel={() => setIsModalVisible(false)}>
                                    <p>
                                        Contoh: Manfaat Baby Brain Gym:
                                        <ul>
                                            <li>Memperkuat otot</li>
                                            <li>Merangsang motorik</li>
                                            <li>
                                                Melatih keseimbangan dan
                                                konsentrasi
                                            </li>
                                        </ul>
                                    </p>
                                </Modal>
                            )}
                    </div>

                    {selectedCat == 'Kursus' && (
                        <div style={{ marginBottom: 20 }}>
                            <Button
                                type="link"
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    zIndex: 999,
                                }}
                                onClick={() => {
                                    setIsModalVisible(true);
                                    setModalType('learning_method');
                                }}>
                                Lihat Contoh
                            </Button>
                            <Form.Item
                                name="learning_method"
                                label="Metode Pembelajaran"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required={selectedCat == 'Kursus'}
                                rules={[
                                    {
                                        async validator(rule, value) {
                                            if (
                                                selectedCat == 'Kursus' &&
                                                (value == '<p><br></p>' ||
                                                    !!!value)
                                            ) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Metode Pembelajaran wajib diisi',
                                                    ),
                                                );
                                            }
                                        },
                                    },
                                ]}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={learningMethod}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            setLearningMethod(val);
                                            form.setFieldsValue({
                                                learning_method: val || null,
                                            });
                                        }}
                                        placeholder="Jelaskan Metode Pembelajaran"
                                    />
                                </WysiwygStyle>
                            </Form.Item>
                            <small>Jelaskan Metode Pembelajaran</small>
                            {isModalVisible === true &&
                                modalType === 'learning_method' && (
                                    <Modal
                                        title="Metode Pembelajaran"
                                        visible={true}
                                        footer={[]}
                                        onCancel={() =>
                                            setIsModalVisible(false)
                                        }>
                                        <p>
                                            Contoh: Metode pembelajaran kelas
                                            PAUD:
                                            <ul>
                                                <li>
                                                    Metode bercerita
                                                    (storytelling): Penyampaian
                                                    materi pembelajaran akan
                                                    dijelaskan melalui
                                                    cerita/dongeng.
                                                </li>
                                                <li>
                                                    Metode project sederhana:
                                                    Metode ini dalam
                                                    pembelajaran artinya
                                                    memberikan pekerjaan kepada
                                                    siswa untuk menyelesaikan
                                                    suatu persoalan/workseheet
                                                    tertentu.
                                                </li>
                                                <li>
                                                    Metode tanya jawab: Pengajar
                                                    akan memberikan pertanyaan
                                                    kepada siswa, dan siswa akan
                                                    menjawabnya.
                                                </li>
                                            </ul>
                                        </p>
                                    </Modal>
                                )}
                        </div>
                    )}

                    {(tipePelaksanaan == 'kursus' ||
                        tipePelaksanaan == 'input_tanggal' ||
                        tipePelaksanaan == 'tanggal_expired') && (
                        <div style={{ marginBottom: 20 }}>
                            <Button
                                type="link"
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    zIndex: 999,
                                }}
                                onClick={() => {
                                    setIsModalVisible(true);
                                    setModalType('schedule_detail');
                                }}>
                                Lihat Contoh
                            </Button>
                            <Form.Item
                                name="schedule_detail"
                                label="Detail Jadwal"
                                style={{ marginBottom: 0 }}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={scheduleDetail}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            setScheduleDetail(val);
                                            form.setFieldsValue({
                                                schedule_detail: val || null,
                                            });
                                        }}
                                        placeholder="Jelaskan Detail Jadwal"
                                    />
                                </WysiwygStyle>
                            </Form.Item>
                            <small>Jelaskan Detail Jadwal</small>
                            {isModalVisible === true &&
                                modalType === 'schedule_detail' && (
                                    <Modal
                                        title="Detail Jadwal"
                                        visible={true}
                                        footer={[]}
                                        onCancel={() =>
                                            setIsModalVisible(false)
                                        }>
                                        <p>
                                            Berikan informasi detail tentang
                                            jadwal pelaksanaan untuk diketahui
                                            pembeli. Dapat diisi dengan jadwal
                                            operasional atau jadwal sesi yang
                                            bisa dipilih.
                                            <br /> Contoh: Jadwal kelas setiap
                                            hari Sabtu jam 09.00 - 10.00 WIB
                                        </p>
                                    </Modal>
                                )}
                        </div>
                    )}

                    <div>
                        <h4>
                            <span style={{ color: 'red' }}>*</span> Jelaskan
                            harga termasuk
                        </h4>
                        <small>
                            Apa yang akan didapatkan jika membeli tiket.{' '}
                        </small>
                        <Button
                            type="link"
                            style={{
                                position: 'absolute',
                                right: 0,
                                zIndex: 999,
                            }}
                            onClick={() => {
                                setIsModalVisible(true);
                                setModalType('hargatermasuk');
                            }}>
                            Lihat Contoh
                        </Button>
                        <Form.Item
                            name="include_price"
                            label="Harga Termasuk"
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Harga Termasuk wajib diisi',
                                },
                            ]}>
                            {/* <TextArea
                                rows={3}
                                showCount
                                placeholder="Jelaskan harga termasuk"
                                maxLength={500}
                            /> */}

                            <WysiwygStyle>
                                <ReactQuill
                                    theme="snow"
                                    value={hrgTermasuk}
                                    onChange={(val) => {
                                        if (val === '<p><br></p>') {
                                            val = null;
                                        }
                                        form.setFieldsValue({
                                            include_price: val,
                                        });
                                        setHrgTermasuk(val);
                                    }}
                                    placeholder="Jelaskan harga termasuk"
                                />
                            </WysiwygStyle>
                        </Form.Item>
                        {isModalVisible === true &&
                            modalType === 'hargatermasuk' && (
                                <Modal
                                    title="Harga Termasuk"
                                    visible={true}
                                    footer={[]}
                                    onCancel={() => setIsModalVisible(false)}>
                                    <p>
                                        contoh: materi pembelajaran/ modul/
                                        ongkos kirim/ material kit/ transport
                                        pengajar/ transport bidan/ sertifikat.
                                    </p>
                                </Modal>
                            )}
                    </div>

                    <div>
                        <h4>Jelaskan yang harus disiapkan</h4>
                        <small>
                            Apa yang peserta harus siapkan sebelum pelaksanaan.{' '}
                        </small>
                        <Button
                            type="link"
                            style={{
                                position: 'absolute',
                                right: 0,
                                zIndex: 999,
                            }}
                            onClick={() => {
                                setIsModalVisible(true);
                                setModalType('persiapan');
                            }}>
                            Lihat Contoh
                        </Button>
                        <Form.Item
                            label="Yang harus dipersiapkan"
                            name="prepare_before">
                            {/* <TextArea
                                rows={3}
                                showCount
                                placeholder="Jelaskan yang harus disiapkan"
                                maxLength={500}
                            /> */}

                            <WysiwygStyle>
                                <ReactQuill
                                    theme="snow"
                                    value={hrsDisiapkan}
                                    onChange={setHrsDisiapkan}
                                    placeholder="Jelaskan yang harus disiapkan"
                                />
                            </WysiwygStyle>
                        </Form.Item>
                        {isModalVisible === true &&
                            modalType === 'persiapan' && (
                                <Modal
                                    title="Yang Harus Dipersiapkan"
                                    visible={true}
                                    footer={[]}
                                    onCancel={() => setIsModalVisible(false)}>
                                    <p>
                                        Contoh: laptop dan koneksi
                                        internet/peralatan menulis & menggambar/
                                        alat musik/ baju olahraga & baju ganti/
                                        matras/ perlak/ popok.
                                    </p>
                                </Modal>
                            )}
                    </div>

                    <div>
                        <h4>Syarat dan Ketentuan tambahan</h4>
                        <small>
                            Syarat dan ketentuan yang berlaku selama
                            pelaksanaan.
                        </small>
                        <Button
                            type="link"
                            style={{
                                position: 'absolute',
                                right: 0,
                                zIndex: 999,
                            }}
                            onClick={() => {
                                setIsModalVisible(true);
                                setModalType('syarat');
                            }}>
                            Lihat Contoh
                        </Button>
                        <Form.Item label="" name="tnc">
                            {/* <TextArea
                                rows={3}
                                showCount
                                placeholder="Syarat dan Ketentuan tambahan"
                                maxLength={500}
                            /> */}

                            <WysiwygStyle>
                                <ReactQuill
                                    theme="snow"
                                    value={snkTambahan}
                                    onChange={setSnkTambahan}
                                    placeholder="Syarat dan Ketentuan tambahan"
                                />
                            </WysiwygStyle>
                        </Form.Item>
                        {isModalVisible === true && modalType === 'syarat' && (
                            <Modal
                                title="Syarat Dan Ketentuan"
                                visible={true}
                                footer={[]}
                                onCancel={() => setIsModalVisible(false)}>
                                <p>
                                    Contoh: Anak harus didampingi oleh orang
                                    tua/ Anak dalam keadaan sehat/ Ibu hamil
                                    memasuki usia kehamilan {'>36-40'} minggu/
                                    Belum termasuk transport/ Belum termasuk
                                    ongkos kirim.
                                </p>
                            </Modal>
                        )}
                    </div>

                    <div>
                        <h4>Informasi lainnya dan informasi tambahan</h4>
                        <small>
                            Tulis informasi lainnya dan informasi tambahan dari
                            pelanggan jika diperlukan.
                        </small>
                        <Button
                            type="link"
                            style={{
                                position: 'absolute',
                                right: 0,
                                zIndex: 999,
                            }}
                            onClick={() => {
                                setIsModalVisible(true);
                                setModalType('tambahan');
                            }}>
                            Lihat Contoh
                        </Button>
                        <Form.Item label="" name="detail_info">
                            {/* <TextArea
                                rows={3}
                                showCount
                                placeholder="Informasi lainnya dan informasi tambahann"
                                maxLength={500}
                            /> */}

                            <WysiwygStyle>
                                <ReactQuill
                                    theme="snow"
                                    value={infoLainnya}
                                    onChange={setInfoLainnya}
                                    placeholder="Informasi lainnya dan informasi tambahan"
                                />
                            </WysiwygStyle>
                        </Form.Item>
                        {isModalVisible === true &&
                            modalType === 'tambahan' && (
                                <Modal
                                    title="Informasi Lainnya dan Informasi Tambahan"
                                    visible={true}
                                    footer={[]}
                                    onCancel={() => setIsModalVisible(false)}>
                                    <p>
                                        Informasi lainnya adalah informasi yang
                                        akan ditampilkan di halaman aktivitas.
                                    </p>
                                    <p>
                                        Informasi tambahan adalah informasi yang
                                        perlu ditanyakan dari pelanggan untuk
                                        diisi sebelum melakukan pembelian.
                                        <br />
                                        Contoh: Info peserta (nama, usia, nomor
                                        ponsel) sudah diberikan oleh peserta.
                                        Cantumkan info tambahan apa saja yang
                                        diperlukan (contoh: ukuran baju, ukuran
                                        sepatu, nama sekolah, dll).
                                    </p>
                                </Modal>
                            )}
                    </div>

                    <div>
                        <h3>Data Tambahan yang dibutuhkan dari pembeli</h3>
                        <Row gutter={24}>
                            <Col lg={8}>
                                <Form.Item
                                    name="product_field_1"
                                    label="Data 1">
                                    <Input placeholder="Masukkan data 1" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    name="product_field_2"
                                    label="Data 2">
                                    <Input placeholder="Masukkan data 2" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    name="product_field_3"
                                    label="Data 3">
                                    <Input placeholder="Masukkan data 3" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <h3>Detail Pelaksanaan</h3>
                        <h4>
                            <span style={{ color: 'red' }}>*</span> Tipe Tanggal
                            Pelaksanaan
                        </h4>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                label="Tipe Tanggal"
                                name="schedule_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tipe Tanggal wajib dipilih',
                                    },
                                ]}
                                style={{ marginBottom: 0 }}>
                                <Select
                                    placeholder="Pilih Tipe Tanggal Pelaksaan"
                                    onChange={(value) => {
                                        setTipePelaksanaan(value);
                                        setJmlPilTgl(2);
                                        handleClearTipeTanggal(value);
                                    }}>
                                    <Option value="pilih_tanggal">
                                        Pelanggan memilih tanggal tersedia
                                    </Option>
                                    <Option value="dengan_tanggal">
                                        Dengan tanggal pelaksaan tertentu
                                    </Option>
                                    <Option value="input_tanggal">
                                        Pelanggan menentukan tanggal
                                    </Option>
                                    <Option value="kursus">Kursus</Option>
                                    <Option value="tanggal_expired">
                                        Dengan masa berlaku
                                    </Option>
                                </Select>
                            </Form.Item>
                            <TipePelaksanaanKet type={tipePelaksanaan} />
                        </div>

                        <h4>
                            <span style={{ color: 'red' }}>*</span> Durasi
                            Aktivitas
                        </h4>
                        <small>
                            Berapa lama durasi pelaksanaan aktivitas dalam satu
                            kali pertemuan.
                        </small>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="event_duration"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Durasi Aktivitas wajib diisi',
                                    },
                                ]}
                                style={{ marginBottom: 0 }}>
                                <Input placeholder="Masukkan Durasi Aktivitas" />
                            </Form.Item>
                            <small>Contoh : 1 jam, 45 menit, 90 menit</small>
                        </div>

                        {tipePelaksanaan === 'pilih_tanggal' && (
                            <>
                                <Form.Item
                                    label="Jumlah Pilihan Tanggal"
                                    name="jmltgl">
                                    <Select
                                        onChange={(value) => {
                                            setJmlPilTgl(value);
                                        }}>
                                        <Option value="2">
                                            2 Pilihan Tanggal
                                        </Option>
                                        <Option value="3">
                                            3 Pilihan Tanggal
                                        </Option>
                                        <Option value="4">
                                            4 Pilihan Tanggal
                                        </Option>
                                        <Option value="5">
                                            5 Pilihan Tanggal
                                        </Option>
                                    </Select>
                                </Form.Item>
                                {[...Array(Number(jmlPilTgl))].map((el, i) => (
                                    <div key={i}>
                                        <h4>
                                            <span style={{ color: 'red' }}>
                                                *
                                            </span>
                                            Atur Tanggal Dan Jam ke-{i + 1}
                                        </h4>
                                        <Row>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Tanggal dan Jam Mulai"
                                                    name={`tglmulai${i}`}
                                                    validateTrigger={[
                                                        'onChange',
                                                    ]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `Tanggal Mulai ke-${
                                                                i + 1
                                                            } wajib diisi`,
                                                        },
                                                        {
                                                            async validator(
                                                                rule,
                                                                value,
                                                            ) {
                                                                if (i > 0) {
                                                                    var before =
                                                                        changeMilisecondToZero(
                                                                            form.getFieldValue(
                                                                                `tglmulai${
                                                                                    i -
                                                                                    1
                                                                                }`,
                                                                            ),
                                                                        );
                                                                    var current =
                                                                        changeMilisecondToZero(
                                                                            form.getFieldValue(
                                                                                `tglmulai${i}`,
                                                                            ),
                                                                        );
                                                                    if (
                                                                        before >=
                                                                        current
                                                                    ) {
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                'Tanggal jam mulai harus lebih besar dari tanggal jam sebelumnya',
                                                                            ),
                                                                        );
                                                                    } else {
                                                                        return Promise.resolve();
                                                                    }
                                                                }
                                                            },
                                                            validateTrigger:
                                                                'onChange',
                                                        },
                                                    ]}>
                                                    <DatePicker
                                                        format="YYYY-MM-DD HH:mm"
                                                        disabledDate={
                                                            disabledDate
                                                        }
                                                        disabledTime={
                                                            disabledTime
                                                        }
                                                        showTime={{
                                                            hideDisabledOptions: true,
                                                        }}
                                                        showTime={{
                                                            defaultValue:
                                                                moment(
                                                                    '00',
                                                                    'mm',
                                                                ),
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Tanggal dan Jam Selesai"
                                                    name={`tglselesai${i}`}
                                                    validateTrigger={[
                                                        'onChange',
                                                    ]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `Tanggal Selesai ke-${
                                                                i + 1
                                                            } wajib diisi`,
                                                        },
                                                        {
                                                            async validator(
                                                                rule,
                                                                value,
                                                            ) {
                                                                var before =
                                                                    changeMilisecondToZero(
                                                                        form.getFieldValue(
                                                                            `tglmulai${i}`,
                                                                        ),
                                                                    );
                                                                var current =
                                                                    changeMilisecondToZero(
                                                                        form.getFieldValue(
                                                                            `tglselesai${i}`,
                                                                        ),
                                                                    );

                                                                if (
                                                                    before >=
                                                                    current
                                                                ) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            'Tanggal jam selesai harus lebih besar dari tanggal jam mulai',
                                                                        ),
                                                                    );
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            },
                                                            validateTrigger:
                                                                'onChange',
                                                        },
                                                    ]}>
                                                    <DatePicker
                                                        format="YYYY-MM-DD HH:mm"
                                                        disabledDate={
                                                            disabledDate
                                                        }
                                                        disabledTime={
                                                            disabledTime
                                                        }
                                                        showTime={{
                                                            hideDisabledOptions: true,
                                                        }}
                                                        showTime={{
                                                            defaultValue:
                                                                moment(
                                                                    '00',
                                                                    'mm',
                                                                ),
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item
                                    label="Batas Pembelian Tiket"
                                    name="purchase_limit"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Batas Pembelian Tiket wajib dipilih',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="Pilih batas pembelian tiket"
                                        onChange={(val) => {
                                            setBatasPembelian(val);
                                            form.setFieldsValue({
                                                purchase_expired: '',
                                            });
                                        }}>
                                        <Option value="hari">
                                            Hitungan Hari
                                        </Option>
                                        <Option value="jam">
                                            Hitungan Jam
                                        </Option>
                                    </Select>
                                </Form.Item>
                                {batasPembelian !== '' && (
                                    <Form.Item
                                        label="Batas Maksimum Tiket Dapat Dibeli"
                                        name="purchase_expired"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Batas Maksimum Tiket Dapat Dibeli wajib dipilih',
                                            },
                                        ]}>
                                        <Select placeholder="Pilih batas maksimum tiket yang dapat dibeli">
                                            {batasPembelian === 'hari' &&
                                                batasHari.map((el, i) => {
                                                    return (
                                                        <Option
                                                            key={i}
                                                            value={el.value}>
                                                            {el.text}
                                                        </Option>
                                                    );
                                                })}
                                            {batasPembelian === 'jam' &&
                                                batasJam.map((el, i) => {
                                                    return (
                                                        <Option
                                                            key={i}
                                                            value={el.value}>
                                                            {el.text}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                )}
                            </>
                        )}

                        {console.log(`TIPE TANGGAL :`, tipePelaksanaan)}

                        {tipePelaksanaan === 'dengan_tanggal' && (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tanggal dan Jam Mulai"
                                            name="start_date1"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Tanggal Mulai wajib diisi',
                                                },
                                            ]}>
                                            <DatePicker
                                                onChange={(value) => {
                                                    form.setFieldValue(
                                                        'start_date1',
                                                        value,
                                                    );
                                                    setToggleCheckDiff(
                                                        !toggleCheckDiff,
                                                    );
                                                }}
                                                format="YYYY-MM-DD HH:mm"
                                                disabledDate={disabledDate}
                                                disabledTime={disabledTime}
                                                // showTime={{
                                                //     hideDisabledOptions: true,
                                                // }}
                                                showTime={{
                                                    defaultValue: moment(
                                                        '00',
                                                        'mm',
                                                    ),
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label="Tanggal dan Jam Selesai"
                                            name="end_date1"
                                            validateTrigger={['onChange']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Tanggal Selesai wajib diisi',
                                                },
                                                {
                                                    async validator(
                                                        rule,
                                                        value,
                                                    ) {
                                                        var before =
                                                            changeMilisecondToZero(
                                                                form.getFieldValue(
                                                                    `start_date1`,
                                                                ),
                                                            );
                                                        var current =
                                                            changeMilisecondToZero(
                                                                form.getFieldValue(
                                                                    `end_date1`,
                                                                ),
                                                            );

                                                        if (before >= current) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Tanggal jam selesai harus lebih besar dari tanggal jam mulai',
                                                                ),
                                                            );
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                    validateTrigger: 'onChange',
                                                },
                                            ]}>
                                            <DatePicker
                                                onChange={(value) => {
                                                    form.setFieldValue(
                                                        'end_date1',
                                                        value,
                                                    );
                                                    setToggleCheckDiff(
                                                        !toggleCheckDiff,
                                                    );
                                                }}
                                                format="YYYY-MM-DD HH:mm"
                                                disabledDate={disabledDate}
                                                disabledTime={disabledTime}
                                                // showTime={{
                                                //     hideDisabledOptions: true,
                                                // }}
                                                showTime={{
                                                    defaultValue: moment(
                                                        '00',
                                                        'mm',
                                                    ),
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    label="Batas Pembelian Tiket"
                                    name="purchase_limit"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Batas Pembelian Tiket wajib dipilih',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="Pilih batas pembelian tiket"
                                        onChange={(val) => {
                                            setBatasPembelian(val);
                                            form.setFieldsValue({
                                                purchase_expired: '',
                                            });
                                        }}>
                                        <Option value="hari">
                                            Hitungan Hari
                                        </Option>
                                        <Option value="jam">
                                            Hitungan Jam
                                        </Option>
                                    </Select>
                                </Form.Item>
                                {batasPembelian !== '' && (
                                    <Form.Item
                                        label="Batas Maksimum Tiket Dapat Dibeli"
                                        name="purchase_expired"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Batas Maksimum Tiket Dapat Dibeli wajib dipilih',
                                            },
                                        ]}>
                                        <Select placeholder="Pilih batas maksimum tiket yang dapat dibeli">
                                            {batasPembelian === 'hari' &&
                                                batasHari.map((el, i) => {
                                                    return (
                                                        <Option
                                                            disabled={checkDisabledSelectDay(
                                                                el,
                                                            )}
                                                            key={i}
                                                            value={el.value}>
                                                            {el.text}
                                                        </Option>
                                                    );
                                                })}
                                            {batasPembelian === 'jam' &&
                                                batasJam.map((el, i) => {
                                                    return (
                                                        <Option
                                                            key={i}
                                                            value={el.value}>
                                                            {el.text}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                )}
                            </>
                        )}

                        {tipePelaksanaan === 'input_tanggal' && (
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="purchase_limit"
                                    label="Masa Aktif Penjualan"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Masa Aktif Penjualan wajib dipilih',
                                        },
                                    ]}
                                    style={{ marginBottom: 0 }}>
                                    <Select
                                        placeholder="Pilih Masa Aktif"
                                        onChange={(val) => {
                                            setBatasPembelian(val);
                                            form.setFieldsValue({
                                                purchase_expired: '',
                                            });
                                        }}>
                                        <Option value="30 Hari">30 Hari</Option>
                                        <Option value="60 Hari">60 Hari</Option>
                                        <Option value="90 Hari">90 Hari</Option>
                                        <Option value="120 Hari">
                                            120 Hari
                                        </Option>
                                        <Option value="180 Hari">
                                            180 Hari
                                        </Option>
                                    </Select>
                                </Form.Item>
                                <small>
                                    Aktivitas tersedia di platform Kiddo.id
                                    selama periode yang dipilih
                                </small>
                            </div>
                        )}

                        {tipePelaksanaan === 'kursus' && (
                            <>
                                <h4>
                                    <span style={{ color: 'red' }}>*</span> Atur
                                    Pertemuan
                                </h4>

                                <div style={{ marginBottom: 20 }}>
                                    <Form.Item
                                        label="Frekuensi pertemuan dalam 1 tiket"
                                        name="schedule"
                                        style={{ marginBottom: 0 }}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Frekuensi Pertemuan wajib diisi',
                                            },
                                        ]}>
                                        <Input placeholder="Frekuensi Pertemuan" />
                                    </Form.Item>
                                    <small>
                                        Contoh 2x seminggu, 4x sebulan
                                    </small>
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    <Form.Item
                                        name="purchase_limit"
                                        label="Masa Aktif Penjualan"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Masa Aktif Penjualan wajib dipilih',
                                            },
                                        ]}
                                        style={{ marginBottom: 0 }}>
                                        <Select
                                            placeholder="Pilih Masa Aktif"
                                            onChange={(val) => {
                                                setBatasPembelian(val);
                                                form.setFieldsValue({
                                                    purchase_expired: '',
                                                });
                                            }}>
                                            <Option value="30 Hari">
                                                30 Hari
                                            </Option>
                                            <Option value="60 Hari">
                                                60 Hari
                                            </Option>
                                            <Option value="90 Hari">
                                                90 Hari
                                            </Option>
                                            <Option value="120 Hari">
                                                120 Hari
                                            </Option>
                                            <Option value="180">
                                                180 Hari
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                    <small>
                                        Aktivitas tersedia di platform Kiddo.id
                                        selama periode yang dipilih
                                    </small>
                                </div>
                            </>
                        )}

                        {tipePelaksanaan === 'tanggal_expired' && (
                            <>
                                <Form.Item
                                    name="date_duration"
                                    label="Masa Berlaku Tiket"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Masa Berlaku wajib dipilih',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="Pilih Masa Berlaku"
                                        onChange={(value) => {
                                            setMasaBerlaku(value);
                                        }}>
                                        <Option value="dengan_durasi">
                                            Dengan Durasi
                                        </Option>
                                        <Option value="dengan_tanggal">
                                            Tanggal Expired
                                        </Option>
                                    </Select>
                                </Form.Item>
                                {masaBerlaku === 'dengan_durasi' && (
                                    <>
                                        <div style={{ marginBottom: 20 }}>
                                            <Form.Item
                                                label="Masa Aktif Tiket"
                                                name="expired_ticket"
                                                style={{ marginBottom: 0 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Masa Aktif Tiket wajib dipilih',
                                                    },
                                                ]}>
                                                <Select placeholder="Durasi">
                                                    <Option value="7 Hari">
                                                        7 Hari
                                                    </Option>
                                                    <Option value="14 Hari">
                                                        14 Hari
                                                    </Option>
                                                    <Option value="30 Hari">
                                                        30 Hari
                                                    </Option>
                                                    <Option value="60 Hari">
                                                        60 Hari
                                                    </Option>
                                                    <Option value="90 Hari">
                                                        90 Hari
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            <small>
                                                Durasi berlaku setelah hari
                                                pembelian
                                            </small>
                                        </div>
                                        <div style={{ marginBottom: 20 }}>
                                            <Form.Item
                                                label="Masa Aktif Penjualan"
                                                name="purchase_limit"
                                                style={{ marginBottom: 0 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Masa Aktif Penjualan wajib dipilih',
                                                    },
                                                ]}>
                                                <Select placeholder="Pilih Masa Aktif">
                                                    <Option value="30 Hari">
                                                        30 Hari
                                                    </Option>
                                                    <Option value="60 Hari">
                                                        60 Hari
                                                    </Option>
                                                    <Option value="90 Hari">
                                                        90 Hari
                                                    </Option>
                                                    <Option value="120 Hari">
                                                        120 Hari
                                                    </Option>
                                                    <Option value="180 Hari">
                                                        180 Hari
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            <small>
                                                Aktivitas tersedia di platform
                                                Kiddo.id selama periode yang
                                                dipilih
                                            </small>
                                        </div>
                                    </>
                                )}
                                {masaBerlaku === 'dengan_tanggal' && (
                                    <Form.Item
                                        label="Tanggal Expired"
                                        name="expired"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Tanggal Expired wajib dipilih',
                                            },
                                        ]}>
                                        <DatePicker
                                            disabledDate={(current) => {
                                                return (
                                                    moment().add(-1, 'days') >=
                                                    current
                                                );
                                            }}
                                            format="YYYY/MM/DD"
                                            placeholder="Pilih Tanggal"
                                        />
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </div>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Row>
                        <Col span="24">
                            <div className="foto_utama">
                                <h4>
                                    <span style={{ color: 'red' }}>*</span> Foto
                                    Utama
                                </h4>
                                <small>
                                    Foto akan ditampilkan paling depan. Ukuran
                                    file maksimum 500Kb dan resolusi maksimum
                                    500x500 pixel.
                                </small>
                                <Form.Item
                                    name="main_photo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Foto utama wajib diisi!',
                                        },
                                    ]}>
                                    <ImgCrop rotate beforeCrop={beforeUpload}>
                                        <Upload
                                            accept="image/*"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            customRequest={uploadMainPic}
                                            onChange={handleChange}
                                            beforeUpload={beforeUpload}>
                                            {imageUrl ? (
                                                <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                />
                                            ) : (
                                                uploadButton
                                            )}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </div>
                            <div className="foto_tambahan">
                                <small>
                                    Ukuran file maksimum 500Kb. Kiddo.id berhak
                                    menambahkan atau mengubah foto/gambar agar
                                    sesuai dengan layanan aktivitas. Pastikan
                                    foto yang diupload tidak melanggar aturan
                                    hak kekayaan intelektual dari pihak selain
                                    merchant terkait. Kiddo.id tidak bertanggung
                                    jawab jika ada pelanggaran hak cipta terkait
                                    gambar/foto yang diupload.
                                </small>
                                <Form.Item
                                    name="other-pic"
                                    label="Foto Tambahan">
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        customRequest={opsionalPic}
                                        onPreview={onPreview}
                                        onChange={onChange}
                                        onRemove={(e) => {
                                            onRemove(e);
                                        }}>
                                        {fileList.length < 4 && '+ Upload'}
                                    </Upload>
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="video"
                                    label="Link Video"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            type: 'url',
                                            message:
                                                'Link video wajib merupakan url yang valid',
                                        },
                                        {
                                            required: false,
                                            pattern: new RegExp(
                                                'https://www.youtube.com',
                                            ),
                                            message:
                                                'Masukan link video Youtube, contoh https://www.youtube.com/.....',
                                        },
                                    ]}>
                                    <Input placeholder="Masukkan Link Video" />
                                </Form.Item>
                                <small>
                                    Tambah video agar pembeli lebih paham
                                    mengenai aktivitasmu. Masukan link video
                                    Youtube
                                </small>
                            </div>

                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="category"
                                    label="Kategori"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Pilih salah satu Kategori',
                                        },
                                    ]}
                                    style={{ marginBottom: 0 }}>
                                    <Select
                                        placeholder="Pilih Kategori"
                                        onChange={(value) =>
                                            setSelectedCat(value)
                                        }>
                                        {categori.map((el, index) => (
                                            <Option
                                                key={index}
                                                value={el.value}>
                                                {el.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <CategoriInformation type={selectedCat} />
                            </div>
                            <Form.Item
                                name="sub_category"
                                label="Sub Kategori"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Pilih salah satu Sub-Kategori',
                                        type: 'array',
                                    },
                                ]}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    maxTagCount={5}
                                    placeholder="Pilih Sub Kategori"
                                    onChange={(val) => {
                                        setSubCatLength(val.length);
                                    }}>
                                    {subcat.map((el, index) => (
                                        <Option
                                            key={index}
                                            value={el.value}
                                            disabled={
                                                Number(subCatLength) === 5
                                                    ? true
                                                    : false
                                            }>
                                            {el.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <p>
                                <span style={{ color: 'red' }}>*</span> Usia
                                Peserta
                            </p>
                            {semuaUmur === false ? (
                                <>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span="12">
                                            <Form.Item
                                                name="usia_dari"
                                                label="Dari"
                                                rules={[
                                                    {
                                                        required:
                                                            !checkIsOrangtua,
                                                        message: 'Wajib diisi!',
                                                    },
                                                ]}>
                                                <Select
                                                    onChange={(val, option) => {
                                                        handleChangeUsia(val);
                                                        setIsDisabled(false);
                                                        setSelectedAge(
                                                            option?.key || 0,
                                                        );
                                                    }}
                                                    allowClear>
                                                    {rangeUmur.map((el, i) => {
                                                        return (
                                                            <Option
                                                                key={i}
                                                                value={
                                                                    el.value
                                                                }>
                                                                {el.text}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span="12">
                                            <Form.Item
                                                name="usia_hingga"
                                                label="Hingga"
                                                rules={[
                                                    {
                                                        required:
                                                            !checkIsOrangtua,
                                                        message: 'Wajib diisi!',
                                                    },
                                                ]}>
                                                <Select
                                                    disabled={isDisabled}
                                                    allowClear>
                                                    {isDisabled === false &&
                                                        rangeUmur.map(
                                                            (el, i) => {
                                                                return (
                                                                    <Option
                                                                        key={i}
                                                                        value={
                                                                            el.value
                                                                        }
                                                                        disabled={
                                                                            i <
                                                                            Number(
                                                                                selectedAge,
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }>
                                                                        {
                                                                            el.text
                                                                        }
                                                                    </Option>
                                                                );
                                                            },
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="orang_tua"
                                        valuePropName="checked">
                                        <Checkbox
                                            onChange={
                                                orang_tua_onCheckboxChange
                                            }>
                                            Orang Tua
                                        </Checkbox>
                                    </Form.Item>
                                </>
                            ) : (
                                <></>
                            )}

                            <Form.Item
                                name="semua_umur"
                                valuePropName="checked"
                                onChange={() => {
                                    setSemuaUmur(!semuaUmur);
                                }}>
                                <Checkbox>Semua Umur</Checkbox>
                            </Form.Item>

                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="type"
                                    label="Tipe Produk"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Tipe Produk wajib dipilih',
                                        },
                                        {
                                            async validator(rule, value) {
                                                if (
                                                    (value == 'online' &&
                                                        aggre) ||
                                                    value == 'things'
                                                ) {
                                                    const resultResponse =
                                                        await serviceFormProduct.onCheckShippingAndAdress(
                                                            idSmallMerchant,
                                                        );
                                                    if (
                                                        resultResponse[0] &&
                                                        resultResponse[1]
                                                    ) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Isi Lokasi Usaha dan Shipping terlebih dahulu di halaman detail merchant.',
                                                            ),
                                                        );
                                                    }
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                            validateTrigger: 'onChange',
                                        },
                                    ]}
                                    style={{ marginBottom: 0 }}>
                                    <Select
                                        onChange={handleProdType}
                                        placeholder="Pilih Tipe Produk">
                                        <Option value="online">
                                            Aktivitas Online
                                        </Option>
                                        <Option value="offline">
                                            Aktivitas Offline
                                        </Option>
                                        <Option value="offline_at_participant">
                                            Aktivitas Offline di Tempat Peserta
                                        </Option>
                                        <Option value="things">
                                            Peralatan Aktivitas (Activity Kit)
                                        </Option>
                                    </Select>
                                </Form.Item>
                                {prodType === 'online' && (
                                    <>
                                        <small>
                                            Merupakan aktivitas yang
                                            dilaksanakan melalui sistem daring
                                            (online) Contoh: kelas online,
                                            webinar, virtual tour, kulwap.
                                        </small>
                                        <Form.Item
                                            name="aggre"
                                            valuePropName="checked"
                                            onChange={() => {
                                                setAggre(!aggre);
                                                form.validateFields(['type']);
                                            }}
                                            style={{ marginTop: 20 }}>
                                            <Checkbox>
                                                Aktivitas ini memerlukan
                                                pengiriman barang kepada
                                                pembeli. Pembeli akan dimintakan
                                                alamat saat melakukan pembelian.
                                            </Checkbox>
                                        </Form.Item>
                                        {aggre && (
                                            <>
                                                {/* <Form.Item
													name="send_from"
													label="Kota asal pengiriman"
													rules={[
														{ required: true, message: "Kota asal pengiriman wajib diisi" },
													]}
												>
													<Input placeholder="Masukkan Kota asal pengiriman" />
												</Form.Item> */}

                                                {/* <Form.Item
													name="shipping_info_1"
													label="Kurir yang tersedia"
													rules={[
														{
															required: true,
															message: "Kurir yang tersedia wajib diisi",
															type: "array",
														},
													]}
												>
													{
														shippingList.length > 0 ? (
															<Select
																showSearch
																// mode="multiple"
																mode="tags"
																tokenSeparators={[","]}
																placeholder="Pilih Kurir yang tersedia"
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
															>
																{
																	shippingList.map((el, idx) => {
																		return <Option key={idx} value={el.code}>{el.name}</Option>
																	})
																}
															</Select>
														) : (
															<Popover content={popoverContent} title="Info" trigger="focus">
																<Input placeholder="Masukkan kurir yang tersedia" />
															</Popover>
														)
													}

												</Form.Item> */}

                                                <Form.Item
                                                    name="shipping_info_2"
                                                    label="Jangkauan pengiriman"
                                                    rules={[
                                                        {
                                                            message:
                                                                'Jangkauan pengiriman wajib diisi',
                                                        },
                                                    ]}>
                                                    <Input placeholder="Masukkan jangkauan pengiriman" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="shipping_info_3"
                                                    label="Info Pengiriman 3"
                                                    rules={[
                                                        {
                                                            message:
                                                                'Info pengiriman 3 wajib diisi',
                                                        },
                                                    ]}>
                                                    <Input placeholder="Masukkan info pengiriman 3" />
                                                </Form.Item>
                                                <Form.Item
                                                    name="weight"
                                                    label="Berat Barang"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Berat Barang wajib diisi',
                                                        },
                                                    ]}>
                                                    <Input
                                                        type="number"
                                                        min={1}
                                                        suffix="gram"
                                                        placeholder="Masukkan berat barang dalam satuan gram"
                                                    />
                                                </Form.Item>
                                            </>
                                        )}
                                    </>
                                )}
                                {prodType === 'offline' && (
                                    <>
                                        <small>
                                            Merupakan aktivitas yang
                                            dilaksanakan secara tatap muka di
                                            lokasi merchant/ditentukan merchant.
                                            Contoh: workshop menggambar,
                                            palydate, kursus renang, les musik
                                            di studio, dll.
                                        </small>
                                        <h4 style={{ marginTop: 20 }}>
                                            Di Kota dan kecamatan mana Aktivitas
                                            akan berlangsung?
                                        </h4>

                                        {/* <Form.Item
											name="city_name"
											label="Kota"
											rules={[
												{ required: true, message: "Kota wajib diisi" },
											]}
										>
											<Input placeholder="Masukkan nama kota" />
										</Form.Item> */}
                                        <Form.Item
                                            name="province"
                                            label="Provinsi"
                                            rules={[{ required: true }]}>
                                            {/* <Input placeholder="Masukkan Provinsi" /> */}
                                            <Select
                                                showSearch
                                                placeholder="Pilih Provinsi"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase(),
                                                        ) >= 0
                                                }
                                                onChange={handleProvinceChange}>
                                                {provinceList.map((el, idx) => {
                                                    return (
                                                        <Option
                                                            key={el.id}
                                                            value={el.id}>
                                                            {el.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        {provinceId != '' && (
                                            <Form.Item
                                                name="city"
                                                label="Kota"
                                                rules={[{ required: true }]}>
                                                {/* <Input placeholder="Masukkan Kota" /> */}
                                                <Select
                                                    showSearch
                                                    placeholder="Pilih Kota"
                                                    optionFilterProp="children"
                                                    filterOption={(
                                                        input,
                                                        option,
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase(),
                                                            ) >= 0
                                                    }
                                                    onChange={handleCityChange}>
                                                    {cityList.map((el, idx) => {
                                                        return (
                                                            <Option
                                                                key={el.id}
                                                                value={el.id}>
                                                                {el.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        )}

                                        {cityId != '' && (
                                            <Form.Item
                                                name="sub_district"
                                                label="Kecamatan"
                                                rules={[{ required: true }]}>
                                                {/* <Input placeholder="Masukkan Kecamatan" /> */}
                                                <Select
                                                    showSearch
                                                    placeholder="Pilih Kecamatan"
                                                    optionFilterProp="children"
                                                    filterOption={(
                                                        input,
                                                        option,
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase(),
                                                            ) >= 0
                                                    }
                                                    onChange={
                                                        handleSubdistrictChange
                                                    }>
                                                    {subdistrictList.map(
                                                        (el, idx) => {
                                                            return (
                                                                <Option
                                                                    key={el.id}
                                                                    value={
                                                                        el.id
                                                                    }>
                                                                    {el.name}
                                                                </Option>
                                                            );
                                                        },
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        )}

                                        <div style={{ marginBottom: 20 }}>
                                            <Form.Item
                                                name="location_detail"
                                                label="Tempat Pelaksanaan Aktivitas"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Tempat Pelaksanaan Aktivitas wajib diisi',
                                                    },
                                                ]}
                                                style={{ marginBottom: 0 }}>
                                                <TextArea
                                                    rows={4}
                                                    placeholder="Alamat pelaksanaan (Misal: Jl. Merdeka No.0, RT/RW, Kel., Kec., Kota, Provinsi) / Wilayah Jangkauan (Misal: Jakarta, Bekasi, Depok, dsb)"
                                                />
                                            </Form.Item>
                                            <small>
                                                Tuliskan alamat lengkap tempat
                                                penyelenggaraan aktivitas.
                                            </small>
                                        </div>

                                        <div style={{ marginBottom: 20 }}>
                                            <Form.Item
                                                name="google_maps"
                                                label="Google Maps Link"
                                                style={{ marginBottom: 0 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Google Maps Link wajib diisi',
                                                    },
                                                    {
                                                        required: true,
                                                        pattern: new RegExp(
                                                            /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g,
                                                        ),
                                                        message:
                                                            'Link Google Maps format tidak sesuai harus menggunakan dibagian depan <iframe>',
                                                    },
                                                    // {
                                                    // 	type: "url",
                                                    // 	message: "Link Google Maps wajib merupakan url yang valid",
                                                    // }
                                                ]}>
                                                <Input placeholder="Masukkan Link Google Maps" />
                                            </Form.Item>
                                            <small>
                                                Anda bisa masukan link lokasi
                                                dari Google Maps untuk
                                                ditampilkan di halaman
                                                aktivitas. Contoh: &#60;iframe
                                                src=......&#60;/iframe&#62;
                                            </small>
                                        </div>
                                    </>
                                )}
                                {prodType === 'offline_at_participant' && (
                                    <div style={{ marginBottom: 20 }}>
                                        <small>
                                            Merupakan aktivitas yang
                                            dilaksanakan secara tatap muka di
                                            lokasi perserta atau lokasi yang
                                            ditentukan peserta. Contoh: les
                                            privat di rumah peserta, mom & baby
                                            care, dll.
                                        </small>

                                        <Form.Item
                                            label="Kota"
                                            name="city_name"
                                            // extra="Pisahkan dengan tanda koma ( , )"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Pilih salah satu Kota',
                                                    type: 'array',
                                                },
                                            ]}>
                                            <Select
                                                allowClear
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Masukkan Nama kota"
                                                tokenSeparators={[',']}>
                                                {allCityList.map((el, idx) => {
                                                    return (
                                                        <Option
                                                            key={idx}
                                                            value={el.name}>
                                                            {el.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {/* 
                                                {subcat
                                                    .sort((a, b) =>
                                                        a.text > b.text ? 1 : -1,
                                                    )
                                                    .map((el, index) => (
                                                        <Option
                                                            key={index}
                                                            value={el.value}
                                                            disabled={
                                                                Number(subCatLength) === 5
                                                                    ? true
                                                                    : false
                                                            }>
                                                            {el.text}
                                                        </Option>
                                                    ))}
                                            </Select> */}
                                        </Form.Item>
                                        {/* <Form.Item
                                            name="location_detail"
                                            label="Tempat Pelaksanaan Aktivitas"
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 20,
                                            }}
                                            // rules={[
                                            // 	{
                                            // 		required: true,
                                            // 		message: "Tempat Pelaksanaan Aktivitas wajib diisi",
                                            // 	},
                                            // ]}
                                        >
                                            <TextArea
                                                rows={3}
                                                placeholder="jika layanan mencakup banyak wilayah, silahkan dituliskan di sini. Misal: Jakarta, Bekasi, Depok."
                                            />
                                        </Form.Item>
                                        <small>
                                            Tuliskan alamat lengkap tempat
                                            penyelenggaraan aktivitas.
                                        </small> */}
                                    </div>
                                )}
                                {prodType === 'things' && (
                                    <div>
                                        <small>
                                            Khusus peralatan saja (acitivity
                                            kit/learning kit) tanpa aktivitas,
                                            bila dengan video maka pilih tipe
                                            aktivitas online.
                                        </small>
                                        {/* <Form.Item
											name="send_from"
											label="Kota asal pengiriman"
											rules={[
												{ required: true, message: "Kota asal pengiriman wajib diisi" },
											]}
										>
											<Input placeholder="Masukkan kota asal pengiriman" />
										</Form.Item> */}

                                        {/* <Form.Item
											name="shipping_info_1"
											label="Kurir yang tersedia"
											rules={[
												{ message: "Kurir yang tersedia wajib diisi" },
											]}
										>
											<Input placeholder="Masukkan kurir yang tersedia" />
										</Form.Item> */}
                                        {/* <Form.Item
											name="shipping_info_1"
											label="Kurir yang tersedia"
											rules={[
												{
													required: true,
													message: "Kurir yang tersedia wajib diisi",
													type: "array",
												},
											]}
										>
											{
												shippingList.length > 0 ? (
													<Select
														showSearch
														// mode="multiple"
														mode="tags"
														tokenSeparators={[","]}
														placeholder="Pilih Kurir yang tersedia"
														optionFilterProp="children"
														filterOption={(input, option) =>
															option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}
													>
														{
															shippingList.map((el, idx) => {
																return <Option key={idx} value={el.code}>{el.name}</Option>
															})
														}
													</Select>
												) : (
													<Popover content={popoverContent} title="Info" trigger="focus">
														<Input placeholder="Masukkan kurir yang tersedia" />
													</Popover>
												)
											}

										</Form.Item> */}

                                        <Form.Item
                                            name="pre_order_day"
                                            label="“Lama pre-order (hari)"
                                            validateTrigger={['onChange']}
                                            rules={[
                                                {
                                                    async validator(
                                                        rule,
                                                        value,
                                                    ) {
                                                        if (
                                                            String(
                                                                value,
                                                            ).includes('-')
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Lama preorder tidak boleh lebih kecil dari 0',
                                                                ),
                                                            );
                                                        }
                                                        if (
                                                            value == 0 ||
                                                            value <= 30
                                                        ) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(
                                                            new Error(
                                                                'Lama preorder harus 0 - 30',
                                                            ),
                                                        );
                                                    },
                                                },
                                            ]}>
                                            <Input
                                                suffix="Hari"
                                                min={0}
                                                type="number"
                                                placeholder="Masukkan lamanya pre-order"
                                            />
                                        </Form.Item>
                                        <small>
                                            Isi lamanya waktu yang diperlukan
                                            untuk pre-order, isi 0 jika bukan
                                            pre-order.
                                        </small>
                                        <Form.Item
                                            name="shipping_info_2"
                                            label="Jangkauan pengiriman"
                                            rules={[
                                                {
                                                    message:
                                                        'Jangkauan pengiriman wajib diisi',
                                                },
                                            ]}>
                                            <Input placeholder="Masukkan jangkauan pengiriman" />
                                        </Form.Item>

                                        <Form.Item
                                            name="shipping_info_3"
                                            label="Info Pengiriman 3"
                                            rules={[
                                                {
                                                    message:
                                                        'Info pengiriman 3 wajib diisi',
                                                },
                                            ]}>
                                            <Input placeholder="Masukkan info pengiriman 3" />
                                        </Form.Item>
                                        <Form.Item
                                            name="weight"
                                            label="Berat Barang"
                                            style={{ marginTop: 20 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Berat barang wajib diisi',
                                                },
                                                {
                                                    min: 1,
                                                    message: 'Minimal 1gram',
                                                },
                                            ]}>
                                            <Input
                                                type="number"
                                                min={1}
                                                suffix="gram"
                                                placeholder="Masukkan berat barang dalam satuan gram"
                                            />
                                        </Form.Item>
                                    </div>
                                )}
                            </div>

                            <div className="varian">
                                <Form.Item
                                    style={{
                                        marginBottom: 0,
                                    }}
                                    name="have_varian"
                                    label={
                                        <h3 style={{ margin: 0 }}>Varian</h3>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Varian wajib dipilih',
                                        },
                                    ]}>
                                    <p>
                                        Apakah Aktivitas memiliki varian harga?
                                    </p>
                                    <Form.Item name="have_varian">
                                        <Radio.Group
                                            onChange={(e) => {
                                                setIsVarian(e.target.value);
                                                clearVariantInput(
                                                    e.target.value,
                                                );
                                                setIsDiskon({
                                                    status: false,
                                                    id: -1,
                                                    harga_asli: '',
                                                });
                                                setVarian([blankVariant]);
                                            }}>
                                            <Radio value={true}>Ya</Radio>
                                            <Radio value={false}>Tidak</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Form.Item>
                                {prodType == 'offline_at_participant' && (
                                    <Form.Item
                                        name={`additional_transport_fee`}
                                        valuePropName="checked"
                                        style={{
                                            marginBottom: 20,
                                        }}>
                                        <Checkbox>
                                            Ada biaya transport tambahan di luar
                                            harga tiket
                                        </Checkbox>
                                    </Form.Item>
                                )}

                                {isVarian && (
                                    <>
                                        {varian.map((el, index) => {
                                            return (
                                                <div key={index}>
                                                    {index !== 0 && (
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            danger
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                right: 5,
                                                            }}
                                                            onClick={() => {
                                                                handleDelVariant(
                                                                    index,
                                                                );
                                                            }}>
                                                            Hapus
                                                        </Button>
                                                    )}

                                                    <h4>
                                                        Nama Varian {index + 1}
                                                    </h4>
                                                    <Form.Item
                                                        label="Nama Varian"
                                                        name={`namavarian${index}`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Nama Varian wajib diisi',
                                                            },
                                                        ]}>
                                                        <Input
                                                            onChange={(e) =>
                                                                handleChangeVarianChild(
                                                                    index,
                                                                    'name',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            placeholder="Masukkan nama Varian"
                                                        />
                                                    </Form.Item>
                                                    <h4>
                                                        Harga Varian {index + 1}
                                                    </h4>
                                                    <Form.Item
                                                        name={`harga_asli${index}`}
                                                        label="Harga Varian"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Harga Varian wajib diisi',
                                                            },
                                                            {
                                                                type: 'number',
                                                                min: 0,
                                                                message:
                                                                    'Harga Varian minimal 0',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            onChange={(val) => {
                                                                // handlePromo(
                                                                //     index,
                                                                //     val,
                                                                // );
                                                                handleChangeVarianChild(
                                                                    index,
                                                                    'price',
                                                                    val,
                                                                );
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            formatter={(
                                                                value,
                                                            ) =>
                                                                `Rp ${value}`.replace(
                                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                                    '.',
                                                                )
                                                            }
                                                            parser={(value) =>
                                                                value.replace(
                                                                    /Rp\s?|(\.*)/g,
                                                                    '',
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={`isdiskon${index}`}
                                                        valuePropName="checked"
                                                        onChange={() => {
                                                            handlePromo(index);
                                                        }}
                                                        style={{
                                                            marginBottom: 0,
                                                        }}>
                                                        <Checkbox>
                                                            Terapkan Diskon
                                                        </Checkbox>
                                                    </Form.Item>
                                                    {varian[index].disc ===
                                                        true && (
                                                        <>
                                                            <div
                                                                style={{
                                                                    marginBottom: 20,
                                                                }}>
                                                                <Form.Item
                                                                    label="Harga Diskon"
                                                                    name={`harga_diskon${index}`}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                'Harga Diskon wajib diisi',
                                                                        },
                                                                        {
                                                                            type: 'number',
                                                                            message:
                                                                                'Harga Diskon wajib angka',
                                                                        },
                                                                        {
                                                                            async validator(
                                                                                rule,
                                                                                value,
                                                                            ) {
                                                                                var harga_diskon =
                                                                                    form.getFieldValue(
                                                                                        `harga_diskon${index}`,
                                                                                    );
                                                                                if (
                                                                                    varian[
                                                                                        index
                                                                                    ]
                                                                                        .price <=
                                                                                    harga_diskon
                                                                                ) {
                                                                                    return Promise.reject(
                                                                                        new Error(
                                                                                            'Harga Diskon harus lebih kecil dari Harga Varian',
                                                                                        ),
                                                                                    );
                                                                                } else {
                                                                                    return Promise.resolve();
                                                                                }
                                                                            },
                                                                            validateTrigger:
                                                                                'onChange',
                                                                        },
                                                                    ]}
                                                                    style={{
                                                                        marginBottom: 0,
                                                                    }}>
                                                                    <InputNumber
                                                                        onChange={(
                                                                            value,
                                                                        ) =>
                                                                            handleChangeVarianChild(
                                                                                index,
                                                                                'sale_price',
                                                                                value,
                                                                            )
                                                                        }
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        formatter={(
                                                                            value,
                                                                        ) =>
                                                                            `Rp ${value}`.replace(
                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                '.',
                                                                            )
                                                                        }
                                                                        parser={(
                                                                            value,
                                                                        ) =>
                                                                            value.replace(
                                                                                /Rp\s?|(\.*)/g,
                                                                                '',
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                                <small>
                                                                    Harga
                                                                    setelah
                                                                    dikurangi
                                                                    diskon
                                                                    (bukan
                                                                    besaran
                                                                    diskon).
                                                                </small>
                                                            </div>
                                                            <div>
                                                                <Form.Item
                                                                    name={`sale_price_expired${index}`}
                                                                    label="Periode Diskon"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                'Periode Diskon wajib dipilih',
                                                                        },
                                                                    ]}>
                                                                    <RangePicker
                                                                        onChange={(
                                                                            value,
                                                                        ) =>
                                                                            handleChangeVarianChild(
                                                                                index,
                                                                                'sale_price_start',
                                                                                value,
                                                                            )
                                                                        }
                                                                        disabledDate={
                                                                            disabledDate
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </>
                                                    )}
                                                    <h4>
                                                        Jumlah Tiket / Barang
                                                    </h4>
                                                    <Form.Item
                                                        label="Tiket / Barang tersedia"
                                                        name={`stock${index}`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Jumlah Tiket wajib diisi',
                                                            },
                                                            {
                                                                type: 'number',
                                                                min: 0,
                                                                message:
                                                                    'Jumlah tiket minimal 0',
                                                            },
                                                            {
                                                                type: 'number',
                                                                max: 100,
                                                                message:
                                                                    'Jumlah tiket maksimal 100',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            onChange={(value) =>
                                                                handleChangeVarianChild(
                                                                    index,
                                                                    'stock',
                                                                    value,
                                                                )
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="Masukkan Jumlah Tiket / Barang"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            );
                                        })}
                                        <Form.Item>
                                            <Button
                                                onClick={handleAddVariant}
                                                block
                                                disabled={
                                                    varian.length >= 5
                                                        ? true
                                                        : false
                                                }>
                                                + Tambah Varian
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                                {isVarian === false && (
                                    <>
                                        <p>Berapa Harga Aktivitasmu ?</p>
                                        <Form.Item
                                            label="Harga Tiket"
                                            name="harga_asli1"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Harga Tiket wajib diisi',
                                                },
                                                {
                                                    type: 'number',
                                                    min: 0,
                                                    message:
                                                        'Harga Tiket minimal 0',
                                                },
                                            ]}>
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                placeholder="Masukkan Harga Tiket"
                                                onChange={(val) => {
                                                    setIsDiskon({
                                                        ...isDiskon,
                                                        harga_asli: val,
                                                    });
                                                }}
                                                formatter={(value) =>
                                                    `Rp ${value}`.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        '.',
                                                    )
                                                }
                                                parser={(value) =>
                                                    value.replace(
                                                        /Rp\s?|(\.*)/g,
                                                        '',
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="diskon1"
                                            valuePropName="checked"
                                            onChange={() => {
                                                setIsDiskon({
                                                    ...isDiskon,
                                                    status: !isDiskon.status,
                                                    id: -1,
                                                });
                                            }}
                                            style={{ marginBottom: 0 }}>
                                            <Checkbox>Terapkan Diskon</Checkbox>
                                        </Form.Item>
                                        {isDiskon.status === true &&
                                            isDiskon.id === -1 && (
                                                <>
                                                    <div
                                                        style={{
                                                            marginBottom: 20,
                                                        }}>
                                                        <Form.Item
                                                            label="Harga Diskon"
                                                            name="harga_diskon1"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Harga Diskon wajib diisi',
                                                                },
                                                                {
                                                                    type: 'number',
                                                                    max: Number(
                                                                        isDiskon.harga_asli -
                                                                            1,
                                                                    ),
                                                                    message: `Harga Diskon harus kurang dari ${isDiskon.harga_asli}`,
                                                                },
                                                            ]}
                                                            style={{
                                                                marginBottom: 0,
                                                            }}>
                                                            <InputNumber
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                formatter={(
                                                                    value,
                                                                ) =>
                                                                    `Rp ${value}`.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        '.',
                                                                    )
                                                                }
                                                                parser={(
                                                                    value,
                                                                ) =>
                                                                    value.replace(
                                                                        /Rp\s?|(\.*)/g,
                                                                        '',
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                        <small>
                                                            Harga setelah
                                                            dikurangi diskon
                                                            (bukan besaran
                                                            diskon).
                                                        </small>
                                                    </div>
                                                    <div>
                                                        <Form.Item
                                                            name="sale_price_expired"
                                                            label="Periode Diskon"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Periode Diskon wajib dipilih',
                                                                },
                                                            ]}>
                                                            <RangePicker
                                                                disabledDate={
                                                                    disabledDate
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            )}
                                        <div>
                                            <p>Jumlah Tiket / Barang</p>
                                            <Form.Item
                                                label="Tiket / Barang tersedia"
                                                name="stock1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Jumlah Tiket wajib diisi',
                                                    },
                                                    {
                                                        type: 'number',
                                                        min: 0,
                                                        message:
                                                            'Jumlah tiket minimal 0',
                                                    },
                                                    {
                                                        type: 'number',
                                                        max: 100,
                                                        message:
                                                            'Jumlah tiket maksimal 100',
                                                    },
                                                ]}>
                                                <InputNumber
                                                    style={{ width: '100%' }}
                                                    placeholder="Masukkan Jumlah Tiket / Barang"
                                                />
                                            </Form.Item>
                                        </div>
                                    </>
                                )}
                            </div>

                            <Form.Item
                                label="Tags"
                                name="tag"
                                extra="Pisahkan dengan tanda koma ( , )">
                                <Select
                                    allowClear
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder="Masukkan Tag"
                                    tokenSeparators={[',']}></Select>
                            </Form.Item>

                            <Form.Item
                                name="premium_product"
                                label="Tanggal Mulai dan Akhir Premium Produk"
                                style={{ width: '100%' }}>
                                <RangePicker disabledDate={disabledDate} />
                            </Form.Item>

                            {/* <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Tanggal Mulai Premium Product"
                                        name="start_premium_product">
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            showTime={{
                                                hideDisabledOptions: true,
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Tanggal Akhir Premium Product"
                                        name="expired_premium_product">
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            showTime={{
                                                hideDisabledOptions: true,
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Status Wajib dipilih',
                                    },
                                ]}>
                                <Select placeholder="Pilih Status">
                                    <Option value="publish">Publish</Option>
                                    <Option value="pending">Pending</Option>
                                    <Option value="draft">Draft</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name="submit">
                                <div>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={isLoading}
                                        style={{ marginRight: 10 }}
                                        disabled={isLoading ? true : false}>
                                        Submit
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            history.goBack();
                                        }}>
                                        Batal
                                    </Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default FormProduct;
