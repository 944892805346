import styled from '@emotion/styled';

const LMSClassCreateStyle = styled.div`
    display: block;

    .avatar-uploader > .ant-upload {
        width: 300px;
        height: 300px;
    }
`;

export default LMSClassCreateStyle;

export const WysiwygStyle = styled.div`
    .ql-editor {
        min-height: 18em;
    }
`;
