import styled from '@emotion/styled';

const InputMultipleStyle = styled.div`
    display: block;
    .group-value {
        margin-top: 12px;
        .value-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            border: 1px solid #d9d9d9;
            padding: 4px 8px;
            margin-bottom: 12px;
        }

        .item-action {
            display: flex;
        }

        a {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 550px;
            white-space: nowrap;
        }
    }
`;

export default InputMultipleStyle;
