import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    Select,
    PageHeader,
    Table,
    Tag,
    Menu,
    Dropdown,
    Input,

} from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import serviceGiftProductList from './index.service';
import GiftProductListStyle from './index.style';
import { formatMoney } from '../../../../utils/format-money';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import { categori, subcat } from '../../../../utils/index-data';
import serviceGiftCategory from '../../Category/index.service';

const GiftProductList = () => {
    const history = useHistory();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [dataGiftProductList, setDataGiftProductList] = useState([]);
    const [dataFilterCategory, setdataFilterCategory] = useState([]);
    const [dataCategory, setDataCategory] = React.useState([]);
    const [setCurrentPage] = useState(1);
    const [setTotalPage] = useState(10);
    const [queryString, setQueryString] = useState({
        queries: {
            page: 1,
            row: 10,
        },
        sort: {
            'gp.created_at': 'desc',
        },
    });

    const [searchValue, setSearchValue] = React.useState({});

    React.useEffect(() => {
        onGetCategory();
    }, []);

    const onGetCategory = async () => {
        const res = await serviceGiftCategory.getListCategory();
        if (
            res.isSuccess &&
            res?.response?.payload &&
            res.response.payload.length > 0
        ) {
            setDataCategory(
                res.response.payload.map((item) => ({
                    value: item.id,
                    text: item.name,
                })),
            );
        }
    };

    const resetFilter = () => {
        setQueryString({
            queries: {
                page: 1,
                row: 10,
            },
        });
        setCurrentPage(1);
        setTotalPage(10);
    };

    useEffect(() => {
        handleLoadData(queryString);
    }, [queryString]);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceGiftProductList.getGiftProductList(
            resultQuery,
        );
        if (result.isSuccess) {
            setDataGiftProductList(result.response);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={searchValue[dataIndex] || ''}
                    onChange={(e) =>
                        setSearchValue((prevState) => {
                            return {
                                ...prevState,
                                [dataIndex]: e.target.value,
                            };
                        })
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: '100%' }}
                    icon={<SearchOutlined />}
                    size="small">
                    Search
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const getColumnSelectProps = (dataIndex) => ({
        filterDropdown: ({ confirm }) => (
            <div style={{ padding: 8 }}>
                <Select
                    showSearch
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    optionFilterProp="children"
                    value={searchValue[dataIndex] || ''}
                    onSelect={(val) => {
                        handleSearch(val, confirm, dataIndex, true);
                    }}>
                    {dataFilterCategory.map((el, idx) => {
                        return (
                            <Option key={idx} value={el.value}>
                                {el.text}
                            </Option>
                        );
                    })}
                </Select>
                <Button
                    type="primary"
                    block
                    onClick={() => {
                        resetFilter();
                    }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const handleSearch = (
        selectedKeys,
        confirm,
        dataIndex,
        isComponentSelect = false,
    ) => {
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        if (isComponentSelect) {
            setSearchValue((prevState) => {
                return { ...prevState, [dataIndex]: selectedKeys };
            });
            resultQuery.filters = {
                [dataIndex]: selectedKeys,
            };
        } else {
            resultQuery.filters = {
                [dataIndex]: searchValue[dataIndex],
            };
        }
        setQueryString(resultQuery);
    };

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'publish') {
            color = 'green';
        }
        if (status === 'pending') {
            color = 'geekblue';
        }
        if (status === 'draft') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    const handleDataMerchantFilter = (data = []) => {
        if (data && data.length > 0) {
            setdataFilterCategory(() => [
                ...(data.length > 0 &&
                    data.map((item) => {
                        return {
                            text: item.name,
                            value: item.name,
                        };
                    })),
            ]);
        }
    };

    const handleResetFilter = () => {
        setSearchValue({});
        const defaultQuery = {
            queries: {
                page: 1,
                row: 10,
            },
        };
        setQueryString(defaultQuery);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Gift Product',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.id
                ? queryString?.sorts?.id === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            render: (_, record) => {
                return (
                    <Link to={`/gift/product/${record.id}`}>{record.id}</Link>
                );
            },
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (_, record) => {
                return (
                    <img
                        src={`${record.image}?id=${
                            Math.floor(Math.random() * 50) + 1
                        }`}
                        alt={record.name}
                        width="100"
                    />
                );
            },
        },
        {
            title: 'Nama Produk',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => {},
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Kategori',
            dataIndex: 'category_id',
            key: 'category_id',
            filters: [...categori, ...subcat],
            filteredValue: queryString?.filters?.category || null,
            ...getColumnSelectProps('category'),
        },
        {
            title: 'Harga Utama',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => {
                return <span>{formatMoney(record.price)}</span>;
            },
        },
        {
            title: 'Harga Diskon',
            dataIndex: 'sale_price',
            key: 'sale_price',
            render: (_, record) => {
                return <span>{formatMoney(record.sale_price)}</span>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'publish_status',
            key: 'publish_status',
            filters: [
                { text: 'DRAFT', value: 'draft' },
                { text: 'PUBLISH', value: 'publish' },
            ],
            render: (publish_status) => handleLabelStatus(publish_status),
            filteredValue: queryString?.filters?.product_publish_status || null,
            ellipsis: true,
        },
        {
            title: 'Modified Date',
            dataIndex: 'modified_at',
            key: 'modified_at',
            sorter: (a, b) => {},
            render: function (modified_at) {
                return (
                    <span>
                        {moment(modified_at).format('DD-MM-YYYY HH:mm:ss')}
                    </span>
                );
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: '',
            render: (_, record) => {
                return <DropdownMenu record={record} key="more" />;
            },
        },
    ];

    const DropdownMenu = ({ record }) => (
        <Dropdown key="more" overlay={menu(record)}>
            <Button
                style={{
                    border: 'none',
                    padding: 0,
                }}>
                <EllipsisOutlined
                    style={{
                        fontSize: 20,
                        verticalAlign: 'top',
                    }}
                />
            </Button>
        </Dropdown>
    );

    const menu = (record) => (
        <Menu>
            <Menu.Item>
                <Button
                    onClick={() => history.push(`/gift/product/${record.id}`)}
                    style={{ width: '100%' }}
                    type="dashed">
                    Detail & Edit
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button
                    style={{ width: '100%' }}
                    type="dashed"
                    onClick={async () => {
                        var ret = window.confirm(
                            'Are you sure delete this product?',
                        );
                        if (ret == true) {
                            const result =
                                await serviceGiftProductList.deleteProduct(
                                    record.id,
                                );
                            if (result.isSuccess) {
                                refreshPage();
                            } else {
                            }
                        } else {
                        }
                    }}>
                    Delete
                </Button>
            </Menu.Item>
        </Menu>
    );
    const refreshPage = () => {
        window.location.reload();
    };
    return (
        <div>
            <div className="header">
                <PageHeader
                    // onBack={() => history.goBack()}
                    className="site-page-header"
                    title="GIFT PRODUK"
                    breadcrumb={{ routes }}
                    subTitle="TABLE LIST"
                    style={{ paddingLeft: 0 }}
                />
                <Button onClick={handleResetFilter} style={{ float: 'right' }}>
                    Reset Filter
                </Button>
            </div>

            <GiftProductListStyle>
            <Form form={form} layout="inline">
                    <Form.Item>
                        <Button type="primary" style={{ marginBottom: 10 }}>
                            <Link to="/gift/product/add">+ Tambah Produk Gift</Link>
                        </Button>
                    </Form.Item>
            </Form>

                <Table
                    loading={isLoading}
                    pagination={{
                        current: queryString.queries.page,
                        defaultCurrent: 1,
                        className: 'pagination',
                        total: dataGiftProductList.total_payload,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        defaultPageSize: 10,
                    }}
                    bordered
                    columns={columns}
                    dataSource={dataGiftProductList.payload}
                    showSorterTooltip={true}
                    onChange={handleChange}
                />
            </GiftProductListStyle>
        </div>
    );
};

export default GiftProductList;
