import styled from '@emotion/styled';

const MainLayoutStyle = styled.section`
    display: block;
    width: 100%;
    height: 100%;
    .container-layout {
        width: 100%;
        height: 100%;
        aside {
            width: 20% !important;
            max-width: 250px !important;
        }
    }

    .logo {
        display: block;
        text-align: center;
        svg {
            ${({ collapsed }) =>
                collapsed
                    ? `
            width: 70%;
            height: 70%;
            padding: 2px;
        `
                    : ``}
        }
    }

    .toggle {
        cursor: pointer;
        margin-left: 30px;
    }

    .ant-layout-sider-children {
        background-color: #fff;
    }

    .site-layout {
        margin-left: 250px !important;
    }

    .site-layout .site-layout-background {
        background: #fff;
    }

    .content {
        background: #fff;
        padding: 20px;
        min-height: 280px;
        overflow: auto;
    }

    .account-settings {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
`;

export default MainLayoutStyle;
