import styled from '@emotion/styled';

const MerchantFormStyle = styled.div`
    .avatar-uploader > .ant-upload {
        width: 150px;
        height: 150px;
    }

    .detail-usaha {
        .avatar-uploader > .ant-upload {
            border-radius: 50%;
            overflow: hidden;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }

    .ant-input-number-handler-wrap {
        display: none;
    }
`;

export default MerchantFormStyle;
