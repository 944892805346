import React from "react";
import FormBanner from "../FormBanner";

const CatalogSlider = () => {

  return (
    <FormBanner jmlInput={6} type="catalog_slider" />
  );
};

export default CatalogSlider;
