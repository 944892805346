import serviceApi from '../../../services/index';

const serviceMerchantUpdate = {
    updateMerchant: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/merchants`,
            version: 'v1',
            appType: 1,
            token: true,
            data: data,
        });
    },
    getMerchantDetail: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/merchants/${id}`,
            version: 'v1',
            appType: 1,
            token: true,
        });
    },
};

export default serviceMerchantUpdate;
