import { Card, Col, Descriptions, Row, Skeleton } from 'antd';
import React from 'react';

const OrderGiftDetailLoading = () => {
  return (
    <Skeleton>
      <Row gutter={16}>
        <Col span={24}>
          <Card title='Order Details' bordered={true}>
            <Descriptions title='' bordered>
              <Descriptions.Item label='Order ID'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Date'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Updated Timestamp'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Nama Gift Produk'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Kategori'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Harga Diskon'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Service Pay'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Payment Gateway Vendor'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Pembayaran Via'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Payment Gateway Status'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Payment Gateway Info'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={8}>
          <Card title='Customer Detail' bordered={true}>
            <Descriptions bordered>
              <Descriptions.Item label='Name' span={3}>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Phone' span={3}>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Address' span={3}>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              
            </Descriptions>
          </Card>
        </Col>
        <Col span={16}>
          <Card title='Gift Product Details' bordered>
            <Skeleton.Input style={{ width: 100 }} active size='large' />
          </Card>
        </Col>
      </Row>
    </Skeleton>
  );
};

export default OrderGiftDetailLoading;
