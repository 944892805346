import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

// LOAD CONTEXT
import AppProvider from './contexts/app';

import 'antd/dist/antd.css';
import './index.scss';
import ThemeConfiguration from './shared/Theme';

ReactDOM.render(
    // <React.StrictMode>
    <ThemeConfiguration>
        <AppProvider>
            <Router>
                <App />
            </Router>
        </AppProvider>
    </ThemeConfiguration>,
    // </React.StrictMode>,
    document.getElementById('root'),
);
