import { notification } from 'antd';

export const notifySuccess = (props) => {
    return notification.success({
        message: 'Success',
        description: props,
    });
};

export const notifyError = (props) => {
    return notification.error({
        message: 'Error',
        description: props,
    });
};
