import serviceApi from '../../../services';

const serviceMerchantHistoryPaket = {
    onGetListHistoryPaket: async (params, id) => {
        return await serviceApi({
            method: 'GET',
            url: `/merchants-history/${id}`,
            version: 'v1',
            appType: 1,
            token: true,
            params: params,
        });
    },
};
export default serviceMerchantHistoryPaket;
