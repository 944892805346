import {
    DeleteFilled,
    EditOutlined,
    FileSearchOutlined,
    FormOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, PageHeader, Space, Table, Tooltip } from 'antd';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ModalRemove from '../../components/ModalRemove';
import { notifyError, notifySuccess } from '../../utils';
import serviceInbox from './index.service';
import InboxPageStyle from './index.style';

const { Search } = Input;

export default function InboxPage() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
        filters: {
            'contact_us.name': '',
        },
        sorts: {
            'contact_us.id': 'DESC',
        },
    });
    const [dataTeam, setDataTeam] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceInbox.getListInbox(resultQuery);
        if (result.isSuccess) {
            setDataTeam(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            key: 'no',
            width: 60,
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            // ...getColumnSearchProps('name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: false,
            // ...getColumnSearchProps('name'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            ellipsis: true,
            // ...getColumnSearchProps('name'),
        },
        {
            title: 'Topik/Kategori',
            dataIndex: 'topik',
            key: 'topik',
            ellipsis: true,
            // ...getColumnSearchProps('name'),
        },
        {
            title: 'Isi Pesan',
            dataIndex: 'pesan',
            key: 'pesan',
            width: 300,
            ellipsis: false,
            // ...getColumnSearchProps('recommend'),
        },
        {
            title: 'Tanggal Masuk',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_, record) => {
                return moment(record.created_at).format('DD MMM YYYY');
            },
            // ...getColumnSearchProps('recommend'),
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Pesan Masuk',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filters['contact_us.name'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };
    return (
        <InboxPageStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Pesan Masuk"
                    breadcrumb={{ routes }}
                    subTitle="Table of Pesan Masuk"
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}></Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataTeam.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataTeam.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </InboxPageStyle>
    );
}
