import { Card, Col, Descriptions, Row, Skeleton } from 'antd';
import React from 'react';

const OrderPPOBDetailLoading = () => {
  return (
    <Skeleton>
      <Row gutter={16}>
        <Col span={24}>
          <Card title='Order Detail PPOB' bordered={true}>
            <Descriptions title='' bordered>
              <Descriptions.Item label='Tangal Order'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Order ID'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Harga'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Harga Diskon'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Kode Promo'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Nama Payment Gateway'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Nama Pembayaran'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='VA BANK'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Biaya Admin'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Status'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Point Klaim'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Link Pembayaran'>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={8}>
          <Card title='Data User Kiddo' bordered={true}>
            <Descriptions bordered>
              <Descriptions.Item label='Nama' span={3}>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='No HP' span={3}>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              <Descriptions.Item label='Email' span={3}>
                <Skeleton.Input style={{ width: 200 }} active size='small' />
              </Descriptions.Item>
              
            </Descriptions>
          </Card>
        </Col>
        <Col span={16}>
          <Card title='Data Pembelian Produk' bordered>
            <Skeleton.Input style={{ width: 100 }} active size='large' />
          </Card>
        </Col>
      </Row>
    </Skeleton>
  );
};

export default OrderPPOBDetailLoading;
