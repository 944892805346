import serviceApi from '../../../services';

const serviceLMSClass = {
    getListQuiz: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/class`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getMerchant: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/merchants`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getProduct: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/products`,
            version: 'v2',
            token: true,
            params: params,
        });
    },
    postDataClass: async (bodyData) => {
        return await serviceApi({
            method: 'POST',
            url: `/class/class`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    updateDataClass: async (bodyData) => {
        return await serviceApi({
            method: 'PUT',
            url: `/class/class`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    getDetailClass: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/class/${id}`,
            version: 'v1',
            token: true,
        });
    },
    getDetailMerchant: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/merchants?merchant_id=${id}`,
            version: 'v1',
            token: true,
        });
    },
    getDetailProduct: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/products?filters%5Bproduct_id%5D=${id}`,
            version: 'v2',
            token: true,
        });
    },
};

export default serviceLMSClass;
