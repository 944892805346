import moment from 'moment';

export const formatDate = (date, formatDate) => {
    let march = moment(date);
    return march.locale('id').format(formatDate);
};

export const formatStringDate = (date, format, toFormat) => {
    let march = moment(date, format);
    return march.locale('id').format(toFormat);
};

export const todayDate = ({ day = 0, type = 'days' }) => {
    let march = moment(new Date()).add(day, type);
    return march.locale('id');
};

export const dayMinus = (date = false, day = 0, type = 'days') => {
    let march = moment(date).subtract(day, type);
    return march.locale('id');
};

export const dayPlus = ({ date = false, day = 0, type = 'days' }) => {
    let march = moment(date).add(day, type);
    return march.locale('id');
};
export const minusHours = ({ date = false, number = 0, type = 'hours' }) => {
    let march = moment(date).subtract(number, type);
    return march.locale('id');
};

export const currentDate = () => {
    let march = moment(new Date());
    return march.locale('id');
};

export const dateDiff = (firstDate, secondDate, type = 'days') => {
    return firstDate.locale('id').diff(secondDate.locale('id'), type);
};

export const checkAge = (date) => {
    var a = moment();
    var b = moment(new Date(date), 'MM-YYYY');
    var age = moment.duration(a.diff(b));
    var years = age.years();
    var months = age.months();
    return `${years} Tahun ${months} bulan`;
};

export const convertToIsoWithoutTime = (date) => {
    var correctDate = new Date(date);

    correctDate.setUTCHours(0, 0, 0, 0);
    return correctDate.toISOString();
};
