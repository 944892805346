import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Select,
    Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceCampaignPage from '../index.service';
import CampaignPageEditStyle from './index.style';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const CampaignEdit = () => {
    const history = useHistory();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);
    const [dataBanner, setDataBanner] = React.useState();
    const [isSelectedPromo, setSelectedPromo] = React.useState(false);

    const routes = [
        {
            path: '',
            breadcrumbName: 'PAGE CAMPAIGN',
        },
        {
            path: '',
            breadcrumbName: 'EDIT',
        },
    ];

    useEffect(() => {
        onGetDataCampaign(params.id);
    }, []);

    const onGetDataCampaign = async (id) => {
        const result = await serviceCampaignPage.onGetDetailCampaign(id);
        if (result.isSuccess) {
            let tempResultData = result.response.payload;
            tempResultData.image = tempResultData.image_banner;
            if (result.response.payload.category === 'promo') {
                setSelectedPromo(true);
            }
            form.setFieldsValue(tempResultData);
            setFileUpload([
                {
                    uid: '1',
                    name: 'Image',
                    status: 'done',
                    url: `${result.response.payload.image_banner}?id=${
                        Math.floor(Math.random() * 50) + 1
                    }`,
                    size: 1999,
                },
            ]);
            setDataBanner(result.response.payload);
        }
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        if (typeof values.image === 'string') {
            formData.append('image_banner', values.image);
        } else {
            formData.append('file', values.image.file.originFileObj);
        }
        formData.append('link_url', values.link_url);
        formData.append('cta', values.cta);
        formData.append('category', values.category);

        const result = await serviceCampaignPage.updateCampaign(
            params.id,
            formData,
        );
        if (result.isSuccess) {
            notifySuccess('Sukses Update Data Page Campaign');
            history.push('/campaign/page');
        } else {
            notifyError(result.message);
        }
        setIsLoading(false);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    return (
        <CampaignPageEditStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Buat Page Campaign"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Banner / Icon"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Image wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onPreview={() => {}}
                                    onChange={(info) => {
                                        const isLt2M =
                                            info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error(
                                                'Image harus lebih kecil dari 2 MB!',
                                            );
                                        } else {
                                            setFileUpload(info.fileList);
                                        }
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}>
                                    {fileUpload.length === 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload Banner / Icon
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="category"
                                label="Kategori Campaign"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Category wajib diisi',
                                    },
                                ]}>
                                <Select
                                    onChange={(value) => {
                                        if (value === 'promo') {
                                            setSelectedPromo(true);
                                            form.setFieldValue('cta', '');
                                        } else {
                                            setSelectedPromo(false);
                                        }
                                    }}
                                    placeholder="Pilih Kategori Page Campaign"
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'akses-cepat'}>
                                        Akses Cepat
                                    </Option>
                                    <Option key={2} value={'promo'}>
                                        Promo
                                    </Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="cta"
                                label="Nama CTA (Khusus Kategori Akses Cepat)"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required={!isSelectedPromo}
                                rules={
                                    isSelectedPromo
                                        ? []
                                        : [
                                              {
                                                  required: true,
                                                  message:
                                                      'Name wajib diisi jika pilih kategori akses cepat',
                                              },
                                          ]
                                }>
                                <Input
                                    disabled={isSelectedPromo}
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Nama CTA jika pilih kategori akses cepat"
                                />
                            </Form.Item>
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="link_url"
                                label="Masukkan URL Internal kiddo"
                                style={{ marginBottom: 0 }}>
                                <Input
                                    size="large"
                                    type={'url'}
                                    placeholder="Masukkan URL"
                                />
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Submit
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history.goBack();
                                    }}>
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </CampaignPageEditStyle>
    );
};

export default CampaignEdit;
