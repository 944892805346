import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/charts';

const PieChart = (props) => {
    const dataChart = props.data;
    var config = {
        appendPadding: 5,
        data: dataChart,
        angleField: 'value',
        colorField: 'type',
        radius: 0.6,
        width: 450,
        height: 300,
        title: 'Age',
        autoFit: false,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            { type: 'pie-legend-active' },
            { type: 'element-active' },
        ],
    };
    return (
        <div style={{ marginLeft: '20px' }}>
            <Pie {...config} />
        </div>
    );
};
export default PieChart;
