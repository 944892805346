import serviceApi from '../../../services';

const serviceMerchantSaldo = {
    onGetData: async (param) => {
        return await serviceApi({
            method: 'GET',
            url: `/merchants/saldos`,
            version: 'v1',
            token: true,
            params: param,
        });
    },
    onGetMerchantName: async () => {
        return await serviceApi({
            method: 'GET',
            url: ` /merchants/saldos/names`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceMerchantSaldo;
