import styled from '@emotion/styled';

const UploadFlatStyle = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid #d9d9d9;
    align-items: center;
    padding: 12px 24px;
    justify-content: space-between;
    height: 110px;

    .container-image {
        position: relative;
        .remove-img {
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        img {
            max-width: 120px;
            max-height: 120px;
            object-fit: contain;
        }
    }
`;

export default UploadFlatStyle;
