import serviceApi from '../../services/index';

const serviceFormProduct = {
    postProduct: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/products`,
            noVersion: true,
            appType: 1,
            token: true,
            params: null,
            data: data,
        });
    },
    postPhoto: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/products/photo/upload`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    getMerchantList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/merchants/names`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    getProvince: async () => {
        return await serviceApi({
            method: 'GET',
            url: `v1/locations/provinces`,
            noVersion: true,
            appType: 1,
            token: true,
        });
    },
    getCity: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/locations/cities`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    getSubDistrict: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/locations/subdistricts`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },
    postSlug: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/products/slug/exist`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    getShippingList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/shippings/merchant?name=` + params,
            noVersion: true,
            appType: 1,
            token: true,
        });
    },
    postPhotoDuplicate: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/products/photo/duplicate`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    onCheckShippingAndAdress: async (id) => {
        const onCheckShipping = serviceApi({
            method: 'GET',
            url: `/merchants/${id}/shippings/exist`,
            version: 'v1',
            appType: 1,
            token: true,
            data: id,
            formData: true,
        });
        const onCheckExistAddress = serviceApi({
            method: 'GET',
            url: `/merchants/${id}/locations/exist`,
            version: 'v1',
            appType: 1,
            token: true,
            data: id,
            formData: true,
        });
        return Promise.all([
            (await onCheckShipping).response?.payload?.is_shipping_exist ||
                false,
            (await onCheckExistAddress).response?.payload?.is_locations_exist ||
                false,
        ]);
    },
};

export default serviceFormProduct;
