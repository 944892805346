import serviceApi from '../../../services';

const serviceKiddofestCoupon = {
    getListCoupon: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/coupon`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    onClaimCoupon: async (id) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kiddofest/coupon/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceKiddofestCoupon;
