import React, { useState } from 'react';
import { PageHeader, Row, Col, Form, Select, Upload, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import MerchantGalleryStyle from './index.style';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const MerchantGallery = () => {
    const history = useHistory();
    const { Option } = Select;

    const [merchant, setMerchant] = useState(null);
    const [gallery, setGallery] = useState({
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: [
            {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
            {
                uid: '-2',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
            {
                uid: '-3',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
        ],
    });

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Dashboard',
        },
        {
            path: 'merchant-gallery',
            breadcrumbName: 'Merchant Gallery',
        },
    ];

    const handleCancel = () =>
        setGallery({ ...gallery, previewVisible: false });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setGallery({
            ...gallery,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    const handleChange = ({ fileList }) =>
        setGallery({ ...gallery, fileList: fileList });

    return (
        <MerchantGalleryStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Merchant Gallery"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <div style={{ minHeight: '60vh' }}>
                <Row>
                    <Col span={12}>
                        <Form layout="vertical">
                            <Form.Item label="Select Merchant" name="merchant">
                                <Select
                                    placeholder="Please select merchant first"
                                    onChange={(val) => setMerchant(val)}>
                                    <Option value="merchant1">
                                        Merchant 1
                                    </Option>
                                    <Option value="merchant2">
                                        Merchant 2
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                    {merchant && (
                        <Col span={24}>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={gallery.fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                onRemove={(e) => {
                                    // return false;
                                }}>
                                {gallery.fileList.length >= 8 ? null : (
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>
                                            Upload
                                        </div>
                                    </div>
                                )}
                            </Upload>
                            <Modal
                                visible={gallery.previewVisible}
                                title={gallery.previewTitle}
                                footer={null}
                                onCancel={handleCancel}>
                                <img
                                    alt="example"
                                    style={{ width: '100%' }}
                                    src={gallery.previewImage}
                                />
                            </Modal>
                        </Col>
                    )}
                </Row>
            </div>
        </MerchantGalleryStyle>
    );
};

export default MerchantGallery;
