import { Modal, PageHeader } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ArticleForm from '../../../components/Artikel/ArticleForm';
import articleService from '../index.service';

const ArticleDetail = (props) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(null);

    const routes = [
        {
            path: '/',
            breadcrumbName: 'Article',
        },
        {
            path: '/article/detail',
            breadcrumbName: 'Update',
        },
    ];

    useEffect(() => {
        getArticleDetail(props.match.params.id);
    }, []);

    const updateArticle = async (data) => {
        setIsLoading(true);
        const result = await articleService.updateArticle(data);
        if (result.isSuccess) {
            modalSuccess();
        } else {
            Modal.error('Gagal Mngupdate Artikel');
        }
        setIsLoading(false);
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil mengubah data product',
            onOk: () => {
                history.push('/article');
            },
        });
    };

    const getArticleDetail = async (id) => {
        const result = await articleService.getArticleDetail(id);
        if (result.isSuccess) {
            setInitialValues(result.response.payload);
        } else {
            Modal.error('Gagal mendapatkan detail artikel');
        }
    };

    return (
        <div>
            <PageHeader
                onBack={() => history.push('/article')}
                className="site-page-header"
                title="Artikel Update"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <div>
                <ArticleForm
                    initValue={initialValues}
                    isLoading={isLoading}
                    onSubmit={updateArticle}
                />
            </div>
        </div>
    );
};

export default ArticleDetail;
