import React, { useState } from 'react';
import ProviderPrefixEditStyle from './index.style';
import { Button, Col, Form, Input, PageHeader, Row, Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import serviceProviderPrefix from '../index.service';
import { notifyError, notifySuccess } from '../../../../utils';

const ProviderPrefixEdit = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [dataPrefix, setDataPrefix] = useState([]);
    const [dataDetailProvider, setDataDetailProvider] = React.useState(false);
    const params = useParams();

    const routes = [
        {
            path: '',
            breadcrumbName: 'PROVIDER PREFIX',
        },
        {
            path: '',
            breadcrumbName: 'EDIT',
        },
    ];

    React.useEffect(() => {
        onGetDetailProvider(params.id);
        // onGetDataPrefix();
    }, []);

    const onGetDataPrefix = async () => {
        const results = await serviceProviderPrefix.listProviderPrefix();
        if (results.isSuccess) {
            setDataPrefix(
                results.response.payload &&
                    results.response.payload.length > 0 &&
                    results.response.payload.map((item) => ({
                        label: item.prefix,
                        value: item.prefix,
                    })),
            );
        } else {
            notifyError(results.response.message);
        }
    };

    const onGetDetailProvider = async (id) => {
        const results = await serviceProviderPrefix.getDetailProvider(id);
        if (results.isSuccess) {
            const res = {
                provider: results.response.payload.provider,
                prefix: results.response.payload.prefix.split(','),
            };
            setDataDetailProvider(results.response);
            form.setFieldsValue(res);
        } else {
            notifyError(results.response.message);
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        const resultValues = {
            id: Number(params.id),
            provider: values.provider,
            prefix: values.prefix.join(','),
            updated_at: `${moment(values.updated_at).format(
                'YYYY-MM-DDTHH:mm:ss',
            )}Z`,
        };
        const results = await serviceProviderPrefix.updateProviderPrefix(
            resultValues,
        );
        if (results.isSuccess) {
            notifySuccess('Sukses update data provider prefix');
            history.push('/ppob/provider');
        } else {
            notifyError(results.message);
        }
        setLoading(false);
    };

    return (
        <ProviderPrefixEditStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Update Data Provider Prefix"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="provider"
                            label="Nama Provider"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Provider wajib diisi',
                                },
                            ]}>
                            <Input
                                size="large"
                                type={'text'}
                                placeholder="Masukkan Nama Provider"
                            />
                        </Form.Item>
                    </Col>

                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="prefix"
                            label="Nomor Prefix"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Prefix wajib diisi',
                                },
                            ]}>
                            <Select
                                mode="tags"
                                allowClear
                                options={dataPrefix}
                                style={{ width: '100%' }}
                                placeholder="Input & Select Prefix"></Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="submit">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={loading}
                            style={{
                                marginRight: 10,
                                marginTop: '24px',
                                width: 200,
                            }}
                            disabled={loading ? true : false}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                history.goBack();
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </ProviderPrefixEditStyle>
    );
};

export default ProviderPrefixEdit;
