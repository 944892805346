import styled from "@emotion/styled";

export const EditorStyle = styled.div`
  .ql-editor {
    min-height: 300px !important;
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }
`

export const UploadMainImageStyle = styled.div`
  .avatar-uploader > .ant-upload {
    width: 350px;
    height: 220px;
  }
`

export const PhotoContentContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, 100px);

  .content-photo {
    border: .5px solid #d1d1d1;
    transition: all .1s ease;
    cursor: pointer;

    &:hover {
      border: 1px solid #427bff;
    }

    &-active {
      border: 2px solid #427bff;

      &:hover {
        border: 3px solid #427bff;
      }
    }
  }
`
