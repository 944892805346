import styled from '@emotion/styled';

const ImmunizationDetailStyle = styled.div`
    display: block;

    p.title {
        font-size: 14px;
        font-weight: bold;
    }
    p.value {
        margin-bottom: 24px;
    }
`;

export default ImmunizationDetailStyle;
