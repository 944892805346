import serviceApi from '../../../services';

const serviceCodePromoMarkerplace = {
    getCodePromoMarketplaceList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/promo-codes`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getCodePromoById: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/promo-codes/${id}`,
            version: 'v1',
            token: true,
        });
    },
    getProduct: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/products`,
            version: 'v2',
            token: true,
            params: params,
        });
    },
    getMerchant: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/merchants`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getUser: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/users`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getPayment: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/payments`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    onSubmitPromoMarketplace: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/promo-codes`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updatePromoMarketplace: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/promo-codes`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getDetailMerchant: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/merchants?merchant_id=${id}`,
            version: 'v1',
            token: true,
        });
    },
    getDetailProduct: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/products?products_id=${id}`,
            version: 'v2',
            token: true,
        });
    },
    getDetailUsers: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/list/users?user_id=${id}`,
            version: 'v2',
            token: true,
        });
    },
};

export default serviceCodePromoMarkerplace;
