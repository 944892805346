import React, { useEffect, useState } from 'react';
import CodePromoMarketplaceAddStyle from './index.style';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    PageHeader,
    Row,
    Select,
} from 'antd';
import DebounceSelectComponent from '../../../../components/DebounceSelect';
import serviceCodePromoMarkerplace from '../index.service';
import moment from 'moment';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceCodePromoPremium from '../index.service';

const { Option } = Select;

export default function CodePromoPremiumEdit() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [typePromo, setTypePromo] = useState(1);
    const [dataPayment, setDataPayment] = useState([]);
    const [dataCC, setDataCC] = useState([]);
    const params = useParams();

    const routes = [
        {
            path: '',
            breadcrumbName: 'Code Promo Premium',
        },
        {
            path: '',
            breadcrumbName: 'Edit',
        },
    ];

    const [listCategory] = useState([
        { value: 'Aktivitas', label: 'Aktivitas' },
        { value: 'Kursus', label: 'Kursus' },
        { value: 'Experience', label: 'Experience' },
        { value: 'Daycare dan Pre-School', label: 'Daycare dan Pre-School' },
        { value: 'Activity Kit', label: 'Activity Kit' },
        { value: 'Kelas Online', label: 'Video Learning' },
        { value: 'Kesehatan', label: 'Kesehatan' },
        { value: 'Keluarga', label: 'Keluarga' },
        { value: 'Festival', label: 'Festival' },
        { value: 'Gratis', label: 'Gratis' },
        { value: 'Kerajinan Tangan', label: 'Kerajinan Tangan' },
        { value: 'Kognitif', label: 'Kognitif' },
        { value: 'Komunikasi', label: 'Komunikasi' },
        { value: 'Kulinari', label: 'Kulinari' },
        // { value: 'Kulwap', label: 'Kulwap' },
        { value: 'Motorik', label: 'Motorik' },
        { value: 'Olahraga', label: 'Olahraga' },
        // { value: 'Pesta', label: 'Pesta' },
        { value: 'Parenting', label: 'Parenting' },
        { value: 'Perawatan', label: 'Perawatan' },
        { value: 'Pertunjukan', label: 'Pertunjukan' },
        { value: 'Playdate', label: 'Playdate' },
        { value: 'Rekreasi', label: 'Rekreasi' },
        // { value: 'Rutin', label: 'Rutin' },
        { value: 'Sains', label: 'Sains' },
        { value: 'Seni Lukis', label: 'Seni Lukis' },
        { value: 'Seni Musik', label: 'Seni Musik' },
        { value: 'Seni Drama', label: 'Seni Drama' },
        { value: 'Seni Tari', label: 'Seni Tari' },
        { value: 'Sensorik', label: 'Sensorik' },
        { value: 'Teknologi', label: 'Teknologi' },
        { value: 'Lainnya', label: 'Lainnya' },
    ]);

    useEffect(() => {
        onGetDataPayment();
        onGetDataPromo(params.id);
    }, []);

    const onGetDataPromo = async (id) => {
        const resultData = await serviceCodePromoPremium.getCodePromoById(id);
        if (resultData.isSuccess) {
            const result = resultData.response.data;
            if (result.included_user && result.included_user.length > 0) {
                const resultIncludeUser =
                    await serviceCodePromoMarkerplace.getDetailUsers(
                        result.included_user,
                    );
                result.included_users =
                    resultIncludeUser.response.payload &&
                    resultIncludeUser.response.payload.length > 0 &&
                    resultIncludeUser.response.payload.map((item) => ({
                        label: item.name,
                        value: item.user_id,
                    }));
            }
            if (result.excluded_user && result.excluded_user.length > 0) {
                const resultExcludeUser =
                    await serviceCodePromoMarkerplace.getDetailUsers(
                        result.excluded_user,
                    );
                result.excluded_users =
                    resultExcludeUser.response.payload &&
                    resultExcludeUser.response.payload.length > 0 &&
                    resultExcludeUser.response.payload.map((item) => ({
                        label: item.name,
                        value: item.user_id,
                    }));
            }
            result.expired_date = moment(result.expired_date);
            result.start_date = moment(result.start_date);
            result.text_for_button = result.button_text;
            result.url_for_button = result.button_url;
            setTypePromo(result.type === 'Percentage' ? '1' : '2');
            result.type = result.type === 'Percentage' ? '1' : '2';
            form.setFieldsValue(result);
        }
    };

    const onFinish = async (value) => {
        const resultValue = {
            id: form.getFieldValue('id'),
            name: value.name,
            type: value.type == 1 ? 'Percentage' : 'Fixed',
            amount: value.amount,
            maximum_discount: value.maximum_discount,
            title: value.title,
            description: value.description,
            image: value.image,
            image_two: value.image_two,
            minimum_cart_amount: value.minimal_amount,
            maximum_cart_amount: value.maximal_amount,
            is_combine: true,
            included_payment: value.included_payment || [],
            excluded_payment: value.excluded_payment || [],
            included_cc_bin: value.included_cc || [],
            excluded_cc_bin: value.excluded_cc || [],
            included_user:
                (value.included_users?.length > 0 &&
                    value.included_users.map((item) => Number(item.value))) ||
                [],
            excluded_user:
                (value.excluded_users?.length > 0 &&
                    value.excluded_users.map((item) => Number(item.value))) ||
                [],
            is_premium_user: value.premium_user == 'N' ? false : true,
            coupon_total: value.coupon_total,
            coupon_exist: value.coupon_exist,
            coupon_user: value.coupon_user,
            expired_date: moment(value.expired_date).toISOString(),
            start_date: moment(value.start_date).toISOString(),
            position: value.position,
            button_text: value.text_for_button,
            button_url: value.url_for_button,
            is_shown: value.show_code,
        };

        const resultSubmit = await serviceCodePromoPremium.updatePromPremium(
            resultValue,
        );

        if (resultSubmit.isSuccess) {
            notifySuccess('Success update code promo');
            history.push('/codepromo/premium/list');
        } else {
            notifyError(resultSubmit.message);
        }
    };

    const onGetDataPayment = async () => {
        const result = await serviceCodePromoMarkerplace.getPayment();
        if (result.isSuccess) {
            const resultPayment = result.response.payload.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            const resultCC = result.response.payload
                .filter((item) => item.code.includes('creditcard'))
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            setDataPayment(resultPayment);
            setDataCC(resultCC);
        }
    };

    const [helperShowCode, setHelperShowCode] = useState(
        'Kode promo tersembunyi',
    );

    const descriptionShowCode = [
        {
            value: 0,
            description: 'Kode promo tersembunyi',
        },
        {
            value: 1,
            description: 'info detail promo akan ditampilkan di halaman /promo',
        },
        {
            value: 3,
            description:
                'logika khusus / Kode promo ini hanya akan tersedia untuk pengguna yang baru terdaftar selama 7 hari',
        },
        {
            value: 5,
            description:
                'detail promo ini akan diduplikasi dengan kode promo acak saat pengguna berlangganan pengguna premium bulanan',
        },
        {
            value: 6,
            description:
                'detail promo ini akan diduplikasi dengan kode promo acak saat pengguna berlangganan pengguna premium tahunan',
        },
    ];

    const getExtraShowCode = (value) => {
        setHelperShowCode(
            descriptionShowCode.find((item) => item.value === value)
                ?.description,
        );
    };

    return (
        <CodePromoMarketplaceAddStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Edit Promo Premium"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <h3 className="title">Code Promo</h3>
                <Card style={{ borderRadius: '24px', marginBottom: '42px' }}>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="name"
                                label="Code Voucher"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Nama Promo"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="type"
                                label="Tipe Promo"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                initialValue="1"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pilih Tipe Promo',
                                    },
                                ]}>
                                <Select
                                    defaultValue={'1'}
                                    onChange={(value) => setTypePromo(value)}
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'1'}>
                                        Diskon Presentase
                                    </Option>
                                    <Option key={2} value={'2'}>
                                        Diskon Tetap
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                extra={
                                    typePromo == '1'
                                        ? 'Masukkan angka dalam diskon %'
                                        : 'Masukkan angka dalam rupiah'
                                }
                                name="amount"
                                label="Jumlah Diskon"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                {typePromo == '1' ? (
                                    <InputNumber
                                        addonAfter="%"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    <InputNumber
                                        style={{
                                            width: '100%',
                                        }}
                                        formatter={(value) =>
                                            `${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                '.',
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/Rp\s?|(\.*)/g, '')
                                        }
                                        addonBefore="Rp. "
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="maximum_discount"
                                label="Maksimal Diskon"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{
                                        width: '100%',
                                    }}
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Rp\s?|(\.*)/g, '')
                                    }
                                    addonBefore="Rp. "
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="minimal_amount"
                                label="Minimal Amount"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{
                                        width: '100%',
                                    }}
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Rp\s?|(\.*)/g, '')
                                    }
                                    addonBefore="Rp. "
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="maximal_amount"
                                label="Maximal Amount"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{
                                        width: '100%',
                                    }}
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Rp\s?|(\.*)/g, '')
                                    }
                                    addonBefore="Rp. "
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                extra="Judul kode promo yang ditampilkan dibanner"
                                name="title"
                                label="Judul"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Judul wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan Judul Code Promo"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="description"
                                label="Deskripsi"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Deskripsi wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan Deskripsi promo"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="image"
                                label="Upload Image Main Poster"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan URL Image Poster"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="image_two"
                                label="Upload Banner Image in Promo Details"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan URL Image Poster"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <h3 className="title">Payment & User</h3>
                <Card style={{ borderRadius: '24px', marginBottom: '42px' }}>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="included_payment"
                                label="Include Payment"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include Payment wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataPayment}
                                    style={{ width: '100%' }}
                                    placeholder="Select Payment"></Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="excluded_payment"
                                label="Exclude Payment"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude Payment wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataPayment}
                                    style={{ width: '100%' }}
                                    placeholder="Select Payment"></Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="included_cc"
                                label="Include CC"
                                extra="Masukkan Include CC format [id_cc]"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include CC wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataCC}
                                    style={{ width: '100%' }}
                                    placeholder="Select CC"></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                extra="Masukkan Exclude CC format [id_cc]"
                                name="excluded_cc"
                                label="Exclude CC"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude CC wajib diisi',
                                //     },
                                // ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={dataCC}
                                    style={{ width: '100%' }}
                                    placeholder="Select CC"></Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="included_users"
                                label="Include Users"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Include Users wajib diisi',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'included_users',
                                            value,
                                        );
                                    }}
                                    value={form.getFieldValue('included_users')}
                                    placeholder="Select include users"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'users.email': value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoPremium.getUser(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.name,
                                                        value: item.user_id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="excluded_users"
                                label="Exclude Users"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Exclude Users wajib diisi',
                                //     },
                                // ]}
                            >
                                <DebounceSelectComponent
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            'excluded_users',
                                            value,
                                        );
                                    }}
                                    value={form.getFieldValue('excluded_users')}
                                    placeholder="Select exclude users"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filters: {
                                                'users.email': value,
                                            },
                                        };
                                        const result =
                                            await serviceCodePromoPremium.getUser(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        label: item.name,
                                                        value: item.user_id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="premium_user"
                                label="Premium User"
                                style={{ marginBottom: 0 }}
                                initialValue={'N'}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Select
                                    defaultValue={'N'}
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'Y'}>
                                        Ya
                                    </Option>
                                    <Option key={2} value={'N'}>
                                        Tidak
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="combine"
                                label="Combine"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    disabled
                                    defaultValue={'Y'}
                                    size="large"
                                    placeholder="Masukkan Include CC"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <h3 className="title">Coupon Manage</h3>
                <Card style={{ borderRadius: '24px', marginBottom: '42px' }}>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="coupon_total"
                                label="Coupon Total"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Coupon Total wajib diisi',
                                    },
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan total coupon"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="coupon_user"
                                label="Coupon User"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Coupon User wajib diisi',
                                    },
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan user coupon"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="coupon_exist"
                                label="Coupon Exist"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Coupon Exist wajib diisi',
                                    },
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan coupon exist"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="start_date"
                                label="Start Date Time"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Start Date wajib diisi',
                                    },
                                ]}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    disabledDate={(current) => {
                                        // Can not select days before today and today
                                        return (
                                            current &&
                                            current <
                                                moment().subtract(1, 'day')
                                        );
                                    }}
                                    showTime={{
                                        defaultValue: moment(
                                            '00:00:00',
                                            'HH:mm:ss',
                                        ),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="expired_date"
                                label="Expired Date Time"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Expired Date wajib diisi',
                                    },
                                ]}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    disabledDate={(current) => {
                                        // Can not select days before today and today
                                        return (
                                            current &&
                                            current <
                                                moment().subtract(1, 'day')
                                        );
                                    }}
                                    showTime={{
                                        defaultValue: moment(
                                            '00:00:00',
                                            'HH:mm:ss',
                                        ),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="position"
                                label="Position"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Masukkan position"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32} style={{ marginBottom: '24px' }}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="text_for_button"
                                label="Text for Button"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan text for button"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="url_for_button"
                                label="URL for Button"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan url for button"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                name="show_code"
                                label="Show Code"
                                style={{ marginBottom: 0 }}
                                initialValue={0}
                                extra={helperShowCode}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Select
                                    onChange={(value) =>
                                        getExtraShowCode(value)
                                    }
                                    defaultValue={0}
                                    style={{ width: '100%' }}>
                                    <Option key={0} value={0}>
                                        0
                                    </Option>
                                    <Option key={1} value={1}>
                                        1
                                    </Option>
                                    <Option key={3} value={3}>
                                        3
                                    </Option>
                                    <Option key={5} value={5}>
                                        5
                                    </Option>
                                    <Option key={6} value={6}>
                                        6
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Form.Item name="submit">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={isLoading}
                            style={{ marginRight: 10, width: 200 }}
                            disabled={isLoading ? true : false}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                history.goBack();
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </CodePromoMarketplaceAddStyle>
    );
}
