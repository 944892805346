import React, { useState, useEffect } from 'react';
import { Col, Input, message, Modal, PageHeader, Row, Form, Button, Spin, Upload } from 'antd';

import { ModalPictureStyle, PictureGalleryStyle } from './index.style';
import { useHistory } from 'react-router-dom';
import articleService from '../Article/index.service';
import { UploadOutlined } from '@ant-design/icons';
import PictureForm from './PictureForm';
import galleryService from './index.service';
import UpdatePicture from './UpdatePicture';

const PictureGallery = () => {
  const history = useHistory()
  const [imageContent, setImageContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureModal, setPictureModal] = useState(false);
  const [addPictureModal, setAddPictureModal] = useState(false)
  const [pictureDetail, setPictureDetail] = useState()
  const [addPictureLoading, setAddPictureLoading] = useState(false)

  const [picture, setPicture] = useState()

  const [form] = Form.useForm()

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "/picture-gallery",
      breadcrumbName: "Picture Gallery",
    },
  ];

  const getImage = async () => {
    setIsLoading(true)
    const result = await galleryService.getPicture()

    if (result.isSuccess) {
      setImageContent(result.response.payload)
    } else {
      message.error('Gagal Mendapatkan Gambar')
    }

    setIsLoading(false)
  }

  const addPicture = async (data) => {
    setAddPictureLoading(true)
    const result = await galleryService.addPicture(data)

    if (result.isSuccess) {
      setPicture();
      form.resetFields();
      setAddPictureModal(false);
      getImage();
    } else {
      message.error('Gagal menambahkan Gambar');
    }

    setAddPictureLoading(false);

  }

  useEffect(() => {
    getImage()
  }, [])


  return (
    <PictureGalleryStyle>
      <PageHeader
        onBack={() => history.goBack()}
        className="site-page-header"
        title="Picture Gallery"
        breadcrumb={{ routes }}
        style={{ paddingLeft: 0 }}
      />
      <Spin
        spinning={isLoading}
        tip='Loading Image...'
      >
        <div className="add-media">
          <Button onClick={() => setAddPictureModal(true)} icon={<UploadOutlined />}>Add Image</Button>
        </div>
        <div className="image-container">
          {
            imageContent && imageContent.length > 0 &&
            imageContent.map(item => {
              return (
                <div className="image-content" onClick={() => {
                  setPictureModal(true)
                  setPictureDetail(item)
                }}>
                  <img src={item.url} alt="" />
                </div>
              )
            })
          }
        </div>
      </Spin>
      <Modal
        visible={addPictureModal}
        width={800}
        onCancel={() => {
          form.resetFields()
          setAddPictureModal(false)
          setPicture()
        }}
        footer={[
          <Button
            onClick={() => {
              form.resetFields()
              setAddPictureModal(false)
              setPicture()
            }}
          >
            Cancel
          </Button>,
          <Button
            type='primary'
            loading={addPictureLoading}
            onClick={() => {
              form.validateFields().then(value => {
                addPicture(value)
              })
            }}
          >
            Simpan
          </Button>
        ]}
      >
        <PictureForm form={form} picture={picture} setPicture={setPicture} />
      </Modal>
      <UpdatePicture
        active={pictureModal}
        pictureDetail={pictureDetail}
        getImage={getImage}
        setPictureDetail={setPictureDetail}
        setActive={setPictureModal}
      />

      {/* <Modal
        visible={pictureModal}
        onCancel={() => {
          setPictureModal(false)
          setPictureDetail()
        }}
        width={1000}
        footer={[
          <Button
            onClick={() => {
              setPictureModal(false)
              setPictureDetail()
            }}
          >
            Cancel
          </Button>
        ]}
      >
        <ModalPictureStyle>
          <div className="picture-container">
            <img src={pictureDetail?.url} alt="" />
          </div>
          <div className="picture-detail">
            <Row>
              <Col span={24}>
                <Form
                  layout='vertical'
                >
                  <Form.Item
                    label={"Image URL"}
                  >
                    <Input value={pictureDetail?.url} disabled />
                    <div className="copy-button">
                      <Button type='link' onClick={() => {
                        navigator.clipboard.writeText(pictureDetail?.url)
                        message.success('Text Copied')
                      }}>
                        Copy link
                      </Button>
                    </div>
                  </Form.Item>

                  <Form.Item
                    label={"Image Name"}
                  >
                    <Input value={pictureDetail?.name} disabled />
                  </Form.Item>
                  <Form.Item
                    label={"Origin Name"}
                  >
                    <Input value={pictureDetail?.origin_name} disabled />
                  </Form.Item>
                  <Form.Item
                    label={"Image Description"}
                  >
                    <Input.TextArea value={pictureDetail?.description} disabled />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </ModalPictureStyle>
      </Modal> */}
    </PictureGalleryStyle>
  );
};

export default PictureGallery;
