import serviceApi from '../../../../services/index';

const serviceProductGift = {
    addProductGift: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/gift/products`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    postSlug: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/products/slug/exist`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    
    updateProductGift: async (id, data) => {
        return await serviceApi({
            method: 'PUT',
            url: `v1/gift/products/${id}`,
            noVersion: true,
            appType: 1,
            token: true,
            data: data,
            formData: true,
        });
    },
    getMerchantList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/merchants/names`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },

};

export default serviceProductGift;
