import serviceApi from '../../../services/index';

const serviceKiddofestGallery = {
    getListGallery: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/gallery`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postDataGallery: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/kiddofest/gallery`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updateDataGallery: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kiddofest/gallery`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getdetailGallery: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/gallery/${id}`,
            version: 'v1',
            token: true,
        });
    },
    deleteGallery: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/kiddofest/gallery/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceKiddofestGallery;
