import React from 'react';
import { Form, Button, Select } from 'antd';
import { useHistory } from 'react-router-dom';

const FormPilihan = (props) => {
	const history = useHistory();
	const { Option } = Select;

	const inputSelect = [];
	for (let i = 1; i <= 6; i++) {
		inputSelect.push(
			<Form.Item
				name={`article${i}`}
				label={`Artikel ${i}`}
			>
				<Select
					allowClear
					showSearch
					placeholder="Pilih Artikel"
					style={{ width: '100%' }}
				>
					<Option>tes</Option>
				</Select>
			</Form.Item>
		)
	}

	return (
		<div>
			<Form layout="vertical">
				{inputSelect}
				<Button htmlType="submit" type="primary" style={{ marginRight: 10 }}>Update</Button>
				<Button onClick={() => history.goBack()}>Batal</Button>
			</Form>
		</div>
	);
};

export default FormPilihan;
