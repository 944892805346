import serviceApi from '../../../services';

const serviceKiddofestRegistrationBooth = {
    getListRegistrationBooth: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/boot`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
};

export default serviceKiddofestRegistrationBooth;
