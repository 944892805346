import serviceApi from '../../../services/index';

const serviceCampaignBanner = {
    getListBanner: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/campaign/banner`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    addBanner: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/campaign/banner`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updateBanner: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/campaign/banner`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getCampaignBanner: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/campaign/banner/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceCampaignBanner;
