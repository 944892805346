import serviceApi from '../../../services';

const serviceLMSTestimoni = {
    getListTestimoni: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/lms/testimoni`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postTestimoniData: async (bodyData) => {
        return await serviceApi({
            method: 'POST',
            url: `/lms/testimoni`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    putTestimoniData: async (bodyData) => {
        return await serviceApi({
            method: 'PUT',
            url: `/lms/testimoni`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    getDetailTestimoni: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/lms/testimoni/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceLMSTestimoni;
