import React from 'react';
import { ThemeProvider } from '@emotion/react';
import token from './token';

const ThemeConfiguration = ({ children, themeOptions = 'default' }) => {
    return (
        <ThemeProvider theme={token[themeOptions]}>{children}</ThemeProvider>
    );
};

export default ThemeConfiguration;
