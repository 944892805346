import serviceApi from '../../../services';

const serviceLMSQuiz = {
    getListQuiz: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/quiz`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getProduct: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/class`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postQuizData: async (bodyData) => {
        return await serviceApi({
            method: 'POST',
            url: `/class/quiz`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    updateQuizData: async (bodyData) => {
        return await serviceApi({
            method: 'PUT',
            url: `/class/quiz`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    getDetailQuizData: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/quiz/${id}`,
            version: 'v1',
            token: true,
        });
    },
    getDetailQuiz: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/quiz/${id}`,
            version: 'v1',
            token: true,
        });
    },
    onGetDetailClass: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/class/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceLMSQuiz;
