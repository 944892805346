import styled from '@emotion/styled';

const OrderGiftStyle = styled.div`
    display: block;
    width: 100%;
    table {
        box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2),
            0 6px 4px 0 rgba(0, 0, 0, 0.19);
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        Button {
            margin-bottom: 10px;
        }
    }

    .pagination {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export default OrderGiftStyle;
