import { RESET, SET_CONFIG, SET_USER } from './types';

export const INITIAL_STATE = {
    user: null,
    config: {
        drawer: false,
    },
};

export function appReducer(state = INITIAL_STATE, action) {
    let newState = { ...state };
    const { type, payload } = action;
    switch (type) {
        case SET_USER:
            newState.user = payload;
            return newState;
        case SET_CONFIG:
            newState.config[payload.key] = payload.value;
            return newState;
        case RESET:
            return INITIAL_STATE;
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}
