import serviceApi from '../../../services';

const serviceLMSParticipant = {
    getListParticipant: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/class/permission`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
};

export default serviceLMSParticipant;
