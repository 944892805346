import axios from 'axios';
import localStorages from '../utils/localStorages';
import qs from 'qs';

const getAuthToken = (options) => {
    let result = {};
    let dataUser = localStorages().get('APP_STATE');
    if (options.token && dataUser && dataUser.user.token) {
        let user = { ...dataUser };
        result = {
            Authorization: 'Bearer ' + user.user.token,
        };
    }

    result['Content-Type'] = options.formData
        ? 'multipart/form-data'
        : 'application/json';
    return result;
};

const collectResponse = async (response, options, status = 'FAILED') => {
    if (status === 'FAILED' && response.status === 401) {
        const initalState = {
            user: null,
            config: {
                drawer: false,
            },
        };
        localStorages().save('APP_STATE', JSON.stringify(initalState));
        window.history.pushState('/');
    }

    return {
        response: response?.data || false,
        status: status,
        type: response?.status,
        isSuccess: status === 'SUCCESS' || false,
        isError: status === 'FAILED' || false,
        headers: {
            count:
                response?.headers['x-total-result'] &&
                Number(response?.headers['x-total-result']),
        },
        message: options.message,
    };
};

const serviceApi = async (options) => {
    let baseUrl =
        options.appType === 2
            ? process.env.REACT_APP_API_URL2
            : process.env.REACT_APP_API_URL1;
    let axiosConfig = {
        baseURL: baseUrl,
        timeout: 20000,
    };

    if (options.noVersion) {
        options.url = `${options.url}`;
    } else {
        options.url = `/${options.version || 'v1'}${options.url}`;
    }

    axiosConfig.headers = getAuthToken(options);

    options.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    };

    const instance = axios.create(axiosConfig);
    return await instance(options)
        .then((res) => {
            return collectResponse(res, options, 'SUCCESS');
        })
        .catch((err) => {
            return collectResponse(err.response, options, 'FAILED');
        });
};

export default serviceApi;
