import serviceApi from '../../../services/index';

const serviceKiddofestEdufair = {
    getListEdufair: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/edufair`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    addEdufair: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/kiddofest/edufair`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getDetailEdufair: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/edufair/${id}`,
            version: 'v1',
            token: true,
        });
    },
    updateEdufair: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kiddofest/edufair`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
};

export default serviceKiddofestEdufair;
