import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import serviceMerchantUpdate from './index.service';
import UpdateMerchantStyle from './index.style';
import { PageHeader, Modal } from 'antd';
import FormMerchant from '../../../components/Merchant/Form/index';

function MerchantCreate(props) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(null);

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Merchant',
        },
        {
            path: 'merchant-gallery',
            breadcrumbName: 'Update',
        },
    ];

    useEffect(() => {
        getMerchantDetail(props.match.params.id);
    }, []);

    const handleUpdateMerchant = async (data) => {
        setIsLoading(true);
        const result = await serviceMerchantUpdate.updateMerchant(data);
        if (result.isSuccess) {
            modalSuccess();
            setIsLoading(false);
        } else {
            Modal.error({
                title: 'Error',
                content: result.response.message,
            });
            setIsLoading(false);
        }
    };

    const getMerchantDetail = async (id) => {
        const result = await serviceMerchantUpdate.getMerchantDetail(id);
        if (result.isSuccess) {
            setInitialValues(result.response.payload);
        } else {
            Modal.error('Upsss, gagal mendapatkan detail merchant!');
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil mengubah data merchant',
            onOk: () => {
                history.push('/merchant');
            },
        });
    };

    return (
        <UpdateMerchantStyle>
            <PageHeader
                onBack={() => history.push('/merchant')}
                className="site-page-header"
                title="Merchant Update"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <div>
                <FormMerchant
                    handleSubmit={handleUpdateMerchant}
                    initVal={initialValues}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isCreate={false}
                />
            </div>
        </UpdateMerchantStyle>
    );
}

export default MerchantCreate;
