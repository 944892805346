import serviceApi from '../../../services/index';

const serviceOrderPpob = {
    getOrderPpob: async (params, data) => {
        return await serviceApi({
            method: 'GET',
            url: `/ppob/orders`,
            version: 'v1',
            token: true,
            params: params,
            data: data,
        });
    },
    getDataPpobDetail: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/ppob/orders/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceOrderPpob;
