import { useHistory } from 'react-router-dom';
import { PageHeader, message, Modal } from 'antd';
import React, { useState } from 'react';
import FormMerchant from '../../../components/Merchant/Form';
import MerchantFormStyle from '../../../components/Merchant/Form/index.style';
import serviceFormMerchant from './index.service';

const MerchantCreate = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Merchant',
        },
        {
            path: 'merchant-gallery',
            breadcrumbName: 'Create',
        },
    ];

    const handleCreateMerchant = async (data) => {
        setIsLoading(true);
        const result = await serviceFormMerchant.postMerchant(data);
        if (result.isSuccess) {
            modalSuccess();
            setIsLoading(false);
        } else {
            if (result.type == 409) {
                Modal.error({
                    title: 'Error',
                    content: result.response.message,
                });
                setIsLoading(false);
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Gagal menambah data Merchant!',
                });
                setIsLoading(false);
            }
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil menambah data Merchant',
            onOk: () => {
                history.push('/merchant');
            },
        });
    };

    return (
        <MerchantFormStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Merchant Create"
                breadcrumb={{ routes }}
                style={{ paddingBottom: 0 }}
            />
            <div className="content">
                <FormMerchant
                    handleSubmit={handleCreateMerchant}
                    initVal={null}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            </div>
        </MerchantFormStyle>
    );
};

export default MerchantCreate;
