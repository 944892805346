import React from 'react';
import SettingsStyle from './index.style';

const index = () => {
  return (
    <SettingsStyle>
      <div>Settings</div>
    </SettingsStyle>
  );
};

export default index;
