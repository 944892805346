import styled from '@emotion/styled';

const LoginStyle = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        width: 515px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -28px;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding-top: 60px;
        z-index: 0;
    }
`;

export default LoginStyle;
