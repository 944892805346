import { PageHeader, message, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import serviceProductCreate from './index.service';
import CreateProductStyle from './index.style';
import FormProduct from '../../../components/Product/FormProduct';

function ProductCreate() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const routes = [
        {
            path: '',
            breadcrumbName: 'Product',
        },
        {
            path: '',
            breadcrumbName: 'Create',
        },
    ];

    const handleCreateProduct = async (data) => {
        setIsLoading(true);
        const result = await serviceProductCreate.postProduct(data);
        if (result.isSuccess) {
            modalSuccess();
            setIsLoading(false);
        } else {
            Modal.error('Gagal menambah data product!');
            setIsLoading(false);
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil menambah data product',
            onOk: () => {
                history.push('/product');
            },
        });
    };

    return (
        <CreateProductStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Product Create"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <div>
                <FormProduct
                    handleSubmit={handleCreateProduct}
                    initVal={null}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isCreate={true}
                    isDuplicate={false}
                />
            </div>
        </CreateProductStyle>
    );
}

export default ProductCreate;
