import styled from '@emotion/styled'

export const PictureGalleryStyle = styled.div`
  hr {
    height: 90px;
    border-bottom: 2px solid #000000;
    width: 100%;
  }

  .image-container {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100px;
    gap: 1rem;
  }

  .image-content {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
    border: 1px solid #cfcfcf;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s ease-in;

    &:hover {
      transform: scale(1.5);
    }

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
`

export const ModalAddPictureStyle = styled.div`
  .avatar-uploader > .ant-upload, img {
    width: 100%;
    height: 220px;
    object-fit: contain;
  }

  .copy-button {
     width: 100%;
      button {
        float: right;
      }
    }
`

export const ModalPictureStyle = styled.div`
  display: flex;
  gap: 1.5rem;

  .picture-container {
    img {
      width: 500px  ;
      height: 500px;
      object-fit: contain;
    }
  }

  .picture-detail {
    width: 100%;

    .copy-button {
     width: 100%;
      button {
        float: right;
      }
    }
  }
`
