import serviceApi from '../../../services';

const servicePPOBProduct = {
    listProduct: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/ppob/product`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    addProduct: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/ppob/product`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updateProduct: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/ppob/product`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getProductOuter: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/ppob/get-products`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getDetailPpob: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/ppob/product/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default servicePPOBProduct;
