import { createContext, useContext, useEffect, useReducer } from 'react';
import { INITIAL_STATE, appReducer } from './app.reducer';
import localStorages from '../utils/localStorages';

const AppStateContext = createContext();
const AppDispatchContext = createContext();

function useApp() {
    const state = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);
    return [state, dispatch];
}

function getInitialState() {
    if (localStorages().get('APP_STATE')) {
        return localStorages().get('APP_STATE');
    }
    return INITIAL_STATE;
}

export default function AppProvider(props) {
    const [state, dispatch] = useReducer(appReducer, getInitialState());
    useEffect(() => {
        localStorages().save('APP_STATE', JSON.stringify(state));
    }, [state]);

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {props.children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
}

export { useApp, AppStateContext, AppDispatchContext };
