import serviceApi from '../../services';

const serviceUsers = {
    getUsersList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/users`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getUserDetail: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/users/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceUsers;
