import React, { useState } from 'react';
import UploadFlatStyle from './index.style';
import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

const UploadFlat = (props) => {
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading...' }, 1000);
            props.onChange(info.file.originFileObj);
            return;
        }
    };

    return (
        <UploadFlatStyle>
            <Upload
                accept="image/*"
                showUploadList={false}
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChange}>
                <Button type="primary" size="middle">
                    Masukkan Gambar
                </Button>
            </Upload>
            <div className="container-image">
                {!!props.value && (
                    <>
                        <div className="remove-img">
                            <CloseOutlined
                                onClick={() => {
                                    props.onChange('');
                                }}
                            />
                        </div>
                        <img
                            src={
                                typeof props.value === 'object'
                                    ? URL.createObjectURL(props.value)
                                    : props.value
                            }
                        />
                    </>
                )}
            </div>
        </UploadFlatStyle>
    );
};

export default UploadFlat;
