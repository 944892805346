import React, { useState } from "react";
import { Modal, PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import ArticleCreateStyle from "./index.style";
import ArticleForm from "../../../components/Artikel/ArticleForm";
import articleService from "../index.service";

const ArticleCreate = () => {
  const history = useHistory();
  const [loading, setIsLoading] = useState(false)

  const routes = [
    {
      path: "/",
      breadcrumbName: "Artikel Management",
    },
    {
      path: "/article",
      breadcrumbName: "Artikel",
    },
    {
      path: "/article/create",
      breadcrumbName: "Create",
    },
  ];

  const createArticle = async (data) => {
    setIsLoading(true);
    const res = await articleService.createArticle(data)
    if (res.isSuccess) {
      modalSuccess()
    } else {
      Modal.error('Gagal Menambahkan artikel')
    }
    setIsLoading(false)
  }

  const modalSuccess = () => {
    Modal.success({
      content: "Berhasil menambahkan artikel",
      onOk: () => {
        history.push('/article')
      }
    })
  }

  // const [form] = Form.useForm();
  // const { Option } = Select;

  // const [categorySelected, setCategorySelected] = useState(0);
  // const [showInput, setShowInput] = useState({
  //   katgori: false,
  //   penulis: false,
  // });
  // const [input, setInput] = useState({
  //   kategori: "",
  //   penulis: "",
  // });

  // const children = [];
  // for (let i = 10; i < 36; i++) {
  //   children.push(
  //     <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
  //   );
  // }

  // const handleInputAdd = (type) => {
  //   switch (type) {
  //     case "kategori":
  //       setCategoryList([
  //         ...categoryList,
  //         {
  //           value: input.kategori,
  //           text: input.kategori,
  //         },
  //       ]);
  //       setShowInput({ ...showInput, kategori: false });
  //       form.setFieldsValue({
  //         newcat: "",
  //         category: [...categorySelected, input.kategori],
  //       });
  //       setCategorySelected([...categorySelected, input.kategori]);
  //       break;
  //     case "penulis":
  //       setListPenulis([
  //         ...listPenulis,
  //         {
  //           value: input.penulis,
  //           text: input.penulis,
  //         },
  //       ]);
  //       setShowInput({ ...showInput, penulis: false });
  //       form.setFieldsValue({ newpenulis: "", penulis: input.penulis });
  //       break;
  //     default:
  //       return false;
  //   }

  return (
    <ArticleCreateStyle>
      <PageHeader
        onBack={() => history.goBack()}
        className="site-page-header"
        title="Artikel Create"
        breadcrumb={{ routes }}
        style={{ paddingLeft: 0 }}
      />
      <div style={{ minHeight: "60vh" }}>
        <ArticleForm
          isLoading={loading}
          onSubmit={createArticle}
        />
      </div>

    </ArticleCreateStyle>
  );
};



export default ArticleCreate;


