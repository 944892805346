import serviceApi from '../../services';

const serviceInbox = {
    getListInbox: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/lms/contact`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
};

export default serviceInbox;
