import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, message, Row, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import galleryService from '../index.service'
import { ModalAddPictureStyle } from '../index.style'

const PictureForm = ({ form, initValue, picture, setPicture }) => {
  const [loadingUploadPhoto, setLoadingUploadPhoto] = useState(false)
  const [mainImage, setMainImage] = useState('')

  const handleUploadChange = info => {
    if (info.file.status === 'uploading') {
      message.loading({ content: 'Uploading', key: 'updatable' })
    }
  }

  const uploadButton = () => {
    return (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )
  }

  useEffect(() => {
    if (initValue) {
      setMainImage(initValue.url)
      form.setFieldsValue({
        title: initValue?.title ?? '',
        caption: initValue?.caption ?? '',
        alternative_text: initValue?.alternative_text ?? '',
        description: initValue?.description ?? '',
        url: initValue?.url ?? ''
      })

    }
  }, [initValue])

  const uploadPic = async ({ file }) => {
    setLoadingUploadPhoto(true)
    const formData = new FormData();
    formData.append('file', file);
    const res = await galleryService.uploadPicture(formData);
    if (res.isSuccess) {
      let result = res.response.payload.url;
      setPicture(result)
      form.setFieldsValue({
        url: result
      })
    } else {
      message.error("Gagal Mengupload foto")
    }
    setLoadingUploadPhoto(false)
  }

  return (
    <ModalAddPictureStyle>
      <Form
        layout='vertical'
        form={form}
      >
        <Row
          gutter={16}
        >
          <Col
            span={8}
          >
            <Form.Item
              label="Picture"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Foto wajib diisi"
                }
              ]}
            >
              <Upload
                accept="image/*"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadPic}
                onChange={handleUploadChange}
                disabled={!!initValue?.url}
              >
                {!!initValue?.url ?
                  <img src={mainImage} alt="uploaded pict" />
                  :
                  picture ?
                    <img src={picture} alt="uploaded pict" />
                    :
                    uploadButton()
                }
              </Upload>
            </Form.Item>
          </Col>
          <Col
            span={12}
          >
            {
              initValue &&
              <Form.Item
                label="Photo Url"
              >
                <Input value={initValue.url} disabled />
                <div className="copy-button">
                  <Button type='link' onClick={() => {
                    navigator.clipboard.writeText(initValue.url)
                    message.success('Text Copied')
                  }}>
                    Copy link
                  </Button>
                </div>
              </Form.Item>
            }
            <Form.Item
              label='Title'
              name='title'
              rules={[
                {
                  required: true,
                  message: "Title Gambar wajib diisi"
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Caption'
              name='caption'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Text Alternative'
              name='alternative_text'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Description'
              name='description'
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalAddPictureStyle>
  )
}

export default PictureForm
