import serviceApi from '../../../services';

const serviceOrderDetails = {
  getDataDetail: async (id) => {
    return await serviceApi({
      method: 'GET',
      url: `/orders/${id}`,
      version: 'v1',
      token: true,
    });
  },
};

export default serviceOrderDetails;
