import styled from '@emotion/styled';

const LMSQuizCreateStyle = styled.div`
    display: block;
`;

export default LMSQuizCreateStyle;

export const WysiwygStyle = styled.div`
    .ql-editor {
        min-height: 18em;
    }
`;
