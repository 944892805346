import serviceApi from "../../services";

const articleService = {
  getArticleList: async (params) => {
    return await serviceApi({
      method: "GET",
      url: `v1/articles`,
      noVersion: true,
      appType: 1,
      token: true,
      params: params,
    })
  },
  getArticleDetail: async (id) => {
    return await serviceApi({
      method: "GET",
      url: `v1/articles/${id}`,
      noVersion: true,
      appType: 1,
      token: true
    })
  },
  getArticleCategory: async () => {
    return await serviceApi({
      method: "GET",
      url: 'v1/articles/categories',
      noVersion: true,
      appType: 1,
      token: true,
    })
  },
  getArticleTags: async () => {
    return await serviceApi({
      method: "GET",
      url: 'v1/articles/tags',
      noVersion: true,
      appType: 1,
      token: true,
    })
  },
  getArticleAuthor: async () => {
    return await serviceApi({
      method: "GET",
      url: 'v1/articles/authors',
      noVersion: true,
      appType: 1,
      token: true,
    })
  },
  getArticlePhotoContent: async () => {
    return await serviceApi({
      method: "GET",
      url: 'v1/articles/images',
      noVersion: true,
      appType: 1,
      token: true,
    })
  },
  createArticle: async (data) => {
    return await serviceApi({
      method: "POST",
      url: 'v1/articles',
      noVersion: true,
      appType: 1,
      token: true,
      params: null,
      data: data,
    })
  },
  postArticlePhoto: async (data) => {
    return await serviceApi({
      method: "POST",
      url: 'v1/articles/images',
      noVersion: true,
      appType: 1,
      token: true,
      data: data,
      formData: true,
    })
  },
  updateArticle: async (data) => {
    return await serviceApi({
      method: "PUT",
      url: 'v1/articles',
      noVersion: true,
      appType: 1,
      token: true,
      params: null,
      data: data,
    })
  },
  deleteArticle: async (id) => {
    return await serviceApi({
      method: "DELETE",
      url: `v1/articles/${id}`,
      noVersion: true,
      appType: 1,
      token: true,
    })
  }
}

export default articleService;
