import React, { useState } from "react";
import { message, Upload } from "antd";
import UploadImageStyle from "./index.style";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const UploadImage = ({ fieldName, fileList, setFileList }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = info => {
        let file = info.file;
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                file.thumbUrl = e.target.result;
                if (file.type === "image/jpeg" || file.type === "image/png" && file.size / 1024 / 1024 < 2) {
                    setImageUrl(file.thumbUrl)
                    setFileList({
                        ...fileList,
                        [fieldName]: file
                    })
                }
            };
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Anda hanya dapat mengupload file JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Ukuran file harus kurang dari 2MB');
        }
        if (file === '') {
            message.error('Wajib upload file');
        }
        return false;
    }

    return (
        <UploadImageStyle>
            <Upload
                accept="image/png, image/jpeg"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ height: '100%', width: '100%' }} /> : (
                    <div>
                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                )}
            </Upload>
        </UploadImageStyle>
    );
}

export default UploadImage;