import React from 'react';
import { useHistory, useParams } from 'react-router';
import KiddofestScheduleEditStyle from './index.style';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Select,
    Upload,
} from 'antd';
import { notifyError, notifySuccess } from '../../../../utils';
import { TimePicker } from 'antd';
import serviceKiddofestSchedule from '../index.service';
import moment from 'moment';

const { Option } = Select;

export default function KiddofestScheduleEdit() {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const params = useParams();

    const routes = [
        {
            path: '',
            breadcrumbName: 'Kiddofest Schedule',
        },
        {
            path: '',
            breadcrumbName: 'Edit',
        },
    ];

    React.useEffect(() => {
        onGetDetailSchedule(params.id);
    }, []);

    const onGetDetailSchedule = async (id) => {
        const res = await serviceKiddofestSchedule.getDetailSchedule(id);
        if (res.isSuccess) {
            const resData = res.response.payload;
            const dataForm = {
                id_kiddofest: resData.kiddofest_id,
                day: resData.day,
                title: resData.title,
                category: resData.category,
                name_mc: resData.mc,
                time_schedule: undefined,
            };
            if (!!res.response.payload.time) {
                const time = res.response.payload.time.split('-');
                dataForm.time_schedule = [
                    moment(time[0], 'HH:mm'),
                    moment(time[1], 'HH:mm'),
                ];
            }
            form.setFieldsValue(dataForm);
        } else {
            notifyError(res.message);
        }
    };

    const onFinish = async (value) => {
        setIsLoading(true);
        const data = {
            id: Number(params.id),
            kiddofest_id: Number(value.id_kiddofest),
            day: Number(value.day),
            title: value.title,
            category: value.category,
            mc: value.name_mc,
            time: `${moment(value.time[0]).format('HH:mm')} - ${moment(
                value.time[1],
            ).format('HH:mm')}`,
        };
        const res = await serviceKiddofestSchedule.editSchedule(data);
        if (res.isSuccess) {
            notifySuccess('Success update schedule');
            history.push('/kiddofest/schedule');
        } else {
            notifyError(res.message);
        }
        setIsLoading(false);
    };

    return (
        <KiddofestScheduleEditStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Edit Kiddofest Schedule"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="id_kiddofest"
                                label="ID Kiddofest"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'ID Kiddofest wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    type="number"
                                    size="large"
                                    placeholder="Masukkan ID Kiddofest"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="day"
                                label="Day"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Day wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    type="number"
                                    size="large"
                                    placeholder="Masukkan Day"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="title"
                                label="Title"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Title wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan ID Title"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="category"
                                label="Category"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Category wajib diisi',
                                    },
                                ]}>
                                <Select
                                    placeholder="Pilih Type"
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'workshop'}>
                                        Workshop
                                    </Option>
                                    <Option key={2} value={'talkshow'}>
                                        Talkshow
                                    </Option>
                                    <Option key={3} value={'hlc'}>
                                        HLC
                                    </Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="name_mc"
                                label="Name MC"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'MC wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Name MC"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 40 }}>
                            <Form.Item
                                name="time"
                                label="Time Schedule"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Time Schedule wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <TimePicker.RangePicker format={'HH:mm'} />
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Save
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history.push('/kiddofest/schedule');
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </KiddofestScheduleEditStyle>
    );
}
