import serviceApi from '../../../services/index';

const serviceKioMasterEpisode = {
    getListEpisode: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kio/eps`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postDataEpisode: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/kio/eps`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updateDataEpisode: async (data, id) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kio/eps`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getdetailEpisode: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/kio/eps/${id}`,
            version: 'v1',
            token: true,
        });
    },
    deleteEpisode: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/kio/eps/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export const serviceKioSubEpisode = {
    getListSubEpisode: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kio/sub`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    postSubEpisode: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/kio/sub`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    updateSubEpisode: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kio/sub`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    getDetailSubEpisode: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/kio/sub/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceKioMasterEpisode;
