import Cookies from 'js-cookie';

const localStorages = () => {
    const storage = Cookies;
    return {
        save: (value = String, item) =>
            storage.set(value, item, { expires: 30 }),
        get: (value = String) => storage.getJSON(value),
        remove: (value = String) => storage.remove(value),
    };
};

export default localStorages;
