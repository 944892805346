import styled from '@emotion/styled';

const CampaignPageEditStyle = styled.div`
    display: block;
    .ql-editor {
        min-height: 18em;
    }
`;

export default CampaignPageEditStyle;
