import serviceApi from '../../../services/index';

const serviceProductDuplicate = {
    createProduct: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/products`,
            version: 'v1',
            appType: 1,
            token: true,
            data: data,
        });
    },
    getProductDetail: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/products/${id}`,
            version: 'v1',
            appType: 1,
            token: true,
        });
    },
};

export default serviceProductDuplicate;
