import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    Select,
    message,
    PageHeader,
    Table,
    Tag,
    Menu,
    Dropdown,
    Pagination,
    Input,
    Space,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import serviceProductList from './index.service';
import ProductListStyle from './index.style';
import { formatMoney } from '../../../utils/format-money';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import { categori, subcat } from '../../../utils/index-data';

const ProductList = () => {
    const history = useHistory();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [dataProductList, setDataProductList] = useState([]);
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const [dataFilterMerchant, setDataFilterMerchant] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(10);
    const [queryString, setQueryString] = useState({
        // queries[row]: 10,
        // offset: 0,
        // merchant: ""
        queries: {
            page: 1,
            row: 10,
        },
    });
    const [selectedValue, setSelectedValue] = useState(false);
    const [searchValue, setSearchValue] = React.useState({});

    const resetFilter = () => {
        setQueryString({
            queries: {
                page: 1,
                row: 10,
            },
        });
        setCurrentPage(1);
        setTotalPage(10);
    };

    useEffect(() => {
        handleLoadData(queryString);
        getMerchant({ search: 'all' });
    }, [queryString]);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceProductList.getProductList(resultQuery);
        if (result.isSuccess) {
            if (queryString.merchant !== '' && queryString.offset === 0) {
                setDataProductList(result.response);
            } else {
                if (queryString.merchant === '' && queryString.offset === 0) {
                    setDataProductList(result.response);
                } else {
                    // let dataBaru = dataProductList.concat(result.response);
                    setDataProductList(result.response);
                }
            }

            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const getMerchant = async (params) => {
        const result = await serviceProductList.getMerchantList(params);
        if (result.isSuccess) {
            handleDataMerchantFilter(result.response.payload);
        } else {
            message.error('Gagal mendapatkan data Merchant!');
        }
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={searchValue[dataIndex] || ''}
                    onChange={(e) =>
                        setSearchValue((prevState) => {
                            return {
                                ...prevState,
                                [dataIndex]: e.target.value,
                            };
                        })
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                {/* <Space> */}
                <Button
                    type="primary"
                    onClick={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: '100%' }}
                    icon={<SearchOutlined />}
                    size="small">
                    Search
                </Button>
                {/* <Button
                        onClick={() => handleResetSearch(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button> */}
                {/* </Space> */}
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;
        if (!!!sorter.field) {
            delete paramResult.sorts;
        } else {
            if (sorter.field == 'title') {
                paramResult.sorts = {
                    ['product_title']:
                        sorter.order === 'descend' ? 'desc' : 'asc',
                };
            } else if (sorter.field == 'stock') {
                paramResult.sorts = {
                    ['product_stock']:
                        sorter.order === 'descend' ? 'desc' : 'asc',
                };
            } else if (sorter.field == 'modified_at') {
                paramResult.sorts = {
                    ['product_modified_at']:
                        sorter.order === 'descend' ? 'desc' : 'asc',
                };
            } else {
                paramResult.sorts = {
                    [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
                };
            }
        }

        paramResult.filters = {
            ...paramResult.filters,
            product_publish_status: filters.publish_status || null,
            category: filters.category || null,
        };

        !filters.publish_status &&
            !paramResult.product_publish_status &&
            delete paramResult.filters.product_publish_status;
        !filters.category &&
            !paramResult.cateogry &&
            delete paramResult.filters.category;

        setQueryString(paramResult);
    };

    const getColumnSelectProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Select
                    showSearch
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //     option.children
                    //         .toLowerCase()
                    //         .indexOf(input.toLowerCase()) >= 0
                    // }
                    // filterSort={(optionA, optionB) =>
                    //     optionA.children
                    //         .toLowerCase()
                    //         .localeCompare(optionB.children.toLowerCase())
                    // }
                    value={searchValue[dataIndex] || ''}
                    onSelect={(val) => {
                        handleSearch(val, confirm, dataIndex, true);
                    }}>
                    {dataFilterMerchant.map((el, idx) => {
                        return (
                            <Option key={idx} value={el.value}>
                                {el.text}
                            </Option>
                        );
                    })}
                </Select>
                <Button
                    type="primary"
                    block
                    onClick={() => {
                        resetFilter();
                    }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const handleSearch = (
        selectedKeys,
        confirm,
        dataIndex,
        isComponentSelect = false,
    ) => {
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        if (isComponentSelect) {
            setSearchValue((prevState) => {
                return { ...prevState, [dataIndex]: selectedKeys };
            });
            resultQuery.filters = {
                [dataIndex]: selectedKeys,
            };
        } else {
            resultQuery.filters = {
                [dataIndex]: searchValue[dataIndex],
            };
        }
        setQueryString(resultQuery);
    };

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'publish') {
            color = 'green';
        }
        if (status === 'pending') {
            color = 'geekblue';
        }
        if (status === 'draft') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    const handleDataMerchantFilter = (data = []) => {
        if (data && data.length > 0) {
            setDataFilterMerchant(() => [
                ...(data.length > 0 &&
                    data.map((item) => {
                        return {
                            text: item.name,
                            value: item.name,
                        };
                    })),
            ]);
        }
    };

    const handleResetFilter = () => {
        setSearchValue({});
        const defaultQuery = {
            queries: {
                page: 1,
                row: 10,
            },
        };
        setQueryString(defaultQuery);
        form.setFieldsValue({
            queries: {
                page: 1,
                row: 10,
            },
        });
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Product',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id_product',
            key: 'id_product',
            sorter: (a, b) => {},
            // sortDirections: ['descend', 'ascend'],
            // sortOrder: !!queryString?.sorts?.id
            //   ? queryString?.sorts?.id === 'desc'
            //     ? 'descend'
            //     : 'ascend'
            //   : null,
            render: (_, record) => {
                return (
                    <Link to={`product/detail/${record.id}`}>{record.id}</Link>
                );
            },
            ...getColumnSearchProps('id_product'),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (_, record) => {
                return <img src={record.image} alt={record.name} width="100" />;
            },
        },
        {
            title: 'Product Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => {},
            ...getColumnSearchProps('product_title'),
        },
        {
            title: 'Merchant',
            dataIndex: 'merchant',
            key: 'merchant',
            ...getColumnSelectProps('merchant_name'),
        },
        {
            title: 'Categories',
            dataIndex: 'category',
            key: 'category',
            filters: [...categori, ...subcat],
            render: (_, record) => {
                if (record.categories.includes('Kelas Online')) {
                    let indexOnlineClass =
                        record.categories.indexOf('Kelas Online');
                    record.categories[indexOnlineClass] = 'Video Learning';
                    return record.categories.toString();
                }
                return record.categories.toString();
            },
            filteredValue: queryString?.filters?.category || null,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => {
                return <span>{formatMoney(record.price)}</span>;
            },
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
            sorter: (a, b) => {},
        },
        {
            title: 'Status',
            dataIndex: 'publish_status',
            key: 'publish_status',
            filters: [
                { text: 'DRAFT', value: 'draft' },
                { text: 'PUBLISH', value: 'publish' },
                { text: 'PENDING', value: 'pending' },
            ],
            render: (publish_status) => handleLabelStatus(publish_status),
            filteredValue: queryString?.filters?.product_publish_status || null,
            ellipsis: true,
        },
        {
            title: 'Modified Date',
            dataIndex: 'modified_at',
            key: 'modified_at',
            sorter: (a, b) => {},
            render: function (modified_at) {
                return (
                    <span>
                        {moment(modified_at).format('DD-MM-YYYY HH:mm:ss')}
                    </span>
                );
            },
            // sortDirections: ["descend", "ascend"],
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: '',
            render: (_, record) => {
                return <DropdownMenu record={record} key="more" />;
            },
        },
    ];

    const onPaginationChange = (page, pageSize) => {
        setQueryString({
            ...queryString,
            queries: {
                page: page,
                row: pageSize,
            },
        });
        setCurrentPage(page);
        setTotalPage(pageSize);
    };

    const DropdownMenu = ({ record }) => (
        <Dropdown key="more" overlay={menu(record)}>
            <Button
                style={{
                    border: 'none',
                    padding: 0,
                }}>
                <EllipsisOutlined
                    style={{
                        fontSize: 20,
                        verticalAlign: 'top',
                    }}
                />
            </Button>
        </Dropdown>
    );

    const menu = (record) => (
        <Menu>
            <Menu.Item>
                <a
                    href={`${process.env.REACT_APP_API_COMMERCE}${record.slug}`}
                    target="_blank">
                    <Button style={{ width: '100%' }} type="dashed">
                        Preview
                    </Button>
                </a>
            </Menu.Item>
            <Menu.Item>
                <Button
                    onClick={() => history.push(`/product/detail/${record.id}`)}
                    style={{ width: '100%' }}
                    type="dashed">
                    Detail
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button
                    onClick={() =>
                        history.push(`/product/duplicate/${record.id}`)
                    }
                    style={{ width: '100%' }}
                    type="dashed">
                    Duplicate
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button
                    style={{ width: '100%' }}
                    type="dashed"
                    onClick={async () => {
                        var ret = window.confirm(
                            'Are you sure delete this product?',
                        );
                        if (ret == true) {
                            const result =
                                await serviceProductList.deleteProduct(
                                    record.id,
                                );
                            if (result.isSuccess) {
                                refreshPage();
                            } else {
                            }
                        } else {
                        }
                    }}>
                    Delete
                </Button>
            </Menu.Item>
        </Menu>
    );
    const refreshPage = () => {
        window.location.reload();
    };
    return (
        <div>
            <div className="header">
                <PageHeader
                    // onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Product List"
                    breadcrumb={{ routes }}
                    subTitle="Table of product list "
                    style={{ paddingLeft: 0 }}
                />
                <Button onClick={handleResetFilter} style={{ float: 'right' }}>
                    Reset Filter
                </Button>
            </div>

            <ProductListStyle>
                <Form form={form} layout="inline">
                    <Form.Item>
                        <Button type="primary" style={{ marginBottom: 10 }}>
                            <Link to="product/create">+ Add New Product</Link>
                        </Button>
                    </Form.Item>
                    {/* <Form.Item name="per_page">
            <Select
              style={{ width: 150 }}
              defaultValue={10}
              onSelect={(val) => {
                setQueryString({ ...queryString, per_page: val })
              }}
            >
              <Option value={10}>10/Page</Option>
              <Option value={20}>20/Page</Option>
              <Option value={50}>50/Page</Option>
              <Option value={100}>100/Page</Option>
            </Select>
          </Form.Item> */}
                </Form>
                <Table
                    loading={isLoading}
                    pagination={{
                        current: queryString.queries.page,
                        defaultCurrent: 1,
                        className: 'pagination',
                        total: dataProductList.total_payload,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        defaultPageSize: 10,
                    }}
                    bordered
                    columns={columns}
                    dataSource={dataProductList.payload}
                    showSorterTooltip={true}
                    onChange={handleChange}
                />
                {/* <div className="pagination">
          <Pagination pageSize={totalPage} showQuickJumper current={currentPage} defaultCurrent={1} total={dataProductList?.total_payload} onChange={onPaginationChange} />
        </div> */}

                {/* <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            style={{ margin: '10px auto' }}
            onClick={() => {
              setQueryString({ ...queryString, offset: queryString.offset + 8 })
            }}
            disabled={isLoading}
          >
            {isLoading ? <LoadingOutlined spin /> : 'Load More'}
          </Button>
        </div> */}
            </ProductListStyle>
        </div>
    );
};

export default ProductList;
