import {
    DeleteFilled,
    EditOutlined,
    FileSearchOutlined,
    FormOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, PageHeader, Space, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceJournalImmunization from '../index.service';
import JournalImmunizationStyle from './index.style';

const { Search } = Input;

export default function JournalImmunization() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
        filter: {
            'mt_immunization.name': '',
        },
    });
    const [dataImmunization, setDataImmunization] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceJournalImmunization.getImmunizationList(
            resultQuery,
        );
        if (result.isSuccess) {
            setDataImmunization(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleResetFilter = () => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => handleResetSearch(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            // sorter: (a, b) => {},
            // sortDirections: ['descend', 'ascend'],
            // sortOrder: !!queryString?.sorts?.age
            //     ? queryString?.sorts?.age === 'desc'
            //         ? 'descend'
            //         : 'ascend'
            //     : null,
            // ...getColumnSearchProps('age'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // ...getColumnSearchProps('name'),
        },
        {
            title: 'Recommend',
            dataIndex: 'recommend',
            key: 'recommend',
            // ...getColumnSearchProps('recommend'),
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            key: 'detail',
            ellipsis: true,
        },
        {
            title: 'Position',
            dataIndex: 'have_before',
            key: 'position',
        },
        {
            title: 'Type',
            dataIndex: 'recommend_type',
            key: 'recommend_type',
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history.push(
                                        `/journal/immunization/${record.id}`,
                                    )
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Detail">
                            <FileSearchOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history.push(
                                        `/journal/immunization/detail/${record.id}`,
                                    )
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    var ret = window.confirm(
                                        'Are you sure delete this immunization?',
                                    );
                                    if (ret == true) {
                                        const result =
                                            await serviceJournalImmunization.onDeleteImmunization(
                                                record.id,
                                            );
                                        if (result.isSuccess) {
                                            handleLoadData(queryString);
                                            notifySuccess(
                                                'Success Remove Data Immunization',
                                            );
                                        }
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Immunization List',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filter['mt_immunization.name'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <JournalImmunizationStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Immunization List"
                    breadcrumb={{ routes }}
                    subTitle="Table of immunization list "
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="immunization/create">+ Add Immunization</Link>
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataImmunization.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataImmunization.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </JournalImmunizationStyle>
    );
}
