import styled from "@emotion/styled";

const ArticleListStyle = styled.div`
  .pagination {
    float: right;
    margin: 10px auto;
    padding-bottom: 25px;
  }
`;

export default ArticleListStyle;
