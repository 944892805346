import serviceApi from '../../../services/index';

const serviceFormMerchant = {
    postMerchant: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `v1/merchants`,
            noVersion: true,
            appType: 1,
            token: true,
            params: null,
            data: data,
            // formData: true,
        });
    },
};

export default serviceFormMerchant;
