import React, { useState } from 'react'
import { Button, Form, Modal } from 'antd'
import PictureForm from '../PictureForm'
import galleryService from '../index.service'

const UpdatePicture = ({ active, getImage, setActive, pictureDetail, setPictureDetail }) => {
  const [form] = Form.useForm()

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const updatePict = async (data) => {
    setLoadingUpdate(true)
    let dataPut = {
      ...data,
      id: pictureDetail.id
    }
    const result = await galleryService.updatePicture(dataPut);
    if (result.isSuccess) {
      setActive(false)
      setPictureDetail()
    }
    getImage()
    setLoadingUpdate(false)
  }

  const deletePict = async () => {
    var ret = window.confirm(
      'Are you sure delete this picture?',
    );

    if (ret === true) {
      setLoadingDelete(true)
      const result = await galleryService.deletePicture(pictureDetail.id);
      if (result.isSuccess) {
        setActive(false)
        setPictureDetail()
        getImage()
      }
      setLoadingDelete(false)
    }
  }

  return (
    <Modal
      visible={active}
      width={800}
      onCancel={() => {
        setActive(false)
        setPictureDetail()
      }}
      footer={[
        <Button
          onClick={() => {
            setActive(false)
          }}
        >
          Cancel
        </Button>,
        <Button
          type='primary'
          loadingDelete={loadingDelete}
          onClick={() => {
            deletePict()
            form.resetFields()
          }}

          danger
        >
          Delete
        </Button>,
        <Button
          type='primary'
          loading={loadingUpdate}
          onClick={() => {
            form.validateFields().then(value => {
              updatePict(value)
              form.resetFields()
            })
          }}
        >
          Update
        </Button>
      ]}
    >
      <PictureForm form={form} initValue={pictureDetail} />
    </Modal>
  )
}

export default UpdatePicture
