import React from 'react';
import ProviderPrefixListStyle from './index.style';
import serviceProviderPrefix from '../index.service';
import { useHistory } from 'react-router-dom';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import ModalRemove from '../../../../components/ModalRemove';
import moment from 'moment';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { notifyError } from '../../../../utils';

const ProviderPrefixList = () => {
    const history = useHistory();
    const [dataProviderPrefix, setDataProviderPrefix] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        sorts: {
            created_at: 'desc',
        },
    });

    React.useEffect(() => {
        onLoadData();
    }, []);

    const onLoadData = async () => {
        setIsLoading(true);
        const results = await serviceProviderPrefix.listProviderPrefix(
            queryString,
        );
        if (results.isSuccess) {
            setDataProviderPrefix(results.response);
            setIsLoading(false);
        } else {
            notifyError(results.response.message);
        }
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Nama Provider',
            dataIndex: 'provider',
            key: 'provider',
        },
        {
            title: 'Nomor Prefix',
            dataIndex: 'prefix',
            key: 'prefix',
        },
        {
            title: 'Tanggal & Jam Update',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (item) => moment.utc(item).format('DD MMM YYYY HH:mm:ss'),
        },

        {
            title: 'Aksi',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history.push(`/ppob/provider/${record.id}`)
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/ppob/provider/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage:
                                                'Sukses menghapus data prefix',
                                        },
                                        onReGetData: () => {
                                            onLoadData(queryString);
                                        },
                                        title: 'Delete Confirmation',
                                        desc: 'Apakah Anda yakin ingin menghapus data Prefix ?',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'PROVIDER PREFIX',
        },
        {
            path: 'list',
            breadcrumbName: 'LIST',
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            onLoadData(paramResult);
        }, 500);
    };

    return (
        <ProviderPrefixListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="PPOB "
                    breadcrumb={{ routes }}
                    subTitle="Table PPOB Provider"
                />
            </div>
            <div className="btn-top-table">
                <Button
                    onClick={() => history.push('/ppob/provider/add')}
                    type="primary"
                    style={{ marginBottom: 10 }}>
                    Tambah Prefix
                </Button>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataProviderPrefix.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataProviderPrefix.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </ProviderPrefixListStyle>
    );
};

export default ProviderPrefixList;
