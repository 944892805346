import {
    Card,
    Col,
    Descriptions,
    PageHeader,
    Divider,
    Row,
    Table,
    Tag,
} from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import OrderPpobDetailStyle from './index.style';
import serviceOrderPPOBDetail from '../index.service';
import OrderPPOBDetailLoading from './index.loading';
import { formatMoney } from '../../../../utils/format-money';
import moment from 'moment';

const OrderPpobDetail = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = React.useState(true);
    const [dataOrderDetail, setDataOrderDetail] = React.useState({});
    const [dataTables, setDataTables] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(props.match.params.id);
    }, []);

    const handleLoadData = async (id) => {
        const result = await serviceOrderPPOBDetail.getDataPpobDetail(id);
        if (result.isSuccess && !!result.response) {
            setDataOrderDetail(result.response.payload);
            setDataTables([
                {
                    ppob_name: result.response.payload.ppob_name,
                    user_phone: result.response.payload.user_phone,
                    payment_unix: result.response.payload.payment_unix,
                    ppob_type: result.response.payload.ppob_type,
                    param: result.response.payload.param,
                },
            ]);
            setLoading(false);
        }
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'PPOB Order',
        },
        {
            path: 'detail',
            breadcrumbName: 'Detail',
        },
    ];

    const columns = [
        {
            id: 'ppob_name',
            key: 'ppob_name',
            title: 'Nama Produk',
            render: (_, record) => {
                return record.ppob_name;
            },
        },
        {
            title: 'Nomor User',
            render: (_, record) => {
                return record.user_phone;
            },
        },
        {
            title: 'No VA',
            render: (_, record) => {
                return record.reference_number || '-';
            },
        },
        {
            title: 'Kategori',
            render: (_, record) => {
                return record.ppob_type;
            },
        },
        {
            title: 'Periode (BPJS)',
            render: (_, record) => {
                return record.param || '-';
            },
        },
    ];

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'Completed') {
            color = 'green';
        }
        if (status === 'Pending') {
            color = 'geekblue';
        }
        if (status === 'Cancelled') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status?.toUpperCase()}
            </Tag>
        );
    };

    return (
        <OrderPpobDetailStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Order Detail PPOB"
                breadcrumb={{ routes }}
                subTitle="Form Order Detail"
            />
            {isLoading ? (
                <OrderPPOBDetailLoading />
            ) : (
                <Row gutter={16}>
                    <Col span={24}>
                        <Card
                            loading={isLoading}
                            title="Order PPOB Details"
                            bordered={true}>
                            <Descriptions title="" bordered>
                                <Descriptions.Item
                                    label="Tanggal Order"
                                    span={2}>
                                    {moment
                                        .utc(dataOrderDetail.created_at)
                                        .format('DD MMMM YYYY')}
                                </Descriptions.Item>

                                <Descriptions.Item label="Order ID">
                                    {dataOrderDetail.order_id}
                                </Descriptions.Item>

                                <Descriptions.Item label="Harga">
                                    {formatMoney(dataOrderDetail.price)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Kode Promo">
                                    {dataOrderDetail.kodepromo || '-'}
                                </Descriptions.Item>

                                <Descriptions.Item label="Harga Diskon">
                                    {dataOrderDetail.sale_price
                                        ? formatMoney(
                                              dataOrderDetail.sale_price,
                                          )
                                        : '-'}
                                </Descriptions.Item>

                                {/* <Descriptions.Item label="Kode Promo">
                                    {dataOrderDetail?.items[0]?.promo_code}
                                </Descriptions.Item> */}

                                <Descriptions.Item label="Nama Payment Gateway">
                                    {dataOrderDetail.payment_vendor}
                                </Descriptions.Item>

                                <Descriptions.Item label="Nama Pembayaran">
                                    {dataOrderDetail.payment_code}
                                </Descriptions.Item>

                                <Descriptions.Item label="VA BANK">
                                    {dataOrderDetail.payment_unix}
                                </Descriptions.Item>

                                <Descriptions.Item label="Biaya Admin">
                                    {formatMoney(dataOrderDetail.charge)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Status">
                                    {handleLabelStatus(dataOrderDetail.status)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Point Klaim">
                                    {dataOrderDetail.kiddo_point}
                                </Descriptions.Item>

                                <Descriptions.Item
                                    style={{ maxWidth: '300px' }}
                                    label="Link Pembayaran">
                                    {dataOrderDetail.payment_send && (
                                        <>
                                            <p>Link pay : </p>
                                            {dataOrderDetail.payment_send}
                                            <Divider />
                                        </>
                                    )}
                                    {dataOrderDetail.payment_response && (
                                        <>
                                            <p>Payment gateway send : </p>
                                            {dataOrderDetail.payment_response}
                                            <Divider />
                                        </>
                                    )}

                                    {dataOrderDetail.payment_webhook && (
                                        <>
                                            <p>Payment gateway webhook : </p>
                                            {dataOrderDetail.payment_webhook}
                                            <Divider />
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>

                    {/* Data User Kiddo */}
                    <Col span={8}>
                        <Card
                            loading={isLoading}
                            title="Data User Kiddo"
                            bordered={true}>
                            <Descriptions bordered>
                                <Descriptions.Item label="Nama" span={3}>
                                    {dataOrderDetail.user_name}
                                </Descriptions.Item>
                                <Descriptions.Item label="No Hp" span={3}>
                                    {dataOrderDetail.user_phone}
                                </Descriptions.Item>
                                <Descriptions.Item label="Email" span={3}>
                                    {dataOrderDetail.user_email}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>

                    <Col span={16}>
                        {!isLoading && (
                            <Card
                                loading={isLoading}
                                title="Data Pembelian Produk"
                                bordered>
                                <Table
                                    loading={isLoading}
                                    columns={columns}
                                    dataSource={dataTables}
                                />
                            </Card>
                        )}
                    </Col>
                </Row>
            )}
        </OrderPpobDetailStyle>
    );
};

export default OrderPpobDetail;
