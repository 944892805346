import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ModalRemove from '../../../components/ModalRemove';
import { notifyError, notifySuccess } from '../../../utils';
import serviceKiddofestBanner from './index.service';
import KioBannerStyle from './index.style';

export default function KioBanner() {
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useHistory();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filters: {
            deleted_at: 'null',
        },
    });
    const [dataBanner, setDataBanner] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
        return () => {
            setDataBanner([]);
        };
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceKiddofestBanner.getListBanner(resultQuery);
        if (result.isSuccess) {
            setDataBanner(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 60,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            render: (value) => {
                return (
                    <img
                        src={`${value}?id=${
                            Math.floor(Math.random() * 50) + 1
                        }`}
                        width={180}
                    />
                );
            },
        },
        {
            title: 'Link URL',
            dataIndex: 'link_youtube',
            key: 'link_youtube',
            ellipsis: true,
            render: (value) => {
                return !!value ? (
                    <a href={value} target="_blank">
                        {value}
                    </a>
                ) : (
                    '-'
                );
            },
        },
        {
            title: 'Aksi',
            width: 80,
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history.push(
                                        `/kio/banner/edit/${record.id}`,
                                    )
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/kio/banner/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage: 'Sukses Hapus Data',
                                        },
                                        onReGetData: () => {
                                            handleLoadData(queryString);
                                        },
                                        title: 'Konfirmasi Hapus Banner!',
                                        desc: 'Apakah anda yakin ingin menghapus banner ini',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'list',
            breadcrumbName: 'LIST',
        },
        {
            path: 'index',
            breadcrumbName: 'BANNER KIO & FRIENDS',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filters['kiddofest_banner.category'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <KioBannerStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="MASTER BANNER"
                    breadcrumb={{ routes }}
                    subTitle=""
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="/kio/banner/add">+ Tambah Banner</Link>
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Cari by kategori"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataBanner.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataBanner.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </KioBannerStyle>
    );
}
