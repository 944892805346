import serviceApi from "../../services";

const HomePageService = {
  postPicture: async (data) => {
    return await serviceApi({
      method: "POST",
      url: 'v1/public/media/file/upload',
      noVersion: true,
      appType: 1,
      token: true,
      data: data,
      formData: true,
    })
  },
  getGeneralPicture: async () => {
    return await serviceApi({
      method: 'GET',
      url: `/public/media`,
      version: 'v1',
      token: true,
    })
  },
  getHomepageList: async () => {
    return await serviceApi({
      method: "GET",
      url: 'v1/homepages',
      noVersion: true,
      appType: 1,
      token: true,
    })
  },
  updateHomepage: async (data) => {
    return await serviceApi({
      method: "PUT",
      url: 'v1/homepages',
      noVersion: true,
      appType: 1,
      token: true,
      data: data,
    })
  }
}

export default HomePageService
