import serviceApi from '../../../services';

const serviceLMSTeam = {
    getListTeam: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/lms/team`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getDetailTeam: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/lms/team/${id}`,
            version: 'v1',
            token: true,
        });
    },
    postTeamData: async (bodyData) => {
        return await serviceApi({
            method: 'POST',
            url: `/lms/team`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
    putTeamData: async (bodyData) => {
        return await serviceApi({
            method: 'PUT',
            url: `/lms/team`,
            version: 'v1',
            token: true,
            data: bodyData,
        });
    },
};

export default serviceLMSTeam;
