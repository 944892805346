import {
    DeleteOutlined,
    DragOutlined,
    PlusOutlined,
    SearchOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Collapse,
    Form,
    Image,
    Input,
    message,
    Modal,
    PageHeader,
    Row,
    Select,
    Tooltip,
    Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import HomePageService from './index.service';
import {
    HomePageStyle,
    ModalImageSelector,
    PictureBannerStyle,
} from './index.style';
import _ from 'lodash';

const Homepage = () => {
    const history = useHistory();

    const [loading, setIsLoading] = useState(false);
    const [loadingUploadPhoto, setLoadingUploadPhoto] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [imageSelectorModal, setImageSelectorModal] = useState(false);

    const [selectedItem, setSelectedItem] = useState({
        itemId: '',
        bannerId: '',
        fieldName: '',
    });

    const [selectedImage, setSelectedImage] = useState();
    const [imageList, setImageList] = useState([]);
    const [filteredImage, setFilteredImage] = useState([]);

    const { Option } = Select;

    const { Panel } = Collapse;

    const handleUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading', key: 'updatable' });
        }
    };

    const handleLoadGeneralImage = async () => {
        const result = await HomePageService.getGeneralPicture();
        if (result.isSuccess) {
            setImageList(result.response.payload);
        }
    };

    const handleLoadData = async () => {
        setIsLoading(true);
        const result = await HomePageService.getHomepageList();
        if (result.isSuccess) {
            setItemList(result.response.payload);
        }
        setIsLoading(false);
        setIsUpdated(false);
    };

    const addItem = () => {
        setItemList((prevState) => [
            ...prevState,
            {
                id: Math.max(...prevState.map((item) => item.id)) + 1,
                template_id: 3,
                position:
                    Math.max(...prevState.map((item) => item.position)) + 1,
                function: 'products',
                title: '',
                subtitle: '',
                background_image: '',
                banner_images: [],
                marketplace_api_endpoint: '',
                marketplace_api_params: {
                    param: '',
                    tag: '',
                    per_page: '',
                    offset: '0',
                    order: '',
                    order_by: '',
                },
            },
        ]);
        setIsUpdated(true);
    };

    useEffect(() => {
        handleLoadData();
        handleLoadGeneralImage();
    }, []);

    const uploadButton = () => {
        return (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
    };

    const categori = [
        { value: 'Aktivitas', text: 'Aktivitas' },
        { value: 'Kursus', text: 'Kursus' },
        { value: 'Experience', text: 'Experience' },
        { value: 'Daycare dan Pre-School', text: 'Daycare dan Pre-School' },
        { value: 'Activity Kit', text: 'Activity Kit' },
        { value: 'Kelas Online', text: 'Kelas Online' },
    ];

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
                key: 'updatable',
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
                key: 'updatable',
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
        // return false;
    };

    const uploadPic = async ({ file }, fieldName, id, elemId) => {
        setLoadingUploadPhoto(true);
        const formData = new FormData();
        formData.append('file', file);
        const res = await HomePageService.postPicture(formData);

        if (res.isSuccess) {
            let result = res.response.payload;
            let arrayData = [...itemList];
            const itemId = arrayData.findIndex((i) => i.id === id);
            const bannerId = arrayData[itemId].banner_images.findIndex(
                (i) => i.id === elemId,
            );

            if (fieldName === 'banner_images') {
                if (elemId) {
                    arrayData[itemId].banner_images[bannerId].image_url =
                        result.url;
                } else {
                    const newBanner = {
                        id:
                            arrayData[itemId].banner_images.length > 0
                                ? Math.floor(Math.random() * 90 + 10)
                                : Math.max(
                                      ...arrayData[itemId].banner_images.map(
                                          (item) => item.id,
                                      ),
                                  ) + 1,
                        image_url: result.url,
                        target_url: '',
                        position:
                            arrayData[itemId].banner_images.length > 0
                                ? Math.max(
                                      ...arrayData[itemId].banner_images.map(
                                          (item) => item.position,
                                      ),
                                  ) + 1
                                : 0,
                        category:
                            arrayData?.[itemId]?.banner_images?.[0]?.category ??
                            arrayData[itemId].marketplace_api_params.param,
                        created_at: new Date().toJSON(),
                    };
                    arrayData[itemId].banner_images.push(newBanner);
                }
            } else if (fieldName === 'background_image') {
                arrayData[itemId].background_image = result.url;
            }

            setIsUpdated(true);
            setItemList(arrayData);
        } else {
            message.error('Gagal Mengupload foto');
        }
        setLoadingUploadPhoto(false);
    };

    const handleItemPosition = (result) => {
        const { destination, source } = result;

        if (!result.destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const newArray = Array.from(itemList);
        newArray.splice(
            destination.index,
            0,
            newArray.splice(source.index, 1)[0],
        );
        setItemList(
            newArray.map((item, i) => {
                return {
                    ...item,
                    position: i + 1,
                };
            }),
        );
        setIsUpdated(true);
    };

    const HandleBannerPosition = (result, id) => {
        const { destination, source } = result;

        if (!result.destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        let allArray = [...itemList];
        let newArray = Array.from(
            allArray.find((el) => el.id === id).banner_images,
        );
        newArray.splice(
            destination.index,
            0,
            newArray.splice(source.index, 1)[0],
        );
        allArray[allArray.findIndex((i) => i.id === id)].banner_images =
            newArray.map((item, i) => {
                return {
                    ...item,
                    position: i + 1,
                };
            });

        setItemList(allArray);
        setIsUpdated(true);
    };

    const changeValue = (e, key, nameField) => {
        let tempArr = [...itemList];
        let selectedArr = tempArr.find((item) => item.id === key);
        let name, value, template;

        if (e.target) {
            name = e.target.name;
            value = e.target.value;
        } else {
            name = nameField;
            value = e;
        }

        _.set(selectedArr, name, value);

        if (nameField === 'template_id') {
            switch (e) {
                case 3:
                    template = 'products';
                    break;
                case 4:
                    template = 'banner_local';
                    break;
                case 6:
                    template = 'banner_local';
                    break;
                case 8:
                    template = 'banner';
                    break;
                case 9:
                    template = 'products';
                    break;
                case 16:
                    template = 'banner';
                    break;
                default:
                    template = '';
                    break;
            }
        } else {
            template = selectedArr.function;
        }

        selectedArr.function = template;
        tempArr[tempArr.findIndex((i) => i.id === key)] = selectedArr;
        setItemList(tempArr);
        setIsUpdated(true);
    };

    const updateHomepage = async () => {
        setLoadingUpdate(true);
        const result = await HomePageService.updateHomepage(itemList);
        if (result.isSuccess) {
            handleLoadData();
            message.success('update homepage success');
        } else {
            message.error('error updating homepage');
        }
        setLoadingUpdate(false);
    };

    const routes = [
        {
            path: '/',
            breadcrumbName: 'Homepage Management',
        },
    ];

    return (
        <HomePageStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Homepage"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    gap: '16px',
                    marginBottom: '20px',
                    justifyContent: 'end',
                }}>
                <Button
                    onClick={() => {
                        setIsUpdated(false);
                        handleLoadData();
                    }}>
                    Cancel
                </Button>
                <Button
                    type="primary"
                    disabled={!isUpdated}
                    onClick={updateHomepage}
                    loading={loadingUpdate}>
                    Update Homepage
                </Button>
            </div>

            <Modal
                visible={imageSelectorModal}
                title="Select Image"
                width={1400}
                onCancel={() => {
                    setImageSelectorModal(false);
                    setSelectedImage();
                }}
                onOk={() => {
                    setImageSelectorModal(false);
                    setSelectedImage();
                    uploadPic();
                }}>
                <div className="search-colomn" style={{ marginBottom: '16px' }}>
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="Search Image..."
                        onChange={(e) => {
                            setFilteredImage(
                                imageList.filter((item) => {
                                    const regex = new RegExp(
                                        `${e.target.value}`,
                                        'gi',
                                    );
                                    return item.title.match(regex);
                                }),
                            );
                        }}
                    />
                </div>
                <ModalImageSelector>
                    {(filteredImage.length > 0 ? filteredImage : imageList).map(
                        (item) => {
                            return (
                                <div
                                    className={`image-item ${
                                        selectedImage?.id === item.id
                                            ? 'selected'
                                            : ''
                                    }`}
                                    onClick={() => setSelectedImage(item)}>
                                    <img src={item.url} alt="item" />
                                </div>
                            );
                        },
                    )}
                </ModalImageSelector>
            </Modal>

            <div style={{ minHeight: '60vh' }}>
                {itemList && itemList.length > 0 && (
                    <>
                        <DragDropContext
                            onDragEnd={(result) => handleItemPosition(result)}>
                            <Droppable
                                droppableId="droppable-2"
                                type="content"
                                direction="vertical">
                                {(provided, _) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {itemList.map((item, key) => {
                                            return (
                                                <Draggable
                                                    key={key}
                                                    draggableId={`draggable-${key}`}
                                                    index={key}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            style={{
                                                                ...provided
                                                                    .draggableProps
                                                                    .style,
                                                                boxShadow:
                                                                    snapshot.isDragging
                                                                        ? '0 0 .4rem #666'
                                                                        : 'none',
                                                            }}>
                                                            <div
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    marginBottom:
                                                                        '16px',
                                                                }}>
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        right: '0px',
                                                                        marginTop:
                                                                            '15px',
                                                                        marginRight:
                                                                            '20px',
                                                                        display:
                                                                            'flex',
                                                                        zIndex: '999',
                                                                        gap: '16px',
                                                                    }}>
                                                                    <div
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            const ret =
                                                                                window.confirm(
                                                                                    'Are you sure delete this section ?',
                                                                                );
                                                                            if (
                                                                                ret ===
                                                                                true
                                                                            ) {
                                                                                setItemList(
                                                                                    (
                                                                                        prevState,
                                                                                    ) =>
                                                                                        prevState.filter(
                                                                                            (
                                                                                                sect,
                                                                                            ) =>
                                                                                                sect.id !==
                                                                                                item.id,
                                                                                        ),
                                                                                );
                                                                            } else {
                                                                                return;
                                                                            }

                                                                            setIsUpdated(
                                                                                true,
                                                                            );
                                                                        }}>
                                                                        <DeleteOutlined />
                                                                    </div>

                                                                    <div
                                                                        {...provided.dragHandleProps}>
                                                                        <DragOutlined />
                                                                    </div>
                                                                </div>

                                                                <Collapse>
                                                                    <Panel
                                                                        header={
                                                                            item.title
                                                                                ? item.title
                                                                                : item.function
                                                                        }
                                                                        key={
                                                                            key
                                                                        }>
                                                                        <Row
                                                                            gutter={
                                                                                16
                                                                            }>
                                                                            <Col
                                                                                span={
                                                                                    4
                                                                                }>
                                                                                <div
                                                                                    style={{
                                                                                        marginBottom:
                                                                                            '10px',
                                                                                    }}>
                                                                                    <label
                                                                                        style={{
                                                                                            display:
                                                                                                'block',
                                                                                        }}>
                                                                                        template
                                                                                    </label>
                                                                                    <Select
                                                                                        disabled={
                                                                                            ![
                                                                                                3,
                                                                                                4,
                                                                                                6,
                                                                                                8,
                                                                                                9,
                                                                                                16,
                                                                                            ].includes(
                                                                                                item.template_id,
                                                                                            )
                                                                                        }
                                                                                        style={{
                                                                                            width: '100%',
                                                                                        }}
                                                                                        defaultValue={
                                                                                            item.template_id
                                                                                        }
                                                                                        onChange={(
                                                                                            e,
                                                                                        ) =>
                                                                                            changeValue(
                                                                                                e,
                                                                                                item.id,
                                                                                                'template_id',
                                                                                            )
                                                                                        }>
                                                                                        <Option
                                                                                            value={
                                                                                                3
                                                                                            }>
                                                                                            Product
                                                                                            Section
                                                                                        </Option>
                                                                                        <Option
                                                                                            value={
                                                                                                4
                                                                                            }>
                                                                                            Banner
                                                                                            2x3
                                                                                        </Option>
                                                                                        <Option
                                                                                            value={
                                                                                                6
                                                                                            }>
                                                                                            Slider
                                                                                            Banner
                                                                                        </Option>
                                                                                        <Option
                                                                                            value={
                                                                                                8
                                                                                            }>
                                                                                            Fix
                                                                                            Single
                                                                                            Banner
                                                                                        </Option>
                                                                                        <Option
                                                                                            value={
                                                                                                9
                                                                                            }>
                                                                                            Product
                                                                                            Section
                                                                                            With
                                                                                            Image
                                                                                        </Option>
                                                                                        <Option
                                                                                            value={
                                                                                                16
                                                                                            }>
                                                                                            Promo
                                                                                            Slider
                                                                                            Banner
                                                                                        </Option>
                                                                                    </Select>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    4
                                                                                }>
                                                                                <div
                                                                                    style={{
                                                                                        marginBottom:
                                                                                            '10px',
                                                                                    }}>
                                                                                    <label>
                                                                                        function
                                                                                    </label>
                                                                                    <Input
                                                                                        name="function"
                                                                                        disabled
                                                                                        value={
                                                                                            item.function
                                                                                        }
                                                                                        defaultValue={
                                                                                            item.function
                                                                                        }
                                                                                        onChange={(
                                                                                            e,
                                                                                        ) =>
                                                                                            changeValue(
                                                                                                e,
                                                                                                item.id,
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        {[
                                                                            4,
                                                                            6,
                                                                            8,
                                                                            3,
                                                                            9,
                                                                            16,
                                                                        ].includes(
                                                                            item.template_id,
                                                                        ) && (
                                                                            <>
                                                                                {item.template_id ===
                                                                                    9 && (
                                                                                    <Row>
                                                                                        <Col
                                                                                            span={
                                                                                                6
                                                                                            }>
                                                                                            <label>
                                                                                                Picture
                                                                                                Background
                                                                                            </label>
                                                                                            <ImgCrop
                                                                                                rotate
                                                                                                beforeCrop={
                                                                                                    beforeUpload
                                                                                                }
                                                                                                aspect={
                                                                                                    18 /
                                                                                                    13
                                                                                                }>
                                                                                                <Upload
                                                                                                    accept="image/*"
                                                                                                    listType="picture-card"
                                                                                                    className="avatar-uploader"
                                                                                                    showUploadList={
                                                                                                        false
                                                                                                    }
                                                                                                    customRequest={(
                                                                                                        e,
                                                                                                    ) =>
                                                                                                        uploadPic(
                                                                                                            e,
                                                                                                            'background_image',
                                                                                                            item.id,
                                                                                                        )
                                                                                                    }
                                                                                                    beforeUpload={
                                                                                                        beforeUpload
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleUploadChange
                                                                                                    }>
                                                                                                    {item.background_image ? (
                                                                                                        <img
                                                                                                            src={
                                                                                                                item.background_image
                                                                                                            }
                                                                                                            alt="avatar"
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                height: '100%',
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        uploadButton()
                                                                                                    )}
                                                                                                </Upload>
                                                                                            </ImgCrop>
                                                                                            {/* <img src={item.background_image} alt="avatar" style={{
                                                  width: "450px",
                                                  height: "325px",
                                                  paddingBottom: "16px",
                                                  cursor: 'pointer',
                                                }} onClick={() => {
                                                  setSelectedItem({
                                                    itemId: item.id,
                                                    fieldName: 'background_image'
                                                  })
                                                  setImageSelectorModal(true)
                                                }} /> */}
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                <Row
                                                                                    gutter={
                                                                                        24
                                                                                    }>
                                                                                    <Col
                                                                                        span={
                                                                                            12
                                                                                        }>
                                                                                        <div
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '10px',
                                                                                            }}>
                                                                                            <label>
                                                                                                Title
                                                                                            </label>
                                                                                            <Input
                                                                                                name="title"
                                                                                                defaultValue={
                                                                                                    item.title
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) =>
                                                                                                    changeValue(
                                                                                                        e,
                                                                                                        item.id,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '10px',
                                                                                            }}>
                                                                                            <label>
                                                                                                Subtitle
                                                                                            </label>
                                                                                            <Input
                                                                                                name="subtitle"
                                                                                                defaultValue={
                                                                                                    item.subtitle
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) =>
                                                                                                    changeValue(
                                                                                                        e,
                                                                                                        item.id,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        {!(
                                                                                            item.template_id ===
                                                                                            4
                                                                                        ) && (
                                                                                            <div
                                                                                                style={{
                                                                                                    marginBottom:
                                                                                                        '10px',
                                                                                                }}>
                                                                                                <label>
                                                                                                    URL
                                                                                                </label>
                                                                                                <Input
                                                                                                    name="marketplace_api_endpoint"
                                                                                                    defaultValue={
                                                                                                        item.marketplace_api_endpoint
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e,
                                                                                                    ) =>
                                                                                                        changeValue(
                                                                                                            e,
                                                                                                            item.id,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                        {![
                                                                                            4,
                                                                                            6,
                                                                                            8,
                                                                                            16,
                                                                                        ].includes(
                                                                                            item.template_id,
                                                                                        ) && (
                                                                                            <div
                                                                                                style={{
                                                                                                    marginBottom:
                                                                                                        '10px',
                                                                                                }}>
                                                                                                <label>
                                                                                                    Tag
                                                                                                </label>
                                                                                                <Input
                                                                                                    name="marketplace_api_params.tag"
                                                                                                    defaultValue={
                                                                                                        item
                                                                                                            .marketplace_api_params
                                                                                                            .tag
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e,
                                                                                                    ) =>
                                                                                                        changeValue(
                                                                                                            e,
                                                                                                            item.id,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    </Col>
                                                                                    <Col
                                                                                        span={
                                                                                            12
                                                                                        }>
                                                                                        <div
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '10px',
                                                                                            }}>
                                                                                            <label>
                                                                                                Kategori
                                                                                            </label>
                                                                                            <Input
                                                                                                name="marketplace_api_params.param"
                                                                                                defaultValue={
                                                                                                    item
                                                                                                        .marketplace_api_params
                                                                                                        .param
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) =>
                                                                                                    changeValue(
                                                                                                        e,
                                                                                                        item.id,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        {![
                                                                                            4,
                                                                                            6,
                                                                                            8,
                                                                                            16,
                                                                                        ].includes(
                                                                                            item.template_id,
                                                                                        ) && (
                                                                                            <div
                                                                                                style={{
                                                                                                    marginBottom:
                                                                                                        '10px',
                                                                                                }}>
                                                                                                <label>
                                                                                                    Jumlah
                                                                                                    Produk
                                                                                                </label>
                                                                                                <Input
                                                                                                    type={
                                                                                                        'number'
                                                                                                    }
                                                                                                    min={
                                                                                                        0
                                                                                                    }
                                                                                                    name="marketplace_api_params.per_page"
                                                                                                    defaultValue={
                                                                                                        item
                                                                                                            .marketplace_api_params
                                                                                                            .per_page
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e,
                                                                                                    ) =>
                                                                                                        changeValue(
                                                                                                            e,
                                                                                                            item.id,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                        <div
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '10px',
                                                                                                width: '200px',
                                                                                            }}>
                                                                                            <label
                                                                                                style={{
                                                                                                    display:
                                                                                                        'block',
                                                                                                }}>
                                                                                                Order
                                                                                                By
                                                                                            </label>
                                                                                            <Select
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    item
                                                                                                        .marketplace_api_params
                                                                                                        .order_by
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) =>
                                                                                                    changeValue(
                                                                                                        e,
                                                                                                        item.id,
                                                                                                        'marketplace_api_params.order_by',
                                                                                                    )
                                                                                                }>
                                                                                                <Option value="ASC">
                                                                                                    Ascending
                                                                                                </Option>
                                                                                                <Option value="DESC">
                                                                                                    Descending
                                                                                                </Option>
                                                                                            </Select>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                        {(item.template_id ===
                                                                            6 ||
                                                                            item.template_id ===
                                                                                4 ||
                                                                            item.template_id ===
                                                                                8 ||
                                                                            item.template_id ===
                                                                                16) && (
                                                                            <>
                                                                                <label
                                                                                    style={{
                                                                                        display:
                                                                                            'block',
                                                                                        marginBottom:
                                                                                            '16px',
                                                                                    }}>
                                                                                    Picture
                                                                                    Banner
                                                                                </label>
                                                                                {
                                                                                    item.template_id ===
                                                                                    4 ? (
                                                                                        item
                                                                                            .banner_images
                                                                                            .length <
                                                                                        6 ? (
                                                                                            <ImgCrop
                                                                                                rotate
                                                                                                beforeCrop={
                                                                                                    beforeUpload
                                                                                                }
                                                                                                aspect={
                                                                                                    item.template_id ===
                                                                                                    4
                                                                                                        ? 1 /
                                                                                                          1
                                                                                                        : 205 /
                                                                                                          74
                                                                                                }>
                                                                                                <Upload
                                                                                                    accept="image/*"
                                                                                                    showUploadList={
                                                                                                        false
                                                                                                    }
                                                                                                    customRequest={(
                                                                                                        e,
                                                                                                    ) =>
                                                                                                        uploadPic(
                                                                                                            e,
                                                                                                            'banner_images',
                                                                                                            item.id,
                                                                                                        )
                                                                                                    }
                                                                                                    beforeUpload={
                                                                                                        beforeUpload
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleUploadChange
                                                                                                    }>
                                                                                                    <Button
                                                                                                        disabled={
                                                                                                            !item
                                                                                                                .marketplace_api_params
                                                                                                                .param
                                                                                                        }
                                                                                                        icon={
                                                                                                            <UploadOutlined />
                                                                                                        }>
                                                                                                        Add
                                                                                                        Banner
                                                                                                    </Button>
                                                                                                </Upload>
                                                                                            </ImgCrop>
                                                                                        ) : (
                                                                                            // <Button
                                                                                            //   icon={<UploadOutlined />}
                                                                                            //   onClick={() => {
                                                                                            //     setSelectedItem({
                                                                                            //       itemId: item.id,
                                                                                            //       fieldName: 'banner_images'
                                                                                            //     })
                                                                                            //     setImageSelectorModal(true)
                                                                                            //   }}
                                                                                            //   disabled={!item.marketplace_api_params.param}
                                                                                            // >Add Banner</Button>
                                                                                            <>

                                                                                            </>
                                                                                        )
                                                                                    ) : item.template_id ===
                                                                                      8 ? (
                                                                                        item
                                                                                            .banner_images
                                                                                            .length <
                                                                                        1 ? (
                                                                                            <ImgCrop
                                                                                                rotate
                                                                                                beforeCrop={
                                                                                                    beforeUpload
                                                                                                }
                                                                                                aspect={
                                                                                                    item.template_id ===
                                                                                                    4
                                                                                                        ? 1 /
                                                                                                          1
                                                                                                        : 205 /
                                                                                                          74
                                                                                                }>
                                                                                                <Upload
                                                                                                    accept="image/*"
                                                                                                    showUploadList={
                                                                                                        false
                                                                                                    }
                                                                                                    customRequest={(
                                                                                                        e,
                                                                                                    ) =>
                                                                                                        uploadPic(
                                                                                                            e,
                                                                                                            'banner_images',
                                                                                                            item.id,
                                                                                                        )
                                                                                                    }
                                                                                                    beforeUpload={
                                                                                                        beforeUpload
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleUploadChange
                                                                                                    }>
                                                                                                    <Button
                                                                                                        disabled={
                                                                                                            !item
                                                                                                                .marketplace_api_params
                                                                                                                .param
                                                                                                        }
                                                                                                        icon={
                                                                                                            <UploadOutlined />
                                                                                                        }>
                                                                                                        Add
                                                                                                        Banner
                                                                                                    </Button>
                                                                                                </Upload>
                                                                                            </ImgCrop>
                                                                                        ) : (
                                                                                            // <Button
                                                                                            //   icon={<UploadOutlined />}
                                                                                            //   onClick={() => {
                                                                                            //     setSelectedItem({
                                                                                            //       itemId: item.id,
                                                                                            //       fieldName: 'banner_images'
                                                                                            //     })
                                                                                            //     setImageSelectorModal(true)
                                                                                            //   }}
                                                                                            //   disabled={!item.marketplace_api_params.param}
                                                                                            // >Add Banner</Button>
                                                                                            <>

                                                                                            </>
                                                                                        )
                                                                                    ) : (
                                                                                        <ImgCrop
                                                                                            rotate
                                                                                            beforeCrop={
                                                                                                beforeUpload
                                                                                            }
                                                                                            aspect={
                                                                                                item.template_id ===
                                                                                                4
                                                                                                    ? 1 /
                                                                                                      1
                                                                                                    : 205 /
                                                                                                      74
                                                                                            }>
                                                                                            <Upload
                                                                                                accept="image/*"
                                                                                                showUploadList={
                                                                                                    false
                                                                                                }
                                                                                                customRequest={(
                                                                                                    e,
                                                                                                ) =>
                                                                                                    uploadPic(
                                                                                                        e,
                                                                                                        'banner_images',
                                                                                                        item.id,
                                                                                                    )
                                                                                                }
                                                                                                beforeUpload={
                                                                                                    beforeUpload
                                                                                                }
                                                                                                onChange={
                                                                                                    handleUploadChange
                                                                                                }>
                                                                                                <Button
                                                                                                    disabled={
                                                                                                        !item
                                                                                                            .marketplace_api_params
                                                                                                            .param
                                                                                                    }
                                                                                                    icon={
                                                                                                        <UploadOutlined />
                                                                                                    }>
                                                                                                    Add
                                                                                                    Banner
                                                                                                </Button>
                                                                                            </Upload>
                                                                                        </ImgCrop>
                                                                                    )
                                                                                    // <Button
                                                                                    //   icon={<UploadOutlined />}
                                                                                    //   onClick={() => {
                                                                                    //     setSelectedItem({
                                                                                    //       itemId: item.id,
                                                                                    //       fieldName: 'banner_images'
                                                                                    //     })
                                                                                    //     setImageSelectorModal(true)
                                                                                    //   }}
                                                                                    //   disabled={!item.marketplace_api_params.param}
                                                                                    // >Add Banner</Button>
                                                                                }

                                                                                <PictureBannerStyle>
                                                                                    <DragDropContext
                                                                                        onDragEnd={(
                                                                                            result,
                                                                                        ) =>
                                                                                            HandleBannerPosition(
                                                                                                result,
                                                                                                item.id,
                                                                                            )
                                                                                        }>
                                                                                        <Droppable
                                                                                            droppableId="droppable-1"
                                                                                            type="Banner"
                                                                                            direction="horizontal">
                                                                                            {(
                                                                                                provided,
                                                                                                _,
                                                                                            ) => (
                                                                                                <div
                                                                                                    ref={
                                                                                                        provided.innerRef
                                                                                                    }
                                                                                                    {...provided.droppableProps}
                                                                                                    style={{
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        overflow:
                                                                                                            'auto',
                                                                                                        whiteSpace:
                                                                                                            'nowrap',
                                                                                                        width: '100%',
                                                                                                    }}>
                                                                                                    {item.banner_images.map(
                                                                                                        (
                                                                                                            elem,
                                                                                                            key,
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                <Draggable
                                                                                                                    key={
                                                                                                                        key
                                                                                                                    }
                                                                                                                    draggableId={`dragable-${key}`}
                                                                                                                    index={
                                                                                                                        key
                                                                                                                    }>
                                                                                                                    {(
                                                                                                                        provided,
                                                                                                                        snapshot,
                                                                                                                    ) => (
                                                                                                                        <Col
                                                                                                                            span={
                                                                                                                                6
                                                                                                                            }
                                                                                                                            ref={
                                                                                                                                provided.innerRef
                                                                                                                            }
                                                                                                                            {...provided.draggableProps}
                                                                                                                            {...provided.dragHandleProps}
                                                                                                                            style={{
                                                                                                                                ...provided
                                                                                                                                    .draggableProps
                                                                                                                                    .style,
                                                                                                                                boxShadow:
                                                                                                                                    snapshot.isDragging
                                                                                                                                        ? '0 0 .4rem #666'
                                                                                                                                        : 'none',
                                                                                                                            }}>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    backgroundColor:
                                                                                                                                        '#FFF',
                                                                                                                                    padding:
                                                                                                                                        '1rem',
                                                                                                                                    position:
                                                                                                                                        'relative',
                                                                                                                                    width: '100%',
                                                                                                                                }}>
                                                                                                                                <ImgCrop
                                                                                                                                    rotate
                                                                                                                                    beforeCrop={
                                                                                                                                        beforeUpload
                                                                                                                                    }
                                                                                                                                    aspect={
                                                                                                                                        item.template_id ===
                                                                                                                                        4
                                                                                                                                            ? 1 /
                                                                                                                                              1
                                                                                                                                            : 205 /
                                                                                                                                              74
                                                                                                                                    }>
                                                                                                                                    <Upload
                                                                                                                                        accept="image/*"
                                                                                                                                        listType="picture-card"
                                                                                                                                        className="avatar-uploader"
                                                                                                                                        showUploadList={
                                                                                                                                            false
                                                                                                                                        }
                                                                                                                                        customRequest={(
                                                                                                                                            e,
                                                                                                                                        ) =>
                                                                                                                                            uploadPic(
                                                                                                                                                e,
                                                                                                                                                'banner_images',
                                                                                                                                                item.id,
                                                                                                                                                elem.id,
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        beforeUpload={
                                                                                                                                            beforeUpload
                                                                                                                                        }
                                                                                                                                        onChange={
                                                                                                                                            handleUploadChange
                                                                                                                                        }>
                                                                                                                                        {
                                                                                                                                            <img
                                                                                                                                                src={
                                                                                                                                                    elem.image_url
                                                                                                                                                }
                                                                                                                                                alt="avatar"
                                                                                                                                                style={{
                                                                                                                                                    width: '100%',
                                                                                                                                                    height: '100%',
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        }
                                                                                                                                    </Upload>
                                                                                                                                </ImgCrop>
                                                                                                                                {/* <img src={elem.image_url} alt="avatar" style={{
                                                                    width: "300px",
                                                                    height: "300px",
                                                                    marginBottom: '16px'
                                                                  }} onClick={() => {
                                                                    setSelectedItem({
                                                                      itemId: item.id,
                                                                      bannerId: elem.id,
                                                                      fieldName: 'banner_images'
                                                                    })
                                                                    setImageSelectorModal(true)
                                                                  }} /> */}
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        display:
                                                                                                                                            'block',
                                                                                                                                    }}>
                                                                                                                                    <label>
                                                                                                                                        Target
                                                                                                                                        Url
                                                                                                                                    </label>
                                                                                                                                    <Input
                                                                                                                                        name={`banner_images[${itemList[
                                                                                                                                            itemList.findIndex(
                                                                                                                                                (
                                                                                                                                                    i,
                                                                                                                                                ) =>
                                                                                                                                                    i.id ===
                                                                                                                                                    item.id,
                                                                                                                                            )
                                                                                                                                        ].banner_images.findIndex(
                                                                                                                                            (
                                                                                                                                                i,
                                                                                                                                            ) =>
                                                                                                                                                i.id ===
                                                                                                                                                elem.id,
                                                                                                                                        )}].target_url`}
                                                                                                                                        defaultValue={
                                                                                                                                            elem.target_url
                                                                                                                                        }
                                                                                                                                        style={{
                                                                                                                                            display:
                                                                                                                                                'block',
                                                                                                                                        }}
                                                                                                                                        onChange={(
                                                                                                                                            e,
                                                                                                                                        ) =>
                                                                                                                                            changeValue(
                                                                                                                                                e,
                                                                                                                                                item.id,
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    )}
                                                                                                                </Draggable>
                                                                                                            );
                                                                                                        },
                                                                                                    )}
                                                                                                    {
                                                                                                        provided.placeholder
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                        </Droppable>
                                                                                    </DragDropContext>
                                                                                </PictureBannerStyle>
                                                                            </>
                                                                        )}
                                                                    </Panel>
                                                                </Collapse>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <Button
                            style={{ marginTop: '1rem' }}
                            type="dashed"
                            onClick={addItem}
                            block
                            icon={<PlusOutlined />}>
                            Add field
                        </Button>
                    </>
                )}
            </div>
        </HomePageStyle>
    );
};

export default Homepage;
