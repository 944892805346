import styled from "@emotion/styled";

const MerchantGalleryStyle = styled.div`
  .ant-upload-list-picture-card-container,
  .ant-upload {
    height: 150px;
    width: 150px;
  }
`;

export default MerchantGalleryStyle;
