import serviceApi from '../../../services';

const serviceKiddofestSchedule = {
    getListSchedule: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/schedule`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    createSchedule: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: `/kiddofest/schedule`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    editSchedule: async (data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/kiddofest/schedule`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    deleteSchedule: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/kiddofest/schedule/${id}`,
            version: 'v1',
            token: true,
        });
    },
    getDetailSchedule: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/kiddofest/schedule/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceKiddofestSchedule;
