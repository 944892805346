import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';
import { useRef } from 'react';
import InputMultipleStyle from './index.style';

export default function InputMultiple({
    placeholder = '',
    size = 'large',
    onChange = () => {},
    initialvalue = [],
    maxLink = 1,
}) {
    const inputRef = useRef(null);
    const [value, setValue] = React.useState('');
    const [valueInput, setValueInput] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [selectedIndexEdit, setSelectedIndexEdit] = React.useState(null);

    React.useEffect(() => {
        if (initialvalue.length > 0) {
            setValueInput(initialvalue);
        }
    }, [initialvalue]);

    React.useEffect(() => {
        onChange(valueInput);
    }, [valueInput]);

    const onAddLink = () => {
        if (selectedIndexEdit !== null) {
            valueInput[selectedIndexEdit] = inputRef.current.input.value;
            setValueInput(valueInput);
            setValue('');
            inputRef.current.input.value = '';
            setSelectedIndexEdit(null);
        } else {
            setValueInput((prevState) => [
                ...prevState,
                inputRef.current.input.value,
            ]);
            inputRef.current.input.value = '';
            setValue('');
        }
    };

    return (
        <InputMultipleStyle>
            <Input.Group compact>
                <Input
                    disabled={
                        selectedIndexEdit == null &&
                        valueInput.length == maxLink
                    }
                    onChange={(e) => {
                        e.preventDefault();
                        const validateUrl = e.target.value.match(
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                        );
                        if (validateUrl == null) {
                            setError('Text shouldbe Url Format');
                            setValue(e.target.value);
                        } else {
                            setValue(e.target.value);
                            setError(false);
                        }
                    }}
                    value={value}
                    ref={inputRef}
                    placeholder={placeholder}
                    size={size}
                    style={{ width: 'calc(100% - 200px)' }}
                />
                <Button
                    disabled={value == '' || error}
                    size="large"
                    type="primary"
                    onClick={onAddLink}>
                    {selectedIndexEdit !== null ? 'Update' : 'Add'}
                </Button>
            </Input.Group>
            <p style={{ color: '#d9363e', marginTop: '4px' }}>{error}</p>
            <div className="group-value">
                {valueInput.length > 0 &&
                    valueInput.map((item, key) => (
                        <div key={key} className="value-item">
                            <a href={item} target="_blank">
                                {item}
                            </a>
                            <div className="item-action">
                                <Button
                                    onClick={() => {
                                        setValue(item);
                                        setSelectedIndexEdit(key);
                                    }}
                                    style={{ marginRight: '12px' }}>
                                    <EditOutlined
                                        style={{ marginLeft: '8px' }}
                                    />
                                    Edit
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const copyValue = [...valueInput];
                                        copyValue.splice(key, 1);
                                        setValueInput(copyValue);
                                    }}
                                    danger>
                                    <DeleteOutlined
                                        style={{ color: '#d9363e' }}
                                    />
                                    Delete
                                </Button>
                            </div>
                        </div>
                    ))}
            </div>
        </InputMultipleStyle>
    );
}
