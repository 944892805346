import React from 'react';
import LMSQuizCreateStyle, { WysiwygStyle } from './index.style';
import { notifyError, notifySuccess } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Upload,
    Select,
} from 'antd';
import UploadFlat from '../../../../components/UploadFlat';
import ReactQuill from 'react-quill';
import DebounceSelectComponent from '../../../../components/DebounceSelect';
import serviceLMSQuiz from '../index.service';

const { Option } = Select;

const LMSQuizCreate = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const routes = [
        {
            path: '',
            breadcrumbName: 'Learners Camp Quiz',
        },
        {
            path: '',
            breadcrumbName: 'Create',
        },
    ];
    const [loading, setLoading] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const onFinish = async (value) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('class_id', value.class_id.value);
        formData.append('question_type', value.question_type);
        formData.append('question_for', value.question_for);
        formData.append('question_number', value.question_number);
        formData.append('question_of_number', value.question_of_number);
        formData.append('question', value.question);
        formData.append('answer_a', value.answer_a);
        formData.append('image_a', value.image_a);
        formData.append('answer_b', value.answer_b);
        formData.append('image_b', value.image_b);
        formData.append('answer_c', value.answer_c);
        formData.append('image_c', value.image_c);
        formData.append('answer_d', value.answer_d);
        formData.append('image_d', value.image_d);
        formData.append('answer_e', value.answer_e);
        formData.append('image_e', value.image_e);
        formData.append('answer_true', value.answer_true);

        const res = await serviceLMSQuiz.postQuizData(formData);

        if (res.isSuccess) {
            notifySuccess('Success add new quiz');
            history.push('/learnerscamp/quiz');
        } else {
            notifyError(res.message);
        }
        setIsLoading(false);
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <LMSQuizCreateStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Learners Camp Quiz"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="class_id"
                                label="Nama Kelas"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kelas wajib diisi',
                                    },
                                ]}>
                                <DebounceSelectComponent
                                    size="large"
                                    mode="single"
                                    onChange={(value) =>
                                        form.setFieldValue('class_id', value)
                                    }
                                    value={form.getFieldValue('class_id')}
                                    placeholder="Pilih Kelas"
                                    fetchList={async (value) => {
                                        const params = {
                                            queries: {
                                                page: 1,
                                                rows: 10,
                                            },
                                            filter: {
                                                'class.name': value,
                                            },
                                        };
                                        const result =
                                            await serviceLMSQuiz.getProduct(
                                                params,
                                            );
                                        if (result.isSuccess) {
                                            const resultData =
                                                result.response.payload &&
                                                result.response.payload.length >
                                                    0 &&
                                                result.response.payload.map(
                                                    (item) => ({
                                                        key: item.id,
                                                        label: `${
                                                            item.judul_kelas
                                                        } ${
                                                            !!item.sub_judul
                                                                ? `- ${item.sub_judul}`
                                                                : ''
                                                        }`,
                                                        value: item.id,
                                                    }),
                                                );
                                            return resultData;
                                        }
                                        return [];
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="question_type"
                                label="Tipe Pertanyaan"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tipe Pertanyaan wajib diisi',
                                    },
                                ]}>
                                <Select
                                    size="large"
                                    placeholder="Pilih Tipe Pertanyaan"
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'survey'}>
                                        Survey
                                    </Option>
                                    <Option key={2} value={'quiz'}>
                                        Quiz
                                    </Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="question_for"
                                label="Kategori Pertanyaan"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Kategori Pertanyaan wajib diisi',
                                    },
                                ]}>
                                <Select
                                    size="large"
                                    placeholder="Pilih salah satu pertanyaan untuk Orang Tua atau Anak"
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'parent'}>
                                        Parent
                                    </Option>
                                    <Option key={2} value={'child'}>
                                        Child
                                    </Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="question_number"
                                label="Nomor Pertanyaan"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nomor Pertanyaan wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    type="number"
                                    size="large"
                                    placeholder="Masukkan Nomor Pertanyaan"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="question_of_number"
                                label="Jumlah Pertanyaan"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Jumlah Pertanyaan wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    type="number"
                                    size="large"
                                    placeholder="Masukkan Jumlah Pertanyaan"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="question"
                                label="Isi Pertanyaan"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pertanyaan wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={form.getFieldsValue('question')}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            form.setFieldsValue({
                                                question: val || null,
                                            });
                                        }}
                                        placeholder="Isi Pertanyaan"
                                    />
                                </WysiwygStyle>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="answer_a"
                                label="Pilihan Jawaban A"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jawaban A wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input.TextArea
                                    style={{
                                        height: '110px',
                                        resize: 'none',
                                    }}
                                    size="large"
                                    placeholder="Masukkan Jawaban A"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image_a"
                                label="Properti Jawaban A"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <UploadFlat />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="answer_b"
                                label="Pilihan Jawaban B"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jawaban B wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input.TextArea
                                    style={{
                                        height: '110px',
                                        resize: 'none',
                                    }}
                                    size="large"
                                    placeholder="Masukkan Jawaban B"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image_b"
                                label="Properti Jawaban B"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <UploadFlat />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="answer_c"
                                label="Pilihan Jawaban C"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jawaban C wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input.TextArea
                                    style={{
                                        height: '110px',
                                        resize: 'none',
                                    }}
                                    size="large"
                                    placeholder="Masukkan Jawaban C"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image_c"
                                label="Properti Jawaban C"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <UploadFlat />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="answer_d"
                                label="Pilihan Jawaban D"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jawaban D wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input.TextArea
                                    style={{
                                        height: '110px',
                                        resize: 'none',
                                    }}
                                    size="large"
                                    placeholder="Masukkan Jawaban D"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image_d"
                                label="Properti Jawaban D"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <UploadFlat />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="answer_e"
                                label="Pilihan Jawaban E"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jawaban E wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input.TextArea
                                    style={{
                                        height: '110px',
                                        resize: 'none',
                                    }}
                                    size="large"
                                    placeholder="Masukkan Jawaban E"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image_e"
                                label="Properti Jawaban E"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <UploadFlat />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="answer_true"
                                label="Jawaban yang benar"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan Day"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Form.Item name="submit">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={isLoading}
                            style={{ marginRight: 10, width: 200 }}
                            disabled={isLoading ? true : false}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                history.goBack();
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </LMSQuizCreateStyle>
    );
};

export default LMSQuizCreate;
