import React from 'react';
import KioSubEpisodeStyle from './index.style';
import serviceKioMasterEpisode, {
    serviceKioSubEpisode,
} from '../index.service';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { Link, useHistory, useParams } from 'react-router-dom';
import ModalRemove from '../../../../components/ModalRemove';
import { notifyError, notifySuccess } from '../../../../utils';

const KioSubEpisodeList = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useHistory();
    const params = useParams();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filters: {
            id_kio_episode: params.id,
            deleted_at: 'null',
        },
    });
    const [dataSubEpisode, setDataSubEpisode] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
        return () => {
            setDataSubEpisode([]);
        };
    }, []);

    const handleLoadData = async (query) => {
        setIsLoading(true);
        let resultQuery = { ...query };
        const resultDetailEpisode =
            await serviceKioMasterEpisode.getdetailEpisode(params.id);
        const result = await serviceKioSubEpisode.getListSubEpisode(
            resultQuery,
        );
        if (result.isSuccess) {
            if (!!!result.response.payload) {
                setIsLoading(false);
                return false;
            }
            const mergeWithParent = result.response.payload.map((item) => ({
                ...item,
                parent_title: resultDetailEpisode.response.data.title,
            }));
            result.response.payload = mergeWithParent;
            setDataSubEpisode(result.response);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            notifyError(result.response.message);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 60,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Judul Episode',
            dataIndex: 'parent_title',
            key: 'parent_title',
            ellipsis: true,
        },
        {
            title: 'Sub Judul',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
        },
        {
            title: 'Deskripsi',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            render: (value) => {
                return (
                    <img
                        src={`${value}?id=${
                            Math.floor(Math.random() * 50) + 1
                        }`}
                        width={180}
                    />
                );
            },
        },
        {
            title: 'Link Youtube',
            dataIndex: 'link_youtube',
            key: 'link_youtube',
            ellipsis: true,
            render: (value) => {
                return !!value ? (
                    <a href={value} target="_blank">
                        {value}
                    </a>
                ) : (
                    '-'
                );
            },
        },
        {
            title: 'Aksi',
            width: 80,
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history.push(
                                        `/kio/sub/episode/edit/${params.id}/${record.id}`,
                                    )
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/kio/sub/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage: 'Sukses Hapus Data',
                                        },
                                        onReGetData: () => {
                                            handleLoadData(queryString);
                                        },
                                        title: 'Konfirmasi Hapus Sub Episode!',
                                        desc: 'Apakah anda yakin ingin menghapus Sub Episode ini',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'list',
            breadcrumbName: 'LIST',
        },
        {
            path: 'index',
            breadcrumbName: 'SUB EPISODES',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filters['kiddofest_banner.category'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <KioSubEpisodeStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.push('/kio/episode')}
                    className="site-page-header"
                    title="SUB EPISODE"
                    breadcrumb={{ routes }}
                    subTitle=""
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to={`/kio/sub/episode/create/${params.id}`}>
                        + Tambah Episode
                    </Link>
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Cari by kategori"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataSubEpisode.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataSubEpisode.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </KioSubEpisodeStyle>
    );
};

export default KioSubEpisodeList;
