import React, { useEffect, useState } from 'react';
import ProviderPrefixAddStyle from './index.style';
import {
    Button,
    Col,
    Form,
    Input,
    PageHeader,
    Row,
    Select,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import serviceProviderPrefix from '../index.service';
import { notifyError, notifySuccess } from '../../../../utils';

const ProviderPrefixAdd = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [dataPrefix, setDataPrefix] = useState([]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'PROVIDER PREFIX',
        },
        {
            path: '',
            breadcrumbName: 'BUAT',
        },
    ];

    useEffect(() => {
        // onGetDataPrefix();
    }, []);

    const onGetDataPrefix = async () => {
        const result = await serviceProviderPrefix.listProviderPrefix();
        if (result.isSuccess) {
            const resultPrefix = result.response.payload.map((item) => ({
                label: item.prefix,
                value: item.id,
            }));
            setDataPrefix(resultPrefix);
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        // const formData = new FormData();
        // formData.append('provider', values.provider);
        // formData.append('prefix', values.prefix);
        // formData.append('updated_at', moment().format('YYYY-MM-DDTHH:mm:ssZ'));
        // formData.append('created_at', moment().format('YYYY-MM-DDTHH:mm:ssZ'));
        const resultValues = {
            provider: values.provider,
            prefix: values.prefix.join(','),
            // updated_at: `${moment(values.updated_at).format('YYYY-MM-DD')}`,
        };
        const results = await serviceProviderPrefix.createProviderPrefix(
            resultValues,
        );
        if (results.isSuccess) {
            notifySuccess('Sukses menambahkan data provider prefix');
            history.push('/ppob/provider');
        } else {
            notifyError(results.message);
        }
        setLoading(false);
    };

    return (
        <ProviderPrefixAddStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Form Provider Prefix"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="provider"
                            label="Nama Provider"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Provider wajib diisi',
                                },
                            ]}>
                            <Input
                                size="large"
                                type={'text'}
                                placeholder="Masukkan Nama Provider"
                            />
                        </Form.Item>
                    </Col>

                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="prefix"
                            label="Nomor Prefix"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Prefix wajib diisi',
                                },
                            ]}>
                            <Select
                                mode="tags"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Input & Select Prefix"></Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="submit">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={loading}
                            style={{
                                marginRight: 10,
                                marginTop: '24px',
                                width: 200,
                            }}
                            disabled={loading ? true : false}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                history.goBack();
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </ProviderPrefixAddStyle>
    );
};

export default ProviderPrefixAdd;
