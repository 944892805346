import serviceApi from '../../../services';

const servicesTransaction = {
    getData: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/reports/transaction`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    getDataLocation: async () => {
        return await serviceApi({
            method: 'GET',
            url: '/reports/transaction/locations',
            version: 'v1',
            token: true,
        });
    },
};

export default servicesTransaction;
