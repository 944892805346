import serviceApi from "../../services"

const galleryService = {
  getPicture: async () => {
    return await serviceApi({
      method: 'GET',
      url: `/public/media`,
      version: 'v1',
      token: true,
    })
  },
  uploadPicture: async (data) => {
    return await serviceApi({
      method: "POST",
      url: '/public/media/file/upload',
      version: 'v1',
      token: true,
      data: data,
      formData: true,
    })
  },
  addPicture: async (data) => {
    return await serviceApi({
      method: "POST",
      url: '/public/media',
      version: 'v1',
      token: true,
      data: data,
    })
  },
  updatePicture: async (data) => {
    return await serviceApi({
      method: "PUT",
      url: '/public/media',
      version: 'v1',
      token: true,
      data: data,
    })
  },
  deletePicture: async (id) => {
    return await serviceApi({
      method: "DELETE",
      url: `/public/media/${id}`,
      version: 'v1',
      token: true,
    })
  }
}

export default galleryService;
