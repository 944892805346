import { Button, PageHeader, Table } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate, formatStringDate } from '../../../utils/format-date';
import servicesTransaction from './index.service';
import TrackerTransactionStyle from './index.style';

const TrackerTransaction = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [queryString, setQueryString] = React.useState({
        groups: {
            unit_time: 'day',
        },
        queries: {
            row: 10,
            page: 1,
        },
    });
    const [dataTrasaction, setDataTransaction] = React.useState([]);
    const [columnLocation, setColumnLocation] = React.useState([]);

    React.useEffect(() => {
        loadDataLocation();
        handleLoadData(queryString);
    }, []);

    const onHandleColumnLocation = (param = []) => {
        if (param && param.length > 0) {
            setColumnLocation(() => [
                ...(param.length > 0 &&
                    param.map((item) => {
                        return {
                            title: item,
                            key: item.split(' ').join('_').toLowerCase(),
                            dataIndex: item.split(' ').join('_').toLowerCase(),
                            width: 150,
                        };
                    })),
            ]);
        }
    };

    const loadDataLocation = async () => {
        let result = await servicesTransaction.getDataLocation();
        if (result.isSuccess) {
            onHandleColumnLocation(result.response.payload);
        }
    };

    const manageLocation = (data = []) => {
        let tempData = { ...data };
        for (let i = 0; i < data.payload.length; i++) {
            for (
                let j = 0;
                j < data?.payload[i]?.product_locations?.length;
                j++
            ) {
                tempData.payload[i][
                    tempData.payload[i].product_locations[j].name
                        .split(' ')
                        .join('_')
                        .toLowerCase()
                ] = tempData?.payload[i]?.product_locations[j].count;
            }
        }
        return tempData;
    };

    const handleLoadData = async (params) => {
        setIsLoading(true);
        const result = await servicesTransaction.getData(params);
        if (result.isSuccess && result.response) {
            setDataTransaction(manageLocation(result.response));
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: 'Tanggal',
            dataIndex: 'timestamp',
            key: 'timestamp',
            fixed: 'left',
            width: 150,
            filters: [
                { text: 'Daily', value: 'day' },
                { text: 'Weekly', value: 'week' },
                { text: 'Monthly', value: 'month' },
            ],
            filteredValue: [queryString.groups.unit_time] || null,
            filterMultiple: false,
            ellipsis: true,
            render: (_, record) => {
                return formatStringDate(record.timestamp, 'DD-MM-YYYY', 'LL');
            },
        },
        {
            title: 'Promo Code',
            children: [
                {
                    title: 'Generic',
                    width: 150,
                    key: 'generic',
                    dataIndex: 'voucher_usage_generic',
                },
                {
                    title: 'Unique',
                    key: 'unique',
                    dataIndex: 'voucher_usage_unique',
                    width: 150,
                },
            ],
        },
        {
            title: 'Sold Product Type',
            children: [
                {
                    title: 'Aktivitas',
                    key: 'aktivitas',
                    dataIndex: 'product_type_activity',
                    width: 150,
                },
                {
                    title: 'Kursus',
                    dataIndex: 'product_type_course',
                    key: 'kursus',
                    width: 150,
                },
                {
                    title: 'Daycare',
                    dataIndex: 'product_type_daycare',
                    key: 'daycare',
                    width: 150,
                },
                {
                    title: 'Activity Kit',
                    dataIndex: 'product_type_activity_kit',
                    key: 'activitykit',
                    width: 150,
                },
                {
                    title: 'Experience',
                    dataIndex: 'product_type_experience',
                    key: 'experience',
                    width: 150,
                },
                {
                    title: 'Online Class',
                    dataIndex: 'product_type_online_class',
                    key: 'onlineclass',
                    width: 150,
                },
                {
                    title: 'Kesehatan',
                    dataIndex: 'product_type_kesehatan',
                    key: 'kesehatan',
                    width: 150,
                },
                {
                    title: 'Keluarga',
                    dataIndex: 'product_type_keluarga',
                    key: 'keluarga',
                    width: 150,
                },
            ],
        },
        {
            title: 'Sold Product Category',
            children: [
                {
                    title: 'Festival',
                    dataIndex: 'product_category_festival',
                    key: 'aktivitas',
                    width: 150,
                },
                {
                    title: 'Kerajinan Tangan',
                    dataIndex: 'product_category_handycraft',
                    key: 'kerajinan-tangan',
                    width: 150,
                },
                {
                    title: 'Kognitif',
                    dataIndex: 'product_category_cognitive',
                    key: 'kognitif',
                    width: 150,
                },
                {
                    title: 'Kompetisi',
                    dataIndex: 'product_category_competition',
                    key: 'kompetisi',
                    width: 150,
                },
                {
                    title: 'Komunikasi',
                    dataIndex: 'product_category_communication',
                    key: 'komunikasi',
                    width: 150,
                },
                {
                    title: 'Kulinari',
                    dataIndex: 'product_category_culinary',
                    key: 'kulinari',
                    width: 150,
                },
                {
                    title: 'Motorik',
                    dataIndex: 'product_category_motorize',
                    key: 'motorik',
                    width: 100,
                },
                {
                    title: 'Olahraga',
                    dataIndex: 'product_category_sport',
                    key: 'olahraga',
                    width: 150,
                },
                {
                    title: 'Parenting',
                    dataIndex: 'product_category_parenting',
                    key: 'parenting',
                    width: 150,
                },
                {
                    title: 'Perawatan',
                    dataIndex: 'product_category_nursing',
                    key: 'perawatan',
                    width: 150,
                },
                {
                    title: 'Pertunjukan',
                    dataIndex: 'product_category_performance',
                    key: 'pertunjukan',
                    width: 150,
                },
                {
                    title: 'Playdate',
                    dataIndex: 'product_category_playdate',
                    key: 'playdate',
                    width: 150,
                },
                {
                    title: 'Rekreasi',
                    dataIndex: 'product_category_recreation',
                    key: 'rekreasi',
                    width: 150,
                },
                {
                    title: 'Sains',
                    dataIndex: 'product_category_science',
                    key: 'sains',
                    width: 150,
                },
                {
                    title: 'Seni Drama',
                    dataIndex: 'product_category_drama',
                    key: 'seni-drama',
                    width: 150,
                },
                {
                    title: 'Seni Lukis',
                    dataIndex: 'product_category_painting',
                    key: 'seni-lukis',
                    width: 150,
                },
                {
                    title: 'Seni Musik',
                    dataIndex: 'product_category_music',
                    key: 'seni-musik',
                    width: 150,
                },
                {
                    title: 'Seni Tari',
                    dataIndex: 'product_category_dance',
                    key: 'seni-tari',
                    width: 150,
                },
                {
                    title: 'Sensorik',
                    dataIndex: 'product_category_sensory',
                    key: 'sensorik',
                    width: 150,
                },
                {
                    title: 'Teknologi',
                    dataIndex: 'product_category_technology',
                    key: 'teknologi',
                    width: 150,
                },
            ],
        },
        {
            title: 'Product Province',
            children: [...columnLocation],
        },
        {
            title: 'Payment Channel',
            children: [
                {
                    title: 'VA BCA',
                    dataIndex: 'payment_channel_bca_virtual_account',
                    key: 'va_bca',
                    width: 150,
                },
                {
                    title: 'VA BNI',
                    dataIndex: 'payment_channel_bni_virtual_account',
                    key: 'va_bni',
                    width: 150,
                },
                {
                    title: 'VA Mandiri',
                    dataIndex: 'payment_channel_mandiri_virtual_account',
                    key: 'va_mandiri',
                    width: 150,
                },
                {
                    title: 'VA Permata',
                    dataIndex: 'payment_channel_permata_virtual_account',
                    key: 'va_permata',
                    width: 150,
                },
                {
                    title: 'OVO',
                    dataIndex: 'payment_channel_ovo',
                    key: 'ovo',
                    width: 150,
                },
                {
                    title: 'Gopay',
                    dataIndex: 'payment_channel_gopay',
                    key: 'gopay',
                    width: 150,
                },
                {
                    title: 'LinkAja',
                    dataIndex: 'payment_channel_linkaja',
                    key: 'linkaja',
                    width: 150,
                },
                {
                    title: 'Credit Card',
                    dataIndex: 'payment_channel_credit_card',
                    key: 'credit_card',
                    width: 150,
                },
                {
                    title: 'Bca Klikpay',
                    dataIndex: 'payment_channel_bca_klikpay',
                    key: 'bca_klikpay',
                    width: 150,
                },
            ],
        },
        {
            title: 'Participant Age Group',
            children: [
                {
                    title: '0 - 3 years',
                    dataIndex: 'participat_age_0_to_4_years',
                    key: '0_3_years',
                    width: 150,
                },
                {
                    title: '4 - 5 years',
                    dataIndex: 'participat_age_4_to_5_years',
                    key: '4_5_years',
                    width: 150,
                },
                {
                    title: '6 - 8 years',
                    dataIndex: 'participat_age_6_to_8_years',
                    key: '6_8_years',
                    width: 150,
                },
                {
                    title: '9 - 12 years',
                    dataIndex: 'participat_age_9_to_12_years',
                    key: '9_12_years',
                    width: 150,
                },
                {
                    title: '12+ years',
                    dataIndex: 'participat_age_12_plus_years',
                    key: '12_plus_years',
                    width: 150,
                },
                {
                    title: 'Parents',
                    dataIndex: 'participat_age_parents',
                    key: 'parents',
                    width: 150,
                },
            ],
        },
        {
            title: 'Customer Retention',
            children: [
                {
                    title: '0',
                    dataIndex: 'customer_retention_0_x',
                    key: 'cr_0',
                    width: 150,
                },
                {
                    title: '1x',
                    dataIndex: 'customer_retention_1_x',
                    key: 'cr_1x',
                    width: 150,
                },
                {
                    title: '2x',
                    dataIndex: 'customer_retention_2_x',
                    key: 'cr_2x',
                    width: 150,
                },
                {
                    title: '3x',
                    dataIndex: 'customer_retention_3_x',
                    key: 'cr_3x',
                    width: 150,
                },
                {
                    title: 'lebih dari 3x',
                    dataIndex: 'customer_retention_more_than_3_x',
                    key: 'cr_up_3x',
                    width: 150,
                },
            ],
        },
        {
            title: 'Customer Spending Within 8 Weeks',
            children: [
                {
                    title: '100 - 200rb',
                    dataIndex: 'customer_spending_100_to_200',
                    key: 'sp_100_200',
                    width: 150,
                },
                {
                    title: '201 - 300rb',
                    dataIndex: 'customer_spending_201_to_300',
                    key: 'sp_201_300',
                    width: 150,
                },
                {
                    title: '301 - 400rb',
                    dataIndex: 'customer_spending_301_to_400',
                    key: 'sp_301_400',
                    width: 150,
                },
                {
                    title: '401 - 500rb',
                    dataIndex: 'customer_spending_401_to_500',
                    key: 'sp_401_500',
                    width: 150,
                },
                {
                    title: 'di atas 500rb',
                    dataIndex: 'customer_spending_more_than_500',
                    key: 'sp_up_500',
                    width: 150,
                },
            ],
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Tracker Report',
        },
        {
            path: 'transaction',
            breadcrumbName: 'Transaction',
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let tempQuery = { ...queryString };
        if (!!filters.timestamp) {
            tempQuery.groups.unit_time = filters.timestamp[0];
        }
        tempQuery.queries.row = pagination.pageSize;
        tempQuery.queries.page = pagination.current;
        setQueryString(tempQuery);
        handleLoadData(tempQuery);
    };

    return (
        <TrackerTransactionStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Tracker Report"
                    breadcrumb={{ routes }}
                    subTitle="Table of transaction"
                />
            </div>
            <Table
                scroll={{ x: 1500, y: 1000 }}
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataTrasaction.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataTrasaction.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </TrackerTransactionStyle>
    );
};

export default TrackerTransaction;
