import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import serviceProductCreate from './index.service';
import UpdateProductStyle from './index.style';
import { PageHeader, Modal } from 'antd';
import FormProduct from '../../../components/Product/FormProduct';

function ProductCreate(props) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(null);

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Product',
        },
        {
            path: 'merchant-gallery',
            breadcrumbName: 'Update',
        },
    ];

    useEffect(() => {
        getProductDetail(props.match.params.id);
    }, []);

    const handleUpdateProduct = async (data) => {
        setIsLoading(true);
        const result = await serviceProductCreate.updateProduct(data);
        if (result.isSuccess) {
            modalSuccess();
            setIsLoading(false);
        } else {
            Modal.error('Gagal mendapatkan data product detail!');
            setIsLoading(false);
        }
    };

    const getProductDetail = async (id) => {
        const result = await serviceProductCreate.getProductDetail(id);
        if (result.isSuccess) {
            setInitialValues(result.response);
        } else {
            Modal.error('Upsss, gagal mendapatkan detail product!');
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil mengubah data product',
            onOk: () => {
                history.push('/product');
            },
        });
    };

    return (
        <UpdateProductStyle>
            <PageHeader
                onBack={() => history.push('/product')}
                className="site-page-header"
                title="Product Update"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <div>
                <FormProduct
                    handleSubmit={handleUpdateProduct}
                    initVal={initialValues}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isCreate={false}
                    isDuplicate={false}
                />
            </div>
        </UpdateProductStyle>
    );
}

export default ProductCreate;
