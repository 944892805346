import styled from "@emotion/styled";

const ProductListStyle = styled.div`
  display: block;
  width: 100%;

  table {
    box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 4px 0 rgba(0, 0, 0, 0.19);
  }
  .pagination {
    float: right;
    margin: 10px auto;
    padding-bottom: 25px;
  }
`;

export default ProductListStyle;
