import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    PageHeader,
    message,
    Row,
    Select,
    Upload,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceKiddofestGallery from '../index.service';
import KiddofestGalleryEditStyle from './index.style';

const { Option } = Select;

export default function KiddofestGalleryEdit() {
    const history = useHistory();
    const params = useParams();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);
    const [dataGallery, setDataGallery] = React.useState();

    React.useEffect(() => {
        onGetData(params.id);
    }, []);

    const onGetData = async (id) => {
        const res = await serviceKiddofestGallery.getdetailGallery(id);
        if (res.isSuccess) {
            const resultData = res.response.payload;
            const dataForm = {
                id: resultData.id,
                year: resultData.year,
                size: resultData.size,
                image: resultData.image,
            };
            form.setFieldsValue(dataForm);
            setFileUpload([
                {
                    uid: '1',
                    name: 'image',
                    status: 'done',
                    url: resultData.image,
                    size: 1999,
                },
            ]);
            setDataGallery(res.response.payload);
        } else {
            notifyError(res.message);
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const routes = [
        {
            path: '',
            breadcrumbName: 'GALERI',
        },
        {
            path: '',
            breadcrumbName: 'EDIT',
        },
    ];

    const onFinish = async (value) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', dataGallery.id);
        formData.append('year', value.year);
        formData.append('size', value.size);
        if (typeof value.image == 'string') {
            formData.append('image', value.image);
        } else {
            formData.append('file', value.image.file.originFileObj);
        }
        const res = await serviceKiddofestGallery.updateDataGallery(formData);
        if (res.isSuccess) {
            setIsLoading(false);
            notifySuccess('Sukses update foto');
            history.push('/kiddofest/gallery');
        } else {
            setIsLoading(false);
            notifyError('Something went wrong');
        }
    };

    return (
        <KiddofestGalleryEditStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="EDIT DATA GALERI"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="year"
                                label="Edit Tahun Foto"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kategori foto wajib diisi',
                                    },
                                ]}>
                                 <Select
                                    placeholder="Edit Tahun Foto"
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'2018'}>
                                        2018
                                    </Option>
                                    <Option key={2} value={'2019'}>
                                        2019
                                    </Option>
                                    <Option key={3} value={'2020'}>
                                        2020
                                    </Option>
                                    <Option key={4} value={'2022'}>
                                        2022
                                    </Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="size"
                                label="Edit Skala Foto"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Skala foto wajib diisi',
                                    },
                                ]}>
                                <Select
                                    placeholder="Note : Skala foto 797x350 disetiap tahunnyadiberikan 1 ruang, sisanya skala 390x350"
                                    style={{ width: '100%' }}>
                                    <Option key={1} value={'797x350'}>
                                        Skala Foto 797 x 350
                                    </Option>
                                    <Option key={2} value={'390x350'}>
                                        Skala Foto 390 x 350
                                    </Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Edit Foto"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Foto wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onPreview={() => {}}
                                    onChange={(info) => {
                                        const isLt2M =
                                            info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error(
                                                'Foto harus lebih kecil dari 2 MB!',
                                            );
                                        } else {
                                            setFileUpload(info.fileList);
                                        }
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}>
                                    {fileUpload.length == 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload Foto
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Simpan
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history.goBack();
                                    }}>
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </KiddofestGalleryEditStyle>
    );
}
