import serviceApi from '../../../services/index';

const serviceJournalImmunization = {
    getImmunizationList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `/immunization`,
            version: 'v1',
            token: true,
            params: params,
        });
    },
    addImmunization: async (data) => {
        return await serviceApi({
            method: 'POST',
            url: '/immunization',
            version: 'v1',
            token: true,
            data: data,
        });
    },
    onGetDetailImmunization: async (id) => {
        return await serviceApi({
            method: 'GET',
            url: `/immunization/${id}`,
            version: 'v1',
            token: true,
        });
    },
    updateImmunization: async (id, data) => {
        return await serviceApi({
            method: 'PUT',
            url: `/immunization/${id}`,
            version: 'v1',
            token: true,
            data: data,
        });
    },
    onDeleteImmunization: async (id) => {
        return await serviceApi({
            method: 'DELETE',
            url: `/immunization/${id}`,
            version: 'v1',
            token: true,
        });
    },
};

export default serviceJournalImmunization;
