import styled from '@emotion/styled';

const ProductGiftAddStyle = styled.div`
.avatar-uploader > .ant-upload {
    width: 300px;
    height: 300px;
  }

  .ql-editor {
    min-height: 18em;
    }
`;

export default ProductGiftAddStyle;
