import {
    Card,
    Col,
    Descriptions,
    PageHeader,
    Divider,
    Row,
    Table,
    Tag,
} from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import OrderGiftDetailStyle from './index.style';
import serviceOrderGiftDetail from '../index.service';
import OrderGiftDetailLoading from './index.loading';
import { formatMoney } from '../../../utils/format-money';
import { formatDate } from '../../../utils/format-date';
import moment from 'moment';

const OrderGiftDetail = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = React.useState(true);
    const [dataOrderDetail, setDataOrderDetail] = React.useState({});

    React.useEffect(() => {
        handleLoadData(props.match.params.id);
    }, []);

    const handleLoadData = async (id) => {
        const result = await serviceOrderGiftDetail.getDataGiftDetail(id);
        if (result.isSuccess && !!result.response) {
            setDataOrderDetail(result.response.payload);
            setLoading(false);
        }
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Gift Order',
        },
        {
            path: 'detail',
            breadcrumbName: 'Detail',
        },
    ];

    const columns = [
        {
            id: 'name',
            key: 'name',
            title: 'Nama Penerima',
            render: (_, record) => {
                return record.name_receiver;
            },
        },
        {
            title: 'Email',
            render: (_, record) => {
                return record.email_receiver;
            },
        },
        {
            title: 'No Hp',
            render: (_, record) => {
                return record.phone_receiver;
            },
        },
        {
            title: 'Kode Voucher',
            render: (_, record) => {
                return record.voucher;
            },
        },
        {
            title: 'Masa Voucher',
            render: (_, record) => {
                return record.voucher_expired;
            },
        },
    ];

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'Completed') {
            color = 'green';
        }
        if (status === 'Pending') {
            color = 'geekblue';
        }
        if (status === 'Cancelled') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    return (
        <OrderGiftDetailStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Order Detail"
                breadcrumb={{ routes }}
                subTitle="Table of gift order detail"
            />
            {isLoading ? (
                <OrderGiftDetailLoading />
            ) : (
                <Row gutter={16}>
                    <Col span={24}>
                        <Card
                            loading={isLoading}
                            title="Order Gift Details"
                            bordered={true}>
                            <Descriptions title="" bordered>
                                <Descriptions.Item label="Order ID" span={1}>
                                    {dataOrderDetail.id}
                                </Descriptions.Item>

                                <Descriptions.Item label="Date">
                                    {moment
                                        .utc(dataOrderDetail.date_order)
                                        .format('DD MMMM YYYY')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Updated Timestamp">
                                    {formatDate(
                                        dataOrderDetail.updated_at,
                                        'LL',
                                    )}
                                </Descriptions.Item>

                                <Descriptions.Item label="Nama Gift Produk">
                                    {dataOrderDetail.items[0].product_name}
                                </Descriptions.Item>

                                <Descriptions.Item label="Kategori">
                                    {dataOrderDetail.items[0].categorie}
                                </Descriptions.Item>

                                <Descriptions.Item label="Harga Utama">
                                    {formatMoney(dataOrderDetail.price)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Harga Diskon">
                                    {dataOrderDetail.sale_price
                                        ? formatMoney(
                                              dataOrderDetail.sale_price,
                                          )
                                        : '-'}
                                </Descriptions.Item>

                                <Descriptions.Item label="Service Pay">
                                    {formatMoney(
                                        dataOrderDetail.payment_charge,
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label=" Payment Gateway Vendor">
                                    {dataOrderDetail.payment_vendor}
                                </Descriptions.Item>
                                <Descriptions.Item label=" Pembayatan Via">
                                    {dataOrderDetail.payment_method}
                                </Descriptions.Item>
                                <Descriptions.Item label="Payment Gateway Status">
                                    {handleLabelStatus(
                                        dataOrderDetail.status_payment,
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Payment Gateway Info">
                                    {dataOrderDetail.payment_gateway_linkpay && (
                                        <>
                                            <p>Link pay : </p>
                                            {
                                                dataOrderDetail.payment_gateway_linkpay
                                            }
                                            <Divider />
                                        </>
                                    )}
                                    {dataOrderDetail.payment_gateway_send && (
                                        <>
                                            <p>Payment gateway send : </p>
                                            {
                                                dataOrderDetail.payment_gateway_send
                                            }
                                            <Divider />
                                        </>
                                    )}

                                    {dataOrderDetail.payment_gateway_webhook && (
                                        <>
                                            <p>Payment gateway webhook : </p>
                                            {
                                                dataOrderDetail.payment_gateway_webhook
                                            }
                                            <Divider />
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            loading={isLoading}
                            title="Data Pembeli : User Kiddo"
                            bordered={true}>
                            <Descriptions bordered>
                                <Descriptions.Item
                                    label="Nama Penerima"
                                    span={3}>
                                    {dataOrderDetail.name_buyer}
                                </Descriptions.Item>
                                <Descriptions.Item label="No Hp" span={3}>
                                    {dataOrderDetail.phone_buyer}
                                </Descriptions.Item>
                                <Descriptions.Item label="Email" span={3}>
                                    {dataOrderDetail.email_buyer}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col span={16}>
                        {!isLoading && (
                            <Card
                                loading={isLoading}
                                title="Data Penerima Gift"
                                bordered>
                                <Table
                                    loading={isLoading}
                                    columns={columns}
                                    dataSource={dataOrderDetail.items}
                                />
                            </Card>
                        )}
                    </Col>
                </Row>
            )}
        </OrderGiftDetailStyle>
    );
};

export default OrderGiftDetail;
