import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import serviceApi from '../../services';
import { notifyError, notifySuccess } from '../../utils';

export default function ModalRemove({
    title = 'Delete Confirmation',
    desc = 'Are You Sure To Delete This Data',
    okText = 'Yes',
    cancelText = 'Cancel',
    onReGetData = () => {},
    handleReset = () => {},
    configAPI = {
        url: '',
        params: '',
        method: 'delete',
        version: '',
        token: true,
        successMessage: 'Success Remove Data',
    },
}) {
    Modal.confirm({
        style: { width: '400px' },
        title: '',
        icon: null,
        width: 383,
        icon: <ExclamationCircleOutlined />,
        okButtonProps: { size: 'large' },
        cancelButtonProps: { size: 'large' },
        onOk: async () => {
            const { url, method, successMessage } = configAPI;
            if (url.length > 0) {
                const response = await serviceApi({
                    ...configAPI,
                });
                if (response.isError) {
                    notifyError(response.message);
                } else {
                    notifySuccess(successMessage);
                }
                handleReset();
                onReGetData();
                return true;
            }
        },
        onCancel() {},
        content: (
            <div className="modal__table--remove-content">
                <div className="modal__table--remove-title">{title}</div>
                <div className="modal__table--remove-desc">{desc}</div>
            </div>
        ),
        okText: okText,
        cancelText: cancelText,
        getContainer: document.getElementById('modal-remove'),
    });
}
