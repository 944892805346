import styled from "@emotion/styled";

const CreateProductStyle = styled.div`
  .avatar-uploader > .ant-upload {
    width: 300px;
    height: 300px;
  }
`;

export default CreateProductStyle;
