import styled from "@emotion/styled";

const BannerStyle = styled.div`
  .btn-update {
    position: absolute;
    right: 10px;
    z-index: 999;
    top: -35px;
  }
`;

export default BannerStyle;
