import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Upload,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../../utils';
import SubEpisodeCreateStyle from './index.style';
import serviceKioMasterEpisode, {
    serviceKioSubEpisode,
} from '../../index.service';

const SubEpisodeCreate = () => {
    const history = useHistory();
    const [initialValue, setInitialValue] = React.useState({
        title: '',
        sub_title: '',
        description: '',
        image: '',
        link_youtube: '',
    });
    const [isLoading, setIsLoading] = React.useState(true);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);
    const routes = [
        {
            path: '',
            breadcrumbName: 'SUB EPISODE',
        },
        {
            path: '',
            breadcrumbName: 'FORM',
        },
    ];
    const params = useParams();

    React.useEffect(() => {
        onGetDetailParentEpisode();
    }, []);

    const onGetDetailParentEpisode = async () => {
        setIsLoading(true);
        const results = await serviceKioMasterEpisode.getdetailEpisode(
            params.id,
        );
        if (results.isSuccess) {
            setInitialValue((prevState) => ({
                ...prevState,
                title: results.response.data.title,
            }));
            setIsLoading(false);
        }
    };

    const onFinish = async (value) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('kio_episode', params.id);
        formData.append('title', value.sub_title);
        formData.append('description', value.description);
        formData.append('photo', value.image.file.originFileObj);
        formData.append('link_youtube', value.link_youtube);
        const res = await serviceKioSubEpisode.postSubEpisode(formData);
        if (res.isSuccess) {
            setIsLoading(false);
            notifySuccess('Sukses menambahkan Sub Episode');
            history.push(`/kio/sub/episode/${params.id}`);
        } else {
            setIsLoading(false);
            notifyError('Something went wrong');
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    return (
        <SubEpisodeCreateStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Create Sub Episode Kio"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            {!isLoading && (
                <Form
                    layout="vertical"
                    initialValues={initialValue}
                    form={form}
                    onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col md={16} sm={24} xs={24}>
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="title"
                                    label="Judul Episode"
                                    style={{ marginBottom: 0 }}
                                    validateTrigger={['onChange', 'onBlur']}>
                                    <Input
                                        disabled
                                        size="large"
                                        placeholder="Masukkan Judul Episode"
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="sub_title"
                                    label="Sub Judul Episode"
                                    style={{ marginBottom: 0 }}
                                    validateTrigger={['onChange', 'onBlur']}>
                                    <Input
                                        size="large"
                                        placeholder="Masukkan Sub Judul Episode"
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="description"
                                    label="Deskripsi Video Singkat"
                                    style={{ marginBottom: 0 }}
                                    validateTrigger={['onChange', 'onBlur']}>
                                    <Input
                                        size="large"
                                        placeholder="Masukkan Deskripsi Video Singkat"
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="image"
                                    label="Gambar Thumbnail"
                                    style={{ marginBottom: 0 }}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Thumbnail wajib diisi',
                                        },
                                    ]}
                                    validateTrigger={['onChange', 'onBlur']}>
                                    <Upload
                                        listType="picture"
                                        customRequest={dummyRequest}
                                        accept="image/*"
                                        onPreview={() => {}}
                                        onChange={(info) => {
                                            const isLt2M =
                                                info.file.size / 1024 / 1024 <
                                                2;
                                            if (!isLt2M) {
                                                message.error(
                                                    'Thumbnail harus lebih kecil dari 2 MB!',
                                                );
                                            } else {
                                                setFileUpload(info.fileList);
                                            }
                                        }}
                                        fileList={fileUpload}
                                        defaultFileList={[...fileUpload]}>
                                        {fileUpload.length == 0 && (
                                            <Button icon={<UploadOutlined />}>
                                                Upload Thumbnail
                                            </Button>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="link_youtube"
                                    label="Link Youtube"
                                    style={{ marginBottom: 0 }}
                                    validateTrigger={['onChange', 'onBlur']}>
                                    <Input
                                        size="large"
                                        placeholder="Masukkan Link Youtube"
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item name="submit">
                                <div>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        shape="round"
                                        loading={isLoading}
                                        style={{ marginRight: 10, width: 200 }}
                                        disabled={isLoading ? true : false}>
                                        Simpan
                                    </Button>
                                    <Button
                                        shape="round"
                                        style={{ width: 200 }}
                                        onClick={() => {
                                            history.goBack();
                                        }}>
                                        Batal
                                    </Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
        </SubEpisodeCreateStyle>
    );
};

export default SubEpisodeCreate;
