import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Space, Table, Tag } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notifyError } from '../../../../utils';
import { formatMoney } from '../../../../utils/format-money';
import serviceOrderPpob from '../index.service';
import OrderPpobStyle from './index.style';
import moment from 'moment';

const { Search } = Input;

const OrderPpob = () => {
    const history = useHistory();
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
        sorts: {
            ['p.created_at']: 'desc',
        },
        filter: {
            user_name: '',
            order_id: '',
        },
    });
    const [dataOrderPpob, setDataOrderPpob] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceOrderPpob.getOrderPpob(resultQuery);
        if (result.isSuccess && result.response.payload.length > 0) {
            setDataOrderPpob(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleSearch = (selectedKeys, dataIndex) => {
        // confirm();
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
            sorts: {
                ['p.created_at']: 'desc',
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleResetFilter = () => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'ORDER',
        },
        {
            path: 'table',
            breadcrumbName: 'TABLE',
        },
    ];

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'Completed') {
            color = 'green';
        }
        if (status === 'Pending') {
            color = 'geekblue';
        }
        if (status === 'Cancelled') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status?.toUpperCase()}
            </Tag>
        );
    };

    const columns = [
        {
            title: 'No',
            width: 70,
            key: 'id',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Order Date',
            dataIndex: 'order_created ',
            key: 'order_created ',
            render: (_, record) => {
                return moment
                    .utc(record.created_at)
                    .format('DD-MM-YYYY HH:mm:ss');
            },
        },
        {
            title: 'Order ID',
            dataIndex: 'order_id',
            key: 'order_id',
        },
        {
            title: 'Nama User',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Nama Produk',
            dataIndex: 'ppob_name',
            key: 'ppob_name',
        },

        {
            title: 'Nomor VA',
            dataIndex: 'reference_number',
            key: 'reference_number',
            render: (value, record) => {
                return value || '-';
            },
        },
        {
            title: 'Nomor User',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Harga',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => {
                return formatMoney(record.price);
            },
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // filters: [
            //     { text: 'COMPLETE', value: 'Completed' },
            //     { text: 'PENDING', value: 'Pending' },
            //     { text: 'CANCEL', value: 'Cancelled' },
            // ],
            // filteredValue: queryString?.filters?.status || null,
            // onFilter: (value, record) => record.status.includes(value),
            ellipsis: true,
            render: (status) => handleLabelStatus(status),
        },

        {
            title: 'Detail Order',
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Button
                            onClick={() =>
                                history.push(`/ppob/order/${record.id}`)
                            }
                            style={{ width: '100%' }}
                            type="dashed">
                            Detail
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.queries.page = 1;
        if (value.includes('PPOB')) {
            resultQuery.filter['order_id'] = value;
        } else if (!value) {
            resultQuery.filter['order_id'] = value;
            resultQuery.filter['user_name'] = value;
        } else {
            resultQuery.filter['user_name'] = value;
        }
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <OrderPpobStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="PPOB"
                    breadcrumb={{ routes }}
                    subTitle="LIST TABLE ORDER "
                />
                <div className="btn-top-table">
                    <div className="container-search">
                        <Search
                            placeholder="Search by order id / user name"
                            onSearch={(value) => onSearchByName(value)}
                            enterButton
                            allowClear
                        />
                    </div>
                </div>
            </div>
            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataOrderPpob.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataOrderPpob.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </OrderPpobStyle>
    );
};

export default OrderPpob;
