import serviceApi from '../../services/index';

const serviceOrderList = {
  getOrderList: async (params) => {
    return await serviceApi({
      method: 'GET',
      url: `/orders`,
      version: 'v1',
      token: true,
      params: params,
    });
  },
};

export default serviceOrderList;
