import serviceApi from '../../../services/index';

const serviceProductList = {
    getProductList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v2/products`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        })
    },
    getMerchantList: async (params) => {
        return await serviceApi({
            method: 'GET',
            url: `v1/merchants/names`,
            noVersion: true,
            appType: 1,
            token: true,
            params: params,
        });
    },

    deleteProduct: async (params) => {
        return await serviceApi({
            method: 'DELETE',
            url: `v1/products/${params}`,
            noVersion: true,
            appType: 1,
            token: true,
        });
    },
};

export default serviceProductList;
